import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import SpinnerText from "../../common/spinnerwithText";
import fire from "../../../services/fire";
import { getCurrentUser } from "../../../services/user";
import { getBrowser } from "../../../services/getbrowser";
import { Link } from "react-router-dom";

class ReportBug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
      component: this.props.component,
      context: this.props.context,
      rendertime: this.props.rendertime,
      message: this.props.message,
      loading: true,
      submitting: false,
      children: this.props.children,
      userinput: "",
      modalobj: { showmodal: false, state: "ready", title: "Report a Problem" },
    };
  }

  handleClose = () => {
    this.setState({
      modalobj: { showmodal: false },
      updating: false,
      submitting: false,
    });
  };

  getModalObj = () => {
    let modalobj = this.state.modalobj;
    let title = "";
    if (modalobj.state === "ready") {
      title = "Report a Problem";
    } else {
      title = "We have received your report";
    }
    modalobj.title = title;
    return modalobj;
  };

  showModal = () => {
    const { updating, error } = this.state;
    let modalobj = this.getModalObj();
    let formClass = "form-control form-control-lg ";
    if (error) {
      formClass = `${formClass} is-invalid`;
    }
    let body = (
      <div className="form-group">
        <label htmlFor="report" className="defaultfontsize">
          Describe the issue you encountered:
        </label>

        <textarea
          placeholder="Ex. The mass timings are all wrong..."
          name="report"
          id="report"
          className={formClass}
          onChange={this.handleChange}
          value={this.state.userinput}
        />
        {error && (
          <small className="text-danger">
            *Please provide brief description
          </small>
        )}
      </div>
    );
    let footer = (
      <>
        <Button
          disabled={updating}
          variant="outline-secondary"
          className="mr-2"
          onClick={this.handleClose}
        >
          Cancel
        </Button>
        {updating !== true && (
          <Button variant="danger" onClick={() => this.handleReport()}>
            Send Report
          </Button>
        )}
        {updating === true && (
          <Button disabled variant="danger" onClick={() => {}}>
            <SpinnerText text="Please wait..." />
          </Button>
        )}
      </>
    );
    if (modalobj.state === "reported") {
      body = (
        <div className="text-left">
          <p>Thank you for reporting a problem.</p>
          <p>Every report matters to us.</p>
          <p>Please give us time to fix the issue.</p>
          <p>
            We apologise we are not able to reply to everyone who submits a
            report.
          </p>
          <p>
            Please check our <Link to="/faq">FAQ here</Link> or{" "}
            <Link to="/schedule">Schedule here</Link>.
          </p>
          <p>
            For urgent matters, please email{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:mycatholicsg@catholic.org.sg`}
            >
              mycatholicsg@catholic.org.sg
            </a>
          </p>
        </div>
      );
      footer = (
        <>
          <Button variant="outline-secondary" onClick={this.handleClose}>
            Close
          </Button>
        </>
      );
    }
    return (
      <Modal
        show={modalobj.showmodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="text-danger font-weight-bold">
            {modalobj.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">{body}</Modal.Body>
        <Modal.Footer>
          <div className="mx-auto">{footer}</div>
        </Modal.Footer>
      </Modal>
    );
  };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ userinput: value });
  };

  handleReport = async () => {
    const input = this.state.userinput;
    if (input === "") {
      this.setState({ error: "Empty" });
      return;
    }
    const browser = await getBrowser();

    this.setState({ browser, updating: true });

    const curUser = await getCurrentUser();
    const context = this.state.context;
    const uid = curUser.uid;
    //we don't need this
    delete context.user;
    delete context.smallpic;
    let name = browser?.name ? browser.name : "?";
    let version = browser?.version ? browser.version : "?";
    const bugobj = {
      name: curUser.displayName,
      email: curUser.email,
      browser: { name, version },
      context: context,
      rendertime: this.state.rendertime,
      userid: uid,
      page: this.state.page,
      bugstatus: "new",
      component: this.state.component,
      userinput: this.state.userinput.substr(0, 500),
      timestamp: new Date().getTime(),
      useragent: navigator.userAgent,
    };

    //save to bugreports collection
    fire
      .firestore()
      .collection("bugreports")
      .add(bugobj)
      .then((docRef) => {
        this.setState({
          updating: false,
          modalobj: { state: "reported", showmodal: true },
        });
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        this.setState({
          updating: false,
          modalobj: { state: "reported", showmodal: true },
        });
      });
  };

  display(type) {
    if (type === "line") {
      return (
        <div
          className="text-left px-0 my-0 pt-1 pb-1"
          style={{ fontSize: "14px", padding: "20px 0px" }}
        >
          {/* {state === "reported" ? (
            <div className="text-center">
              Please note we will not reply to every individual report. Thank
              you for helping us improve MyCatholic.SG!
            </div>
          ) : ( */}
          <>
            <div style={{ lineHeight: "1.8em", fontSize: "14px" }}>
              Found a problem? Please help us report the problem so we can fix
              it quickly.
              <span>
                <Button
                  variant="link"
                  className="btn btn-sm px-1 py-0"
                  style={{ marginTop: "-4px" }}
                  onClick={() =>
                    this.setState({
                      submitting: false,
                      modalobj: { showmodal: true, state: "ready" },
                    })
                  }
                >
                  <div className="text-center">Report Problem</div>
                </Button>
              </span>
            </div>
          </>
          {/* )} */}
          {this.showModal()}
        </div>
      );
    }
    let reported = false;
    return (
      <div
        className="card alert-warning px-3 my-4"
        style={{ fontSize: "14px", padding: "20px 0px" }}
      >
        {reported ? (
          <div className="text-center font-weight-bold">
            Thank you for helping us improve MyCatholic.SG!
          </div>
        ) : (
          <>
            <div>
              <b>Mass timing issue?</b> <br />
              Please help us report the issue if you encounter incorrect Mass
              timings.
            </div>
            <div className="float-right">
              <Button
                variant="outline-danger"
                className="float-right mt-4 btn btn-sm"
                style={{ width: "200px" }}
                onClick={() =>
                  this.setState({
                    submitting: false,
                    modalobj: { showmodal: true },
                  })
                }
              >
                <div className="text-center">Report Issue</div>
              </Button>
            </div>
          </>
        )}
        {this.showModal()}
      </div>
    );
  }

  render() {
    // const type = "line";
    // return this.display(type);
    return null;
  }
}

export default ReportBug;
