import {
  ACTION_ADD,
  ACTION_NTHWEEK,
  ACTION_OVERRIDE,
  ACTION_REMOVE,
  ACTION_WEEKLY,
  getAdminScheduleMaxDays,
  getEventTypeColor,
} from "../core/massschedule/utils/const";
import {
  convertTime12to24,
  getDatesOfDayOfWeek,
  getFirstDayOfNextMonth,
  getLastDayOfMonth,
  getTotalDaysInMonth,
  mapDayToNum,
} from "./date";

export function prepareInput(schedule) {
  const weekly = [];
  const nthday = [];
  const override = [];
  const add = [];
  const remove = [];
  schedule.forEach((entry) => {
    if (entry.action === ACTION_WEEKLY) {
      weekly.push(entry);
    } else if (entry.action === ACTION_NTHWEEK) {
      nthday.push(entry);
    } else if (entry.action === ACTION_OVERRIDE) {
      override.push(entry);
    } else if (entry.action === ACTION_ADD) {
      add.push(entry);
    } else if (entry.action === ACTION_REMOVE) {
      remove.push(entry);
    }
  });
  return {
    step1: [...weekly, ...nthday], //process first
    step2: [...override, ...add], //process second
    step3: [...remove],
  };
}

export function createScheduleObj(
  schedule,
  showall,
  currentDate,
  startDate,
  days,
  type = "frontend"
) {
  if (!schedule) {
    return null;
  }
  //let us get only maximum 36 days
  const maxDays = days; // > getMaxDays() ? getMaxDays() : days;
  //get end date
  const endDate = new Date(currentDate);
  endDate.setDate(endDate.getDate() + maxDays);
  //process "weekly" and "nthweek"
  let entries = processStep1(
    schedule.step1,
    showall,
    currentDate,
    startDate,
    endDate,
    maxDays,
    type
  );
  //process "override" and "add"
  entries = processStep2(schedule.step2, entries);
  //process "remove"
  entries = processStep3(schedule.step3, entries);
  //try to sort?
  return entries;
}

function processStep1(
  schedule,
  showall,
  currentDate,
  startDate,
  endDate,
  maxDays,
  type = "frontend"
) {
  const entries = processEntries(
    schedule,
    showall,
    currentDate,
    startDate,
    endDate
  );

  if (!showall) {
    //if showall === false, must have specify how many days to view
    //get how many days where computed
    //if did not reach the requested days, get values for next month
    const lastDay = getLastDayOfMonth(currentDate);
    const diff = lastDay.getTime() - currentDate.getTime();
    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
    let remainingDays = maxDays - diffDays;
    let totalDays = 0;
    if (remainingDays > 0) {
      // console.log(startDate, "=>", totalDays);

      const nextMonth = getFirstDayOfNextMonth(startDate);
      totalDays = getTotalDaysInMonth(nextMonth);
      const remaining = processEntries(
        schedule,
        showall,
        nextMonth,
        nextMonth,
        endDate
      );

      entries.push(...remaining);
    }
    if (type === "admin") {
      let processedDays = remainingDays - totalDays;

      let nDate = startDate;
      //start with next month
      nDate.setMonth(nDate.getMonth() + 1);

      //make sure this does not exceed the maximum in admin to avoid memory issues
      while (processedDays >= 0 && processedDays < getAdminScheduleMaxDays()) {
        const nextMonth = getFirstDayOfNextMonth(nDate);
        let totalDays = getTotalDaysInMonth(nextMonth);
        processedDays = processedDays - totalDays;
        nDate.setMonth(nDate.getMonth() + 1);
        const remaining = processEntries(
          schedule,
          showall,
          nextMonth,
          nextMonth,
          endDate
        );

        entries.push(...remaining);
      }
    }
  }
  return entries;
}

function processStep2(schedule, entries) {
  const results = [];
  //process override
  //get all override
  const override = schedule.filter((i) => i.action === ACTION_OVERRIDE);
  const matches = [];
  const additional = [];
  //get how many matches to remove
  override.forEach((sched) => {
    //do override
    let [match, obj] = processOverride(sched, entries);
    if (match?.length) matches.push(...match);
    additional.push(obj);
  });

  //remove based on matches
  const updated = new Map();
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    if (isFoundInMatch(entry, matches)) {
      //if current entry is found in the "matches" to remove, SKIP
      continue;
    } else {
      if (!updated.has(entry.id)) {
        //if not matched, then need to display
        updated.set(entry.id, entry);
      }
    }
  }
  //get all the items to display later
  updated.forEach((value) => {
    results.push(value);
  });
  additional.forEach((i) => results.push(i));
  //then add
  //process add
  schedule.forEach((sched) => {
    if (sched.action === ACTION_ADD) {
      //do add
      const result = processAddition(sched);

      results.push(result);
    }
  });
  //if schedule.length === 0, then no override, result
  return !schedule.length ? entries : results;
}

function processStep3(schedule, entries) {
  const results = [];
  //process remove
  //get all remove
  const remove = schedule.filter((i) => i.action === ACTION_REMOVE);
  const matches = [];
  //get how many matches to remove
  remove.forEach((sched) => {
    //do remove
    const [match] = processRemove(sched, entries);
    if (match?.length) matches.push(...match);
  });
  //remove based on matches
  const updated = new Map();
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    if (isFoundInMatchToRemove(entry, matches)) {
      //if current entry is found in the "matches" to remove, SKIP
      continue;
    } else {
      if (!updated.has(entry.id)) {
        //if not matched, then need to display
        updated.set(entry.id, entry);
      }
    }
  }
  //get all the items to display later
  updated.forEach((value) => {
    results.push(value);
  });

  //if schedule.length === 0, then no override, result
  return !schedule.length ? entries : results;
}

function isFoundInMatch(item, matches) {
  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    const itemStart = new Date(item.start);
    const matchStart = new Date(match.start);
    if (
      itemStart.getFullYear() === matchStart.getFullYear() &&
      itemStart.getMonth() === matchStart.getMonth() &&
      itemStart.getDate() === matchStart.getDate()
    ) {
      return true;
    }
  }
  return false;
}

function isFoundInMatchToRemove(item, matches) {
  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    const itemStart = new Date(item.start);
    const matchStart = new Date(match.start);
    if (
      itemStart.getFullYear() === matchStart.getFullYear() &&
      itemStart.getMonth() === matchStart.getMonth() &&
      itemStart.getDate() === matchStart.getDate() &&
      itemStart.getHours() === matchStart.getHours() &&
      itemStart.getMinutes() === matchStart.getMinutes() &&
      item.type === match.type
    ) {
      return true;
    }
  }
  return false;
}

function processSchedule(entry, showall, currentDate, startDate, endDate) {
  const results = [];
  if (entry.action === ACTION_WEEKLY) {
    //populate all specified days "Sundays" at 8am, Mandarin, Main Church, Notes, tag
    results.push(
      ...processWeekly(showall, entry, currentDate, startDate, endDate)
    );
  } else if (entry.action === ACTION_NTHWEEK) {
    //1st Sunday|8:00am|Mandarin|Main Church|Notes|tag
    results.push(
      ...processNthDay(showall, entry, currentDate, startDate, endDate)
    );
  }

  return results;
}

function processWeekly(showall, elem, currentDate, startDate, endDate) {
  const day = mapDayToNum(elem.text.toLowerCase());
  //this returns an array of dates for the specified day
  //if day is 0 (Sunday), returns array of all the Sundays of the month
  const daysNum = getDatesOfDayOfWeek(
    startDate.getFullYear(),
    startDate.getMonth(),
    day
  );

  const days = [];

  for (let i = 0; i < daysNum.length; i++) {
    const exactDay = daysNum[i];
    //looping through days in month
    if (showall) {
      days.push(getScheduleObj(elem, startDate, exactDay, day));
    } else {
      const result = getScheduleinRangeObj(
        elem,
        currentDate,
        startDate,
        endDate,
        exactDay,
        day
      );
      if (result) {
        days.push(result);
      }
    }
  }
  return days;
}

function processNthDay(showall, elem, currentDate, startDate, endDate) {
  //"1st", "thursday"
  const items = elem.text.toLowerCase().split(" ");
  const day = mapDayToNum(items[1]);

  const daysNum = getDatesOfDayOfWeek(
    startDate.getFullYear(),
    startDate.getMonth(),
    day
  );

  const week = parseInt(items[0]);
  //make sure we dont get 5th Sunday when month has 4 Sundays
  const exactDay = week <= daysNum.length ? daysNum[week - 1] : 0;
  const days = [];
  if (exactDay) {
    if (showall) {
      days.push(getScheduleObj(elem, startDate, exactDay, day));
    } else {
      const result = getScheduleinRangeObj(
        elem,
        currentDate,
        startDate,
        endDate,
        exactDay,
        day
      );
      if (result) {
        days.push(result);
      }
    }
  }
  return days;
}

function processOverride(schedule, entries) {
  return processExtras(schedule, entries);
}

function processRemove(schedule, entries) {
  const currentDate = new Date(schedule.text);
  const timing = convertTime12to24(schedule.time).split(":");
  const match = [];
  currentDate.setHours(timing[0], timing[1]);
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    const entryDate = new Date(entry.start);
    if (
      currentDate.getFullYear() === entryDate.getFullYear() &&
      currentDate.getMonth() === entryDate.getMonth() &&
      currentDate.getDate() === entryDate.getDate() &&
      currentDate.getHours() === entryDate.getHours() &&
      currentDate.getMinutes() === entryDate.getMinutes() &&
      schedule.type === entry.type
    ) {
      match.push(entry);
    }
  }
  return [match];
}

function processExtras(schedule, entries) {
  const currentDate = new Date(schedule.text);
  const timing = convertTime12to24(schedule.time).split(":");
  const match = [];
  currentDate.setHours(timing[0], timing[1]);

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    const entryDate = new Date(entry.start);
    if (
      currentDate.getFullYear() === entryDate.getFullYear() &&
      currentDate.getMonth() === entryDate.getMonth() &&
      currentDate.getDate() === entryDate.getDate()
    ) {
      match.push(entry);
    }
  }
  //add the override value here
  const obj = createSchedObj(currentDate, currentDate.getDay(), schedule);
  return [match, obj];
}

function processAddition(schedule) {
  const currentDate = new Date(schedule.text);
  let timing;
  if (schedule.time === "12:00 MN") {
    //hack to make sure we don't touch 12:00 MN as display
    //always use 11:59 PM
    timing = convertTime12to24("11:59 PM").split(":");
  } else {
    timing = convertTime12to24(schedule.time).split(":");
  }
  currentDate.setHours(timing[0], timing[1]);
  const day = currentDate.getDay();

  //add the override value here
  return createSchedObj(currentDate, day, schedule);
}

function getScheduleObj(elem, startDate, exactDay, day) {
  let days = null;
  const newDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    exactDay
  );
  if (newDate.getDay() === day) {
    days = createSchedObj(newDate, day, elem);
  }
  return days;
}

function getScheduleinRangeObj(
  elem,
  currentDate,
  startDate,
  endDate,
  exactDay,
  day
) {
  let days = null;
  const newDate = new Date(currentDate);

  newDate.setDate(exactDay);
  let timing = "";
  if (elem.time === "12:00 MN") {
    //make sure we don't touch 12:00 MN as display
    //always use 11:59 PM
    timing = convertTime12to24("11:59 PM").split(":");
  } else {
    timing = convertTime12to24(elem.time).split(":");
  }
  newDate.setHours(timing[0], timing[1]);
  //show only from specified startDate to endDate
  if (newDate >= startDate && newDate <= endDate && newDate.getDay() === day) {
    days = createSchedObj(newDate, day, elem);
  }

  return days;
}

function processEntries(schedule, showall, currentDate, startDate, endDate) {
  const entries = [];

  schedule.forEach((entry) => {
    //process remaining days, starting from nextMonth
    const obj = processSchedule(
      entry,
      showall,
      currentDate,
      startDate,
      endDate
    );
    entries.push(...obj);
  });
  return entries;
}

function createSchedObj(date, day, entry) {
  const { id, time, lang, location, notes, type, parish } = entry;
  const timestamp = date.getTime();
  const start = date;
  const end = new Date(date);
  end.setMinutes(date.getMinutes());
  //we force end time to be xx:xx:59 seconds so our calendar will not overlap;
  //meaning, it will not cross to the next day
  end.setSeconds(59);
  const eventProp = getEventTypeColor(type);
  return {
    id: `${parish}${type.replace(/\s/g, "")}${timestamp}`,
    refid: id,
    parish: parish,
    timestamp: timestamp,
    location: location,
    lang: lang,
    notes: notes,
    start: start.getTime(),
    title: time,
    time: time,
    date: date.getTime(),
    type: type,
    day: day,
    end: end.getTime(),
    colorEvento: eventProp.background,
    color: eventProp.color,
    abbrev: eventProp.abbrev,
    //add the rest here...
  };
}
