import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";

import Form from "../../common/form";

class RegistrationFormMiscellaneous extends Form {
	constructor(props) {
		super(props);

		const { draft } = props;

		let hasSiblings = "";

		if (draft.hasOwnProperty("hasSiblings")) {
			hasSiblings = draft.hasSiblings.toString();
		}

		this.state = {
			data: {
				hasSiblings: hasSiblings || "",
				siblingInformation: draft.siblingInformation || "",
				additionalInfo: draft.additionalInfo || "",
			},
			errors: {},
		};
	}

	schema = {
		siblingInformation: Joi.string().trim().required().label("Siblings Full Name and Class Level"),
		additionalInfo: Joi.string().trim().required().label("Additional Information"),
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleRadioChange = (type) => ({ currentTarget: input }) => {
		const { data } = this.state;
		const newData = { ...data };

		if (type === "hasSiblings") {
			if (input.value.toString() === "false") {
				newData.siblingInformation = "N/A";
			} else {
				newData.siblingInformation = "";
			}
		}

		newData[type] = input.value;

		this.setState({ data: newData });
	}

	doSubmit = async () => {
		const { setRegistrationMiscellaneousDetails } = this.props;
		const { data } = this.state;
		const {
			hasSiblings = "",
			siblingInformation = "",
			// additionalInfo = "", // optional
		} = data;

		if (hasSiblings && siblingInformation) {
			setRegistrationMiscellaneousDetails(data);
		}
	}

	renderNextButton = () => {
		const { data } = this.state;
		const {
			hasSiblings = "",
			siblingInformation = "",
			// additionalInfo = "", // optional
		} = data;

		let isDisabled = true;

		if (hasSiblings && siblingInformation) {
			isDisabled = false;
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled }
			>
				Next Section: Parent's Details
			</Button>
		);
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;

		saveDraft(data);
	}

	handlePressBack = () => {
		const { pressBack } = this.props;

		pressBack();
	}

	render() {
		const { data } = this.state;

		return (
			<div>
				<strong>Miscellaneous Details</strong>
				<br />
				<br />
				<React.Fragment>
					<form onSubmit={ this.handleSubmit}>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								1
							</div>
							<div className="ml-3">
								<div>
									<strong>Does your child have siblings attending catechesis at the same parish?</strong>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="hasSiblings"
										type="radio"
										id="hasSiblings1"
										value={ true }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("hasSiblings") }
										checked={ data.hasSiblings === "true" }
									/>
									<label className="form-check-label ml-2" htmlFor="hasSiblings1">Yes</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="hasSiblings"
										type="radio"
										id="hasSiblings2"
										value={ false }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("hasSiblings") }
										checked={ data.hasSiblings === "false" }
									/>
									<label className="form-check-label ml-2" htmlFor="hasSiblings2">No</label>
								</div>
							</div>
						</div>
						<div className="d-flex">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								2
							</div>
							<div className="container">
								<div>
									<strong>Name and Class Level of Sibling</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"siblingInformation",
										"",
										"text",
										"e.g. Jayne Doe, L1B",
										data.hasSiblings === "false",
									)}
									<p style={{ marginTop: "-16px", marginBottom: "0px" }}>Class not required for siblings also registering this year</p>
								</div>
							</div>
						</div>
						<br />
						<div className="d-flex">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								3
							</div>
							<div className="container">
								<div>
									<strong>Do you have any additional information to share with us? (Optional)</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderTextarea(
										"additionalInfo",
										"",
										"text",
										// "e.g. Medical / Allergy / Diet / Family Issues / Special Needs / etc."
									)}
									<p style={{ marginTop: "-16px", marginBottom: "0px" }}>e.g. Medical / Allergy / Diet / Family Issues / Special Needs / NA etc.</p>
								</div>
							</div>
						</div>
					</form>
					<br />
					<br />
					{ this.props.coordinatorEmail ?
						<>
							If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
							<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
							<br />
							<br />
						</> : null
					}
					{ this.renderNextButton() }
					<Button className="btn-block mb-3" variant="outline-primary" size="lg"
						onClick={ this.handlePressBack }>
						Back
					</Button>
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		);
	}
}

export default RegistrationFormMiscellaneous;