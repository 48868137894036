import React, { useContext, useEffect, useRef } from "react";
import fire from "../../../services/fire";
import "../stylesheets/vendor-page.css";
import Table from "../../common/table";
import FinanceContext from "./financeContext";
import AssignmentsModal from "./AssignmentsModal";
import { VENDOR_COLUMNS } from "../reducers/vendorReducer";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import { Modal } from "react-bootstrap";

const VendorAssignmentPage = () => {
  const {
    vendorDashboardState,
    vendorDashboardDispatch,
    financeDashboardState,
  } = useContext(FinanceContext);
  const {
    searchKey,
    volunteerList,
    isVolunteerListReady,
    isConfirmModalOpen,
    addVolunteerField,
    isBtnLoading,
    isError,
    confirmModalMessage,
    confirmCallback,
  } = vendorDashboardState;
  const { org } = financeDashboardState;
  const vendorDashboardStateRef = useRef();
  vendorDashboardStateRef.current = vendorDashboardState;

  const closeConfirmationModal = () => {
    vendorDashboardDispatch({ type: "RESET_ADD_VOLUNTEER_STATE" });
  };
  const addVolunteer = async () => {
    const currentState = vendorDashboardStateRef.current;
    vendorDashboardDispatch({
      type: "SET_ADD_VOLUNTEER_STATE",
      payload: {
        currentState: currentState,
      },
    });
    vendorDashboardDispatch({
      type: "SET_IS_BTN_LOADING",
      payload: {
        isBtnLoading: true,
      },
    });
    const addVolunteerByEmail = fire
      .functions("asia-east2")
      .httpsCallable("addVendorVolunteer");
    const result = await addVolunteerByEmail({
      email: addVolunteerField,
      orgId: org?.orgId,
    });
    if (result?.data?.status === 1 || result?.data?.status === 2) {
      console.log("success");
      closeConfirmationModal();
      updateVolunteers();
    } else {
      const nextCurrentState = vendorDashboardStateRef.current;
      vendorDashboardDispatch({
        type: "SET_ADD_VOLUNTEER_STATE",
        payload: {
          currentState: nextCurrentState,
        },
      });
      vendorDashboardDispatch({
        type: "SET_CONFIRM_MODAL_MESSAGE",
        payload: {
          confirmModalMessage:
            "An error has occurred. Please check whether the volunteer account email has been entered correctly.",
        },
      });
      vendorDashboardDispatch({
        type: "SET_IS_ERROR",
        payload: {
          isError: true,
        },
      });
    }
  };
  const modifyVolunteer = async (membershipId, shouldEnable) => {
    const currentState = vendorDashboardStateRef.current;
    const currentVolunteerList = currentState.volunteerList;
    const loadingVolunteerList = [];
    currentVolunteerList.forEach((volunteer) => {
      const actionDisabledVolunteer = {
        ...volunteer,
        action: "Loading...",
      };
      loadingVolunteerList.push(actionDisabledVolunteer);
    });
    vendorDashboardDispatch({
      type: "SET_VOLUNTEER_LIST",
      payload: { volunteerList: loadingVolunteerList },
    });

    const modifyVolunteerById = fire
      .functions("asia-east2")
      .httpsCallable("modifyVendorVolunteer");
    const result = await modifyVolunteerById({
      membershipId,
      shouldEnable,
    });
    if (result?.data?.status === 1) {
      updateVolunteers();
    } else {
      vendorDashboardDispatch({
        type: "SET_VOLUNTEER_LIST",
        payload: { volunteerList: currentVolunteerList },
      });
    }
  };
  const updateVolunteers = () => {
    retrieveVolunteers((volunteerList) => {
      vendorDashboardDispatch({
        type: "SET_VOLUNTEER_LIST",
        payload: { volunteerList: volunteerList },
      });
    });
  };
  const retrieveVolunteers = (cb) => {
    fire
      .firestore()
      .collection("membership")
      .where("membershipType", "==", "EVENT_VENDOR")
      .where("orgId", "==", org?.orgId)
      .get()
      .then((resultSnapshot) => {
        const volunteerList = [];
        if (resultSnapshot.docs.length > 0) {
          resultSnapshot.docs.forEach((doc) => {
            const memberObj = doc.data();
            volunteerList.push({
              name: memberObj.name ?? "-",
              userid: memberObj.userid,
              email: memberObj.email,
              contact: memberObj.contact,
              subid: memberObj.subid,
              isActive: memberObj.isActive ? "Active" : "Disabled",
              created: memberObj.created,
              action: memberObj.isActive ? (
                <div className="vendor-page__actions-container">
                  <ClearIcon
                    className="vendor-page__table-view-icon"
                    onClick={() =>
                      modifyVolunteer(memberObj.id, !memberObj.isActive)
                    }
                  />
                  <InfoIcon
                    className="vendor-page__table-view-icon"
                    onClick={() => {
                      vendorDashboardDispatch({
                        type: "SET_ASSIGNMENTS_MODAL_OPEN",
                        payload: {
                          isAssignmentsModalOpen: true,
                        },
                      });
                      vendorDashboardDispatch({
                        type: "SET_SELECTED_VENDOR_UID",
                        payload: {
                          selectedVendorUid: memberObj.userid,
                        },
                      });
                    }}
                  />
                </div>
              ) : (
                <div className="vendor-page__actions-container">
                  <DoneIcon
                    className="vendor-page__table-view-icon"
                    onClick={() =>
                      modifyVolunteer(memberObj.id, !memberObj.isActive)
                    }
                  />
                  <InfoIcon
                    className="vendor-page__table-view-icon"
                    onClick={() => {
                      vendorDashboardDispatch({
                        type: "SET_ASSIGNMENTS_MODAL_OPEN",
                        payload: {
                          isAssignmentsModalOpen: true,
                        },
                      });
                      vendorDashboardDispatch({
                        type: "SET_SELECTED_VENDOR_UID",
                        payload: {
                          selectedVendorUid: memberObj.userid,
                        },
                      });
                    }}
                  />
                </div>
              ),
            });
          });
        }
        cb(volunteerList);
      });
  };
  useEffect(() => {
    retrieveVolunteers((newVolunteerList) => {
      vendorDashboardDispatch({
        type: "SET_VOLUNTEER_LIST",
        payload: { volunteerList: newVolunteerList },
      });
      vendorDashboardDispatch({
        type: "SET_READY",
      });
    });
  }, []);

  return (
    <>
      <AssignmentsModal />
      <Modal
        show={isConfirmModalOpen}
        onHide={closeConfirmationModal}
        style={{ zIndex: "1070" }}
      >
        <Modal.Header>
          <h4>{isError ? "Error" : "Confirmation"}</h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="mb-4">{confirmModalMessage}</div>
            {isError ? (
              <div className="d-flex justify-content-end">
                <div
                  className="btn btn-secondary"
                  onClick={closeConfirmationModal}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between">
                <div
                  className="btn btn-secondary"
                  onClick={closeConfirmationModal}
                >
                  Cancel
                </div>
                <div
                  className="btn ml-2"
                  style={{
                    backgroundColor: isBtnLoading ? "#6c757d" : "#0347ad",
                    color: "#ffffff",
                  }}
                  onClick={() => (isBtnLoading ? {} : confirmCallback())}
                >
                  {isBtnLoading ? "Loading..." : "Confirm"}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <div className="vendor-page__container">
        <div className="vendor-page__header">Vendor Assignment</div>
        <div className="vendor-page__table-controls">
          <div className="d-flex">
            <input
              placeholder="Add Volunteer (Email)"
              className="form-control"
              value={addVolunteerField}
              onChange={(e) => {
                vendorDashboardDispatch({
                  type: "SET_ADD_VOLUNTEER_FIELD",
                  payload: { addVolunteerField: e.target.value },
                });
              }}
            />
            <div
              className="btn py-0 ml-1 mr-4 flex flex-column justify-content-center"
              style={{
                height: "38px",
                backgroundColor:
                  addVolunteerField === "" ? "#6c757d" : "#0347ad",
                color: "#ffffff",
              }}
            >
              <AddIcon
                className="my-1"
                onClick={() => {
                  if (addVolunteerField !== "") {
                    vendorDashboardDispatch({
                      type: "SET_IS_CONFIRM_MODAL_OPEN",
                      payload: {
                        isConfirmModalOpen: true,
                      },
                    });
                    vendorDashboardDispatch({
                      type: "SET_CONFIRM_MODAL_MESSAGE",
                      payload: {
                        confirmModalMessage: `Are you sure you would like to add ${addVolunteerField} as a volunteer?`,
                      },
                    });
                    vendorDashboardDispatch({
                      type: "SET_CONFIRM_CALLBACK",
                      payload: {
                        confirmCallback: addVolunteer,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="vendor-page__table-filters"></div>
          <div className="vendor-page__table-controls-section">
            <input
              className="vendor-page__search-input"
              placeholder="Search ..."
              value={searchKey}
              onChange={(e) => {
                vendorDashboardDispatch({
                  type: "SET_SEARCH_KEY",
                  payload: {
                    searchKey: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="vendor-page__table-body">
          <Table
            columnProps={VENDOR_COLUMNS}
            isLoading={!isVolunteerListReady}
            dataProps={volunteerList}
            filterProps={searchKey}
          />
        </div>
      </div>
    </>
  );
};

export default VendorAssignmentPage;
