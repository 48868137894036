import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncViewAllBulletin({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/viewAllBulletin"));
  console.log("Loading", AsyncViewAllBulletin.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncViewAllBulletin;
