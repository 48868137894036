import MomentTZ from "moment-timezone";

/** @typedef {import("firebase").firestore.Timestamp} Timestamp */

/**
 * @param {Timestamp | { _seconds: number }} timestamp
 */
export function tsToMoment(timestamp) {
  if (
    timestamp == null ||
    timestamp === "" ||
    (typeof timestamp === "object" && Object.keys(timestamp).length === 0)
  ) {
    return null;
  }

  let moment;

  if ("_seconds" in timestamp) {
    const time = new Date(1970, 0, 1);

    time.setSeconds(timestamp._seconds);

    moment = MomentTZ(time);
  } else {
    moment = MomentTZ(timestamp.toDate());
  }

  return moment;
}

/**
 * @param {Timestamp | { _seconds: number }} timestamp
 */
function formatTimestamp(timestamp) {
  const moment = tsToMoment(timestamp);

  if (!moment) return moment;

  return moment.tz("Asia/Singapore").format("DD/MM/YY h:mm a");
}

export default formatTimestamp;
