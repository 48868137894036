import { getSchedulesFromDB } from "../utils/utils";

export async function getAllChurchesSchedules(parishlist, sorted = true) {
  const schedules = [];
  const types = [];
  await Promise.all(
    parishlist.map(async (parish) => {
      //if we are getting all, just get 5 days only
      const [obj, type] = await getSchedulesFromDB(parish.link, sorted, 5);
      if (obj || obj?.length > 0) {
        types.push(...type);
        schedules.push(...obj);
      }
    })
  );
  return [schedules, [...new Set(types)]];
}
