import CoworkLandingView from "../view/CoworkLandingView";
import RoomSchedules from "../view/RoomSchedules";
import ReviewRoomBooking from "../view/ReviewRoomBooking";
import SuccessRoomBooking from "../view/SuccessRoomBooking";
import bookingPayment from "../view/bookingPayment";

import MultiViewMux from "../../common/MultiViewMux";

/**
 * Controller to switch between different views for Commune Booking.
 * @returns A specific view, determined by the `view` variable.
 */
export default function CoworkBookingPage(props) {
  const viewComponentRef = {
    landing: CoworkLandingView,
    roomSchedules: RoomSchedules,
    review: ReviewRoomBooking,
    payment: bookingPayment,
    success: SuccessRoomBooking,
  };

  const initialView = { name: "landing", props: {} };

  return MultiViewMux({
    viewComponentRef,
    initialView,
    commonProps: props,
  });
}
