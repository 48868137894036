import React, { useState } from "react";
import {
  getLocalTime,
  getRoomBookingWithDateHandler,
} from "../../../utils/utils";
import MainMenuCardSimplePanel from "../../blocks/mainMenuCardPanelSimpleBlock";
import ReportBug from "../../report/page/reportBug";
import { Button } from "react-bootstrap";

/**
 * @type {{ id: string, short: string, full: string }[]}
 */
const monthNames = [
  { id: "jan", short: "Jan", full: "January" },
  { id: "feb", short: "Feb", full: "February" },
  { id: "mar", short: "Mar", full: "March" },
  { id: "apr", short: "Apr", full: "April" },
  { id: "may", short: "May", full: "May" },
  { id: "jun", short: "Jun", full: "June" },
  { id: "jul", short: "Jul", full: "July" },
  { id: "aug", short: "Aug", full: "August" },
  { id: "sep", short: "Sep", full: "September" },
  { id: "oct", short: "Oct", full: "October" },
  { id: "nov", short: "Nov", full: "November" },
  { id: "dec", short: "Dec", full: "December" },
];

export default function BookingSchedules(props) {
  const { user, setView, bookerRoleDoc } = props;
  const orgId = "38";
  const [state, setState] = useState({
    menutype: "light",
    data: {
      fullname: "",
      email: "",
      mobile: "",
      dob: "",
      identification: "",
      parish: 0,
      parishcode: "",
      orgId,
      lastmassbooking: "",
    },
    user,
    loading: true,
  });

  const getMainMenuPanels = (orgId) => {
    // Show prev month and next 6 months
    const curDate = new Date();
    const curMonth = curDate.getMonth();
    const curYear = curDate.getFullYear();
    const handlers = [];

    for (let monthOffset = 0; monthOffset <= 6; monthOffset++) {
      const year = curYear + (((curMonth + monthOffset) / 12) >> 0); // Integer div
      const month = (curMonth + monthOffset) % 12;
      const handler = getRoomBookingWithDateHandler(
        setView,
        orgId,
        monthNames[month].id,
        year.toString(),
        bookerRoleDoc
      );
      handlers.push([month, year, handler]);
    }

    let menutype = state.menutype;

    const bookingCards = (
      <div className="row">
        {handlers.map(([month, year, handler], monthOffset) => (
          <div className="col-lg-3" key={`bookingcard-${month}-${year}`}>
            <MainMenuCardSimplePanel
              handler={handler}
              menulinklabel={`${monthNames[month].short} ${year}`}
              // badgelabel={label}
              menuicon={monthOffset === 0 ? "pastcalendar" : "currentcalendar"}
              menutype={menutype}
            />
          </div>
        ))}
      </div>
    );
    let buttons = (
      <button
        type="button"
        onClick={() => props.history.goBack()}
        className="btn btn-link mt-4 btn-block btn-lg"
      >
        Go Back
      </button>
    );
    let report = (
      <div className="py-4 my-4">
        <ReportBug
          context={state}
          rendertime={getLocalTime()}
          page="Room Selector"
          component="roomSelector"
        />
      </div>
    );
    buttons = (
      <>
        {/* <LinkButton
            link="/masses"
            type="button"
            label="Other Parish Schedules"
          /> */}
        <Button
          variant="link"
          onClick={() => props.history.goBack()}
          className="btn-block btn-lg"
        >
          Go Back
        </Button>
      </>
    );
    return (
      <div className="row justify-content-center">
        <div className="container">
          {bookingCards}
          {buttons}
          {/* {report} */}
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="pb-3 pt-3">{getMainMenuPanels(orgId)}</div>
    </div>
  );
}
