import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { useAllProgrammes } from "../hooks/hooks";

export default function MoveClassModal({
  transferModalIsOpen,
  closeModal,
  selectedItems,
  transferClass,
}) {
  const allProgrammes = useAllProgrammes();
  const { selectedParishId = "", programmeType = "" } = selectedItems[0];
  const [updateObj, setUpdateObj] = useState({
    assignedClass: "",
    timeslot: "",
  });

  const originalClasses = new Set(
    selectedItems.map(({ assignedClass }) => assignedClass)
  );

  const selectedProgramme =
    allProgrammes.find(({ parishId, id }) => {
      if (typeof programmeType === "string") {
        return parishId == selectedParishId && id == programmeType;
      } else if (typeof programmeType === "object") {
        return parishId == selectedParishId && id == programmeType.id;
      }
    }) ?? {};
  // console.log({ selectedProgramme, selectedTimeslotAndClass: updateObj });

  return (
    <Modal
      size="xl"
      show={transferModalIsOpen}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable="true"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Move Class</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-3">
          <div className="col-md-8">
            <span>
              Move the following {selectedItems.length} registrant(s) to:
            </span>
          </div>
          <div className="col-md-4">
            <select
              name="programme"
              id="programme"
              value={updateObj.assignedClass}
              onChange={({ currentTarget: input }) => {
                setUpdateObj(JSON.parse(input.value));
              }}
              className="form-control form-control-sm custom-select custom-select-md"
            >
              <option
                hidden
                value={{
                  class: "",
                  timeslot: "",
                }}
                className="text-muted"
              >
                Select class
              </option>
              {selectedProgramme.timeslots?.map(
                ({ classes, name: timeslotName, id: timeslot }) => (
                  <optgroup
                    key={`timeslotoptgroup-${timeslot}`}
                    label={timeslotName}
                  >
                    {classes.map((assignedClass) => (
                      <option
                        key={`classoption-${assignedClass}-${timeslot}`}
                        value={JSON.stringify({
                          assignedClass,
                          timeslot,
                        })}
                      >
                        {assignedClass}
                      </option>
                    ))}
                  </optgroup>
                )
              )}
            </select>
          </div>
        </div>
        <table className="table ">
          <tbody>
            <tr>
              <td>
                <span className="font-weight-bold">Name</span>
              </td>
              <td>
                <span className="font-weight-bold">Current Class</span>
              </td>
              <td>
                <span className="font-weight-bold">New Class</span>
              </td>
            </tr>
            {selectedItems.map((row) => (
              <tr key={`transferclassrow-${row.id}`}>
                <td>{row.child.name ?? ""}</td>
                <td>{row.assignedClass}</td>
                <td>{updateObj.assignedClass}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            Object.values(updateObj).some((v) => v === "") ||
            (originalClasses.size === 1 &&
              originalClasses.has(updateObj.assignedClass))
          }
          variant="primary"
          onClick={() => transferClass(selectedItems, updateObj)}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
