import { getBookingNumberMode } from "../services/settings";

export function getBookingNumber(booking) {
  const mode = getBookingNumberMode();
  let bookingnumber = "";
  if (mode === "serialcounter" && booking.hasOwnProperty("serialcounter")) {
    bookingnumber = booking.serialcounter;
    bookingnumber = `#${("00" + bookingnumber).slice(-3)}`;
  }
  if (mode === "slotcounter" && booking.hasOwnProperty("slotcounter")) {
    bookingnumber = booking.slotcounter;
    bookingnumber = `#${("00" + bookingnumber).slice(-3)}`;
  }
  return bookingnumber;
}
