import React from "react";
import Clock from "react-live-clock";
// import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { getSGTime } from "../../utils/utils.js";

const BrowserTime = () => {
  const currentTime = getSGTime(new Date());
  return (
    <p className="mediumfontsize text-muted pt-0 mt-0 mb-2 pb-1">
      {currentTime.toDateString()}
      {", "}
      <Clock format={"h:mm:ssa"} ticking={true} timezone={"Asia/Singapore"} />
    </p>
  );
};

export default BrowserTime;
