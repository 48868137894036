import React, { useRef, useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import LoadingSpinner from "../../../../common/loadingSpinner";
import ForceGraph2D, {
  ForceGraphMethods,
  NodeObject,
  LinkObject,
} from "react-force-graph-2d";
import proj4 from "proj4";

import {
  useLookupConfig,
  useRegistrations,
  useTransfers,
} from "../hooks/hooks";
import { ParishConfig } from "../../hooks/hooks";
import { computeParishDistance } from "../../services/parishes";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";
import { getOrgShortNameById } from "../../../../../services/organisation";

import PersonIcon from "@material-ui/icons/Person";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

import "../styles.css";

/**
 * @typedef GraphNode
 * @prop {ParishConfig} [parishConfig]
 * @prop {number} transfersIn
 * @prop {number} transfersOut
 * @prop {number} x
 * @prop {number} y
 * @prop {string} [group]
 * @prop {string} id
 * @prop {number} value
 */
/**
 * @typedef GraphLink
 * @prop {string} source
 * @prop {string} srcName
 * @prop {string} target
 * @prop {string} tgtName
 * @prop {number} value
 * @prop {number} distance
 */
/**
 * @typedef GraphData
 * @prop {GraphNode[]} nodes
 * @prop {GraphLink[]} links
 */
/**
 * @typedef GraphVals
 * @prop {number} avgNodeVal
 * @prop {number} maxNodeVal
 * @prop {number} maxLinkVal
 */
/**
 * @typedef GraphState
 * @prop {GraphData} data
 * @prop {GraphVals} vals
 */

const USE_DUMMY_DATA = false;

/**
 * @type {{[name: string]: string[]}}
 */
const CHART_PALETTES = {
  retroMetro: [
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#ede15b",
    "#bdcf32",
    "#87bc45",
    "#27aeef",
    "#b33dc6",
  ],
  dutchField: [
    "#e60049",
    "#0bb4ff",
    "#50e991",
    "#e6d800",
    "#9b19f5",
    "#ffa300",
    "#dc0ab4",
    "#b3d4ff",
    "#00bfa0",
  ],
  riverNights: [
    "#b30000",
    "#7c1158",
    "#4421af",
    "#1a53ff",
    "#0d88e6",
    "#00b7c7",
    "#5ad45a",
    "#8be04e",
    "#ebdc78",
  ],
  springPastels: [
    "#fd7f6f",
    "#7eb0d5",
    "#b2e061",
    "#bd7ebe",
    "#ffb55a",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#8bd3c7",
  ],
  ibm: ["#0fb5ae", "#4046ca", "#f68511", "#de3d82", "#7e84fa", "#72e06a"],
};

const ACCEPTED_STATUS = registrationStatusNameToStatus("accepted");
// const REJECTED_STATUS = registrationStatusNameToStatus("rejected");

// Contains Singapore's UTM zone
const SRS_IDENTIFIER = "EPSG:32648";
const PROJECTION =
  "+proj=utm +zone=48 +ellps=WGS84 +datum=WGS84 +units=m +no_defs";

proj4.defs(SRS_IDENTIFIER, PROJECTION);

/** @param {ParishConfig["location"]} location */
function convertLatLngToUtm(location) {
  const scale = 1e-3; // m to km

  const lng = location.longitude;
  const lat = location.latitude;

  const utmCoords = proj4(SRS_IDENTIFIER, [lng, lat]);
  return { x: utmCoords[0] * scale, y: utmCoords[1] * scale };
  // return { x: lng, y: lat };
}

const districts = ["west", "serangoon", "east", "north", "city", "other"];

export default function ArchdioceseGraph() {
  const lookupConfig = useLookupConfig();
  const allRegistrations = useRegistrations().data;
  const allTransfers = useTransfers().data;

  /** @type {React.MutableRefObject<ForceGraphMethods<NodeObject<GraphNode>, LinkObject<GraphNode, GraphLink>>?>} */
  const graphRef = useRef();
  const [elementWidth, setElementWidth] = useState(0);
  /** @type {[GraphState?, React.Dispatch<React.SetStateAction<GraphState>>]} */
  const [graphState, setGraphState] = useState();

  useEffect(() => {
    //   const graphData = { nodes: registrationCounter, links: transfersCounter };

    const dummyRegistrationCountByParish = [
      { id: "3", value: 33 * 9 }, // CTK
      { id: "4", value: 35 * 9 }, // Divine Mercy
      { id: "7", value: 88 * 9 }, // Holy Spirit
      { id: "10", value: 2 * 9 }, // OLL
      { id: "14", value: 61 * 9 }, // OLSS
      { id: "15", value: 46 * 9 }, // Risen Christ
      { id: "19", value: 45 * 9 }, // St Anthony
      { id: "20", value: 3 * 9 }, // St Bernadette
      { id: "22", value: 49 * 9 }, // SFX
      { id: "24", value: 25 * 9 }, // SJBT
      { id: "26", value: 101 * 9 }, // St Mary
      { id: "29", value: 21 * 9 }, // St Teresa
      { id: "30", value: 34 * 9 }, // SVDP
    ];

    const disallowedParishIds = [undefined, "0"];

    /** @type {{ id: string; value: number }[]} */
    const trueRegistrationCountByParish = Object.entries(
      Object.groupBy(
        allRegistrations.filter(
          ({ status, selectedParishId }) =>
            !disallowedParishIds.includes(selectedParishId) &&
            status === ACCEPTED_STATUS
        ),
        ({ selectedParishId }) => selectedParishId
      )
    )
      .map(([id, registrations]) => ({ id, value: registrations.length }))
      .toSorted(({ id: a }, { id: b }) => +a - +b);

    const registrationCountByParish = USE_DUMMY_DATA
      ? dummyRegistrationCountByParish
      : trueRegistrationCountByParish;

    const graphNodes = registrationCountByParish
      .map((registrationCount) => {
        const { parish } = lookupConfig(registrationCount.id);
        const utm = convertLatLngToUtm(parish?.location);
        /** @type {GraphNode} */
        const node = {
          ...registrationCount,
          group: parish?.district,
          ...utm,
          parishConfig: parish,
          transfersIn: 0,
          transfersOut: 0,
        };
        return node;
      })
      .filter(({ parishConfig }) => parishConfig !== undefined);

    const [minX, maxX, minY, maxY] = graphNodes.reduce(
      (acc, { x, y }) => {
        if (x < acc[0]) acc[0] = x;
        if (x > acc[1]) acc[1] = x;
        if (x < acc[2]) acc[2] = y;
        if (x > acc[3]) acc[3] = y;
        return acc;
      },
      [Infinity, -Infinity, Infinity, -Infinity]
    );
    const rangeX = maxX - minX;
    const rangeY = maxY - minY;

    for (const node of graphNodes) {
      node.x = ((node.x - minX) / rangeX) * 400;
      node.y = (1 - (node.y - minY) / rangeY) * 50;
    }

    /** @type {GraphLink[]} */
    const dummyGraphLinks = [
      {
        source: "3",
        target: "15",
        value: 20,
      },
      {
        source: "10",
        target: "29",
        value: 1,
      },
      {
        source: "15",
        target: "3",
        value: 10,
      },
      {
        source: "22",
        target: "3",
        value: 4,
      },
      {
        source: "22",
        target: "26",
        value: 2,
      },
    ];

    /** @type {GraphLink[]} */
    const trueGraphLinks = Object.entries(
      Object.groupBy(
        allTransfers.filter(
          ({ status, from: { id: fromParishId }, to: { id: toParishId } }) =>
            !disallowedParishIds.includes(fromParishId) &&
            !disallowedParishIds.includes(toParishId) &&
            status === "accepted"
        ),
        ({ from: { id: fromParishId }, to: { id: toParishId } }) =>
          JSON.stringify({ source: fromParishId, target: toParishId })
      )
    ).map(([nodes, transfers]) => ({
      ...JSON.parse(nodes),
      value: transfers.length,
    }));

    const graphLinks = USE_DUMMY_DATA ? dummyGraphLinks : trueGraphLinks;

    for (const id of [
      ...new Set(graphLinks.flatMap(({ source, target }) => [source, target])),
    ]) {
      if (!graphNodes.some(({ id: id_ }) => id === id_)) {
        const { parish } = lookupConfig(id);
        const utm = convertLatLngToUtm(parish?.location);
        graphNodes.push({
          id,
          value: 0,
          group: parish?.district,
          ...utm,
          parishConfig: parish,
          transfersIn: 0,
          transfersOut: 0,
        });
      }
    }

    // Iterate across all unordered node pairs & fill details, create link if necessary.
    for (let i = 0; i < graphNodes.length - 1; i++) {
      const node0 = graphNodes[i],
        {
          id: id0,
          parishConfig: { parish: name0 },
        } = node0;

      for (let j = i + 1; j < graphNodes.length; j++) {
        const node1 = graphNodes[j],
          {
            id: id1,
            parishConfig: { parish: name1 },
          } = node1;
        const distance = computeParishDistance(
          lookupConfig(id0.toString())?.parish,
          lookupConfig(id1.toString())?.parish,
          1e-3
        ); // Distance is commutative

        const link01 = graphLinks.find(
          ({ source, target }) => id0 === source && id1 === target
        );
        const link10 = graphLinks.find(
          ({ source, target }) => id1 === source && id0 === target
        );

        if (!link01)
          graphLinks.push({
            source: id0.toString(),
            srcName: name0,
            target: id1.toString(),
            tgtName: name1,
            value: 0,
            distance,
          });
        else {
          link01.srcName = name0;
          link01.tgtName = name1;
          link01.distance = distance;
          node0.transfersOut += link01.value;
          node1.transfersIn += link01.value;
        }
        if (!link10)
          graphLinks.push({
            source: id1.toString(),
            srcName: name1,
            target: id0.toString(),
            tgtName: name0,
            value: 0,
            distance,
          });
        else {
          link10.srcName = name1;
          link10.tgtName = name0;
          link10.distance = distance;
          node0.transfersIn += link10.value;
          node1.transfersOut += link10.value;
        }
      }
    }

    const nodeVals = graphNodes.map(({ value }) => value);
    const avgNodeVal = nodeVals
      .filter((val) => val > 0)
      .reduce((acc, val, _, { length }) => val / length + acc, 0);
    const maxNodeVal = Math.max(...nodeVals);
    const maxLinkVal = Math.min(
      Math.max(...graphLinks.map(({ value }) => value)),
      maxNodeVal / 5
    );

    setGraphState({
      data: { nodes: graphNodes, links: graphLinks },
      vals: { avgNodeVal, maxNodeVal, maxLinkVal },
    });
  }, []);

  useEffect(() => {
    if (!graphState) return;

    const updateElementWidth = () => {
      const element = document.getElementById("forcegraph-container");
      if (element) {
        const width = element.offsetWidth;
        setElementWidth(width);
      }
    };

    // Initial width on component mount
    updateElementWidth();

    // Update width when the window is resized
    window.addEventListener("resize", updateElementWidth);

    if (graphRef.current) {
      graphRef.current.d3Force("link").strength(({ value, distance }) => {
        const valueStrength = value ? 1e-5 * (Math.log(value) + 1) : 0;
        const distanceStrength = distance * 1e-4;
        const strength = valueStrength + distanceStrength;
        return strength;
      });
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateElementWidth);
    };
  }, [graphState]);

  return (
    <div id="forcegraph-container" className="w-100">
      {!graphState ? (
        <LoadingSpinner />
      ) : (
        <ForceGraph2D
          ref={graphRef}
          className="w-100"
          width={elementWidth}
          height={800}
          graphData={graphState.data}
          nodeLabel={({ parishConfig, value, transfersIn, transfersOut }) =>
            !!parishConfig
              ? renderToString(
                  <span
                    style={{
                      fontFamily: '"Inter Tight", sans-serif',
                      fontSize: 12,
                      color: "#212529",
                      lineHeight: 1,
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      {parishConfig?.parish}
                    </span>
                    <br />
                    <span style={{ fontWeight: 400 }}>
                      <FlightLandIcon style={{ fontSize: "inherit" }} />{" "}
                      {transfersIn}
                      {" | "}
                      <PersonIcon style={{ fontSize: "inherit" }} /> {value}{" "}
                      <span
                        className={
                          transfersIn < transfersOut
                            ? "text-danger"
                            : transfersIn > transfersOut
                            ? "text-success"
                            : "text-muted"
                        }
                      >
                        (
                        {transfersIn > transfersOut ? (
                          <TrendingUpIcon style={{ fontSize: "inherit" }} />
                        ) : transfersIn < transfersOut ? (
                          <TrendingDownIcon style={{ fontSize: "inherit" }} />
                        ) : (
                          <TrendingFlatIcon style={{ fontSize: "inherit" }} />
                        )}
                        {Math.abs(transfersIn - transfersOut)})
                      </span>
                      {" | "}
                      <FlightTakeoffIcon style={{ fontSize: "inherit" }} />{" "}
                      {transfersOut}
                    </span>
                  </span>
                )
              : ""
          }
          nodeColor={({ parishConfig }) =>
            !!parishConfig
              ? CHART_PALETTES.ibm[
                  districts.findIndex(
                    (district) => district === parishConfig.district
                  )
                ] + (parishConfig.isActive ? "ff" : "7f")
              : ""
          }
          nodeVal={({ value }) =>
            (value === 0 ? graphState.vals.avgNodeVal : value) /
              graphState.vals.maxNodeVal +
            0.01
          }
          nodeRelSize={20}
          nodeCanvasObjectMode={() => "after"}
          nodeCanvasObject={(
            { x, y, value, parishConfig },
            ctx,
            globalScale
          ) => {
            if (!!parishConfig) {
              const label = getOrgShortNameById(parishConfig.id);
              const fontSize = 12 / globalScale;
              ctx.font = `800 ${fontSize}px "Inter Tight", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
          "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
          sans-serif`;
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillStyle = "black";
              ctx.fillText(
                label,
                x,
                20 *
                  Math.sqrt(
                    (value === 0 ? graphState.vals.avgNodeVal : value) /
                      graphState.vals.maxNodeVal
                  ) +
                  10 +
                  y
              );
            }
          }}
          enableNodeDrag={false}
          linkCurvature={0.25}
          linkLabel={({ srcName, tgtName, value }) =>
            renderToString(
              <span
                style={{
                  fontFamily: '"Inter Tight", sans-serif',
                  fontSize: 12,
                  color: "#212529",
                }}
              >
                <span style={{ fontWeight: 800 }}>{srcName}</span>
                <br />
                <span style={{ fontWeight: 500 }}>
                  <ArrowDownwardIcon style={{ fontSize: "inherit" }} /> {value}
                </span>
                <br />
                <span style={{ fontWeight: 800 }}>{tgtName}</span>
              </span>
            )
          }
          // linkDirectionalArrowLength={5}
          // linkDirectionalArrowRelPos={1}
          linkDirectionalParticles={({ value }) =>
            value && Math.round(1 + 3 * Math.log(value))
          }
          linkDirectionalParticleSpeed={({ distance }) => distance * 5e-4}
          linkDirectionalParticleWidth={4}
          linkColor={({ value }) =>
            `#777777${Math.round(
              32 + 192 * Math.tanh((2 * value) / graphState.vals.maxLinkVal)
            ).toString(16)}`
          }
          linkVisibility={({ value }) => !!value}
          // warmUpTicks={100}
          cooldownTicks={150}
          onEngineStop={() => graphRef.current?.zoomToFit(100, 50)}
          enableZoomInteraction={false}
          enablePanInteraction={false}
        />
      )}
    </div>
  );
}
