import { isHoliday, years } from "./holiday";

export const monthRef = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const yearRef = years.map((v) => v.toString());

export const timeslotRef = {
  wdam: {
    name: "Weekday AM",
    hour: 9,
    durationMins: 240,
    dateValidator: (year, month, date, day) =>
      !isHoliday(year, month, date) && day > 0 && day < 6,
  },
  wdpm: {
    name: "Weekday PM",
    hour: 13,
    durationMins: 300,
    dateValidator: (year, month, date, day) =>
      !isHoliday(year, month, date) && day > 0 && day < 6,
  },
  wdn: {
    name: "Weekday Evening",
    hour: 18,
    durationMins: 240,
    dateValidator: (year, month, date, day) =>
      !isHoliday(year, month, date) && day > 0 && day < 6,
  },
  weam: {
    name: "Weekend / PH AM",
    hour: 9,
    durationMins: 240,
    dateValidator: (year, month, date, day) =>
      isHoliday(year, month, date) || day === 0 || day === 6,
  },
  wepm: {
    name: "Weekend / PH PM",
    hour: 13,
    durationMins: 300,
    dateValidator: (year, month, date, day) =>
      isHoliday(year, month, date) || day === 0 || day === 6,
  },
  wen: {
    name: "Weekend / PH Evening",
    hour: 18,
    durationMins: 240,
    dateValidator: (year, month, date, day) =>
      isHoliday(year, month, date) || day === 0 || day === 6,
  },
  // OBSOLETE
  we: {
    name: "Weekend / PH",
    hour: 9,
    durationMins: 780,
    dateValidator: (year, month, date, day) =>
      isHoliday(year, month, date) || day === 0 || day === 6,
  },
  weam: {
    name: "Weekend/PH AM",
    hour: 9,
    durationMins: 240,
    dateValidator: (year, month, date, day) =>
      isHoliday(year, month, date) || day === 0 || day === 6,
  },
  wepm: {
    name: "Weekend/PH PM",
    hour: 13,
    durationMins: 300,
    dateValidator: (year, month, date, day) =>
      isHoliday(year, month, date) || day === 0 || day === 6,
  },
  wen: {
    name: "Weekend/PH Evening",
    hour: 18,
    durationMins: 240,
    dateValidator: (year, month, date, day) =>
      isHoliday(year, month, date) || day === 0 || day === 6,
  },
};

/**
 * @param {keyof timeslotRef} timeslot
 */
export function getTimeslotName(timeslot) {
  return timeslotRef[timeslot]?.name;
}

export const roleRef = {
  pub: { shortName: "Pub", name: "Individual", groupName: "Public" },
  org: { shortName: "Org", name: "Org Member", groupName: "Org Members" },
  su: { shortName: "SU", name: "Superuser", groupName: "Superusers" },
};

/**
 * @param {keyof roleRef} role
 */
export function getRoleName(role) {
  return roleRef[role]?.name;
}
