import React from "react";
import Form from "../../common/form";
import ArchdioceseLogo from "../../blocks/archdioceseLogoBlock";

class RegistrationError extends Form {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <ArchdioceseLogo />
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-4">
            <main className="container">
              <div className="pb-2 pt-5 text-center">
                <h1 className="pb-2">Ooops...</h1>

                <h5 className="pt-2 pb-0">Something went wrong.</h5>
                <p className="pt-0 pb-2">
                  Please clear your browser cache, reload this page and try
                  again later.
                </p>
                <p className="pt-0 pb-2">
                  Alternatively, you may use Chrome or Safari browser on another
                  device.
                </p>
              </div>
              <div className="text-center my-4">
                <a
                  rel="noopener noreferrer"
                  className="defaultfontsize text-center btn-lg btn-primary btn-block"
                  href="register"
                >
                  Try Again
                </a>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistrationError;
