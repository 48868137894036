import React, { useState } from "react";
import { Button } from "react-bootstrap";
// import { validateAccounts } from "../../scripts/data/pfs/validateAccount";
// import { getDefaultRandomId } from "../../utils/utils";
// import { checkValidMCSDistribution } from "../../scripts/data/pfs/checkvalidmcsdistribution";
// import { get230624Invites } from "../../scripts/data/pfs/juneTwentyThreeInv";
// import { getUsersByDateRange, updateSubID } from "../../services/user";
// import fire from "../../services/fire";
// import Input from "./input";
// import {
//   getPPConfig,
//   // processAnonymizeBookingCancellations,
//   savePPConfig,
//   validatePPConfig,
// } from "../../scripts/scripts";
// import { getParishes } from "../../services/parish";
// import FileSaver from "file-saver";

// // import { getCommunionServiceNotes } from "../../utils/misc";
// let timing;
// timing = 1000 * 5; //5 seconds
// timing = 1000 * 60 * 3; //5 minutes

const ENABLE_TEST = false;

function InvisibleComponents({ user }) {
  const [value, setValue] = useState(null);
  // const [startText, setStartText] = useState("October 1, 2021 11:00:00 am");
  // const [endText, setEndText] = useState("October 2, 2021 11:00:00 am");
  // const [stop, setStop] = useState(false);
  // const parishes = getParishes();
  let test = ENABLE_TEST;

  if (!test) {
    return null;
  }

  /*
          
          let count = 0;
          let skipped = 0;
          let nosubid = 0;
          for (let i = 0; i < out.length; i++) {
            const entry = out[i];
            if (entry?.hasOwnProperty("subid")) {
              const subid = entry.subid;
              const userid = entry?.userid;
              if (subid?.length === 4) {
                //this has subid property, this can update
                // console.log(entry);
                const subid2 = subid?.substr(-2);
                count++;
                await updateSubID(userid, subid2);
                console.log(`(${count}) Updated SubID: ${subid} => ${subid2}`);
              } else {
                //skipped
                skipped++;
                console.log(
                  `(${skipped}) Skipped: ${entry?.email} => ${subid}`
                );
              }
            } else {
              nosubid++;
              console.log(`(${nosubid}) No Sub ID for: ${entry?.email}`);
            }
          }
          console.log(`Found: ${out.length}`);
          console.log(`No SubID: -${nosubid}`);
          console.log(`Skipped: -${skipped}`);
          console.log(`Updated: ${count}`);
          */

  // const processSubID = async (start, end) => {
  //   let countOnly = false;
  //   // const start = range[0];
  //   // const end = range[1];
  //   const startTS = new Date(start).getTime();
  //   const endTS = new Date(end).getTime();
  //   const out = await getUsersByDateRange(startTS, endTS);

  //   console.log(out);
  //   if (countOnly) {
  //     console.log(`Entries Found: ${out.length}`);
  //     return;
  //   }

  //   let count = 0;
  //   let skipped = 0;
  //   let nosubid = 0;

  //   const promises = out.map((entry) => {
  //     if (entry?.hasOwnProperty("subid")) {
  //       const subid = entry.subid;
  //       const userid = entry?.userid;
  //       if (subid?.length === 4) {
  //         //this has subid property, this can update
  //         const subid2 = subid?.substr(-2);
  //         count++;
  //         console.log(`(${count}) Updating SubID: ${subid} => ${subid2}`);
  //         return updateSubID(userid, subid2);
  //       } else {
  //         //skipped
  //         skipped++;
  //         console.log(`(${skipped}) Skipped: ${entry?.email} => ${subid}`);
  //       }
  //     } else {
  //       nosubid++;
  //       console.log(`(${nosubid}) No Sub ID for: ${entry?.email}`);
  //     }
  //   });

  //   Promise.all(promises)
  //     .then(() => {
  //       console.log(`Found: ${out.length}`);
  //       console.log(`No SubID: -${nosubid}`);
  //       console.log(`Skipped: -${skipped}`);
  //       console.log(`Updated: ${count}`);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  // const handleStartChange = (e) => {
  //   const { name, value } = e.target;
  //   setStartText(value);
  // };

  // const handleEndChange = (e) => {
  //   const { name, value } = e.target;
  //   setEndText(value);
  // };

  return (
    <div>
      {/* {parishes.map((i) => (
        <Button
          variant="success"
          className="mx-4 my-1"
          key={i.link}
          onClick={() => {
            const collection = `massbookings/parishes/${i.link}`;

            const processFunc = () => {
              // method to be executed;
              console.log("Calling processAnonymize()...");
              processAnonymize(collection).then((i) => {
                if (i.length === 0) {
                  //terminate
                  setStop(true);
                  console.log("Empty");
                  clearInterval(interval);
                }
                console.log("Done.");
              });
            };
            processFunc();
            const interval = setInterval(processFunc, timing);
          }}
        >
          {i.link}
        </Button>
      ))} */}
      {/* <Button
        variant="success"
        className="mx-4 my-1"
        onClick={() => {
          const collection = `bookingcancellations`;

          // method to be executed;
          console.log(
            `Calling processAnonymizeBookingCancellations(${collection})...`
          );
          // processAnonymizeBookingCancellations(collection).then((i) => {
          //   console.log("Done.");
          // });

          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymizeBookingCancellations()...");
            processAnonymizeBookingCancellations(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Run
      </Button> */}
      {/* <Button
        variant="success"
        className="mx-4 my-1"
        onClick={() => {
          const collection = `parishionerbookings`;

          const processFunc = () => {
            // method to be executed;
            console.log(`Calling processAnonymizeBookings(${collection})...`);
            processAnonymizeBookings(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                // clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Run Asc
      </Button> */}
      {/* <Button
        variant="success"
        className="mx-4 my-1"
        onClick={() => {
          const collection = `parishionerbookings`;

          const processFunc = () => {
            // method to be executed;
            console.log(`Calling processAnonymizeBookings(${collection})...`);
            processAnonymizeBookings(collection, "b").then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                // clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Run Desc
      </Button> */}
      {/* <Button
        variant="success"
        className="mx-4 my-1"
        onClick={() => {
          const collection = "massbookings/parishes/christtheking";

          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        CTK
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/nativityofblessedvirgin";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Nativity
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/divinemercy";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Divine Mercy
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/holycross";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Holy Cross
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/holyfamily";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Holy Family
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/holyspirit";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Holy Spirit
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/holytrinity";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Holy Trinity
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/immaculateheartofmary";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        IHM
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/ladyoflourdes";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Lourdes
      </Button>
      <Button
        className="mx-4 my-1"
        variant="success"
        onClick={() => {
          const collection = "massbookings/parishes/novena";
          const processFunc = () => {
            // method to be executed;
            console.log("Calling processAnonymize()...");
            processAnonymize(collection).then((i) => {
              if (i.length === 0) {
                //terminate
                setStop(true);
                console.log("Empty");
                clearInterval(interval);
              }
              console.log("Done.");
            });
          };
          processFunc();
          const interval = setInterval(processFunc, timing);
        }}
      >
        Novena
      </Button> */}
      {/* <Button
        variant="success"
        onClick={async () => {
          const fcmServer = fire
            .functions("asia-east2")
            .httpsCallable("fcmServer");
          console.log("Trigger fcmServer...");
          const obj = {
            type: "topic",
            topic: "18",
            // type: "token",
            // tokens: [
            //   // "c0ceJYSTSMm-tWt1Tw9m24:APA91bEBSrtktpQatV055esFoz8Jjnn45ELxYNZLeXgb4qbfdMqBX-M4LSQEw01gksb83GG4YKPj9flP0_90ae_aO5kI8nkLdcpPy99HY1JP8jv_OUTfjkCSw86VRtKm4q7QRIxYbgxu",
            //   // "c0ceJYSTSMm-tWt1Tw9m24:APA91bGzCOTX5SbUwfOdZtV4dvikhtJrl8uILC6R01CPriewubiElkKXTw0Lgn8l_Uq5q8r-qou7S9iu3z_figGVHliQQ59kyySVGMqIL18dZr7GUaKMOlLqlDk1oncdqIiT1h1K_2gA",
            //   // "eXYYbaZf_0Jyh_GWR65f_o:APA91bEWck0uxtMUXa19Z18Zt2cXmkQwHeEpuT8h_d9zvhQ0FWFVEcllnjYLcjnkWD8E3RQUlzPe0LKl_socNAdHyt58BAxjnEmMANVgrzur8XjKTrr7CgnjsBTf1e-_M60WpPTqQEee",
            //   "eao0LHTaRO2UVuRj21SSrB:APA91bGtH5U-COP-BEeTUTPPbDhtHgBn5zdtDB8PZRbLUvQ9HjlsgnpKzTnIGbaMd232bkIaj4AslkZkGLoRp7X9opGwQ00bO9q-ydV99iYui10ZJ8EBY4f9uVPiyWfee2lpSt_7a4mN",
            // ],
            notification: {
              // title: "In His Image 🙏",
              // body: "Reflect His love in your marriage. Love unconditionally. 💖",
              title: "Latest myCatholicSG App Updates",
              body: `Dear Brothers and Sisters in Christ,

We've updated our myCatholicSG app to fix some bugs that you have reported.

Please update your app now to the latest versions.

Android: https://play.google.com/store/apps/details?id=com.CSG.CatholicSG

iOS: https://apps.apple.com/sg/app/catholicsg-app/id1151027240`,
            },
          };

          const out = await fcmServer({ obj });
          console.log(out);
        }}
      >
        Run
      </Button> */}
      {/* <Button
        variant="success"
        onClick={async () => {
          //do something here
          //1. read the config
          const entries = getPPConfig();
          //2. verify the data based on parish.js or whatever
          const validated = validatePPConfig(entries);
          //3. write the data to the database
          savePPConfig(validated);
        }}
      >
        Run
      </Button> */}
      {/* <Button
        variant="success"
        onClick={async () => {
          const func = fire.functions("asia-east2").httpsCallable("webscr");
          console.log("Trigger Webscr...");
          let url =
            "https://www.catholic.sg/archbishops-daily-reflection-today?id=dcocloudfunction";
          // url = "https://google.com";
          // url = "https://mycatholic.sg";
          // url = "https://healsoc.com/lee";
          // url =
          //   "https://app1.catholic.sg/api/v1.0/index.php/webview/scripture_reflection";
          let out = await func({
            url: url,
            action: "default",
            output: "save",
          });
          console.log(out);
          if (out.data.action === "screenshot") {
            const o = Buffer.from(out.data.message, "base64");
            // console.log(o);
            const blob = new Blob([o], {
              type: "application/png",
            });
            FileSaver.saveAs(blob, "file.png");
          } else {
            //
          }
        }}
      >
        Run
      </Button> */}
      {/* <Button
        variant="success"
        onClick={async () => {
          // processAnonymize();
          // processAnonymize("parishionerbookings");
          const reading = fire
            .functions("asia-east2")
            .httpsCallable("massreading");
          console.log("Trigger Mass Reading...");

          let out = await reading({ input: "20240606" });
          console.log(out);
          console.log(out.data.results.items);
          // const cron = fire
          //   .functions("asia-east2")
          //   .httpsCallable("runCronReflection");
          // console.log("Trigger Cron...");

          // let out = await cron({});
          // console.log(out);

          // const announcement = fire
          //   .functions("asia-east2")
          //   .httpsCallable("announcements");
          // console.log("Getting Announcements...");

          // out = await announcement({ org: user?.parish?.toString() || "" });
          // console.log(out);
          // const sched = fire.functions("asia-east2").httpsCallable("schedule");
          // console.log("Getting Schedule...");

          // out = await sched({ input: "all" });
          // // console.log(out?.data?.results?.items);
          // // let output = out?.data?.results?.items.map((i) => {
          // //   const updatedNotes = getCommunionServiceNotes(i);
          // //   return { ...i, notes: updatedNotes };
          // // });
          // // console.log(output);
          // console.log(out);
          // const func = fire.functions("asia-east2").httpsCallable("events");
          // console.log("Getting List...");
          // out = await func({ type: "getList" });
          // console.log(out);

          // //GET EVENT DETAILS
          // console.log("Getting Event Details...");
          // out = await func({
          //   type: "getEventDetails",
          //   arg: {
          //     eventId: "teWJeNU2KxcYkgYjWwtF",
          //   },
          // });
          // console.log(out);
          // // out = await func({
          // //   type: "setInterest",
          // //   arg: {
          // //     eventId: "ZQM4i3jUEqpWkeRqtwQS",
          // //     parentEventId: "0TefN8bHMHaisZEFLvoV",
          // //   },
          // // });
          // // console.log(out);
          // // out = await func({
          // //   type: "cancelBooking",
          // //   arg: { eventId: "oBbOHSdvccFUJgdaNffw" },
          // // });
          // // console.log(out);
          // // out = await func({ type: "getList" });
          // // // const out = await func({
          // // //   type: "getList",
          // // // });
          // console.log("Getting Bookings...");
          // out = await func({
          //   type: "getBookings",
          //   arg: "Zfwh2CTcsWND8Enbh07udH3zP1p1",
          // });
          // console.log(out);
          // console.log("Getting Interested...");
          // out = await func({
          //   type: "getInterested",
          //   arg: "Zfwh2CTcsWND8Enbh07udH3zP1p1",
          // });
          // console.log(out);
          // let formResponse = [];
          // console.log("Booking... Confraternity");
          // formResponse = [
          //   {
          //     fieldId: "radiobuttons_CCA3F60E-EB6E-41C3-8C50-462DFBB2E38E",
          //     label: "Are you a Catholic?",
          //     value: "Yes",
          //   },
          // ];
          // out = await func({
          //   type: "setBooking",
          //   arg: { eventId: "3ZwZwfBpBE5zull6r1XE", formResponse },
          // });
          // console.log(out);
          // console.log("Booking... Google Event");
          // formResponse = [
          //   {
          //     fieldId: "text_area_BE6CD4E2-E88B-4B20-B5A2-0AF736698183",
          //     label: "Say something",
          //     value: "this is a testand another test",
          //   },
          // ];
          // out = await func({
          //   type: "setBooking",
          //   arg: { eventId: "vqXKQlwI2K6YH1ThBHFv", formResponse },
          // });
          // console.log(out);
          // console.log("Booking... Old Testament");
          // formResponse = [
          //   {
          //     fieldId: "checkboxes_A89CAAF8-C928-4566-9D21-E66C60DAC9A9",
          //     label: "Tick the one that applies to you:",
          //     value: [
          //       "I have read the Old Testament",
          //       "I have read the New Testament",
          //       "I have not read the bible at all",
          //     ],
          //   },
          // ];
          // out = await func({
          //   type: "setBooking",
          //   arg: { eventId: "H0QayNa7WGaLQLqIXvNm", formResponse },
          // });
          // console.log(out);
          // console.log("Booking... OYP");
          // formResponse = [
          //   {
          //     fieldId: "dropdown_F58A1CA6-78E1-4896-B64B-8CA6D4F29D9B",
          //     label: "T-Shirt Size",
          //     value: "XLarge",
          //   },
          // ];
          // out = await func({
          //   type: "setBooking",
          //   arg: { eventId: "asxHw98cqTXE4JnfmVn4", formResponse },
          // });
          // console.log(out);
          // console.log("Booking... Catholic Teaching");
          // formResponse = [
          //   {
          //     fieldId: "text_input_7A731E02-B498-417F-96FC-6D62EC156F00",
          //     label: "Please explain why you want to attend this talk. ",
          //     value: "Testing 123.",
          //   },
          // ];
          // out = await func({
          //   type: "setBooking",
          //   arg: { eventId: "oBbOHSdvccFUJgdaNffw", formResponse },
          // });
          // console.log(out);
          // console.log("Cancelling...");
          // out = await func({
          //   type: "cancelBooking",
          //   arg: { eventId: "3ZwZwfBpBE5zull6r1XE" },
          // });
          // console.log(out);
          // console.log("Cancelling...");
          // out = await func({
          //   type: "cancelBooking",
          //   arg: { eventId: "vqXKQlwI2K6YH1ThBHFv" },
          // });
          // console.log(out);
          // console.log("Cancelling...");
          // out = await func({
          //   type: "cancelBooking",
          //   arg: { eventId: "ZQM4i3jUEqpWkeRqtwQS" },
          // });
          // console.log(out);
          // console.log("Cancelling...");
          // out = await func({
          //   type: "cancelBooking",
          //   arg: { eventId: "II1c81J2HDyA0jCBEQBL" },
          // });
          // console.log(out);
          // console.log("Cancelling...");
          // out = await func({
          //   type: "cancelBooking",
          //   arg: { eventId: "H0QayNa7WGaLQLqIXvNm" },
          // });
          // console.log(out);
          // console.log("Cancelling... Catholic Teaching");
          // out = await func({
          //   type: "cancelBooking",
          //   arg: { eventId: "oBbOHSdvccFUJgdaNffw" },
          // });
          // console.log(out);
          // console.log("Cancelling... OYP");
          // out = await func({
          //   type: "cancelBooking",
          //   arg: { eventId: "asxHw98cqTXE4JnfmVn4" },
          // });
          // console.log(out);
          // console.log("Booking...");
          // out = await func({
          //   type: "setBooking",
          //   arg: { eventId: "3ZwZwfBpBE5zull6r1XE", formResponse },
          // });
          // console.log(out);
        }}
      >
        Get Values
      </Button> */}
      {/* <Input
        type={"text"}
        name={"startText"}
        value={startText}
        label={"Start Text"}
        placeholder={startText}
        onChange={handleStartChange}
      />
      <Input
        type={"text"}
        name={"endText"}
        value={endText}
        label={"End Text"}
        placeholder={endText}
        onChange={handleEndChange}
      />
      <Button
        variant="success"
        onClick={async () => {
          const range = [
            // "June 1, 2020 12:00:00 am", //start
            // "June 20, 2020 12:00:00 am",
            // "June 23, 2020 12:00:00 am",
            // "June 23, 2020 06:00:00 am",
            // "June 23, 2020 10:00:00 am",
            // "June 26, 2020 12:00:00 am", //1
            // "June 27, 2020 9:00:00 am", //600+
            // "June 27, 2020 10:00:00 am", //3500+
            // "June 27, 2020 11:00:00 am", //2700+
            // "June 27, 2020 12:00:00 pm", //2077
            // "June 27, 2020 12:30:00 pm", //886
            // "June 27, 2020 1:00:00 pm", //847
            // "June 27, 2020 3:00:00 pm", //2784
            // "June 27, 2020 4:00:00 pm", //1115
            // "June 27, 2020 6:00:00 pm", //1852
            // "June 27, 2020 8:00:00 pm", //1480
            // "June 27, 2020 10:00:00 pm", //1475
            // "June 28, 2020 12:00:00 am", //952
            // "June 28, 2020 6:00:00 am", //398
            // "June 28, 2020 9:00:00 am", //366
            // "June 28, 2020 10:00:00 am", //400
            // "June 28, 2020 11:00:00 am", //621
            // "June 28, 2020 12:00:00 pm", //
            // "June 28, 2020 1:00:00 pm", //
            // "June 28, 2020 2:00:00 pm", //
            // "June 28, 2020 3:00:00 pm", //3339
            // "June 28, 2020 6:00:00 pm", //1433
            // "June 28, 2020 9:00:00 pm", //1244
            // "June 29, 2020 12:00:00 am", //910
            // "June 29, 2020 6:00:00 am", //161
            // "June 29, 2020 9:00:00 am", //227
            // "June 29, 2020 12:00:00 pm", //487
            // "June 29, 2020 6:00:00 pm", //770
            // "June 29, 2020 9:00:00 pm", //394
            // "June 30, 2020 6:00:00 am", //870
            // "June 30, 2020 6:00:00 pm", //1238
            // "June 30, 2020 9:00:00 pm", //348
            // "July 01, 2020 9:00:00 am", //524
            // "July 01, 2020 3:00:00 pm", //778
            // "July 02, 2020 6:00:00 am", //1332
            // "July 03, 2020 6:00:00 am", //1321
            // "July 04, 2020 6:00:00 am", //945
            // "July 05, 2020 6:00:00 am", //1525
            // "July 06, 2020 6:00:00 am", //2330
            // "July 07, 2020 6:00:00 am", //785
            // "July 08, 2020 6:00:00 am", //1556
            // "July 10, 2020 6:00:00 am", //1279
            // "July 12, 2020 6:00:00 am", //1595
            // "July 15, 2020 6:00:00 am", //2861
            // "July 18, 2020 6:00:00 am", //1562
            "July 20, 2020 6:00:00 am", //1908
            "July 22, 2020 6:00:00 am", //1908

            // "December 1, 2020 12:00:00 am",
            // "July 1, 2021 12:00:00 am",
            // "December 1, 2021 12:00:00 am",
            // "July 1, 2022 12:00:00 am",
            // "December 1, 2022 12:00:00 am",
            // "July 1, 2023 12:00:00 am",
            // "December 1, 2023 12:00:00 am",
            // "July 1, 2024 12:00:00 am",
          ];

          let start = new Date(startText);
          let blocks = 6;
          let end = new Date(start.getTime() + blocks * 60 * 60 * 1000); // Add blocks hours to start

          while (start < new Date(endText)) {
            // Replace new Date() with the final date if you have one
            console.log(
              `Processing: ${new Date(start).toLocaleString()} => ${new Date(
                end
              ).toLocaleString()}`
            );
            await processSubID(start, end);

            // Update start and end for the next iteration
            start = new Date(end.getTime());
            end = new Date(start.getTime() + blocks * 60 * 60 * 1000); // Add blocks hours to start
          }
        }}
      >
        Run
      </Button> */}
      <Button
        variant="success"
        onClick={async () => {
          // const out = get230624Invites();
          // console.log(out);
          // await checkValidMCSDistribution();
          // const runCustomBalloting = fire
          //   .functions("asia-east2")
          //   .httpsCallable("runCustomBalloting", { timeout: 540000 });
          // const out = await runCustomBalloting({ count: 1000 });
          // console.log(out);
          // console.log("Done");
          // const winnersRef = fire
          //   .firestore()
          //   .collection("ballotregistrationresults")
          //   .doc("results")
          //   .collection("fourthballot")
          //   // .collection("thirdballot")
          //   .doc("winners0");
          // const winnersDoc = await winnersRef.get();
          // if (winnersDoc.exists) {
          //   const winners = JSON.parse(winnersDoc.data().winners);
          //   console.log(winners);
          // } else {
          //   console.log("Winners doc not found");
          // }
        }}
      >
        Run
      </Button>
      {/* {value &&
        value.map((i) => {
          const id = getDefaultRandomId();

          return (
            <div key={id}>
              {i.email}, {i.message}
            </div>
          );
        })} */}
    </div>
  );
}

export default InvisibleComponents;
