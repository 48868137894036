import React, { useContext, useState } from "react";
import MainMenuCardSimplePanel from "../../blocks/mainMenuCardPanelSimpleBlock";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PayplusContext from "../payplusContext";

const OrgPaymentSelector = () => {
  const { payplusState, payplusDispatch } = useContext(PayplusContext);
  const { isCampaignLoading, parishCampaigns, orgCampaigns, orgList } =
    payplusState;

  const [expandOrgList, setExpandOrgList] = useState(false);

  const displayOrgList = () => {
    const organizations = [];
    if (orgCampaigns && orgCampaigns.length > 0) {
      orgList.forEach((org) => {
        const campaigns = orgCampaigns.filter((e) => e.orgName === org);
        organizations.push(
          <MainMenuCardSimplePanel
            handlerPrimary={(e) => {
              e.preventDefault();
              payplusDispatch({
                type: "SET_SELECTED_LIST",
                payload: { selectedCampaignList: campaigns },
              });
            }}
            menulinklabel={`${org}`}
            menuicon="payment"
            menutype="light"
            menuDescription={`${campaigns.length} Payment${
              campaigns.length > 1 ? "s" : ""
            } Available`}
          />
        );
      });
    }
    return organizations;
  };
  // 1. Obtain list & names of other orgs
  // 2. Group them together into separate lists via map
  // 3. Add "Back to previous selection"

  return (
    <>
      <h2>Select Organization</h2>
      <div className="pb-3 pt-0 px-0">
        {isCampaignLoading ? (
          <p className="defaultfontsize text-center">Loading ...</p>
        ) : parishCampaigns.length === 0 && orgCampaigns.length === 0 ? (
          <p className="defaultfontsize text-center text-danger">
            No Pay✝ collections currently ongoing
          </p>
        ) : (
          // If there are parish campaigns, separate orgs from parish. Otherwise, display orgs list directly

          <>
            {parishCampaigns.length > 0 && !expandOrgList && (
              <MainMenuCardSimplePanel
                handlerPrimary={(e) => {
                  e.preventDefault();
                  payplusDispatch({
                    type: "SET_SELECTED_LIST",
                    payload: { selectedCampaignList: parishCampaigns },
                  });
                }}
                menulinklabel="My Parish"
                menuicon="payment"
                menutype="light"
              />
            )}
            {parishCampaigns.length > 0 &&
              orgCampaigns.length > 0 &&
              !expandOrgList && (
                <MainMenuCardSimplePanel
                  handlerPrimary={() => {
                    setExpandOrgList(true);
                  }}
                  menulinklabel="Other Organizations"
                  menuicon="payment"
                  menutype="light"
                />
              )}

            {expandOrgList && (
              <div
                className="payplus-back--btn"
                onClick={() => setExpandOrgList(false)}
              >
                <ArrowBackIosIcon style={{ fontSize: 16 }} />
                Back to Previous Selection
              </div>
            )}
            {(expandOrgList || parishCampaigns.length === 0) && (
              <div className="pb-3 pt-0 px-0">{displayOrgList()}</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default OrgPaymentSelector;
