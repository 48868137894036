import React, { useContext } from "react";
import { Navbar } from "react-bootstrap";
import smallLogo from "../../../img/logo/logo-archdiocese_white-90.png";
import FinanceContext from "./financeContext";

import "../stylesheets/finance-top-nav.css";

const FinanceTopNav = (user) => {
  const { financeDashboardState } = useContext(FinanceContext);
  const { org } = financeDashboardState;
  return (
    <Navbar fixed="top" className="finance-topnav__container">
      <div className="finance-topnav__section-container">
        <img
          alt="myCatholicSG"
          src={smallLogo}
          className="finance-topnav__logo d-inline-block align-top"
        />
        <div className="finance-topnav__logo-text">
          <span className="font-italic">my</span>CatholicSG
          <span> / Finance</span>
        </div>
      </div>
      <div className="finance-topnav__section-container finance-topnav__section--center">
        {org?.orgName}
      </div>
      <div className="finance-topnav__section-container finance-topnav__section--right"></div>
    </Navbar>
  );
};

export default FinanceTopNav;
