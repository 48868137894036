import React from "react";
import Steps from "../../common/steps";

function BookingSteps({ step }) {
  const enable = true;
  if (!enable) {
    return null;
  }
  const steps = [
    { step: 1, label: "Select" },
    { step: 2, label: "Review & Pay" },
    { step: 3, label: "Confirm" },
  ];
  return <Steps key={step} steps={steps} current={step} />;
}

export default BookingSteps;
