import React from "react";
/**
 * Source: https://codesandbox.io/s/react-textarea-auto-height-s96b2?file=/src/App.js:598-615
 */
const ResizeTextarea = ({ name, label, error, ...rest }) => {
  const textareaRef = React.useRef(null);
  const { value } = { ...rest };
  React.useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      32
    )}px`;
  }, [value]);
  let formClass = "form-control form-control-lg ";
  if (error) {
    formClass = `${formClass} is-invalid`;
  }
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <textarea
        {...rest}
        ref={textareaRef}
        name={name}
        id={name}
        className={formClass}
        style={{
          minHeight: 32,
          resize: "none",
        }}
      />
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default ResizeTextarea;
