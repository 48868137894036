import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncPost({ ...rest }) {
  const AsyncLoad = loadable(() => import("../admin/post"));
  console.log("Loading", AsyncPost.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncPost;
