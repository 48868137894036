import React, { Component } from "react";
import WarningBox from "./warningBox";
import fire from "../../services/fire";
import { getBrowser } from "../../services/getbrowser";
import { getParishTimezoneWarningById } from "../../services/parish";

class TimezoneWarning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user,
      data: this.props.data,
      wrongtimezone: false,
      timezone: "",
    };
  }

  async componentDidMount() {
    const user = this.state.user;
    const data = this.state.data;
    let wrongtimezone = false;
    let timezone = "";
    const curdate = new Date();
    const curtime = curdate.toString();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let offset = curdate.getTimezoneOffset();
    /*
     ** -480
     ** - means ahead of GMT => 480 minutes ahead, 480/60 = 8 hours ahead of GMT
     ** or GMT+8
     */
    const SG_TZ_OFFSET = -480;
    if (
      offset !== SG_TZ_OFFSET &&
      // tz !== "Asia/Singapore" &&
      // curtime.indexOf("Singapore") === -1 &&
      getParishTimezoneWarningById(data.parish)
    ) {
      wrongtimezone = true;
      //get timezone
      timezone = tz;
      //write to db, we want to know how many have experienced this
      const browser = await getBrowser();
      let name = browser?.name ? browser.name : "?";
      let version = browser?.version ? browser.version : "?";
      const obj = {
        timeoffset: offset,
        name: user.displayName,
        email: user.email,
        browser: { name, version },
        userid: user.uid,
        created: curtime,
        timestamp: curdate.getTime(),
        timezone: timezone,
        useragent: navigator.userAgent,
        data,
      };
      await fire.firestore().doc(`tzwarning/${user.uid}`).set(obj);
    }
    this.setState({ wrongtimezone: wrongtimezone, timezone });
  }

  render() {
    const { wrongtimezone } = this.state;
    const fix = "Please set your timezone to Singapore.";
    return wrongtimezone ? (
      <WarningBox>
        <b>Alert!</b>
        Seems like your time is incorrect. {fix}
      </WarningBox>
    ) : null;
  }
}

export default TimezoneWarning;
