import React, { Component } from "react";

class ScrollToTop extends Component {
  handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  render() {
    const css = this.props.css || "btn-outline-primary";
    return (
      <div onClick={this.handleClick} className="text-center">
        <button
          className={`btn btn-lg ${css} btn-block`}
          style={{ border: "1px solid #dddddd" }}
        >
          Scroll to Top
        </button>
      </div>
    );
  }
}

export default ScrollToTop;
