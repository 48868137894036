import React, { useContext } from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import EqualizerIcon from '@material-ui/icons/Equalizer';
import CropFreeIcon from "@material-ui/icons/CropFree";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import FinanceContext from "./financeContext";

import "../stylesheets/finance-side-nav.css";

const FinanceSideNav = () => {
  const { financeDashboardState, financeDashboardDispatch } =
    useContext(FinanceContext);
  const { selection } = financeDashboardState;

  const selectSection = (selection) => {
    financeDashboardDispatch({ type: "SELECT_TAB", payload: { selection } });
  };

  return (
    <div className="finance-sidenav__container">
      <div
        className={
          selection === 0
            ? "finance-sidenav__section--selected"
            : "finance-sidenav__section"
        }
        onClick={() => selectSection(0)}
      >
        <DashboardIcon className="finance-sidenav__icon" />
        <div className="finance-sidenv__section-text">Transactions</div>
      </div>
      {/* <div
        className={selection === 1 ? "finance-sidenav__section--selected" : "finance-sidenav__section"}
        onClick={() => selectSection(1)}
      >
        <EqualizerIcon className="finance-sidenav__icon" />
        <div className="finance-sidenv__section-text">Statistics</div>
      </div> */}
      <div
        className={
          selection === 2
            ? "finance-sidenav__section--selected"
            : "finance-sidenav__section"
        }
        onClick={() => selectSection(2)}
      >
        <CropFreeIcon className="finance-sidenav__icon" />
        <div className="finance-sidenv__section-text">QR Codes</div>
      </div>
      {/* <div
      className={
        selection === 3
          ? "finance-sidenav__section--selected"
          : "finance-sidenav__section"
      }
      onClick={() => selectSection(3)}
      >
        <AssignmentIndIcon className="finance-sidenav__icon" />
        <div className="finance-sidenv__section-text text-center">
          Vendor Assignment
        </div>
      </div> */}
    </div>
  );
};

export default FinanceSideNav;
