import fire from "../../../../../services/fire";

export async function downloadCert(certificateData, user) {
  const getSignedFile = fire
    .functions("asia-east2")
    .httpsCallable("getSignedFile");

  return await getSignedFile({
    user: { userid: user.uid },
    fileKey: certificateData?.path,
  }).then(async (result) => {
    if (result.data) {
      const resultData = result.data;
      const { file } = resultData;

      const downloadLink = document.createElement("a");
      downloadLink.target = "_blank";
      downloadLink.href = file;
      downloadLink.download = `${certificateData.name}`;
      downloadLink.click();
    }
    return;
  });
}
