export const INIT_FINANCE_DASHBOARD_STATE = {
  user: null,
  org: null,
  isReady: false,
  selection: 0
}

export const financeDashboardReducer = (state, action) => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case "SELECT_TAB": {
      const { selection } = payload;
      return { ...state, selection };
    }
    case "SET_READY": {
      const { user, org, isReady } = payload;
      return { ...state, user, org, isReady };
    }
    default:
      return state;
  }
}





