import React from "react";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";

function ResetSent({ email = "" }) {
  return (
    <div>
      <div className="pb-2 pt-5 text-center">
        <EmailOutlinedIcon
          className="mycatholicsgred"
          style={{ fontSize: "80px" }}
        />
        <h1 className="pb-2">Email sent</h1>

        <h5 className="py-2">
          Please check your email <span className="text-danger">{email}</span>{" "}
          to reset your password. You should be receiving it shortly.
        </h5>
      </div>
      <div className="pt-2 pb-4 text-left">
        <p className="">
          If you did not receive the email,{" "}
          <b>check your junk/spam folder after few minutes</b>.
        </p>
      </div>
    </div>
  );
}

export default ResetSent;
