import React, { Component } from "react";
import ArchdioceseLogo from "../blocks/archdioceseLogoBlock";
import SimpleMessage from "../blocks/simpleMessageBlock";

class PageNotFound extends Component {
  render() {
    const { user, isEventOrForm, customMessage, content, link, label } = this.props;
    return (
      <React.Fragment>
        <ArchdioceseLogo />
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-4">
            <main className="container">
              {isEventOrForm ?
                <SimpleMessage
                  title= "Ooops"
                  content= {content}
                  link= {link}
                  label= {label}
                />
                :
                 <>
                  {!user && (
                    <SimpleMessage
                      title="Ooops"
                      content= { customMessage || "Seems like the page does not exist."}
                      link="/login"
                      label="Show me log in page"
                    />
                  )}
                  {user && (
                    <SimpleMessage
                      title="Ooops"
                      content={ customMessage || "Seems like the page does not exist."}
                      link="/home"
                      label="Back to Home"
                    />
                  )}
              </>
              }
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageNotFound;
