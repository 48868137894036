/**
 * @param {import("../../hooks/hooks").RegistrationDatum["sacraments"]} sacraments
 */
export function getSacramentsSortKey(sacraments) {
  return (
    sacraments?.baptism?.received * 8 +
    sacraments?.reconciliation?.received * 4 +
    sacraments?.eucharist?.received * 2 +
    sacraments?.confirmation?.received * 1
  );
}

/** @type {{name: import("../../hooks/hooks").RegistrationStatus, value: number}[]} */
export const registrationStatusFilterOptions = [
  { name: "pending", value: 1 },
  { name: "rejected", value: 2 },
  { name: "accepted", value: 0 },
];

/** @type {{name: import("../../hooks/hooks").TransfersStatus, value: import("../../hooks/hooks").TransfersStatus}[]} */
export const transfersStatusFilterOptions = [
  { name: "pendingOut", value: "pendingOut" },
  { name: "rejectedOut", value: "rejectedOut" },
  { name: "pendingIn", value: "pendingIn" },
  { name: "rejectedIn", value: "rejectedIn" },
  { name: "accepted", value: "accepted" },
];
