import React, { useState, useEffect, useReducer, useMemo } from "react";
import DailySchedules from "../components/dailySchedules";
import Header from "../../common/header";
import ScrollToTop from "../../common/scrollToTop";
import InvalidParish from "../../common/invalidParish";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import BottomPrompt from "../components/bottomPrompt";
// import ParishLogo from "../../parish/parishLogoBlock";
import LinkButton from "../../blocks/linkButtonBlock";
import fire from "../../../services/fire";
import {
  getOrgByCode,
  getOrgBookingCutoffTimeById,
} from "../../../services/organisation";
import { getRoleName } from "../service/references";
import {
  getCurrentUserData as getCurrentUserDataFromDb,
  userLogout,
  getCurrentUser,
  getUserRoomBookings,
  // isChild,
} from "../../../services/user";
import {
  isSuperAdminRole,
  isSystemAdminRole,
  isSystemValidatorRole,
} from "../../roles/service/roles";
import {
  disableBooking,
  bookRoomStartDay,
  bookRoomEndDay,
} from "../../../services/settings";
import { logEvent } from "../../../services/log";
import UpdateInProgress from "../../staticpages/page/updateInProgress";
import { getLatestVersion } from "../../../services/getversion";
import ReloadModal from "../../common/reloadModal";
import ReportBug from "../../report/page/reportBug";
import DemoBar from "../../common/demoBar";
import {
  isEventClosed,
  isAllowedToBookRoom,
} from "../../../services/eventbookings";
import { getMonthlyRoomTimings } from "../../../services/event";
import {
  mapToDay,
  getEventDate,
  getLocalTime,
  getFirstLastDay,
  getNextBookingDays,
} from "../../../utils/date";
import MyBookingOption from "../../common/myBookingOption";
import NameClock from "../../common/nameClock";
import BookingSteps from "../components/bookingSteps";
import RoomScheduleCalendar from "../components/roomScheduleCalendar";
// import WarningBox from "../../common/warningBox";
import {
  getCalResources,
  getDailyCalEvents,
  getMonthlyCalEvents,
  calReducer,
} from "../service/calendarUtils";

function capitalise(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function RoomSchedules(props) {
  const { user, viewProps, setView, restoreView } = props;

  const orgCode = "alp";
  const [state, setState] = useState({
    user: user,
    eventtype: viewProps.event || "room",
    bookerRole: viewProps.bookerRoleDoc.type,
    bookerOrgs: viewProps.bookerRoleDoc.orgs,
    owner: {
      fullname: "",
      email: "",
      mobile: "+65",
    },
    eventschedule: [],
    // preferredOrg: viewProps.orgId,
    preferredOrgCode: orgCode,
    preferredMonth: viewProps.month.toLowerCase(),
    preferredYear: viewProps.year,
    loading: true,
    selected: 0,
    selectedItem: null,
  });

  async function getEventTimings(org, bookerRole, bookerOrgs) {
    const { preferredYear, preferredMonth } = state;
    const actualdate = `${preferredMonth} 01 ${preferredYear}`;
    const orgcode = org;

    let date = new Date(actualdate);
    let y = date.getFullYear();
    let m = date.getMonth();
    let firstDay = getFirstLastDay(m, y);
    let lastDay = getFirstLastDay(m, y, 1);
    let timings = await getMonthlyRoomTimings(
      orgcode,
      firstDay,
      lastDay,
      bookerRole,
      bookerOrgs
    );
    return timings;
  }

  function systemOverride(curUser) {
    let result = false;

    if (
      isSuperAdminRole(curUser) ||
      // isParishPriestRole(curUser) ||
      isSystemAdminRole(curUser) ||
      isSystemValidatorRole(curUser)
    ) {
      result = true;
    }
    return result;
  }

  async function getCurrentUserData() {
    const {
      bookerRole,
      bookerOrgs,
      preferredYear,
      preferredMonth,
      preferredOrgCode,
    } = state;
    const eventtype = state.eventtype;
    const uid = state.user.uid;
    //retrieve latest user data from firestore
    const user = await getCurrentUserDataFromDb(uid);

    if (user) {
      const origorg = orgCode;
      const { fullname, email, mobile, dob } = user;
      const orgcode = orgCode;
      const usereventbookingobj = await getUserRoomBookings(uid);
      const preferredorgcode = preferredOrgCode;
      const preferredorg = getOrgByCode(preferredorgcode);
      const curdate = `${preferredMonth}${preferredYear}`;

      const curUser = await getCurrentUser();
      const systemoverride = systemOverride(curUser);
      const allowed = isAllowedToBookRoom(orgcode, preferredorgcode);

      const enablewindow = parseInt(origorg) !== parseInt(orgcode);
      //do check if need to enable 2-day window
      const [start, end] = getNextBookingDays(
        bookRoomStartDay(),
        bookRoomEndDay()
      );
      const schedulelist = await generateScheduleList(
        systemoverride,
        preferredorg,
        usereventbookingobj,
        bookerRole,
        bookerOrgs,
        enablewindow,
        start,
        end
      );

      const localtime = getLocalTime();
      const version = await getLatestVersion();
      setState((oldState) => ({
        ...oldState,
        start,
        end,
        schedulelist,
        bookerRole,
        bookerOrgs,
        owner: {
          fullname,
          email,
          mobile,
          orgId: orgcode,
          dob,
          orgcode: preferredorgcode,
        },
        loading: false,
        version: version?.version,
        localtime,
      }));
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  async function generateScheduleList(
    systemoverride,
    org,
    usereventbookingobj,
    bookerRole,
    bookerOrgs,
    enablewindow = false,
    start = 1,
    end = 2
  ) {
    const { preferredMonth, preferredOrgCode } = state;
    const preferredorgcode = preferredOrgCode;
    const bookingcutofftime = getOrgBookingCutoffTimeById(org._id);

    // const bookingenabled = true;
    // const nextbookingenabled = true;

    let eventschedule = [];
    // if (
    //   (bookingenabled === true && preferredMonth === getPreviousMonth()) ||
    //   (nextbookingenabled === true && preferredMonth === getNextMonth()) ||
    //   systemoverride === true
    // ) {
    eventschedule = await getEventTimings(
      preferredorgcode,
      bookerRole,
      bookerOrgs
    );
    const userbookedroomIds =
      usereventbookingobj?.map((doc) => doc.roomId) ?? [];
    //now process here
    const localtime = getLocalTime();

    //loop through the entire events
    for (let i = 0; i < eventschedule.length; i++) {
      //add check here to force disable the schedule if beyond the 180-day window
      if (enablewindow) {
        if (
          eventschedule[i].date.seconds <= start ||
          eventschedule[i].date.seconds >= end
        ) {
          //mark disable so we can still see the seats
          eventschedule[i].disabled = true;
          //don't display???
          // eventschedule[i].hide = true;
        }
      }
      //do logic to display close - this close is based on 48 hour limit
      const roomclosed = isEventClosed(
        eventschedule[i],
        localtime,
        bookingcutofftime
      );

      // if (
      //   bookerRole !== "su" &&
      //   !eventschedule[i].allowedTenantRoles?.includes(bookerRole)
      // ) {
      //   eventschedule[i].hide = true;
      // } else
      if (roomclosed) {
        eventschedule[i].closed = roomclosed;
      } else if (userbookedroomIds.includes(eventschedule[i].id)) {
        eventschedule[i].booked = true;
        eventschedule[i].disabled = true;
      }
    }
    // }

    return eventschedule;
  }

  useEffect(() => {
    getCurrentUserData();
    window.scrollTo(0, 0);
  }, []);

  const handleRoomSelection = (item) => {
    //this is intended to trigger refresh of the related components
    setState((oldState) => ({
      ...oldState,
      selected: item.id,
      selectedItem: item,
    }));
  };

  const handleButtonContinue = (roomUsageDetails) => {
    const {
      preferredOrgCode,
      preferredMonth,
      preferredYear,
      selected: id,
      bookerRole,
      bookerOrgs,
      // user,
    } = state;

    setView({
      name: "review",
      props: {
        org: preferredOrgCode,
        year: preferredYear,
        month: preferredMonth,
        schedule: id,
        bookerRole,
        bookerOrg: bookerOrgs[0],
        roomUsageDetails,
      },
    });
  };

  function noRoomSlots() {
    const { user } = state;
    return (
      <>
        <Header type="backandlogo" smallpic={user.photoURL} />
        {/* <ParishLogo parish={parish} /> */}

        <div className="row justify-content-center mx-0">
          <div className="col-lg-6 px-1">
            <main className="container">
              <div className="pb-3 pt-2 px-0">
                <h2 className="mb-0 text-danger">No Rooms Available</h2>
                <div className="pt-3 pb-0 form-group">
                  <p className="defaultfontsize">
                    Sorry, there are currently no rooms available for booking
                    for{" "}
                    <span className="bold">
                      {capitalise(state.preferredMonth)} {state.preferredYear}
                    </span>
                    .
                  </p>
                  <p className="defaultfontsize">
                    Please check back again later.
                  </p>

                  {/* <p className="defaultfontsize">
                    Thank you for your patience.
                  </p> */}
                </div>
                {/* <button
                  className="btn btn-lg btn-block btn-primary"
                  onClick={() => window.location.reload()}
                >
                  Press to Reload
                </button> */}
                <button
                  className="btn btn-lg btn-block btn-link"
                  onClick={() => restoreView("landing")}
                >
                  Select another Role / Month
                </button>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }

  const {
    owner,
    schedulelist,
    preferredOrgCode,
    preferredMonth,
    loading,
    version,
    selected,
    selectedItem,
    localtime,
  } = state;

  const org = getOrgByCode(preferredOrgCode);

  const calEvents = useMemo(
    () => schedulelist?.filter((event) => !event?.hide && !event?.closed),
    [schedulelist]
  );
  const calResources = useMemo(
    () => (schedulelist != null ? getCalResources(calEvents) : []),
    [schedulelist]
  );
  const dailyCalEvents = useMemo(
    () =>
      schedulelist != null
        ? getDailyCalEvents(calEvents, state.bookerRole)
        : [],
    [schedulelist]
  );
  const monthlyCalEvents = useMemo(
    () => (schedulelist != null ? getMonthlyCalEvents(calEvents) : []),
    [schedulelist]
  );
  const firstEventDate = dailyCalEvents[0]?.startDate;

  const initialState = {
    curView: "month",
    firstEventDate,
    date: firstEventDate,
    dailyCalEvents,
    monthlyCalEvents,
    events: monthlyCalEvents,
    resources: calResources,
    onEventSelect: handleRoomSelection,
  };
  const [calState, calDispatch] = useReducer(calReducer, initialState);
  useEffect(() => {
    calDispatch({ type: "reset", payload: initialState });
  }, [schedulelist]);

  if (!org) {
    return <InvalidParish smallpic={user.photoURL} />;
  }

  if (loading === true) {
    return <LoadingSpinnerNav type="backandlogo" smallpic={user.photoURL} />;
  }

  if (disableBooking()) {
    return <UpdateInProgress />;
  }

  if (schedulelist.length === 0) {
    return noRoomSlots(org);
  }
  // let hasHidden = false;
  let prevDate = "";
  let prevDay = "";

  logEvent("roombooking_schedule_load", {
    email: user.email,
    org: owner.orgId,
    month: preferredMonth,
  });

  return (
    <>
      <Header type="backandlogo" smallpic={user.photoURL} />
      <DemoBar />
      <ReloadModal />
      {/* <ParishLogo parish={parish} /> */}
      <div className="row justify-content-center mx-0">
        <div className="col-xl-6 px-1">
          <main className="container">
            <div className="pb-3 pt-2 px-0">
              <div className="text-center">
                <NameClock name={owner.fullname} />
              </div>
              <div className="row pt-0 px-2 pb-0 justify-content-center text-center defaultfontsize">
                <MyBookingOption />
              </div>

              <div className="py-4">
                <BookingSteps step={1} />
              </div>
              <hr />
              <div className="text-center mb-2">
                <h2 className="mb-0">Available Rooms</h2>
                <div className="mediumfontsize">
                  Booking as <strong>{getRoleName(state.bookerRole)}</strong>
                </div>
                <button
                  className="btn btn-lg btn-block btn-link"
                  onClick={() => restoreView("landing")}
                >
                  Select another Role / Month
                </button>
              </div>
              <div className="clearfix"></div>
              <div className="pt-2 form-group d-block d-md-none">
                {schedulelist.map((event) => {
                  // This manually configures the labels and separator
                  const { id, day, date } = event;
                  let separator = "";
                  let labelday = mapToDay(day);
                  let labeldate = getEventDate(date);
                  const completedate = `${labelday}${labeldate}`;
                  const prevcompletedate = `${prevDay}${prevDate}`;

                  // Start: This logic determines the separator and the labels to indicate groupings
                  if (prevcompletedate !== completedate) {
                    if (prevDay === "Sunday") {
                      separator = (
                        <div
                          className="py-3 mb-5"
                          style={{ borderBottom: "2px dashed #aaaaaa" }}
                        ></div>
                      );
                    }
                    prevDay = labelday;
                    prevDate = labeldate;
                  } else {
                    //remove labels if the dates are the same
                    separator = "";
                    labelday = "";
                    labeldate = "";
                  }
                  // End
                  const isclosed = event.closed || event?.disabled;
                  //if hidden
                  if (event?.hide === true) {
                    // hasHidden = true;
                    return null;
                  }
                  return (
                    <DailySchedules
                      {...props}
                      key={id}
                      details={event}
                      labelday={labelday}
                      labeldate={labeldate}
                      separator={separator}
                      closed={isclosed}
                      overridelabel={""}
                      selectedTime={state.selected}
                      bookerRole={state.bookerRole}
                      bookerOrgs={state.bookerOrgs}
                      onRoomSelection={() => handleRoomSelection(event)}
                    />
                  );
                })}
              </div>
              <div className="py-3 d-none d-md-block">
                <RoomScheduleCalendar
                  calState={calState}
                  calDispatch={calDispatch}
                />
              </div>
              <ScrollToTop />
              <LinkButton type="link" link="/home" label="Back to Home" />
              <BottomPrompt
                label="Agree and Continue"
                version={version}
                selected={selected}
                selectedItem={selectedItem}
                onHide={() => calDispatch({ type: "deselectEvent" })}
                handleButtonContinue={handleButtonContinue}
                detailsRequired={selectedItem?.paxLimit > 1}
                bookerOrg={state.bookerOrgs[0]}
                bookerRole={state.bookerRole}
              />
              <div className="py-4 my-4">
                <ReportBug
                  context={state}
                  rendertime={localtime}
                  page="Room Schedule"
                  component="roomSchedule"
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default RoomSchedules;
