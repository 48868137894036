import React from 'react';

import '../../stylesheets/finance-qr-modal.css';

const QRSetupStatus = ({ status = 0 }) => {

  const isSelected = (index) => {
    return index <= status;
  };

  return (
    <div className="create-qr-modal-status__container ">
      <div className="create-qr-modal-status-bar__container">
        <div className="create-qr-modal-status__icon--selected">1</div>
        <div className={isSelected(1) ?
          "create-qr-modal-status__divider--selected" : "create-qr-modal-status__divider"} />
        <div className={isSelected(1) ?
          "create-qr-modal-status__icon--selected" : "create-qr-modal-status__icon"}>
          2
        </div>
        <div className={isSelected(2) ?
          "create-qr-modal-status__divider--selected" : "create-qr-modal-status__divider"} />
        <div className={isSelected(2) ?
          "create-qr-modal-status__icon--selected" : "create-qr-modal-status__icon"}>
          3
        </div>
      </div>
      <div className="create-qr-modal-status-bar__container">
        <div className="create-qr-modal-status__header--selected">Edit QR Details</div>
        <div className={isSelected(1) ?
          "create-qr-modal-status__header--selected" : "create-qr-modal-status__header"}>
          Assign QR
        </div>
        <div className={isSelected(2) ?
          "create-qr-modal-status__header--selected" : "create-qr-modal-status__header"}>
          Final Confirmation
        </div>
      </div>

    </div >
  )
};

export default QRSetupStatus