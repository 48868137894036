import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAdminEmailPage({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/AdminEmailPage"));
  console.log("Loading", AsyncAdminEmailPage.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAdminEmailPage;
