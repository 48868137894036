import React, { Component } from "react";
import Header from "./header";
import SimpleMessage from "../blocks/simpleMessageBlock";

class InvalidParish extends Component {
  render() {
    return (
      <React.Fragment>
        <Header type="backandlogo" smallpic={this.props.smallpic}/>
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-4 pt-5">
            <main className="container">
              <SimpleMessage
                title="Ooops"
                content="Seems like you selected an invalid Parish."
                link="/home"
                label="Back to Home"
              />
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InvalidParish;
