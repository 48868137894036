import {
  getMassDate,
  isSaturdayWeekendMass,
  isSundayWeekendMass,
  getLocalTime,
  getSGTime,
} from "../utils/utils";
import { getAllowChangeParishDate } from "./settings";
import fire from "./fire";

export async function getUserBookingById(parishionerid, eventid) {
  const db = fire.firestore();
  var parishionerbookings = db
    .collection("parishionerbookings")
    .where("massid", "==", eventid)
    .where("parishionerid", "==", parishionerid);

  const result = await parishionerbookings.get();
  let booking = [];
  let counter = 0;
  result.forEach(function (doc) {
    const data = doc.data();
    // console.log(
    //   `getUserBookingById -- User ID: ${parishionerid} Event ID: ${eventid} Count: ${counter}`
    // );
    if (counter === 0) {
      //only save the first one in case there's duplicate booking
      booking.push(data);
    }
    counter++;
  });
  return booking;
}

export async function getLatestUserBookings(parishionerid, count = 2) {
  const db = fire.firestore();
  var parishionerbookings = db
    .collection("parishionerbookings")
    .where("parishionerid", "==", parishionerid)
    .limit(count)
    .orderBy("massdate", "desc");
  const result = await parishionerbookings.get();
  let booking = [];
  result.forEach(function (doc) {
    const data = doc.data();
    // console.log(
    //   `getUserBookingById -- User ID: ${parishionerid} Event ID: ${eventid} Count: ${counter}`
    // );
    booking.push(data);
  });
  return booking;
}

export function getSortedMassBookings(lastmassbooking) {
  var massbookingobj = {};
  if (lastmassbooking.hasOwnProperty("massparishid")) {
    //this means parishioner has booked before we allow multiple bookings, NO NEED TO SORT
    massbookingobj = [lastmassbooking];
  } else {
    //SORT BOOKINGS HERE
    let tmpobj = lastmassbooking.sort(datecompare);

    massbookingobj = tmpobj;
  }
  return massbookingobj;
}

export function getUniqueBookedSaturdayDates(bookings) {
  let uniqueBookedDates = [];
  //iterate the entire bookings and get the dates
  if (bookings && bookings.length > 0) {
    for (let i = 0; i < bookings.length; i++) {
      if (isSaturdayWeekendMass(bookings[i].massdate)) {
        let labeldate = getMassDate(bookings[i].massdate);
        if (uniqueBookedDates.indexOf(labeldate) === -1) {
          //push ONLY if it does not exists
          uniqueBookedDates.push(labeldate);
        }
      }
    }
  }
  //get the unique dates
  return uniqueBookedDates;
}

export function getUniqueBookedSundayDates(bookings) {
  let uniqueBookedDates = [];
  //iterate the entire bookings and get the dates
  if (bookings && bookings.length > 0) {
    for (let i = 0; i < bookings.length; i++) {
      if (isSundayWeekendMass(bookings[i].massdate)) {
        let labeldate = getMassDate(bookings[i].massdate);
        if (uniqueBookedDates.indexOf(labeldate) === -1) {
          //push ONLY if it does not exists
          uniqueBookedDates.push(labeldate);
        }
      }
    }
  }
  //get the unique dates
  return uniqueBookedDates;
}

export function getUniqueBookedDates(bookings) {
  let uniqueBookedDates = [];
  //iterate the entire bookings and get the dates
  if (bookings && bookings.length > 0) {
    for (let i = 0; i < bookings.length; i++) {
      let labeldate = getMassDate(bookings[i].massdate);
      if (uniqueBookedDates.indexOf(labeldate) === -1) {
        //push ONLY if it does not exists
        uniqueBookedDates.push(labeldate);
      }
    }
  }
  //get the unique dates
  return uniqueBookedDates;
}

export function isAllowToChangeParish(bookings) {
  let allowchange = false;
  if (bookings) {
    const sortedbookings = getSortedMassBookings(bookings);

    const latestbooking = sortedbookings[0];

    const localtime = getLocalTime() / 1000;
    const last = latestbooking.massdate.seconds;
    let daystochange = getAllowChangeParishDate();
    let hourstochangemsec = daystochange * 24 * 60 * 60;
    if (localtime - last > hourstochangemsec) {
      allowchange = true;
    }
  } else {
    // no bookings at all, allow to change
    allowchange = true;
  }
  return allowchange;
}

export function isAllowToChangeParishwithLastChange(bookings) {
  let allowchange = false;
  let lastbookingok = true;
  let previousbookingok = true;
  let futurebookings = false;
  let totalbookings = 0;
  if (bookings) {
    const sortedbookings = getSortedMassBookings(bookings);
    const latestbooking = sortedbookings[0];
    const localtime = getSGTime(new Date()).getTime() / 1000;

    const last = latestbooking.massdate.seconds;
    const daystochange = getAllowChangeParishDate();
    const hourstochangemsec = daystochange * 24 * 60 * 60;
    if (sortedbookings.length >= 2) {
      const previousbooking = sortedbookings[1];
      const previous = previousbooking.massdate.seconds;
      if (localtime - previous < hourstochangemsec) {
        previousbookingok = false;
      }
    }

    if (localtime - last > hourstochangemsec) {
      allowchange = true;
    }
    if (localtime < last) {
      futurebookings = true;
    }
    if (localtime - last < hourstochangemsec) {
      lastbookingok = false;
    }
    totalbookings = sortedbookings.length;
  } else {
    // no bookings at all, allow to change
    allowchange = true;
    futurebookings = false;
  }
  console.log({ previousbookingok });
  return [
    allowchange,
    futurebookings,
    lastbookingok,
    previousbookingok,
    totalbookings,
  ];
}

function datecompare(a, b) {
  if (a.massdate.seconds > b.massdate.seconds) {
    return -1;
  }
  if (a.massdate.seconds < b.massdate.seconds) {
    return 1;
  }
  return 0;
}
