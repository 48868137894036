/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import { Button, Image, Modal } from "react-bootstrap";
import { formatTime } from "../../../utils/utils";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import fire from "../../../services/fire";
import NewlineText from "../../common/newlineText";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { logEvent } from "../../../services/log";
import ShowMoreText from "react-show-more-text";
import { validateYouTubeUrl } from "../../../services/validation";
import YoutubeEmbed from "../../common/youtubeEmbed";
import { getUserLikesById } from "../../../services/like";
import YouTubeIcon from "@material-ui/icons/YouTube";
import "../css/index.css";

class PostCard extends Component {
  constructor(props) {
    super(props);
    const {
      user,
      variant,
      item,
      decorator,
      deletehandler,
      status,
      likehandler,
      showstats,
      source,
      likecallback,
    } = props;

    this.state = {
      user,
      variant,
      item,
      decorator,
      deletehandler,
      status,
      likehandler,
      showstats,
      showmodal: false,
      showmore: false,
      source,
      likecallback,
      heartprop: "heart",
    };
  }

  componentDecorator = (href, text, key) => {
    let display = (
      <>
        Click here <LaunchOutlinedIcon className="mediumfontsize" />
      </>
    );
    if (href.startsWith("mailto")) {
      display = text;
    }
    return (
      <a href={href} key={key} rel="noopener noreferrer" target="_blank">
        {display}
      </a>
    );
  };

  datecompare(a, b) {
    if (a.created > b.created) {
      return -1;
    }
    if (a.created < b.created) {
      return 1;
    }
    return 0;
  }

  likeHandler = async (item) => {
    const likecallback = this.state.likecallback;
    const user = this.state.user;
    const uid = user.userid;
    logEvent("like_clicked", {
      email: user.email,
      parish: user.parish,
    });
    this.setState({
      liking: true,
      heartprop: "heart heartclick is_animating",
    });
    const localtime = new Date().getTime();
    const type = "instaposts";
    //add to user to optimize
    const tempobj = {
      created: localtime,
      header: item.header,
      type: type,
      objid: item.id,
    };

    //get latest likes here
    const userRef = fire.firestore().doc(`users/${uid}`);

    const likes = await getUserLikesById(uid);

    //append to front, sort and get only 30
    let temp;
    if (likes && likes.length) {
      //check if item is already in likeobj
      let liked = false;
      for (let i = 0; i < likes.length; i++) {
        if (likes[i].objid === item.id) {
          liked = true;
        }
      }

      temp = liked ? [...likes] : [tempobj, ...likes];
    } else {
      temp = [tempobj];
    }
    //sort by date
    let likeobj = temp.sort(this.datecompare);
    //ignore the rest
    likeobj = likeobj.slice(0, 30);

    //update user in DB
    await userRef.update({ likes: likeobj });
    //like here
    const like = fire.functions("asia-east2").httpsCallable("like");
    const obj = { header: item.header, objid: item.id, objtype: type };
    //call now
    await like({
      actor: user,
      obj: obj,
    });
    if (likecallback) {
      //call callback
      likecallback(likeobj);
    }
    this.setState({
      item: {
        ...item,
        likes: item.likes + 1,
        liked: true,
      },
      liking: false,
      heartprop: "heartclick",
    });
  };

  handleClose = () => {
    this.setState({ showmodal: false });
  };

  showModal = () => {
    const showmodal = this.state.showmodal;
    const likers = this.state.likers;
    return (
      <Modal
        show={showmodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <span className="font-weight-bold">View Likes</span>
        </Modal.Header>
        <Modal.Body className="">
          {likers &&
            likers.map((item) => {
              const photo = item.smallpic;
              return (
                <div key={item.created}>
                  <div className="float-left" style={{ width: "50px" }}>
                    {!photo && (
                      <AccountCircleRoundedIcon
                        className="nophotoiconcolor"
                        style={{
                          fontSize: "36px",
                          marginRight: "5px",
                          marginLeft: "3px",
                          marginTop: "0px",
                        }}
                      />
                    )}
                    {photo && (
                      <Image
                        src={photo}
                        width="30px"
                        height="30px"
                        alt=""
                        roundedCircle
                        style={{
                          fontSize: "40px",
                          marginRight: "5px",
                          marginLeft: "5px",
                          marginTop: "5px",
                        }}
                      />
                    )}
                  </div>
                  <div
                    className="font-weight-bold text-truncate"
                    style={{ paddingTop: "5px" }}
                  >
                    {item.fullname}
                  </div>
                  <div className="clearfix"></div>
                </div>
              );
            })}
        </Modal.Body>
      </Modal>
    );
  };

  executeOnClick = () => {
    const user = this.state.user;
    let showmore = !this.state.showmore;
    this.setState({ showmore });
    logEvent("post_seemore_click", {
      email: user.email,
      parish: user.parish,
    });
  };

  viewLikesHandler = async (item) => {
    const source = this.state.source;
    const user = this.state.user;
    logEvent(`post_${source}_view_likes`, {
      email: user.email,
      parish: user.parish,
    });
    const db = fire.firestore();
    const results = await db
      .collection("likes")
      .where("objid", "==", item.id)
      .orderBy("timestamp", "desc")
      .limit(25)
      .get();
    let likers = [];
    results.forEach((doc) => {
      likers.push(doc.data());
    });
    this.setState({ likers, showmodal: true });
  };

  clickHeart = async (item) => {
    const liking = this.state.liking;
    if (liking) {
      return;
    }
    let heartprop = "heart is_animating";
    this.setState({ heartprop });
    if (!item.liked) {
      await this.likeHandler(item);
    } else {
      await this.viewLikesHandler(item);
    }
  };

  onMouseOver = (item) => {
    const heartprop = this.state.heartprop;
    this.setState({ heartprop: `${heartprop} heartclick` });
  };

  onMouseOut = (item) => {
    let heartprop = this.state.heartprop;
    const animating = heartprop.split(" ");
    if (animating.includes("is_animating")) {
      return;
    }

    if (!item.liked) {
      heartprop = "heart";
    }

    this.setState({ heartprop });
  };

  render() {
    const { item, variant, deletehandler, showstats, showmore } = this.state;
    if (!item || item.length === 0) {
      return null;
    }
    let uploadedphoto = item?.bigphoto || "";
    let photo = item.smallpic;
    let likestatus = item.liked;
    let text = "Like";
    if (item.likes === 1) {
      text = `1 Like`;
    } else if (item.likes > 1) {
      text = `${item.likes} Likes`;
    }
    let padding = "mt-0 pt-4";
    let readpadding = "pt-0 pb-0 mb-0";
    if (showmore) {
      readpadding = "pt-0 pb-4 mb-0";
      padding = "mt-2 pt-0";
    }
    const showdetails = item.likes === 0 ? false : showstats;
    const forceshow = true;
    //replacing newline with space + newline to fix asdfasdfasdfasdf
    let content = item.content.replace(/\n/g, " \n");
    let youtubeId = null;
    let youtubelink = "https://youtu.be/";
    if (item.youtube && validateYouTubeUrl(item.youtube)) {
      //get youtubeId from url
      let r,
        rx =
          /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

      r = item.youtube.match(rx);
      if (r[1]) {
        youtubeId = r[1];
      }
    }
    //youtube found
    if (youtubeId) {
      youtubelink = `${youtubelink}${youtubeId}`;
      youtubelink = (
        <div className="pt-3 text-left">
          <YouTubeIcon className="text-danger" />{" "}
          <a href={youtubelink} rel="noopener noreferrer" target="_blank">
            {youtubelink}
          </a>
        </div>
      );
    }
    let heartprop = this.state.heartprop;
    if (likestatus) {
      heartprop = "heart heartclick";
    }
    content = (
      <>
        <ShowMoreText
          lines={3}
          more="Read more"
          less="Read less"
          anchorClass="seemore pointer"
          onClick={this.executeOnClick}
          expanded={false}
        >
          <NewlineText text={content} />
        </ShowMoreText>
      </>
    );
    return (
      <div className={`darkgraypanel roundcorner my-3 pb-4 px-0 ${variant}`}>
        {/* // <div className={` my-3 `} style={{backgroundColor:"#f7fafb"}}> */}
        <div className="text-muted pt-4 px-3">
          <div className="">
            <div className="text-left float-left">
              <div className="text-left float-left">
                {/* <Link to="/profile" style={{ textDecoration: "none" }}> */}
                <>
                  <div className="" style={{ width: "50px" }}>
                    {!photo && (
                      <AccountCircleRoundedIcon
                        className="nophotoiconcolor"
                        style={{
                          fontSize: "36px",
                          marginRight: "5px",
                          marginLeft: "3px",
                          marginTop: "0px",
                        }}
                      />
                    )}
                    {photo && (
                      <Image
                        src={photo}
                        width="40px"
                        height="40px"
                        alt=""
                        roundedCircle
                        style={{
                          fontSize: "40px",
                          marginRight: "5px",
                          marginTop: "0px",
                        }}
                      />
                    )}
                  </div>
                </>
                {/* </Link> */}
              </div>
              <div className="text-left float-left">
                {/* <Link to="/" style={{ textDecoration: "none" }}> */}
                <h6 className="pb-0 mb-0 text-dark">{item.author}</h6>
                {/* </Link> */}
                {/* <WatchLaterOutlinedIcon
                                style={{ fontSize: "12px" }}
                              />{" "} */}
                <small className="text-muted font-weight-light">
                  {new Date(item.created).toDateString()} &bull;{" "}
                  {formatTime(item.created)}
                </small>
              </div>
            </div>
            {deletehandler && (
              <div className="text-right float-right pull-right">
                <Button
                  variant="link"
                  className="float-right btn-sm text-danger p-1"
                  onClick={() => deletehandler(item.id, item.created)}
                >
                  <div className="float-right" style={{ width: "25px" }}>
                    <DeleteOutlineOutlinedIcon
                      style={{
                        fontSize: "18px",
                        marginTop: "-2px",
                      }}
                    />
                  </div>
                  {/* <div className="float-left">Delete</div> */}
                </Button>
              </div>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <p
          className="px-3 font-weight-bold pt-2 pb-0 mb-0 text-primary"
          style={{ marginLeft: "0px" }}
        >
          {item.header}
        </p>
        <div
          className={`px-3 ${readpadding} text-dark`}
          style={{ marginLeft: "0px" }}
        >
          {content}
        </div>
        <div className="clearfix"></div>
        {youtubeId && uploadedphoto === "" && (
          <div className={padding}>
            <YoutubeEmbed
              className="text-left mt-0 mb-0"
              youtubeid={youtubeId}
            />
          </div>
        )}
        {uploadedphoto && (
          <div className={`${padding} img-fluid`}>
            <img src={uploadedphoto} alt="" className="img-fluid responsive" />
          </div>
        )}
        {uploadedphoto && youtubeId && (
          <div className="px-3 text-left">{youtubelink}</div>
        )}
        {/* <div className="py-0 text-dark">
          <hr
            style={{
              backgroundColor: "#ddd",
              height: "1px",
              border: "0",
              marginBottom: "11px",
            }}
          />
        </div> */}
        <div
          className="pt-3 px-3"
          style={{
            // borderBottom: "1px solid #ddd",
            paddingBottom: "25px",
          }}
        >
          {/* <div className="float-left">
            {!likestatus ? (
              <div
                className="text-dark pointer"
                style={{ width: "100px" }}
                onClick={() => this.likeHandler(item)}
              >
                <LikeIcon variant="heart" active={false} /> {text}
              </div>
            ) : (
              <div
                className="text-primary pointerhover"
                style={{ width: "100px" }}
                onClick={() => this.viewLikesHandler(item)}
              >
                <LikeIcon variant="heart" active={true} color="text-danger" />{" "}
                <span className="actualtext">{text}</span>
              </div>
            )}
          </div> */}
          <div
            className="float-left p-0 m-0 pointer likediv"
            onClick={() => this.clickHeart(item)}
            onMouseOver={() => this.onMouseOver(item)}
            onMouseOut={() => this.onMouseOut(item)}
          >
            <div className={`adjustheart float-left ${heartprop}`}></div>
            <div className="float-left adjustleft">{text}</div>
          </div>

          {!forceshow && showdetails && (
            <div className="float-right">
              <div
                className="text-primary pointerhover"
                onClick={() => this.viewLikesHandler(item)}
              >
                <FormatListBulletedIcon className="adjusticon defaultfontsize" />{" "}
                <span className="actualtext">View Likes</span>
              </div>
            </div>
          )}
          {this.props.viewmorecomponent}
        </div>
        {this.showModal()}
      </div>
    );
  }
}
export default PostCard;
