import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncMobileAnalytics({ ...rest }) {
  const AsyncLoad = loadable(() => import("../admin/page/MobileAnalytics"));
  console.log("Loading", AsyncMobileAnalytics.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncMobileAnalytics;
