import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncFormPage({ ...rest }) {
  const AsyncLoad = loadable(() => import("../FormPage"));
  console.log("Loading", AsyncFormPage.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncFormPage;
