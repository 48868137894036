import fire from "./fire";

export async function checkUser(email, source = "unknown", obj = {}) {
  const checkUser = fire.functions("asia-east2").httpsCallable("checkUser");
  await checkUser({
    email: email?.trim(),
    source: source?.trim(),
    obj: obj,
  });
}
