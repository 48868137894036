import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useHistory, Link } from "react-router-dom";
import fire from "../../../../services/fire";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { useForm, Controller } from "react-hook-form";

import Header from "../../../common/header";

import AppGlobalContext from "../../../../AppGlobalContext";
import {
  useActiveRegistration,
  useGuardian,
  useParishesConfig,
  useWardSelection,
} from "../hooks/hooks";

import {
  catchContactUpdatesCollectionName,
  registrationCollectionName,
} from "../services/collections";
import detectParish from "../services/detectParish";
import { isValidPage } from "../services/registrationStatus";
import { maritalStatusList, religionList } from "../services/resources";

import ForbiddenRoute from "../components/ForbiddenRoute";

async function handleSaveData(
  registrationCollection,
  catchContactUpdatesCollection,
  registrationDetails,
  contactUpdateData,
  ward,
  uid,
  callback
) {
  try {
    if (!ward || !ward.uid || !uid) {
      return;
    }

    const mainContact = contactUpdateData.mainContact;
    const secondaryContact = contactUpdateData.secondaryContact;

    let registrationDoc, contactUpdateHistoryDoc;

    if (registrationDetails.id) {
      registrationDoc = registrationCollection.doc(registrationDetails.id);

      const docData = {
        ...registrationDetails,
        postalCode: contactUpdateData.postalCode || "",
        roadName: contactUpdateData.roadName || "",
        blkNo: contactUpdateData.blkNo || "",
        floorNumber: contactUpdateData.floorNumber || "",
        unitNumber: contactUpdateData.unitNumber || "",
        boundary: contactUpdateData.boundary || "",
        fatherReligion: contactUpdateData.fatherReligion || "",
        motherReligion: contactUpdateData.motherReligion || "",
        school: contactUpdateData.school || "",
        mainContact,
        secondaryContact,
      };

      await registrationDoc.update(docData);

      contactUpdateHistoryDoc = catchContactUpdatesCollection.doc();

      const contactUpdateHistoryDocData = {
        id: contactUpdateHistoryDoc.id,
        createdAt: new Date(),
        createdBy: uid,
        registrationId: registrationDetails.id || "",
        child: {
          name: ward.name,
          uid: ward.uid,
        },
        guardianId: registrationDetails.submittedBy || "",
        selectedParishId: registrationDetails.selectedParishId || "",
        original: {
          postalCode: registrationDetails.postalCode || "",
          roadName: registrationDetails.roadName || "",
          blkNo: registrationDetails.blkNo || "",
          floorNumber: registrationDetails.floorNumber || "",
          unitNumber: registrationDetails.unitNumber || "",
          boundary: registrationDetails.boundary || "",
          fatherReligion: registrationDetails.fatherReligion || "",
          motherReligion: registrationDetails.motherReligion || "",
          school: registrationDetails.school || "",
          mainContact: registrationDetails.mainContact,
          secondaryContact: registrationDetails.secondaryContact,
        },
      };

      await contactUpdateHistoryDoc.set(contactUpdateHistoryDocData);

      if (typeof callback === "function") {
        await callback();
      }

      window.scrollTo(0, 0);
    }
  } catch (error) {
    console.error("Error in handleSaveData:", error);

    alert("An error has occured");

    window.scrollTo(0, 0);
  }
}

function ContactUpdateForm({ user, ward, registrationDetails }) {
  const history = useHistory();
  const parishesConfig = useParishesConfig();

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const catchContactUpdatesCollection = db.collection(
    catchContactUpdatesCollectionName
  );

  const {
    register,
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      postalCode: "",
      roadName: "",
      blkNo: "",
      floorNumber: "",
      unitNumber: "",
      boundary: "",
      fatherReligion: "",
      motherReligion: "",
      school: "",
      mainContact: {
        name: "",
        relationship: "Father",
        relationshipOthers: "",
        maritalStatus: "",
        religion: "",
        marriedInCatholicChurch: "",
        parishInvolvement: "",
        email: "",
        mobileNo: "+65",
        homeNo: "+65",
        volunteer: false,
      },
      secondaryContact: {
        exists: "",
        name: "",
        relationship: "",
        relationshipOthers: "",
        legalGuardian: "",
        email: "",
        mobileNo: "+65",
        volunteer: false,
        emergencies: false,
      },
    },
  });

  const effectTriggeredRef = useRef(false);

  useEffect(() => {
    if (!effectTriggeredRef.current && registrationDetails.id) {
      effectTriggeredRef.current = true;
      const values = {
        postalCode: registrationDetails.postalCode || "",
        roadName: registrationDetails.roadName || "",
        blkNo: registrationDetails.blkNo || "",
        floorNumber: registrationDetails.floorNumber || "",
        unitNumber: registrationDetails.unitNumber || "",
        boundary: registrationDetails.boundary || "",
        fatherReligion: registrationDetails.fatherReligion || "",
        motherReligion: registrationDetails.motherReligion || "",
        school: registrationDetails.school || "",
        mainContact: {
          name: "",
          relationship: "Father",
          relationshipOthers: "",
          maritalStatus: "",
          religion: "",
          marriedInCatholicChurch: "",
          parishInvolvement: "",
          email: "",
          mobileNo: "+65",
          homeNo: "+65",
          volunteer: false,
          contact: "",
        },
        secondaryContact: {
          exists: null,
          name: "",
          relationship: "",
          relationshipOthers: "",
          email: "",
          mobileNo: "+65",
          volunteer: false,
          emergencies: false,
        },
      };

      if (registrationDetails.mainContact) {
        values.mainContact = registrationDetails.mainContact;
      }

      if (registrationDetails.secondaryContact) {
        values.secondaryContact = registrationDetails.secondaryContact;
      }

      reset({ ...values });
    }

    return () => {
      effectTriggeredRef.current = false;
      reset();
    };
  }, [registrationDetails]);

  const callBack = useCallback(
    () =>
      history.push({
        pathname: "/catch",
        updateContactSuccess: true,
      }),
    [history]
  );

  function onSubmit() {
    const contactUpdateData = getValues();

    handleSaveData(
      registrationCollection,
      catchContactUpdatesCollection,
      registrationDetails,
      contactUpdateData,
      ward,
      user.uid,
      callBack
    );
  }

  const inputPostalCode = watch("postalCode");
  const boundary = watch("boundary");

  const [inputCount, setInputCount] = useState(0);

  useEffect(() => {
    if (inputCount > 1) {
      setValue("roadName", "");
      setValue("blkNo", "");
      setValue("boundary", "");

      clearErrors("postalCode");
    } else {
      setInputCount(inputCount + 1);
    }
  }, [inputPostalCode]);

  const mainRelationship = watch("mainContact.Relationship");
  const secondaryContactExists = watch("secondaryContact.exists");
  const secondaryRelationship = watch("secondaryContact.relationship");
  const secondaryEmergencies = watch("secondaryContact.emergencies");

  let showMainOthers = false,
    showSecondary = false,
    showSecondaryOthers = false;

  if (mainRelationship === "Others") {
    showMainOthers = true;
  }

  if (secondaryContactExists) {
    showSecondary = true;
  }

  if (secondaryRelationship === "Others") {
    showSecondaryOthers = true;
  }

  async function getAddress() {
    const boundaries = await detectParish(inputPostalCode);

    if (boundaries !== -1) {
      if (boundaries.parishId) {
        setValue("roadName", boundaries.loc.ROAD_NAME);
        setValue("blkNo", boundaries.loc.BLK_NO);
        setValue("boundary", boundaries.parishId);

        clearErrors("postalCode");

        return () => {};
      }
    }

    setValue("roadName", "");
    setValue("blkNo", "");
    setValue("boundary", "");

    setError("postalCode");
  }

  let homeParish = "";

  if (boundary) {
    const boundayParish = parishesConfig.find(({ id }) => id == boundary);

    if (boundayParish) {
      homeParish = boundayParish.parish;
    }
  }

  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Child Details</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100 mb-0">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Residential Address</Form.Label>
                  <Row className="w-100 mx-0">
                    <Col className="px-0 pr-sm-2" xs={12} sm={6}>
                      <Form.Control
                        size="lg"
                        className="w-100"
                        type="number"
                        maxLength={6}
                        placeholder="Enter postal code"
                        {...register("postalCode", { required: true })}
                        isInvalid={!!errors.postalCode}
                      />
                    </Col>
                    <Col className="px-0 pl-sm-2 mt-3 mt-sm-0" xs="auto">
                      <Button
                        variant={
                          inputPostalCode.length === 6 || boundary
                            ? "outline-primary"
                            : "primary"
                        }
                        size="lg"
                        className="w-100"
                        disabled={inputPostalCode.length !== 6}
                        onClick={getAddress}
                      >
                        {boundary ? "Edit Address" : "Get Address"}
                      </Button>
                    </Col>
                    {boundary ? (
                      <>
                        <Col className="px-0 pr-sm-2 mt-3" xs={12} sm={6}>
                          <Form.Control
                            size="lg"
                            className="w-100"
                            placeholder="Block no"
                            {...register("blkNo", { required: false })}
                            isInvalid={!!errors.blkNo}
                            disabled={true}
                          />
                        </Col>
                        <Col className="px-0 pl-sm-2 mt-3" xs={12} sm={6}>
                          <Form.Control
                            size="lg"
                            className="w-100"
                            placeholder="Street Name"
                            {...register("roadName", { required: false })}
                            isInvalid={!!errors.roadName}
                            disabled={true}
                          />
                        </Col>
                        <Col className="px-0 pr-sm-2 mt-3" xs={12} sm={6}>
                          <Form.Control
                            size="lg"
                            className="w-100"
                            placeholder="Please enter floor number"
                            {...register("floorNumber", { required: true })}
                            isInvalid={!!errors.floorNumber}
                          />
                        </Col>
                        <Col className="px-0 pl-sm-2 mt-3" xs={12} sm={6}>
                          <Form.Control
                            size="lg"
                            className="w-100"
                            placeholder="Please enter unit number"
                            {...register("unitNumber", { required: true })}
                            isInvalid={!!errors.unitNumber}
                          />
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Form.Row>
                {boundary ? (
                  <>
                    <Form.Row className="align-items-center mt-4">
                      <Form.Label className="w-100 mb-0 text-wrap">
                        Based on your address, your home parish is{" "}
                        <span className="font-weight-bold">{homeParish}</span>
                      </Form.Label>
                    </Form.Row>
                  </>
                ) : (
                  ""
                )}
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Father's Religion&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("fatherReligion")}
                    isInvalid={!!errors.fatherReligion}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {religionList.map((v) => (
                      <option key={`fatherreligion-${v}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Mother's Religion&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("motherReligion")}
                    isInvalid={!!errors.motherReligion}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {religionList.map((v) => (
                      <option key={`motherreligion-${v}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Name of school</Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="E.g. Holy Innocents' Primary School"
                    {...register("school", { required: true })}
                    isInvalid={!!errors.school}
                  />
                  <span className="text-muted">
                    If not applicable, please indicate "N/A". If waiting for an
                    application for a different school next year to be approved,
                    please indicate the current school.
                  </span>
                </Form.Row>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <br />
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Preferred Contact</h3>
            <Alert
              variant="primary"
              className="d-flex justify-content-start align-items-center px-2"
            >
              <InfoOutlinedIcon className="text-primary mr-1" />
              <span className="text-dark">
                The Preferred Contact will be contacted for all catechetical
                matters and any emergencies.
              </span>
            </Alert>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Name</Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="Enter name"
                    {...register("mainContact.name", { required: true })}
                    isInvalid={!!errors.mainContact?.name}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="d-flex flex-row w-100">
                    Relationship to child
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("mainContact.relationship", {
                      required: true,
                    })}
                    isInvalid={!!errors.mainContact?.relationship}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    <option key="mainFather" value="Father">
                      Father
                    </option>
                    <option key="mainMother" value="Mother">
                      Mother
                    </option>
                    <option key="mainOthers" value="Others">
                      Others
                    </option>
                  </Form.Control>
                  {showMainOthers ? (
                    <Form.Control
                      size="lg"
                      className="w-100 mt-3"
                      placeholder="e.g. Grandparent"
                      {...register("mainContact.relationshipOthers", {
                        required: showMainOthers,
                      })}
                    />
                  ) : null}
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="d-flex flex-row w-100">
                    Religion
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("mainContact.religion", { required: true })}
                    isInvalid={!!errors.mainContact?.religion}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {religionList.map((v) => (
                      <option key={`maincontactreligion-${v}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Marital Status&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("mainContact.maritalStatus", {
                      required: false,
                    })}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {maritalStatusList.map((v) => (
                      <option key={`maincontactmarital-${v}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Married in the Catholic Church?&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Row className="w-100">
                    <Controller
                      control={control}
                      name="mainContact.marriedInCatholicChurch"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              name="mainMarriedInCatholicChurch"
                              label="Yes"
                              id="mainMarriedInCatholicChurchTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              name="mainMarriedInCatholicChurch"
                              label="No"
                              id="mainMarriedInCatholicChurchFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                            />
                          </Col>
                        </>
                      )}
                    />
                  </Row>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Involved in a parish ministry or community?&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="e.g. Church Warden"
                    {...register("mainContact.parishInvolvement", {
                      required: false,
                    })}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Email address</Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="Enter email address"
                    {...register("mainContact.email", { required: true })}
                    isInvalid={!!errors.mainContact?.email}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Col className="px-0 pr-sm-2" xs={12} sm={6}>
                    <Form.Label className="w-100">Mobile No.</Form.Label>
                    <Form.Control
                      size="lg"
                      className="w-100"
                      {...register("mainContact.mobileNo", {
                        required: true,
                        validate: (val) => /^\+65\s?\d{4}\s?\d{4}$/.test(val),
                      })}
                      isInvalid={!!errors.mainContact?.mobileNo}
                    />
                  </Col>
                  <Col className="px-0 pl-sm-2 mt-3 mt-sm-0" xs={12} sm={6}>
                    <Form.Label className="w-100 text-wrap">
                      Home Number&nbsp;
                      <span className="text-muted">(optional)</span>
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      className="w-100"
                      {...register("mainContact.homeNo", { required: false })}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Row className="w-100 mx-0">
                    <Form.Check
                      className="w-100"
                      type="checkbox"
                      name="mainVolunteer"
                      label={
                        <>
                          I am available to volunteer for Catechetical
                          activities and agree to be contacted by the Catechist
                          Ministry for help if needed.&nbsp;
                          <span className="text-muted">(optional)</span>
                        </>
                      }
                      id="mainVolunteer"
                      {...register("mainContact.volunteer", {
                        required: false,
                      })}
                    />
                  </Row>
                </Form.Row>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <br />
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Secondary Contact (Optional)</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Add a secondary contact?
                  </Form.Label>
                  <Row className="w-100">
                    <Controller
                      control={control}
                      name="secondaryContact.exists"
                      rules={{ validate: (v) => v === true || v === false }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              name="secondaryContact"
                              label="Yes"
                              id="secondaryContactTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              name="secondaryContact"
                              label="No"
                              id="secondaryContactFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                            />
                          </Col>
                        </>
                      )}
                    />
                  </Row>
                </Form.Row>
                {showSecondary ? (
                  <>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">Name</Form.Label>
                      <Form.Control
                        size="lg"
                        className="w-100"
                        placeholder="Enter name"
                        {...register("secondaryContact.name", {
                          required: showSecondary,
                        })}
                        isInvalid={!!errors.secondaryContact?.name}
                      />
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="d-flex flex-row w-100">
                        Relationship to child
                      </Form.Label>
                      <Form.Control
                        className="w-100"
                        as="select"
                        size="lg"
                        {...register("secondaryContact.relationship", {
                          required: showSecondary,
                        })}
                        isInvalid={!!errors.secondaryContact?.relationship}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          className="text-muted font-italic"
                        >
                          Please select
                        </option>
                        <option key="secondaryFather" value="Father">
                          Father
                        </option>
                        <option key="secondaryMother" value="Mother">
                          Mother
                        </option>
                        <option key="secondartOthers" value="Others">
                          Others
                        </option>
                      </Form.Control>
                      {showSecondaryOthers ? (
                        <Form.Control
                          size="lg"
                          className="w-100 mt-3"
                          placeholder="e.g. Grandparent"
                          {...register("secondaryContact.relationshipOthers", {
                            required: showSecondary && showSecondaryOthers,
                          })}
                        />
                      ) : null}
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">Email address</Form.Label>
                      <Form.Control
                        size="lg"
                        className="w-100"
                        placeholder="Enter email address"
                        {...register("secondaryContact.email", {
                          required: showSecondary,
                        })}
                        isInvalid={!!errors.secondaryContact?.email}
                      />
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Col className="px-0 pr-sm-2" xs={12} sm={6}>
                        <Form.Label className="w-100">Mobile No.</Form.Label>
                        <Form.Control
                          size="lg"
                          className="w-100"
                          {...register("secondaryContact.mobileNo", {
                            required: showSecondary,
                            validate: (val) =>
                              /^\+65\s?\d{4}\s?\d{4}$/.test(val),
                          })}
                          isInvalid={!!errors.secondaryContact?.mobileNo}
                        />
                      </Col>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Row className="w-100 mx-0">
                        <Form.Check
                          className="w-100"
                          type="checkbox"
                          name="secondaryVolunteer"
                          label={
                            <>
                              I am available to volunteer for Catechetical
                              activities and agree to be contacted by The
                              Catechists for help if needed.&nbsp;
                              <span className="text-muted">(optional)</span>
                            </>
                          }
                          id="secondaryVolunteer"
                          {...register("secondaryContact.volunteer", {
                            required: false,
                          })}
                        />
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Row className="w-100 mx-0">
                        <Form.Check
                          className="w-100"
                          type="checkbox"
                          name="secondaryEmergencies"
                          label="Only to be contacted for emergencies"
                          id="secondaryEmergencies"
                          {...register("secondaryContact.emergencies", {
                            required: false,
                          })}
                          isInvalid={!!errors.secondaryContact?.emergencies}
                        />
                      </Row>
                    </Form.Row>
                    {secondaryEmergencies === true ? (
                      <Alert
                        variant="warning"
                        className="d-flex justify-content-start align-items-center px-2"
                      >
                        <InfoOutlinedIcon className="text-warning mr-1" />
                        <span className="text-dark">
                          The Secondary Contact will only be contacted for
                          emergencies.
                        </span>
                      </Alert>
                    ) : secondaryEmergencies === false ? (
                      <Alert
                        variant="primary"
                        className="d-flex justify-content-start align-items-center px-2"
                      >
                        <InfoOutlinedIcon className="text-primary mr-1" />
                        <span className="text-dark">
                          The Secondary Contact will be contacted for all
                          catechetical matters and any emergencies.
                        </span>
                      </Alert>
                    ) : null}
                  </>
                ) : null}
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col className="d-none d-sm-flex" />
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Link className="text-dark" to={"/catch"}>
              <Button variant="outline-primary" size="lg" className="w-100">
                Cancel
              </Button>
            </Link>
          </Col>
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default function ContactUpdate() {
  const { user } = useContext(AppGlobalContext);
  const activeRegistrationState = useActiveRegistration();
  const { isGuardian, wardsData } = useGuardian();
  const { selectedWardIndex } = useWardSelection();
  const history = useHistory();

  const ward = wardsData[selectedWardIndex];

  const statusName = activeRegistrationState.status;

  const isAccessingValidPage = isValidPage({
    isGuardian: isGuardian,
    statusName,
    page: "contactUpdate",
  });

  if (!activeRegistrationState.exists) {
    history.push("/catch");
    return null;
  }

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ minHeight: "calc(100vh - 4.5rem)" }}
      >
        <Header type="backandlogo" smallpic={user.photoURL} />
        <Container className="my-4 flex-grow-1 d-flex flex-column px-4">
          <h2 className="mb-2">Contact Update</h2>
          {isAccessingValidPage ? (
            <ContactUpdateForm
              user={user}
              ward={ward}
              registrationDetails={activeRegistrationState.data}
            />
          ) : (
            <Card className="flex-grow-1 d-flex flex-column">
              <Card.Body
                className="d-flex flex-column justify-content-center align-items-center py-5"
                style={{ gap: "1.5rem" }}
              >
                <ForbiddenRoute />
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
}
