import React from "react";
import bscpriest from "../../img/priest/frjohanw.jpeg";
import stiggypriest from "../../img/priest/stignatius.jpg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Image } from "react-bootstrap";

const Banner = ({ parish }) => {
  console.log({ parish });
  if (parish === 1) {
    return (
      <div
        className="p-4 my-3"
        style={{ backgroundColor: "#c2d69b", borderRadius: "5px" }}
      >
        <Image
          src={bscpriest}
          alt=""
          rounded
          style={{
            float: "left",
            width: "60px",
            marginTop: "5px",
            marginRight: "10px",
          }}
        />
        <p className="my-0">
          Fr Johan, our Parish Priest, needs your help for a 3-minute survey to
          find out how we can make our church more welcoming to our Non-Catholic
          friends.
        </p>
        <div style={{ float: "none" }}>
          <a
            target="_blank"
            role="button"
            className="float-right btn btn-warning mt-2"
            rel="noopener noreferrer"
            href="http://consulus.parish-state-of-life-parishionersquestionnaire.alchemer.com/s3/"
          >
            Start Survey <ArrowForwardIcon className="text-dark" />
          </a>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  } else if (parish === 23) {
    return (
      <div
        className="p-4 my-3"
        style={{
          backgroundColor: "#ffa959",
          borderRadius: "5px",
          backgroundImage: "linear-gradient(#feb370, #fe9f42)",
        }}
      >
        <Image
          src={stiggypriest}
          alt=""
          roundedCircle
          style={{
            float: "left",
            width: "60px",
            marginTop: "5px",
            marginRight: "10px",
          }}
        />
        <p className="my-0">
          Fr Colin needs your help to hit our parish’s funding target for our
          Jubilee charity, Morning Star Community Services. Come let us walk
          with the poor!
        </p>
        <div className="float-right mt-2">
          <div className="float-right">
            <a
              target="_blank"
              role="button"
              className="float-right btn btn-success mt-0"
              rel="noopener noreferrer"
              href="https://www.giving.sg/campaigns/stiggydiamondjubilee"
            >
              Give Now <ArrowForwardIcon />
            </a>
          </div>
          <div className="float-right pr-2 mt-2">
            <i>Tax deductible</i>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  } else {
    return null;
  }
};

export default Banner;
