import React, { Component } from "react";

class WarningBox extends Component {
  render() {
    const { children, type } = this.props;
    const style = type ? type : "alert-danger";

    return (
      <div
        className={`card ${style} px-3 my-4`}
        style={{ fontSize: "14px", padding: "10px 0px 12px 0px" }}
      >
        {children}
      </div>
    );
  }
}

export default WarningBox;
