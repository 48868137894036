import React, { useRef, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TransfersDatum, RegistrationStatus } from "../../hooks/hooks";
import {
  useExternalTransfers,
  TransfersStatus,
  // QueriedAdminContext,
  useRowFilters,
  useQueriedAdminRerenderer,
  useRegistrations,
  useLookupConfig,
} from "../hooks/hooks";

import {
  getListProps,
  extFsmEdges,
  TransfersAction,
} from "../services/transfersTable";
import { transfersStatusDictionary } from "../../services/transfersStatus";

import { getParishNameById } from "../../../../../services/parish";
import fire from "../../../../../services/fire";
import AppGlobalContext from "../../../../../AppGlobalContext";
import AbstractList from "../components/AbstractList";
import {
  registrationCollectionName,
  transfersCollectionName,
} from "../../services/collections";

import RegistrationDetails from "../components/RegistrationDetails";
import TransferDetails from "../components/TransferDetails";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

// const createCatchRegistration = fire
//   .functions("asia-east2")
//   .httpsCallable("createCatchRegistration");

export default function TransfersListExt() {
  const rerenderQueriedAdminState = useQueriedAdminRerenderer();
  const lookupConfig = useLookupConfig();
  const registrationsState = useRegistrations();
  const transfersState = useExternalTransfers();
  const rowFilters = useRowFilters();

  const { user } = useContext(AppGlobalContext);

  const [selectedNotes, setSelectedNotes] = useState([]);

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const transfersCollection = db.collection(transfersCollectionName);

  /** @type {TransfersDatum[]} */
  const initialSelectedRows = [];
  const selectedRowsRef = useRef(initialSelectedRows);

  /**
   * @param {TransfersAction} action
   * @param {TransfersStatus} currentStatus
   */
  async function handleAction(action, currentStatus) {
    const selectedCount = selectedRowsRef.current.length;

    if (selectedCount === 0) {
      alert("Please select some rows first!");
      return Promise.resolve();
    }

    /** @type {TransfersStatus} */
    let newStatus;
    /** @type {RegistrationStatus} */
    let currentRegistrationStatus;
    /** @type {RegistrationStatus} */
    let newRegistrationStatus;
    /** @type {Record<TransfersStatus, RegistrationStatus>} */
    const transferToRegistrationStatus = {
      pendingOut: "pending",
      rejectedOut: "rejected",
      pendingIn: "pending",
      rejectedIn: "rejected",
      accepted: "transferred",
    };

    switch (action) {
      case "accept":
      case "reject":
      case "unassign":
        newStatus = extFsmEdges[action][currentStatus];
        currentRegistrationStatus = transferToRegistrationStatus[currentStatus];
        newRegistrationStatus = transferToRegistrationStatus[newStatus];

        const batch = db.batch();
        let updatedCount = 0;

        const toUpdateTransfers = [];
        const toUpdateRegistrations = [];

        for (const row of selectedRowsRef.current) {
          let registrationUpdate = {};
          let transferUpdate = {};

          if (
            action === "reject" ||
            action === "accept" ||
            !transfersState.data.some(
              ({ submittedBy, status, child = {} }) =>
                status === newStatus &&
                submittedBy === row.submittedBy &&
                child.uid === row.child.uid
            )
          ) {
            if (currentStatus === "pendingOut" && action === "accept") {
              transferUpdate = {
                ...transferUpdate,
                status: "accepted",
              };

              registrationUpdate = {
                ...registrationUpdate,
                status: registrationStatusNameToStatus("transferred"),
                // selectedParishId: row.to.id,
                // programmeType: row.to.programmeId ?? "",
                // timeslot: row.to.timeslotId ?? "",
                // assignedClass: "",
              };
            } else {
              transferUpdate = {
                ...transferUpdate,
                status: newStatus,
              };
            }

            if (action === "accept" && newStatus === "accepted") {
              if (currentStatus === "pendingOut") {
                transferUpdate = {
                  ...transferUpdate,
                  outProcessedAt: new Date(),
                  outProcessedBy: user.uid,
                  outProcessedByName: user.displayName,
                };
              } else if (currentStatus === "pendingIn") {
                registrationUpdate = {
                  ...registrationUpdate,
                  status: registrationStatusNameToStatus("accepted"),
                };

                const registration = row.registration;
                const transferTo = row.to;

                // const { data: { ok, message, doc, error } = {} } =
                //   await createCatchRegistration({
                //     uid: user.uid,
                //     parish: user.parish,
                //     registration,
                //     transferTo,
                //   });

                // if (ok) {
                //   // console.log(message);
                // } else {
                //   console.error(error);
                // }
              }
            }

            transfersState.data.find(({ id }) => id === row.id).status =
              currentStatus === "pendingOut" && action === "accept"
                ? ""
                : newStatus;

            updatedCount++;
          }

          if (Object.keys(transferUpdate).length > 0) {
            toUpdateTransfers.push([row.id, transferUpdate]);
            batch.update(transfersCollection.doc(row.id), transferUpdate);
          }
          if (Object.keys(registrationUpdate).length > 0) {
            toUpdateRegistrations.push([
              row.registration.id,
              registrationUpdate,
            ]);
            batch.update(
              registrationCollection.doc(row.registration.id),
              registrationUpdate
            );
          }
        }

        if (updatedCount === 0) {
          alert(
            `There are ${newStatus} transfers in conflict with your selected transfers.`
          );
        } else {
          await batch.commit();

          for (const [id_, update] of toUpdateTransfers) {
            const docToUpdate = transfersState.data.find(
              ({ id }) => id === id_
            );
            if (docToUpdate)
              for (const [key, value] of Object.entries(update))
                docToUpdate[key] = value;
          }

          for (const [id_, update] of toUpdateRegistrations) {
            const docToUpdate = registrationsState.data.find(
              ({ id }) => id === id_
            );
            if (docToUpdate)
              for (const [key, value] of Object.entries(update))
                docToUpdate[key] = value;
          }

          rerenderQueriedAdminState();

          alert(
            updatedCount === selectedCount
              ? "Update successful!"
              : `Update partially successful!\nThere are ${newStatus} transfers in conflict with some of your selected transfers.`
          );
        }

        break;
      case "email":
        let emails = "mailto:";
        if (selectedRowsRef.current.length === 1) {
          emails += selectedRowsRef.current[0].registration.mainContact.email;

          if (
            selectedRowsRef.current[0].registration.secondaryContact.exists &&
            selectedRowsRef.current[0].registration.secondaryContact
              .emergencies === false
          ) {
            emails += `;${selectedRowsRef.current[0].registration.secondaryContact.email}`;
          }
        } else {
          emails += "?bcc=";
          selectedRowsRef.current.forEach((row, index) => {
            emails += row.registration.mainContact.email;

            if (
              row.registration.secondaryContact.exists &&
              row.registration.secondaryContact.emergencies === false
            ) {
              emails += `;${row.registration.secondaryContact.email}`;
            }

            if (index < selectedRowsRef.current.length - 1) {
              emails += ";";
            }
          });
        }

        window.location.href = emails;
        break;
    }
    return Promise.resolve();
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setModalIsOpen(true);
    setSelectedItem(item);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
  };

  const dataProps = transfersState.data.filter(
    ({ status, from, to, registration: { level } }) => {
      return (
        ("" === rowFilters.programme ||
          (from.id === rowFilters.parish &&
            from.programmeId === rowFilters.programme) ||
          (to.id === rowFilters.parish &&
            to.programmeId === rowFilters.programme)) &&
        ("" === rowFilters.level || level === rowFilters.level) &&
        ("" === rowFilters.status || status === rowFilters.status)
      );
    }
  );
  const viewButtonProps = {
    openModal,
    selectedItem,
  };

  return (
    <>
      <AbstractList
        {...getListProps(lookupConfig, viewButtonProps)}
        id="external-transfers-table"
        statusDictionary={transfersStatusDictionary}
        currentStatus={rowFilters.status}
        rawDataProps={dataProps}
        selectedRowsRef={selectedRowsRef}
        handleAction={handleAction}
      />

      {transfersState.exists
        ? transfersState.data.map((item) => {
            const name = `${item.id || ""}-${item.from.id || ""}-${
              item.to.id || ""
            }`;

            return (
              <button
                key={name}
                id={`button-${name}`}
                style={{ display: "none" }}
                disabled={modalIsOpen}
                onClick={() => {
                  if (!modalIsOpen) {
                    // console.log(item);
                    openModal(item);
                  }
                }}
              />
            );
          })
        : null}

      <Modal
        className="p-0"
        dialogClassName="modal-fullscreen"
        show={modalIsOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="font-weight-bold noprint">
          <div className="w-100 d-flex justify-content-center">
            <Modal.Title>{selectedItem?.registration?.name}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "30px" }}>
            <TransferDetails {...{ user, selectedItem, external: true }} />
          </div>
          <hr />
          <div style={{ padding: "30px" }}>
            <RegistrationDetails
              {...{
                user,
                registrationCollection,
                selectedItem: selectedItem?.registration,
                setSelectedItem: (registration) =>
                  setSelectedItem({ ...selectedItem, registration }),
                selectedNotes,
                setSelectedNotes,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
