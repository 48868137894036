import React from "react";
// import MassIntentionGuide from "../page/help/MassIntentionsGuide";
import PaymentGuide from "../page/help/PaymentGuide";

const ModalPageNavigator = ({ page = 1, data = {} }) => {
  switch (page) {
    //case 1:
    //  return <MassIntentionGuide data={data} />;
    case 2:
      return <PaymentGuide />;
    default:
      return null;
  }
};

export default ModalPageNavigator;
