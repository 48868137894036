import React, { useState, useEffect } from "react";
import Header from "../../common/header";
import { getCurrentUserData as getCurrentUserDataFromDb } from "../../../services/user";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import Button from "react-bootstrap/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { mask4DigitId } from "../../../utils/utils";
// import {
//   getParishLinkById,
//   getPayNowUENById,
//   getShowBannerAtSuccessByCode,
// } from "../../../services/parish";
import { getOrgLinkById } from "../../../services/organisation";
import {
  getSortedRoomBookings,
  getUserBookingById,
} from "../../../services/roombookings";
import { getAllowedCurrentDates } from "../../../services/settings";
import { logEvent, logMessage } from "../../../services/log";
import LinkButton from "../../blocks/linkButtonBlock";
import StandardMessageContent from "../../staticpages/page/standardMessageContent";
import DemoBar from "../../common/demoBar";
// import Banner from "../../common/banner";
import NameClock from "../../common/nameClock";
import RoomDetail from "../../common/roomDetail";
import { getBookingNumber } from "../../../utils/bookingnumber";
// import ParishPriest from "../../blocks/parishPriestPhotoBlock";
// import WeeklyMassOfferingBar from "../../common/weeklyMassOfferingBar";
// import WallofHopeRedirect from "../../wallofhope/component/WallOfHopeRedirect";
import BookingSteps from "../components/bookingSteps";

function SuccessRoomBooking(props) {
  const {
    viewProps: { schedule: selectedschedule, usePayPlus },
    restoreView,
  } = props;

  const [state, setState] = useState({
    data: {
      fullname: "",
      email: "",
      identification: "",
      org: 0,
      orgcode: 0,
      subid: "",
    },
    roomtiming: [],
    preferredschedule: selectedschedule ?? null,
    chosenroom: "",
    user: props.user,
    loading: true,
    submitting: false,
    allowed: false,
    preferredMonth: props.viewProps.month.toLowerCase(),
    preferredYear: props.viewProps.year,
  });

  async function getCurrentUserData() {
    try {
      const uid = state.user.uid;
      const user = await getCurrentUserDataFromDb(uid);

      if (user) {
        const {
          fullname,
          email,
          identification,
          orgId: org,
          subid,
          lastroombooking,
          userid,
        } = user;
        const { preferredYear, preferredMonth, preferredschedule } = state;

        // Not needed. User does not require an organisation for booking
        // if (!org) {
        //   props.history.push("/account/setup");
        //   return;
        // }
        // const allowedcurdate = getAllowedCurrentDates();
        const curdate = `${preferredMonth}${preferredYear}`;
        // console.log(`year: ${preferredYear} month: ${preferredMonth}`);

        // //if invalid month and year, redirect home
        // if (!allowedcurdate.includes(curdate)) {
        //   //NOT ALLOWED, REDIRECT TO HOME FOR NOW
        //   props.history.push("/home");
        //   return;
        // }

        if (!preferredschedule || !lastroombooking) {
          logMessage("successRoomBooking", {
            email,
            code: "empty_booking",
            message: `User ID: ${userid} Empty lastroombooking`,
          });
          props.history.push("/home");
          return;
        }

        let roombookingobj = getSortedRoomBookings(lastroombooking);
        //delete if duplicate preferredschedule
        let dupcount = 0;
        let dupObj = null;
        for (let i = 0; i < roombookingobj.length; i++) {
          if (roombookingobj[i].roomId === preferredschedule) {
            dupcount++;
            dupObj = roombookingobj[i];
          }
        }
        // console.log("Room Bookings: ", roombookingobj);
        const latestbooking = await getUserBookingById(
          userid,
          preferredschedule
        );

        const orgcode =
          latestbooking[0].tenantOrg &&
          getOrgLinkById(latestbooking[0].tenantOrg);
        if (dupcount > 1) {
          // const selfDeleteMassBooking = fire
          //   .functions("asia-east2")
          //   .httpsCallable("selfDeleteMassBooking");

          // const bookstatus = await selfDeleteMassBooking({
          //   parishionerid: userid,
          //   bookings: roombookingobj,
          //   roomId: preferredschedule,
          //   parishcode: parishcode,
          //   timestamp: dupObj.date,
          // });
          // console.log("Force Deleted: ", bookstatus);
          logMessage("successRoomBooking", {
            email,
            code: "duplicate_in_successbooking",
            message: `User ID: ${userid} Org: ${orgcode} Room ID: ${dupObj} Duplicates: ${dupcount}`,
          });
          // props.history.push("/mybookings");
        }

        setState({
          ...state,
          data: {
            fullname,
            email,
            identification,
            org,
            orgcode,
            subid,
          },
          roombookingobj,
          latestbooking,
          loading: false,
        });
      } else {
        //USER NOT FOUND
        //LOGOUT!!!
        console.log("Error 1");
        // await userLogout();
        // window.location.reload();
        // props.history.replace("/home");
      }
    } catch (error) {
      //USER NOT FOUND
      //LOGOUT!!!
      console.log("Error 2:", error);
      // props.history.replace("/home");

      // await userLogout();
      // window.location.reload();
    }
  }

  useEffect(() => {
    getCurrentUserData();
    window.scrollTo(0, 0);
  }, []);

  const handleOpenMyPayPlus = () => {
    window.open("/mypayplus", "_self");
  };

  function render() {
    const { data, loading, latestbooking, user } = state;
    const { fullname, subid } = data;
    if (loading === true) {
      return <LoadingSpinnerNav smallpic={user.photoURL} />;
    }
    // let bookingobj = roombookingobj[0];
    // let [bookingobj] = roombookingobj.filter(
    //   (b) => b.roomId === preferredschedule
    // );
    if (latestbooking.length === 0) {
      //room is already deleted

      return (
        <StandardMessageContent
          header="Booking not found"
          link="/home"
          linklabel="Back to Home"
          body="It seems like this item has already been deleted."
        />
      );
    }
    logEvent("roombooking_success", {
      email: data.email,
      org: data.org,
    });
    // const showbanner = getShowBannerAtSuccessByCode(data.orgcode);
    // let banner = <Banner parish={parseInt(data.org)} />;
    return (
      <React.Fragment>
        <Header smallpic={user.photoURL} />
        <DemoBar />
        <div className="row justify-content-center mx-0">
          <div className="col-lg-6 px-1">
            <main className="container">
              <div className="pb-3 pt-2 text-center">
                <React.Fragment>
                  <div className="mt-0">
                    <BookingSteps step={4} />
                  </div>
                  <div className="center-content mt-2">
                    <br></br>
                    <CheckCircleOutlineIcon
                      style={{ fontSize: "40px", color: "green" }}
                    />
                    <h3 className="text-left ml-2 mb-0">Booking Confirmed</h3>
                    <br></br>
                  </div>
                  <div className="clearfix"></div>
                  <div className="card mt-3 mb-0 pt-2 pb-0">
                    <NameClock name={fullname} id={mask4DigitId(subid)} />
                  </div>
                  <div
                    className="mt-2 text-center pb-2 card mb-2"
                    style={{ border: "1px solid #29a745" }}
                  >
                    <div className="py-2 bg-success text-white">
                      <h3 className="my-0">Room</h3>
                      {latestbooking[0].roomNotes && (
                        <p className="py-0 mb-2">
                          {latestbooking[0].roomNotes}
                        </p>
                      )}
                    </div>
                    <RoomDetail
                      notes={latestbooking[0].roomNotes}
                      name={latestbooking[0].roomName}
                      function={latestbooking[0].roomFunction}
                      location={latestbooking[0].roomLocation}
                      venue={latestbooking[0].roomVenue}
                      date={latestbooking[0].roomDate}
                      duration={latestbooking[0].roomDuration}
                      slotType={latestbooking[0].roomSlotType}
                      paxLimit={latestbooking[0].roomPaxLimit}
                      rate={latestbooking[0].roomRate}
                      roomUsageDetails={latestbooking[0].roomUsageDetails}
                      bookerOrg={latestbooking[0].tenantOrg}
                      referenceNo={latestbooking[0].referenceNo}
                      number={getBookingNumber(latestbooking[0])}
                    />
                  </div>
                  {/* <div className="text-center my-0 px-1 pt-2 pb-0 defaultfontsize">
                    <ParishPriest
                      parishId={latestbooking[0].parish}
                      size="small"
                    />{" "}
                    <span style={{ paddingLeft: "10px" }}>See you soon!</span>
                  </div> */}

                  {!usePayPlus && (
                    <div
                      className="text-left mt-3 mb-4 px-1 py-4 mediumfontsize"
                      style={{
                        borderBottom: "1px dashed #cdcdcd",
                        borderTop: "1px dashed #cdcdcd",
                      }}
                    >
                      This serves as your booking confirmation. No email will be
                      sent.
                      {/* Please come 20 minutes before Mass starts. Bring your
                    NRIC/FIN/Passport for verification. */}
                    </div>
                  )}
                  {usePayPlus && (
                    <div
                      className="text-left mt-3 mb-4 px-1 py-4 mediumfontsize"
                      style={{
                        borderBottom: "1px dashed #cdcdcd",
                        borderTop: "1px dashed #cdcdcd",
                      }}
                    >
                      This serves as your booking confirmation. No email will be
                      sent.<br></br>
                      <br></br>You may view your transaction history in{" "}
                      <Button
                        variant="link"
                        className="mb-1 py-0 px-0 mediumfontsize"
                        onClick={handleOpenMyPayPlus}
                      >
                        <i>myPay+</i>
                      </Button>
                      .
                    </div>
                  )}
                  {/* <WallofHopeRedirect /> */}
                  {/* <WeeklyMassOfferingBar
                    label="paynow_clicked_successbooking"
                    email={data.email}
                    parish={data.org}
                    uen={getPayNowUENById(data.org)}
                  /> */}
                  <Button
                    className="btn-lg btn btn-block btn-primary"
                    onClick={() => restoreView("roomSchedules")}
                  >
                    Book Another Room
                  </Button>
                  <LinkButton
                    link="/mybookings"
                    type="frame"
                    label="Check myBookings"
                  />
                  {/* <div className="text-left">{showbanner && banner}</div> */}
                  <LinkButton type="link" link="/home" label="Back to Home" />
                </React.Fragment>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return render();
}

export default SuccessRoomBooking;
