import React from "react";

import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

/**
 * @typedef {import("../services/registrationTable").StaticActionProps} StaticActionProps
 * @typedef {import("../services/registrationTable").RegistrationAction} RegistrationAction
 */

/**
 * @typedef DynamicActionProps
 * @prop {(action: RegistrationAction) => void} handleAction
 * @prop {boolean} [disabled]
 * @prop {boolean} [isLoading]
 */
/**
 * @param {StaticActionProps & DynamicActionProps} props
 */
export default function RegistrationListButton({
  Icon,
  title,
  action,
  variant,
  handleAction,
  disabled,
  isLoading,
}) {
  return (
    <div className="col-lg">
      <Button
        variant={variant}
        className="my-2 w-100"
        onClick={() => handleAction(action)}
        style={{ minWidth: 300 }}
        disabled={disabled}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          !!Icon && (
            <>
              <Icon />{" "}
            </>
          )
        )}
        <span className="font-weight-bold">{title}</span>
      </Button>
    </div>
  );
}
