import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAccountManagerIdEdit({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/accountManagerIdEdit"));
  console.log("Loading", AsyncAccountManagerIdEdit.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAccountManagerIdEdit;
