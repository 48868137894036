import React from "react";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

/**
 * @typedef ProgrammePillProps
 * @prop {string} name
 * @prop {string} type
 */

/**
 * @param {ProgrammePillProps} props
 */
export default function ProgrammePill({ type, name }) {
  try {
    const renderTooltip = (props) => <Tooltip {...props}>{name}</Tooltip>;

    return (
      <OverlayTrigger
        placement="top"
        delay={{ hide: 400 }}
        overlay={renderTooltip}
      >
        <Badge variant="dark">{type}</Badge>
      </OverlayTrigger>
    );
  } catch (e) {
    return type;
  }
}
