import React, { Component } from "react";
import logo from "../../img/logo/logo_archdiocese_white_150.png";

class ArchdioceseLogo extends Component {
  render() {
    return (
      <nav className="navbar navbar-light bg-white flex-column">
        <a className="navbar-brand mx-auto" href="# ">
          <img
            src={logo}
            width="120px"
            height="120px"
            alt=""
          />
        </a>
        <h4 className="pt-1">
          <span className="font-italic">my</span>CatholicSG
        </h4>
      </nav>
    );
  }
}

export default ArchdioceseLogo;
