import React from "react";
import YouTubeIcon from "@material-ui/icons/YouTube";

const YoutubeEmbed = ({ title, youtubeid, newVideo }) => {
  return (
    <React.Fragment>
      {title && (
        <h5 className="text-left pt-2 pb-1">
          <YouTubeIcon className="text-danger" /> {title}{" "}
          {newVideo && (
            <span
              className={`defaultstandardfontsize badge badge-pill badge-danger`}
            >
              New
            </span>
          )}{" "}
        </h5>
      )}

      <div
        className="video mb-2"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: "20px",
          height: 0,
        }}
      >
        <iframe
          src={`https://www.youtube.com/embed/${youtubeid}`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default YoutubeEmbed;
