import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";

import Form from "../../common/form";
import FloatingLabelInput from "../../common/FloatingLabelInput";

import {
  getShortMonths,
} from "../../../utils/utils";

import detectParish from "../services/detectParish";
import { transformList, getIdByName, detectIdToParishName } from "../services/resources";

const debounce = (callback, delay) => {
	let timer;

	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => callback(...args), delay);
	};
};

class RegistrationFormBasic extends Form {
	constructor(props) {
		super(props);

		const { draft } = props;

		let childGender = "", dob = "", citizenship = "";

		if (typeof draft.childGender === "number") {
			childGender = draft.childGender.toString();
		}

		if (draft.dob) {
			dob = new Date(draft.dob.seconds * 1000 + draft.dob.nanoseconds/1000000);
		}

		if (typeof draft.citizenship === "number") {
			citizenship = draft.citizenship.toString();
		}

		this.state = {
			data: {
				childName: draft.childName || "",
				childGender: childGender || "",
				dob: dob || new Date(props.dob),
				countryOfBirth: "",
				nationality: "",
				citizenship: citizenship || "",
				homeAddress: draft.homeAddress || "",
				postalCode: draft.postalCode || "",
				parishReason: draft.parishReason || "",
			},
			countryList: [],
			errors: {},
		};
	}

	schema = {
		childName: Joi.string().trim().required().label("Child's Full Name"),
		countryOfBirth: Joi.string().trim().required().label("Child's Country of Birth"),
		nationality: Joi.string().trim().required().label("Child's Nationality"),
		homeAddress: Joi.string().trim().required().label("Child's Home Address"),
		postalCode: Joi.string().trim().required().label("Child's Postal Code"),
		parishReason: Joi.string().trim().required().label("Reason for choosing Parish outside of boundary"),
	};

	async componentDidMount() {
		await this.initTransformList();
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {
		const { draft } = this.props;
		const { data, errors } = this.state;

		if (!data.postalCode && data.postalCode !== prevState.data.postalCode) {
			this.setParishReason();
		}

		if (data.postalCode !== prevState.data.postalCode) {
			this.setIsCheckingBoundaries();
		}

		if (data.postalCode &&
				((draft.selectedParish !== prevProps.draft.selectedParish) ||
				(data.postalCode !== prevState.data.postalCode))) {
			this.debounceCheckBoundary();
		}

		if (!errors.postalCode && prevState.errors.postalCode) {
			this.checkBoundary();
		}
	}

	initTransformList = () => {
		const countryList = transformList("countryList");
		const { draft } = this.props;
		const { data } = this.state;
		const newData = { ...data };

		if (draft.countryOfBirth) {
			newData.countryOfBirth = getIdByName("countryList", draft.countryOfBirth);
		}

		if (draft.nationality) {
			newData.nationality = getIdByName("countryList", draft.nationality);
		}

		if (draft.postalCode) {
			this.checkBoundary();
		}

		this.setState({
			countryList,
			data: newData,
		});
	}

	debounceCheckBoundary = debounce(() => {
		this.checkBoundary();
	}, 500)

	checkBoundary = async() => {
		const { draft } = this.props;
		const { data, errors } = this.state;
		const newErrors = { ...errors };
		const errorMessage = "Your postal code is outside the boundary of your selected Parish";

		let boundaries;
		
		if (data.postalCode) {
			if (data.postalCode.toString().length === 6) {
				boundaries = await detectParish(data.postalCode.toString());
			}
		}

		if (data.postalCode.toString().length === 6) {
			if (boundaries) {
				if (boundaries > 0) {
					delete newErrors.postalCode;
		
					const parishName = detectIdToParishName(boundaries);
	
					if (parishName !== draft.selectedParishName) {
						newErrors.postalCode = errorMessage;
					}
				} else {
					newErrors.postalCode = "The geocoding service is currently not responding. If your home address is outside the Parish boundary, please indicate the reason below. Otherwise, fill in \"N/A\"."
				}
			} else {
				newErrors.postalCode = errorMessage;
			}
		} else if (!data.postalCode || data.postalCode === "") {
			delete newErrors.postalCode;
		} else if (isNaN(parseInt(data.postalCode))) {
			newErrors.postalCode = "Postal Code must be a number."
		} else if (data.postalCode.toString().length !== 6) {
			delete newErrors.postalCode;
		}

		this.setState({
			errors: newErrors,
			isCheckingBoundaries: false,
		});
	}

	setIsCheckingBoundaries = () => this.setState({ isCheckingBoundaries: true });

	setParishReason = () => {
		const { data, errors } = this.state;
		const newData = { ...data };
		const newErrors = { ...errors };

		newData.parishReason = "";
		
		delete newErrors.postalCode;

		this.setState({
			data: newData,
			errors: newErrors,
			isCheckingBoundaries: false,
		});
	}

	handleRadioChange = (type) => ({ currentTarget: input }) => {
		const { data } = this.state;
		const newData = { ...data };

		newData[type] = input.value;

		this.setState({ data: newData });
	}

	handleDateChange = (date) => {
		const { data } = this.state;
		const newData = { ...data };

		newData.dob = date;

		this.setState({
			data: newData,
		});
	}

	displayDateFormat = (date) => {
		if (date === "") {
			return "";
		}

		const shortMonths = getShortMonths();
		const monthStr = shortMonths[date.getMonth()];
		const day = date.getDate();
		const year = date.getFullYear();
		const dateStr = day + " " + monthStr + " " + year;

		return dateStr;
	}

	doSubmit = async () => {
		const { setRegistrationBasicDetails } = this.props;
		const { data } = this.state;
		const {
			childName = "",
			childGender = "",
			dob = new Date(this.props.dob),
			// countryOfBirth = "", // optional
			nationality = "",
			// citizenship = "", // optional
			homeAddress = "",
			postalCode = "",
		} = data;

		if (childName && childGender && dob && nationality && homeAddress && postalCode) {
			setRegistrationBasicDetails(data);
		}
	}

	renderNextButton = () => {
		const { data, errors, isCheckingBoundaries } = this.state;
		const {
			childName = "",
			childGender = "",
			dob = new Date(this.props.dob),
			// countryOfBirth = "", // optional
			nationality = "",
			// citizenship = "", // optional
			homeAddress = "",
			postalCode = "",
			parishReason = "",
		} = data;

		let isDisabled = true;

		if (childName && childGender && dob && nationality && homeAddress && postalCode) {
			isDisabled = false;
		}

		if (errors.hasOwnProperty("postalCode") && !parishReason) {
			isDisabled = true;
		}

		if (postalCode) {
			if (postalCode.toString().length < 6) {
				isDisabled = true;
			}
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled || isCheckingBoundaries }
			>
				Fill in baptism details
			</Button>
		);
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;

		saveDraft(data);
	}

	handlePressBack = () => {
		const { pressBack } = this.props;

		pressBack();
	}

	render() {
		const { countryList, data, errors } = this.state;
		const minDate = new Date(new Date(data.dob).getFullYear(), 0, 1);
		const maxDate = new Date(new Date(data.dob).getFullYear(), 11, 31);

		return (
			<div>
				<strong>Basic Details</strong>
				<br />
				<br />
				<React.Fragment>
					<form onSubmit={ this.handleSubmit}>
						<div className="d-flex">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								1
							</div>
							<div className="container">
								<div>
									<strong>Enter your child's name (As in Birth Certificate)</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"childName",
										"",
										"text",
										"Child's name"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								2
							</div>
							<div className="ml-3">
								<div>
									<strong>Select your child's gender</strong>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="childGender"
										type="radio"
										id="childGender1"
										value={ 0 }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("childGender") }
										checked={ data.childGender === "0" }
									/>
									<label className="form-check-label ml-2" htmlFor="childGender1">Male</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="childGender"
										type="radio"
										id="childGender2"
										value={ 1 }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("childGender") }
										checked={ data.childGender === "1" }
									/>
									<label className="form-check-label ml-2" htmlFor="childGender2">Female</label>
								</div>
							</div>
						</div>
						<div className="d-flex">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								3
							</div>
							<div className="container">
								<div>
									<strong>Enter your child's date of birth</strong>
								</div>
								<div className="ml-2 mt-2 mb-3 pr-2">
									<div className="d-flex com-event__date-input">
										<div className="d-flex flex-column justify-content-center">
											<CalendarTodayIcon />
										</div>
										<DatePicker
											value={ data.dob }
											onChange={ this.handleDateChange }
											minDate={ minDate }
											maxDate={ maxDate }
											openToDate={ data.dob }
											customInput={
											<div>
												<FloatingLabelInput
												// label="Start Date*"
												customWrapperClass="com-event__date-wrapper com-event__input-wrapper"
												customClassName="mb-4"
												value={ this.displayDateFormat(data.dob)}
												readOnly
												/>
											</div>
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								4
							</div>
							<div className="container">
								<div>
									<strong>Select child's country of birth (Optional)</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ countryList.length ?
										this.renderSelect(
											"countryOfBirth",
											"",
											countryList,
										) : null
									}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								5
							</div>
							<div className="container">
								<div>
									<strong>Select child's nationality</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ countryList.length ?
										this.renderSelect(
											"nationality",
											"",
											countryList,
										) : null
									}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								6
							</div>
							<div className="ml-3">
								<div>
									<strong>Select child's residential status (Optional)</strong>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="citizenship"
										type="radio"
										id="citizenship1"
										value={ 0 }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("citizenship") }
										checked={ data.citizenship === "0" }
									/>
									<label className="form-check-label ml-2" htmlFor="citizenship1">Singapore Citizen</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="citizenship"
										type="radio"
										id="citizenship2"
										value={ "1" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("citizenship") }
										checked={ data.citizenship === "1" }
									/>
									<label className="form-check-label ml-2" htmlFor="citizenship2">Permanent Resident</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="citizenship"
										type="radio"
										id="citizenship3"
										value={ "2" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("citizenship") }
										checked={ data.citizenship === "2" }
									/>
									<label className="form-check-label ml-2" htmlFor="citizenship3">FIN/Pass Holder</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="citizenship"
										type="radio"
										id="citizenship4"
										value={ "3" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("citizenship") }
										checked={ data.citizenship === "3" }
									/>
									<label className="form-check-label ml-2" htmlFor="citizenship4">Singapore Visitor</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="citizenship"
										type="radio"
										id="citizenship5"
										value={ "4" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("citizenship") }
										checked={ data.citizenship === "4" }
									/>
									<label className="form-check-label ml-2" htmlFor="citizenship5">Rather not say</label>
								</div>
							</div>
						</div>
						<div className="d-flex">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								7
							</div>
							<div className="container">
								<div>
									<strong>Enter child's home address</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"homeAddress",
										"",
										"text",
										"Enter home address"
									)}
									{ this.renderInput(
										"postalCode",
										"",
										"text",
										"Enter postal code"
									)}
								</div>
							</div>
						</div>
						{ errors.postalCode ?
							<div className="d-flex">
								<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
									style={{ height: "25px", minWidth: "25px" }}>
									8
								</div>
								<div className="container">
									<div>
										<strong>Please state a reason for applying to a Parish outside of your boundary</strong>
									</div>
									<div className="ml-2 mt-2 pl-0 container">
										{ this.renderTextarea(
											"parishReason",
											"",
											"text",
											// "Please state a reason for applying to a Parish outside of your boundary"
										)}
									</div>
								</div>
							</div> : null
						}
					</form>
					<br />
					<br />
					{ this.props.coordinatorEmail ?
						<>
							If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
							<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
							<br />
							<br />
						</> : null
					}
					{ this.renderNextButton() }
					<Button className="btn-block mb-3" variant="outline-primary" size="lg"
						onClick={ this.handlePressBack }>
						Back
					</Button>
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		)
	}
}

export default RegistrationFormBasic;
