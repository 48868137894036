import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncUnverifiedID({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/unverifiedId"));
  console.log("Loading", AsyncUnverifiedID.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncUnverifiedID;
