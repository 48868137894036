import React from "react";
import Header from "../../common/header";
import { withRouter } from "react-router-dom";

const StandardMessageContent = ({ header, body, link, linklabel }) => {
  return (
    <React.Fragment>
      <div className="row justify-content-center mx-0">
        <div className="col-lg-6 px-1">
          <main className="container">
            <Header type="backandlogo" />
            <h1 className="py-3">{header}</h1>
            <h4 className="my-4 pb-4 text-left text-muted">{body}</h4>
            <a
              rel="noopener noreferrer"
              className="btn-lg btn btn-primary btn-block mt-4"
              href={link}
            >
              {linklabel}
            </a>
          </main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(StandardMessageContent);
