import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncStatsDashboard({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/stats"));
  console.log("Loading", AsyncStatsDashboard.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncStatsDashboard;
