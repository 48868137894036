import {
  isArchbishopRole,
  isArchCommsRole,
  isParishPriestRole,
  isSecretaryRole,
  isSuperAdminRole,
  isSupportRole,
  isSystemAdminRole,
  isAttendanceVerifierRole,
  isSystemValidatorRole,
  isFinanceRole,
  isCATCHAdminRole,
  isCATCHParishAdminRole,
  isFestivalAdminRole,
  isSynodAdminRole,
  isEventHubAdminRole,
  isGenericAdminRole,
} from "../../roles/service/roles";

export function showAdminMenu(user) {
  if (
    isArchbishopRole(user) ||
    isArchCommsRole(user) ||
    isParishPriestRole(user) ||
    isSecretaryRole(user) ||
    isSuperAdminRole(user) ||
    isSupportRole(user) ||
    isAttendanceVerifierRole(user) ||
    isSystemAdminRole(user) ||
    isSystemValidatorRole(user) ||
    isFinanceRole(user) ||
    isCATCHAdminRole(user) ||
    isCATCHParishAdminRole(user) ||
    isFestivalAdminRole(user) ||
    isSynodAdminRole(user) ||
    isEventHubAdminRole(user) ||
    isGenericAdminRole(user)
  ) {
    return true;
  }

  return false;
}
