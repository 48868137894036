import React from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

const steps = [
  {
    label: "Generate access code (Child)",
    content: (
      <>
        First, <span className="font-weight-bold">Generate and copy</span> an
        access code on the <span className="font-weight-bold">Child</span>{" "}
        account on this menu.
        <br />
        Any older codes are invalidated. The new code expires in 24 hours.
        <br />
      </>
    ),
  },
  {
    label: "Enter access code (Guardian)",
    content: (
      <>
        Next, <span className="font-weight-bold">paste and Check</span> the
        same access code the <span className="font-weight-bold">Guardian</span>{" "}
        account on this menu.
        <br />
        Only one Guardian account per Child account is allowed.
      </>
    ),
  },
  {
    label: "Access CATCH features (Guardian)",
    content: (
      <>
        Then, on the <span className="font-weight-bold">Guardian</span> account,
        select the Child's name from the dropdown box in the CATCH menu.
        <br />
        This allows you to act on behalf of the Child.
      </>
    ),
  },
];

export default function GuardianControlSteps() {
  return (
    <Stepper orientation="vertical">
      {steps.map(({ label, content }) => (
        <Step active={true} key={label}>
          <StepLabel>{label}</StepLabel>
          <StepContent>{content}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
}
