import React from "react";
import { Button, Modal } from "react-bootstrap";
import SpinnerText from "./spinnerwithText";

function GenericModal({
  show,
  size = "md",
  closehandler,
  body,
  title,
  processing,
  okhandler,
  enablebutton,
  backdrop = true,
  buttontext = "Save",
  buttontype = "success",
  cancelbuttontype = "outline-secondary",
  buttoncanceltext = "Cancel",
  css = "text-danger font-weight-bold", // title css
  bodycss = "",
  footercss = "",
  processingLabel = "Please wait...",
  isCentered = true,
}) {
  return (
    <Modal
      size={size}
      show={show}
      onHide={closehandler}
      animation={true}
      centered={isCentered}
      backdrop={backdrop}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className={css}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodycss}>{body}</Modal.Body>
      <Modal.Footer className={footercss}>
        {closehandler && (
          <Button
            disabled={processing}
            variant={cancelbuttontype}
            onClick={closehandler}
          >
            {buttoncanceltext}
          </Button>
        )}
        {okhandler && processing !== true && (
          <Button
            variant={buttontype}
            disabled={!enablebutton}
            onClick={okhandler}
          >
            {buttontext}
          </Button>
        )}
        {processing === true && (
          <Button disabled variant={buttontype}>
            <SpinnerText text={processingLabel} />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default GenericModal;
