import React, { Component } from "react";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { Link } from "react-router-dom";

class MyBookingOption extends Component {
  render() {
    return (
      <>
        <EventNoteIcon
          className="ml-1 text-primary mr-1 "
          style={{ fontSize: "20px", marginTop: "6px" }}
        />{" "}
        <Link to="/mybookings">
          {" "}
          <span className="font-italic">my</span>Bookings
        </Link>
      </>
    );
  }
}

export default MyBookingOption;
