import React from "react";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import Linkify from "react-linkify";

const NewlineText = ({ text, css = "" }) => {
  const componentDecorator = (href, text, key) => {
    let display = (
      <>
        Click here <LaunchOutlinedIcon className="mediumfontsize" />
      </>
    );
    if (href.startsWith("mailto")) {
      display = text;
    }
    return (
      <a href={href} key={key} rel="noopener noreferrer" target="_blank">
        {display}
      </a>
    );
  };

  return text.split("\n").map((str, id) => (
    <p key={id} className={css}>
      <Linkify componentDecorator={componentDecorator}>{str}</Linkify>
    </p>
  ));
};

export default NewlineText;
