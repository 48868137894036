import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { withRouter } from "react-router-dom";
import AgreeTerms from "../../consent/component/agreeTerms";
import AgreeComs from "../../consent/component/agreeComs";
import AgreeMyCatholicSG from "../../consent/component/agreeMyCatholicSG";
import { Button } from "react-bootstrap";
import { userLogout } from "../../../services/user";
import { logEvent } from "../../../services/log";

class AsckConsent extends Form {
  constructor(props) {
    super(props);
    const { agreecoms, agreemcsg, agreeterms } = this.props.data;

    this.state = {
      data: {
        agreeterms: agreeterms || false,
        agreemcsg: agreemcsg || false,
        agreecoms: agreecoms || false,
      },
      loading: false,
      user: this.props.user,
      errors: {},
    };
  }

  schema = {
    agreeterms: Joi.boolean()
      .invalid(false)
      .required()
      .label("Terms and Conditions"),
    agreemcsg: Joi.boolean().invalid(false).required().label("myCatholicSG"),
    agreecoms: Joi.boolean().optional(),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  doSubmit = async () => {
    const { data, errors } = this.state;
    // Check for custom validation errors before proceeding
    const hasErrors = Object.keys(errors).length !== 0;
    if (hasErrors) {
      return;
    }
    this.setState({
      errors,
      loading: true,
    });
    const agreeterms = data.agreeterms;
    const agreecoms = data.agreecoms;
    const agreemcsg = data.agreemcsg;
    // Call the server
    try {
      const currentUser = fire.auth().currentUser;
      const emailUnsub = agreecoms
        ? { archdiocese: true, parish: true }
        : { archdiocese: false, parish: false };
      const userObj = {
        agreeterms: agreeterms,
        agreemcsg: agreemcsg,
        emailUnsub: emailUnsub,
      };

      await fire.firestore().doc(`users/${currentUser.uid}`).update(userObj);
      logEvent("user_consent_success", {
        email: data.email,
        parish: data.parish,
      });
      window.location.reload();
    } catch (ex) {
      logEvent("user_consent_fail", {
        email: data.email,
        message: ex.message,
      });
      console.error("Unable to Set Data");
    }
  };

  forceLogout = async () => {
    try {
      await userLogout();
      // signed out
      window.location.reload();
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  render() {
    const { loading } = this.state;

    return (
      <div className="pt-4 mt-4 row justify-content-center mx-auto">
        <main className="container">
          <div className="col-lg-6 px-0 mx-auto">
            <div className="card shadow p-4">
              <div className="">
                <h3 className="text-left text-dark mb-4">PDPA Consent</h3>
                <form onSubmit={this.handleSubmit}>
                  <div className="px-2 py-0">
                    {this.renderCheckBox("agreeterms", <AgreeTerms />)}
                  </div>
                  <div className="px-2 py-0">
                    {this.renderCheckBox("agreemcsg", <AgreeMyCatholicSG />)}
                  </div>
                  <div className="px-2 pt-0 pb-4">
                    {this.renderCheckBox("agreecoms", <AgreeComs />)}
                  </div>
                  {!loading
                    ? this.state.data["agreeterms"] === false ||
                      this.state.data["agreemcsg"] === false
                      ? this.renderDisabledBlockButton("Submit")
                      : this.renderBlockButton("Submit")
                    : this.state.data["agreeterms"] === true &&
                      this.state.data["agreemcsg"] === true &&
                      this.renderLoadingBlockButton("Please wait...")}
                </form>

                <div className="mt-5 text-center">
                  <Button
                    variant="link"
                    className="my-4 btn-lg text-center "
                    onClick={this.forceLogout}
                  >
                    <span className="normalsmallfontsize">Logout</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(AsckConsent);
