import React, { Component } from "react";
import { getCurrentUserData, userLogout } from "../../../services/user";
import ParishEdit from "../component/parishEdit";
import Header from "../../common/header";
import {
  enableSelfChangeParish,
  getChangeParishDate,
  getChangeParishEndDate,
} from "../../../services/settings";
import { isAllowToChangeParish } from "../../../services/massbookings";
import { getLastParishChangeHistory } from "../../../services/changeparish";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import { isFestivalScannerRole } from "../../roles/service/roles";
import { getServerTimeTS } from "../../../services/servertime";

class ParishSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      data: {
        fullname: "",
        identification: "",
        email: "",
        dob: "",
        mobile: "+65",
        parish: 0,
      },
      errors: {},
      parish: [],
      loading: true,
    };
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        userid,
        created,
        fullname,
        email,
        mobile,
        dob,
        identification,
        parish,
        lastmassbooking,
        lastdevotionbooking,
      } = user;
      if (!parish) {
        this.props.history.push("/account/setup");
        return;
      }

      const startdate = new Date(getChangeParishDate()).getTime();
      const enddate = new Date(getChangeParishEndDate()).getTime();
      //get server time
      const now = (await getServerTimeTS()) || new Date().getTime();

      const changelog = await getLastParishChangeHistory(
        startdate,
        this.state.user.uid
      );
      //added check to disable parish change after certain date, before the ballot registration
      if (
        now > enddate ||
        !enableSelfChangeParish() ||
        !isAllowToChangeParish(lastmassbooking) ||
        !isAllowToChangeParish(lastdevotionbooking) ||
        changelog
      ) {
        this.props.history.push("/profile");
        return;
      }
      let hasbooking = false;
      if (lastmassbooking || lastdevotionbooking) {
        hasbooking = true;
      }
      this.setState({
        data: {
          hasbooking,
          userid,
          created,
          fullname,
          email,
          mobile,
          dob,
          identification,
          parish,
        },
        loading: false,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  componentDidMount() {
    if (isFestivalScannerRole(this.state.user)) {
      this.props.history.push("/festival/attendance");
      return;
    }
    this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  render() {
    const { data, loading, user } = this.state;

    if (loading === true) {
      return <LoadingSpinnerNav type="backandlogo" smallpic={user.photoURL} />;
    }
    return (
      <React.Fragment>
        <Header type="backandlogo" smallpic={user.photoURL} />

        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-4">
            <main className="container px-0">
              <div className="pb-3 pt-4 mt-4">
                <ParishEdit userdetails={data} />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ParishSetup;
