import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {
  getParishCompleteNameById,
  getParishIdByCode,
} from "../../services/parish";
import { isValidParish } from "../../services/validation";
import catholic200logo from "../../modules/catholic200sg/img/catholic200sg.png";
import { Link } from "react-router-dom";
import { enableFestival } from "../../services/settings";
import { withRouter } from "react-router-dom";
import { getFestivalUrl } from "../../utils/url";
class ParishLogo extends Component {
  images = [
    { id: 0, photo: require("../../img/logo/logo_archdiocese_white.png") },
    { id: 1, photo: require("../../img/parish/200/blessedsacrament.jpg") },
    { id: 2, photo: require("../../img/parish/200/cathedral.jpg") },
    { id: 3, photo: require("../../img/parish/200/christtheking.jpg") },
    { id: 4, photo: require("../../img/parish/200/divinemercy.jpg") },
    { id: 5, photo: require("../../img/parish/200/holycross.jpg") },
    { id: 6, photo: require("../../img/parish/200/holyfamily.jpg") },
    { id: 7, photo: require("../../img/parish/200/holyspirit.jpg") },
    { id: 8, photo: require("../../img/parish/200/holytrinity.jpg") },
    { id: 9, photo: require("../../img/parish/200/immaculateheartofmary.jpg") },
    { id: 10, photo: require("../../img/parish/200/ladyoflourdes.jpg") },
    {
      id: 11,
      photo: require("../../img/parish/200/nativityofblessedvirgin.jpg"),
    },
    { id: 12, photo: require("../../img/parish/200/perpertualsuccour.jpg") },
    { id: 13, photo: require("../../img/parish/200/queenofpeace.jpg") },
    { id: 14, photo: require("../../img/parish/200/starofthesea.jpg") },
    { id: 15, photo: require("../../img/parish/200/risenchrist.jpg") },
    { id: 16, photo: require("../../img/parish/200/sacredheart.jpg") },
    { id: 17, photo: require("../../img/parish/200/novena.jpg") },
    { id: 18, photo: require("../../img/parish/200/stanne.jpg") },
    { id: 19, photo: require("../../img/parish/200/stanthony.jpg") },
    { id: 20, photo: require("../../img/parish/200/stbernadette.jpg") },
    { id: 21, photo: require("../../img/parish/200/stfrancisassisi.jpg") },
    { id: 22, photo: require("../../img/parish/200/stfrancisxavier.jpg") },
    { id: 23, photo: require("../../img/parish/200/stignatius.jpg") },
    { id: 24, photo: require("../../img/parish/200/stjosephbukittimah.jpg") },
    { id: 25, photo: require("../../img/parish/200/stjosephvictoria.jpg") },
    { id: 26, photo: require("../../img/parish/200/stmaryofangels.jpg") },
    { id: 27, photo: require("../../img/parish/200/stmichael.jpg") },
    { id: 28, photo: require("../../img/parish/200/ststephen.jpg") },
    { id: 29, photo: require("../../img/parish/200/stteresa.jpg") },
    { id: 30, photo: require("../../img/parish/200/stvincentdepaul.jpg") },
    { id: 31, photo: require("../../img/parish/200/stspeterandpaul.jpg") },
    { id: 32, photo: require("../../img/parish/200/transfiguration.jpg") },
    { id: 33, photo: require("../../img/logo/logo_archdiocese_white.png") },
  ];
  constructor(props) {
    super(props);
    const { parish, parishId, parishCode } = props;
    this.state = {
      parish,
      parishId,
      parishCode,
    };
  }
  getImageDisplay = (id) => {
    let imagedisplay;
    let parishId = parseInt(id);
    if (!isValidParish(parishId)) {
      parishId = 33;
    }
    [imagedisplay] = this.images.filter((img) => img.id === parseInt(parishId));
    return imagedisplay.photo;
  };
  getUniqueParishId = () => {
    const { parish, parishId, parishCode } = this.props;

    let id;
    if (parishId) {
      id = parishId;
    } else if (parishCode) {
      id = getParishIdByCode(parishCode);
    } else if (parish) {
      id = parish._id;
    } else {
      id = 33;
    }
    return parseInt(id);
  };
  getNameDisplay = (id) => {
    let display = (
      <h4 className="pt-1 text-center text-muted">
        <span className="font-italic">my</span>CatholicSG
      </h4>
    );
    if (!isValidParish(id)) {
      return display;
    }
    if (id) {
      display = getParishCompleteNameById(id);
      //check if need to split
      if (display.indexOf("(") !== -1) {
        let first = display.split("(");
        let second = first[1].split(")");
        display = this.props.isSmall ? (
          ""
        ) : (
          <React.Fragment>
            <h5 className="pt-1 mb-1 text-center text-dark">{first[0]}</h5>
            <h5 className="text-center text-dark">({second[0]})</h5>
          </React.Fragment>
        );
      } else {
        display = this.props.isSmall ? (
          ""
        ) : (
          <h4 className="pt-1 text-center text-dark">{display}</h4>
        );
      }
    }
    return display;
  };

  handleClick = () => {
    this.props.history.push(getFestivalUrl());
  };

  render() {
    let id = parseInt(this.getUniqueParishId());
    let link = "/";
    if (this.props.parishCode) {
      link = `parish/${this.props.parishCode}`;
    }
    return (
      <nav
        className={
          this.props.isSmall
            ? "text-center bg-white flex-column justify-content-center"
            : "text-center bg-white flex-column py-3"
        }
      >
        {link === "/" ? (
          <Image
            src={this.getImageDisplay(id)}
            width={this.props.isSmall ? "80px" : "100px"}
            height={this.props.isSmall ? "80px" : "100px"}
            className="shadow-sm"
            alt=""
            rounded
          />
        ) : (
          <Link
            className={
              this.props.isSmall
                ? "navbar-brand mx-auto"
                : "navbar-brand mx-auto pb-2"
            }
            to={link}
          >
            <Image
              src={this.getImageDisplay(id)}
              width={this.props.isSmall ? "80px" : "100px"}
              height={this.props.isSmall ? "80px" : "100px"}
              className="shadow-sm"
              alt=""
              rounded
            />
          </Link>
        )}
        {this.getNameDisplay(id)}
        {false && (
          <div className="text-center">
            {enableFestival() ? (
              <div className="pointer" onClick={this.handleClick}>
                <Image
                  src={catholic200logo}
                  width={this.props.isSmall ? "100px" : "150px"}
                  style={{ marginTop: "-10px" }}
                  alt=""
                />
              </div>
            ) : (
              <a
                className="my-0 pb-0"
                href="https://catholic200.sg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  src={catholic200logo}
                  width="100px"
                  style={{ marginTop: "-10px" }}
                  alt=""
                />
              </a>
            )}
          </div>
        )}
      </nav>
    );
  }
}

export default withRouter(ParishLogo);
