import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import Header from "../../common/header";
import fire from "../../../services/fire";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import {
  getCurrentUserData as getCurrentUserDataFromDb,
  // isChild,
  // overrideParish,
} from "../../../services/user";
import {
  getRoomDetails,
  // isAllowedToBook,
  isRoomFull,
  isRoomTimeExpired,
  isRoomClosed,
  // getMonthlyBookings,
} from "../service/room";
import SimpleMessage from "../../blocks/simpleMessageBlock";
import {
  getOrgLinkById,
  getOrgBookingCutoffTimeById,
  getOrgIdByCode,
} from "../../../services/organisation";
import LinkButton from "../../blocks/linkButtonBlock";
import {
  getLocalTime,
  getMassDate as getRoomDate,
  // isWeekEndMass,
  // isSaturdayMass,
  // isSundayMass,
} from "../../../utils/utils";
import {
  disableBooking,
  // enableWarningPETVaccinationButton,
  getAllowedCurrentDates,
  // getNextMonth,
  getWebVersion,
  // maxNoPETVaccinationCapacity,
} from "../../../services/settings";
import { logEvent, logMessage } from "../../../services/log";
import SpinnerText from "../../common/spinnerwithText";
import { getLatestVersion } from "../../../services/getversion";
import DemoBar from "../../common/demoBar";
import RoomDetail from "../../common/roomDetail";
// import md5 from "md5";
// import { getMaxKidCountAllowed } from "../../../services/limits";
import BookingSteps from "../components/bookingSteps";
import NameClock from "../../common/nameClock";
// import { excludeList } from "../../../services/bookanyorg";
import GenQRPayment from "../../finance/component/GenQRPayment";
import AppGlobalContext from "../../../AppGlobalContext";
import ModalWindow from "../components/ModalWindow";

function BookingPayment(props) {
  const {
    setView,
    viewProps: {
      payplusData,
      roomId,
      roomOrgId,
      roomFunction,
      roomName,
      roomDate,
      user,
      year,
      month,
      schedule,
    },
    restoreView,
  } = props;

  const [state, setState] = useState({
    cancelBtnVisible: true,
    deletingDraft: false,
    payplusFailureBtn: false,
  });

  const [PayplusGuideModal, setPayplusGuideModal] = useState(false);

  // console.log("paypluscampaignref", payplusData.payplusCampaignRef);
  // console.log("year", year);
  // console.log("month", month);
  // console.log("schedule", schedule);
  // display payplusCampaignId;

  // deleteRoomBooking
  const deleteRoomDraft = async () => {
    // hide cancellation button
    setState((oldState) => ({
      ...oldState,
      cancelBtnVisible: false,
      deletingDraft: true,
    }));

    // this is the cloud function to delete roombooking
    const deleteBooking = fire
      .functions("asia-east2")
      .httpsCallable("deleteRoomBooking");

    try {
      // fetch room object
      const roomObj = await (
        await fire
          .firestore()
          .collection("roombookings")
          .where("roomId", "==", roomId)
          .where("tenantId", "==", user.userid)
          .get()
      ).docs[0]?.data();

      const bookingContext = {
        settings: { bookingcutofftime: 1, bookenabled: true },
        user: {
          parish: user.parish,
          userid: user.userid,
          dob: user.dob,
          mobile: user.mobile,
          email: user.email,
          fullname: user.fullname,
          subid: user.subid,
          identification: user.identification,
          lastroombooking: user.lastroombooking,
        },
        room: roomObj,
      };

      // delete room draft
      const bookstatus = await deleteBooking(bookingContext);
      // console.log("Calling deleteRoomBooking");
      // console.log(bookstatus.data);

      restoreView("roomSchedules");
    } catch (error) {
      console.error("Error in deleteRoomDraft:", error);
    }
  };

  // ******** Callback functions to handle PayPlus state ********

  const handlePayPlusFailure = () => {
    // Called when Payment does not go through within 5 minutes

    // hide cancellation button & display error button
    setState((oldState) => ({
      ...oldState,
      cancelBtnVisible: false,
      payplusFailureBtn: true,
    }));
  };

  const handlePayPlusSuccess = async () => {
    // Called when payment is successful

    // hide cancellation button
    setState((oldState) => ({ ...oldState, cancelBtnVisible: false }));

    //update roombooking document -- isDraft: false, isCompleted: true
    let roomBookingDocument = await fire
      .firestore()
      .collection("roombookings")
      .where("roomId", "==", roomId)
      .where("tenantId", "==", user.userid)
      .get();

    await roomBookingDocument.docs[0].ref.update({
      isDraft: false,
      isCompleted: true,
    });

    //after payment is confirmed and booking document is mark completed, wait 2 seconds before changing to booking successView
    setTimeout(() => {
      setView({
        name: "success",
        props: {
          year,
          month,
          schedule,
          usePayPlus: true,
        },
      });
    }, 3000);
  };

  const handlePayPlusReturnButton = () => {
    // "cancel and select another room"
  };

  const handlePayPlusFailureButton = () => {
    // Called when the Return button has been clicked after the QR code has expired.
    //setEventReviewState({
    //  ...eventReviewState,
    //  page: -1,
    //});
  };

  // TODO modify display page
  const displayPage = (page) => {
    // const { user, submitting } = state;
    return (
      <>
        <Header
          type="backandlogo"
          disablecontrol={true}
          //smallpic={user.photoURL}
        />
        <DemoBar />
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6 px-1">
            <main className="container">{page}</main>
          </div>
        </div>
      </>
    );
  };

  function render() {
    const { cancelBtnVisible, deletingDraft, payplusFailureBtn } = state;
    return displayPage(
      <div className="pb-3 pt-0">
        <div className="mt-2">
          <BookingSteps step={2} />
          <br />
        </div>
        <ModalWindow
          title="How Do I Make Payment?"
          pageNumber={2}
          showModal={PayplusGuideModal}
          onModalCloseCB={() => setPayplusGuideModal(false)}
        />
        <div className="alert alert-primary text-left">
          Your slot has been reserved for the next <b>5 minutes</b>.
          <ul>
            <li>
              If payment is not made within this time, your slot will be
              released and you will need to create a new booking again.
            </li>
            <li>
              Please refrain from <b>opening another tab</b> to make another
              payment, until you have completed this payment.
            </li>
            <li>
              <b>No refunds</b> will be made once payment is completed, even for
              cancelled slots.
            </li>
          </ul>
          <br />
          Payment will be made via the Archdiocese's QR payment service,{" "}
          <span className="font-weight-bold font-italic">Pay+</span>.{"   "}
          <Button
            variant="link"
            className="mb-1 py-0 px-0 biggerfontsize"
            onClick={() => setPayplusGuideModal(true)}
          >
            <i>Find out how</i>
          </Button>
        </div>{" "}
        <div className="mt-2 mb-0 pb-0">
          {
            <GenQRPayment
              userObj={user}
              selectedCampaignId={payplusData.payplusCampaignId}
              onFailureCB={handlePayPlusFailure}
              onSuccessCB={handlePayPlusSuccess}
              showSuccessBtn={false}
              showReturnBtn={false}
              showFailureBtn={false}
              showPaymentSelectorReturnBtn={false}
              step={0}
            />
          }
        </div>
        {cancelBtnVisible && !deletingDraft && (
          <button
            type="button"
            onClick={() => deleteRoomDraft()}
            className="btn btn-link mt-4 btn-block btn-lg"
          >
            Cancel booking and select a different room
          </button>
        )}
        {payplusFailureBtn && !deletingDraft && (
          <button
            type="button"
            onClick={() => deleteRoomDraft()}
            className="btn btn-link mt-4 btn-block btn-lg"
          >
            Cancel booking and try again
          </button>
        )}
        {deletingDraft && (
          <>
            <button disabled className="btn btn-link mt-4 btn-block btn-lg">
              <SpinnerText text="Please Wait..." />
            </button>
          </>
        )}
      </div>
    );
  }

  return render();
}

export default BookingPayment;
