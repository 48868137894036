import React from "react";
import Header from "../../common/header";
import AskConsent from "../component/askConsent";
import ReloadModal from "../../common/reloadModal";

function Consent({ user, data }) {
  return (
    <div>
      <Header user={user} smallpic={user.photoURL} hide="profile" />
      <ReloadModal />
      <AskConsent user={user} data={data} />
    </div>
  );
}

export default Consent;
