import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncMainPriests({ ...rest }) {
  const AsyncLoad = loadable(() => import("../admin/MainPriests"));
  console.log("Loading", AsyncMainPriests.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncMainPriests;
