import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";
import ArrowForwardIosOutlined from '@material-ui/icons/ArrowForwardIosOutlined';

import Form from "../../common/form";

import { transformList, getIdByName } from "../services/resources";

class RegistrationFormMother extends Form {
	constructor(props) {
		super(props);

		const { draft } = props;

		let mobile = "", motherHomeNo = "";

		if (draft.motherMobileNo) {
			mobile = "+".concat(draft.motherMobileNo.toString());
		}

		if (draft.motherHomeNo) {
			motherHomeNo = draft.motherHomeNo.toString();
		}

		this.state = {
			data: {
				motherName: draft.motherName || "",
				mobile: mobile || "+65",
				motherHomeNo: motherHomeNo || "",
				email: draft.motherEmail || "",
				motherMaritalStatus: 0,
				isMotherMarriedInChurch: draft.isMotherMarriedInChurch || "",
				motherReligion: 0,
				motherParishInvolvement: draft.motherParishInvolvement || "",
			},
			maritalStatus: [],
			religions: [],
			errors: {},
		};
	}

	schema = {
		motherName: Joi.string().trim().required().label("Mothers Full Name"),
		mobile: Joi.string().trim().required().label("Mobile Number")
			// eslint-disable-next-line no-useless-escape
			.regex(/^\+[1-9?\s]{1}[0-9?\s]{9,14}$/),
		motherHomeNo: Joi.string().trim().required().label("Mothers Home Number"),
		email: Joi.string().trim().required().label("Email Address")
			// eslint-disable-next-line no-useless-escape
			.regex(/[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/),
		motherMaritalStatus: Joi.string().trim().required().label("Mothers Marital Status"),
		isMotherMarriedInChurch: Joi.string().trim().required().label("Mothers Married in Church"),
		motherReligion: Joi.string().trim().required().label("Mothers Religion"),
		motherParishInvolvement: Joi.string().trim().required().label("Mothers Parish Involvement"),
	};

	async componentDidMount() {
		await this.initTransformList("maritalStatus", "maritalStatus");
		await this.initTransformList("religions", "religionList");
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {
		const { data, errors } = this.state;
		const newState = { ...this.state };

		if (!data.mobile && data.mobile !== prevState.data.mobile) {
			if (errors.mobile) {
				delete newState.errors.mobile;

				this.setState(newState);
			}

		}

		if (!data.email && data.email !== prevState.data.email) {
			if (errors.email) {
				delete newState.errors.email;

				this.setState(newState);
			}
		}
	}
	
	initTransformList = (type, listName) => {
		const { draft } = this.props;
		const { data } = this.state;
		const list = transformList(listName);
		const newData = { ...data };
		const state = {
			[type]: list,
		};

		let key = "", name = "";

		switch (type) {
		case "maritalStatus":
			key = "motherMaritalStatus";
			break;
		case "religions":
			key = "motherReligion";
			break;
		default:
		}

		if (draft[key]) {
			name = draft[key];
			newData[key] = getIdByName(listName, name).toString();;
			state.data = newData;
		}

		this.setState(state);
	}

	handleRadioChange = (type) => ({ currentTarget: input }) => {
		const { data } = this.state;
		const newData = { ...data };

		newData[type] = input.value;

		this.setState({ data: newData });
	}

	doSubmit = async () => {
		const { setRegistrationMothersDetails } = this.props;
		const { data, errors } = this.state;
		const {
			motherName = "",
			mobile = "",
			email = "",
			motherMaritalStatus = 0,
			isMotherMarriedInChurch = "",
			motherReligion = 0,
			// motherParishInvolvement = "", // optional
		} = data;

		const newData = { ...data };
		
		newData.motherMobileNo = mobile;
		newData.motherEmail = email;

		delete newData.mobile;
		delete newData.email;

		if (motherName && mobile && email && motherMaritalStatus &&
			isMotherMarriedInChurch && motherReligion && Object.keys(errors).length === 0) {
			setRegistrationMothersDetails(newData);
		}
	}

	renderNextButton = () => {
		const { data, errors } = this.state;
		const {
			motherName = "",
			mobile = "",
			email = "",
			motherMaritalStatus = 0,
			isMotherMarriedInChurch = "",
			motherReligion = 0,
			// motherParishInvolvement = "", // optional
		} = data;

		let isDisabled = true;

		if (motherName && mobile && email && motherMaritalStatus &&
			isMotherMarriedInChurch && motherReligion && Object.keys(errors).length === 0) {
			isDisabled = false;
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled }
			>
				Father's Details
			</Button>
		);
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;
		const newData = { ...data };
		
		newData.motherMobileNo = data.mobile;
		newData.motherEmail = data.email;

		delete newData.mobile;
		delete newData.email;

		saveDraft(newData);
	}

	handleSkip = () => {
		const { setFormStatus } = this.props;

		setFormStatus("input7");
	}

	handlePressBack = () => {
		const { pressBack } = this.props;

		pressBack();
	}

	render() {
		const { maritalStatus, religions, data } = this.state;

		return (
			<div>
				You are now mid-way through the application process.
				<br />
				<br />
				<div className="alert alert-warning position-relative" role="alert">
					If this section is not applicable, you may choose to skip it.
					<strong className="position-absolute" style={{ right: "5px", bottom: "5px", cursor: "pointer" }} onClick={ this.handleSkip }>
						Skip <ArrowForwardIosOutlined style={{ fontSize: "12px" }} />
					</strong>
				</div>
				<strong>Mother's Details</strong>
				<br />
				<br />
				<React.Fragment>
					<form>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								1
							</div>
							<div className="container">
								<div>
									<strong>Mother's Name</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"motherName",
										"",
										"text",
										"Enter name"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								2
							</div>
							<div className="container">
								<div>
									<strong>Mother's Mobile Number</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"mobile",
										"",
										"text",
										"Enter mobile number"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								3
							</div>
							<div className="container">
								<div>
									<strong>Mother's Home Number (Optional)</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"motherHomeNo",
										"",
										"text",
										"Enter home number"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								4
							</div>
							<div className="container">
								<div>
									<strong>Mother's Email Address</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"email",
										"",
										"text",
										"Enter email address"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								5
							</div>
							<div className="container">
								<div>
									<strong>Mother's Marital Status</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ maritalStatus.length ?
										this.renderSelect(
											"motherMaritalStatus",
											"",
											maritalStatus,
										) : null
									}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								6
							</div>
							<div className="ml-3">
								<div>
									<strong>Mother married in the Catholic Church?</strong>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="isMotherMarriedInChurch"
										type="radio"
										id="isMotherMarriedInChurch1"
										value={ "Yes" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("isMotherMarriedInChurch") }
										checked={ data.isMotherMarriedInChurch === "Yes" }
									/>
									<label className="form-check-label ml-2" htmlFor="isMotherMarriedInChurch1">Yes</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="isMotherMarriedInChurch"
										type="radio"
										id="isMotherMarriedInChurch2"
										value={ "No" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("isMotherMarriedInChurch") }
										checked={ data.isMotherMarriedInChurch === "No" }
									/>
									<label className="form-check-label ml-2" htmlFor="isMotherMarriedInChurch2">No</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="isMotherMarriedInChurch"
										type="radio"
										id="isMotherMarriedInChurch3"
										value={ "Rather not say" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("isMotherMarriedInChurch") }
										checked={ data.isMotherMarriedInChurch === "Rather not say" }
									/>
									<label className="form-check-label ml-2" htmlFor="isMotherMarriedInChurch3">Rather not say</label>
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								7
							</div>
							<div className="container">
								<div>
									<strong>Mother's Religion</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ religions.length ?
										this.renderSelect(
											"motherReligion",
											"",
											religions,
										) : null
									}
								</div>
							</div>
						</div>
						<div className="d-flex">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								8
							</div>
							<div className="container">
								<div>
									<strong>Mother involved in any parish ministry or community? (Optional)</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderTextarea(
										"motherParishInvolvement",
										"",
										"text",
										"e.g. Roman Catholic Archdiocese of Singapore"
									)}
								</div>
							</div>
						</div>
					</form>
					<br />
					<br />
					{ this.props.coordinatorEmail ?
						<>
							If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
							<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
							<br />
							<br />
						</> : null
					}
					{ this.renderNextButton() }
					<Button className="btn-block mb-3" variant="outline-primary" size="lg"
						onClick={ this.handlePressBack }>
						Back
					</Button>
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		);
	}
}

export default RegistrationFormMother;