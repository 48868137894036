import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAdminStatsDashboard({ ...rest }) {
  const AsyncLoad = loadable(() => import("../admin/stats"));
  console.log("Loading", AsyncAdminStatsDashboard.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAdminStatsDashboard;
