import React, { Component } from "react";
import { getMassTime, getMassTimeAMPM } from "../../../utils/utils";

class PreviewScheduleEntry extends Component {
  checkError(value) {
    let temp =
      value.indexOf("NaN") !== -1 || value !== "Error" ? (
        value
      ) : (
        <strong className="text-danger">Error</strong>
      );
    return temp;
  }

  checkNaN(value) {
    let temp =
      value.indexOf("NaN") === -1 ? (
        value
      ) : (
        <strong className="text-danger">Error</strong>
      );
    return temp;
  }

  checkEmpty(value) {
    let temp =
      value.trim() !== "" ? (
        value.trim()
      ) : (
        <strong className="text-danger">Error</strong>
      );
    return temp;
  }

  disabledButton = (mass, massCapacity) => {
    const { id } = mass;

    return (
      <button
        id={`selectedTime_${id}`}
        disabled
        type="button"
        style={{ border: "1px solid #bbbbbb" }}
        className={`btn  btn-block my-3 py-2`}
      >
        <h4 className="card-title">
          <span className="float-left text-secondary">
            {this.checkNaN(getMassTime(mass.date))}
          </span>
          <span className="float-right text-secondary">
            {this.checkNaN(massCapacity)}
          </span>
        </h4>
        <div className="clearfix pb-1"></div>
        <div className="float-left text-secondary defaultfontsize">
          {this.checkEmpty(mass.lang)} &bull; {this.checkEmpty(mass.location)}
        </div>
      </button>
    );
  };

  enabledButton = (selected, mass, massCapacity) => {
    const { id } = mass;
    let buttonStyle = "btn btn-outline-success btn-block my-3 py-2";
    if (selected === id) {
      //selected button
      buttonStyle = "btn btn-success btn-block my-3 py-2";
    }
    return (
      <button
        id={`selectedTime_${id}`}
        type="button"
        onClick={() => {}}
        className={buttonStyle}
      >
        <h4 className="card-title">
          <span className="float-left">
            {this.checkNaN(getMassTimeAMPM(mass.date))}
          </span>
          <span className="float-right">{this.checkError(massCapacity)}</span>
        </h4>
        <div className="clearfix pb-1"></div>
        <div className="float-left defaultfontsize">
          {this.checkEmpty(mass.lang)} &bull; {this.checkEmpty(mass.location)}
        </div>
      </button>
    );
  };

  render() {
    const { details, selectedTime, closed, forcedclosed } = this.props;
    // console.log("Details:", details);
    let available = details.available;
    let capacity = available;
    let disabled = false;
    let label = "";

    if (available === 0) {
      capacity = "FULL";
      disabled = true;
    } else {
      //plural if more than 1
      capacity += parseInt(available) > 1 ? " Seats" : " Seat";
    }
    label = capacity;
    //check if CLOSED manually either from the backend or via code --> "closed": true
    if (closed) {
      label = "CLOSED";
      disabled = true;
    }

    if (forcedclosed === false) {
      //check if disabled but using different labels
      // capacity = "CLOSED";
      label = "CLOSED";
      disabled = true;
    }

    if (details.capacity === 0 || capacity === "FULL" || available <= 0) {
      label = "Error";
    }

    disabled = false; //THIS IS TO OVERRIDE EVERYTHING, MAKE ALL BUTTONS ENABLED FOR PREVIEW

    if (!disabled) {
      return this.enabledButton(selectedTime, details, label);
    }
    return this.disabledButton(details, label);
  }
}

export default PreviewScheduleEntry;
