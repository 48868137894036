import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
const payPlusQRCode = require("../../wallofhope/img/payPlusQRCode.png");
const scanAndPayButtonImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fmassintentions%2FscanAndPayButton.jpg?alt=media&token=9ee80c42-c7aa-4e95-909d-96733948ee83";

const GenPayPlusGuide = () => {
  const [isMobileView, setIsMobileView] = useState(true);
  return (
    <>
      <div>
        <h5 className="mb-4">How do I make payment?</h5>
        <p>
          Payment has to be made using the{" "}
          <span className="error-text">
            <em>Scan & Pay</em>
          </span>{" "}
          feature on your bank app.
        </p>
        <div className="row mb-4">
          <div className="col" style={{ textAlign: "left" }}>
            <Button
              variant="link"
              style={{
                marginLeft: "-12px",
                fontWeight: isMobileView ? "bold" : "",
              }}
              onClick={() => setIsMobileView(true)}
            >
              Mobile/Tablet
            </Button>
          </div>
          <div className="col" style={{ textAlign: "left" }}>
            <Button
              variant="link"
              style={{
                marginLeft: "-12px",
                fontWeight: isMobileView ? "" : "bold",
              }}
              onClick={() => setIsMobileView(false)}
            >
              Laptop/Desktop
            </Button>
          </div>
          <div className="col">&nbsp;</div>
        </div>
        <hr />
      </div>
      {isMobileView ? (
        <>
          <h5 className="mt-4 mb-4">From your mobile/tablet device</h5>
          <div className="row">
            <div className="col-2" style={{ position: "relative" }}>
              <span
                className="circle-lg"
                style={{
                  textAlign: "right",
                  backgroundColor: "#fde4de",
                  marginLeft: "15px",
                }}
              ></span>
              <h5 style={{ position: "absolute", top: "8px", left: "46px" }}>
                1
              </h5>
            </div>
            <div className="col-10">
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  <b>Tap and hold</b> the QR code on your mobile phone, then
                  select the option{" "}
                  <span className="error-text">
                    <em>Add to Photos</em>
                  </span>
                  .
                </li>
              </ul>

              <div className="mb-5" style={{ textAlign: "center" }}>
                <Image src={payPlusQRCode} width="320px" thumbnail />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <span
                className="circle-lg"
                style={{
                  textAlign: "right",
                  backgroundColor: "#fde4de",
                  marginLeft: "15px",
                }}
              ></span>
              <h5 style={{ position: "absolute", top: "8px", left: "45px" }}>
                2
              </h5>
            </div>
            <div className="col-10">
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  Open your bank app and tap on the feature{" "}
                  <span className="error-text">
                    <em>Scan & Pay</em>
                  </span>
                  .
                </li>
              </ul>
              <div className="mt-3 mb-4" style={{ textAlign: "center" }}>
                <Image src={scanAndPayButtonImageUrl} width="320px" thumbnail />
              </div>
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  Upload the QR code you have just saved onto the bank app.
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-2">
              <span
                className="circle-lg"
                style={{
                  textAlign: "right",
                  backgroundColor: "#fde4de",
                  marginLeft: "15px",
                }}
              ></span>
              <h5 style={{ position: "absolute", top: "8px", left: "45px" }}>
                3
              </h5>
            </div>
            <div className="col-10">
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  Payment amount and reference number will be reflected on your
                  mobile phone.
                </li>
                <br />
                <li>Make payment.</li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <h5 className="mt-4 mb-4">From your laptop/desktop computer</h5>
          <div className="row">
            <div className="col-2">
              <span
                className="circle-lg"
                style={{
                  textAlign: "right",
                  backgroundColor: "#fde4de",
                  marginLeft: "15px",
                }}
              ></span>
              <h5 style={{ position: "absolute", top: "8px", left: "46px" }}>
                1
              </h5>
            </div>
            <div className="col-10">
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  Open your bank app and tap on the feature{" "}
                  <span className="error-text">
                    <em>Scan & Pay</em>
                  </span>
                  .
                </li>
              </ul>
              <div className="mt-3 mb-4" style={{ textAlign: "center" }}>
                <Image src={scanAndPayButtonImageUrl} width="320px" thumbnail />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-2" style={{ position: "relative" }}>
              <span
                className="circle-lg"
                style={{
                  textAlign: "right",
                  backgroundColor: "#fde4de",
                  marginLeft: "15px",
                }}
              ></span>
              <h5 style={{ position: "absolute", top: "8px", left: "45px" }}>
                2
              </h5>
            </div>
            <div className="col-10">
              <ul style={{ paddingLeft: "20px" }}>
                <li>Scan the QR code with your bank app.</li>
              </ul>
              <div className="mb-4" style={{ textAlign: "center" }}>
                <Image src={payPlusQRCode} width="320px" thumbnail />
              </div>
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  Payment amount and reference number will be reflected on your
                  mobile phone.
                </li>
                <br />
                <li>Make payment.</li>
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GenPayPlusGuide;
