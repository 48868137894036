import React from "react";
import Joi from "joi-full";
import { Button } from "react-bootstrap";
import ArrowForwardIosOutlined from '@material-ui/icons/ArrowForwardIosOutlined';

import Form from "../../common/form";

import { transformList, getIdByName } from "../services/resources";

class RegistrationFormFather extends Form {
	constructor(props) {
		super(props);

		const { draft } = props;

		let mobile = "", fatherHomeNo = "";

		if (draft.fatherMobileNo) {
			mobile = "+".concat(draft.fatherMobileNo.toString());
		}

		if (draft.fatherHomeNo) {
			fatherHomeNo = draft.fatherHomeNo.toString();
		}

		this.state = {
			data: {
				fatherName: draft.fatherName || "",
				mobile: mobile || "+65",
				fatherHomeNo: fatherHomeNo || "",
				email: draft.fatherEmail || "",
				fatherMaritalStatus: 0,
				isFatherMarriedInChurch: draft.isFatherMarriedInChurch || "",
				fatherReligion: 0,
				fatherParishInvolvement: draft.fatherParishInvolvement || "",
			},
			maritalStatus: [],
			religions: [],
			errors: {},
		};
	}

	schema = {
		fatherName: Joi.string().trim().required().label("Fathers Full Name"),
		mobile: Joi.string().trim().required().label("Mobile Number")
			// eslint-disable-next-line no-useless-escape
			.regex(/^\+[1-9?\s]{1}[0-9?\s]{9,14}$/),
		fatherHomeNo: Joi.string().trim().required().label("Fathers Home Number"),
		email: Joi.string().trim().required().label("Email Address")
			// eslint-disable-next-line no-useless-escape
			.regex(/[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/),
		fatherMaritalStatus: Joi.string().trim().required().label("Fathers Marital Status"),
		isFatherMarriedInChurch: Joi.string().trim().required().label("Fathers Married in Church"),
		fatherReligion: Joi.string().trim().required().label("Fathers Religion"),
		fatherParishInvolvement: Joi.string().trim().required().label("Fathers Parish Involvement"),
	};

	async componentDidMount() {
		await this.initTransformList("maritalStatus", "maritalStatus");
		await this.initTransformList("religions", "religionList");
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {
		const { data, errors } = this.state;
		const newState = { ...this.state };

		if (!data.mobile && data.mobile !== prevState.data.mobile) {
			if (errors.mobile) {
				delete newState.errors.mobile;

				this.setState(newState);
			}

		}

		if (!data.email && data.email !== prevState.data.email) {
			if (errors.email) {
				delete newState.errors.email;

				this.setState(newState);
			}
		}
	}
	
	initTransformList = (type, listName) => {
		const { draft } = this.props;
		const { data } = this.state;
		const list = transformList(listName);
		const newData = { ...data };
		const state = {
			[type]: list,
		};

		let key = "", name = "";

		switch (type) {
		case "maritalStatus":
			key = "fatherMaritalStatus";
			break;
		case "religions":
			key = "fatherReligion";
			break;
		default:
		}

		if (draft[key]) {
			name = draft[key];
			newData[key] = getIdByName(listName, name).toString();;
			state.data = newData;
		}

		this.setState(state);
	}

	handleRadioChange = (type) => ({ currentTarget: input }) => {
		const { data } = this.state;
		const newData = { ...data };

		newData[type] = input.value;

		this.setState({ data: newData });
	}

	doSubmit = async () => {
		const { setRegistrationFathersDetails } = this.props;
		const { data, errors } = this.state;
		const {
			fatherName = "",
			mobile = "",
			email = "",
			fatherMaritalStatus = 0,
			isFatherMarriedInChurch = "",
			fatherReligion = 0,
			// fatherParishInvolvement = "", // optional
		} = data;

		const newData = { ...data };
		
		newData.fatherMobileNo = mobile;
		newData.fatherEmail = email;

		delete newData.mobile;
		delete newData.email;

		if (fatherName && mobile && email && fatherMaritalStatus &&
			isFatherMarriedInChurch && fatherReligion && Object.keys(errors).length === 0) {
			setRegistrationFathersDetails(newData);
		}
	}

	renderNextButton = () => {
		const { data, errors } = this.state;
		const {
			fatherName = "",
			mobile = "",
			email = "",
			fatherMaritalStatus = 0,
			isFatherMarriedInChurch = "",
			fatherReligion = 0,
			// fatherParishInvolvement = "", // optional
		} = data;

		let isDisabled = true;

		if (fatherName && mobile && email && fatherMaritalStatus &&
			isFatherMarriedInChurch && fatherReligion && Object.keys(errors).length === 0) {
			isDisabled = false;
		}

		return (
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={ this.doSubmit }
				disabled={ isDisabled }
			>
				Indicate Main Contact
			</Button>
		);
	}

	handleOnClick = () => {
		const { saveDraft } = this.props;
		const { data } = this.state;
		const newData = { ...data };
		
		newData.fatherMobileNo = data.mobile;
		newData.fatherEmail = data.email;

		delete newData.mobile;
		delete newData.email;

		saveDraft(newData);
	}

	handleSkip = () => {
		const { setFormStatus } = this.props;

		setFormStatus("input8");
	}

	handlePressBack = () => {
		const { pressBack } = this.props;

		pressBack();
	}

	render() {
		const { maritalStatus, religions, data } = this.state;

		return (
			<div>
				<div className="alert alert-warning position-relative" role="alert">
					If this section is not applicable, you may choose to skip it.
					<strong className="position-absolute" style={{ right: "5px", bottom: "5px", cursor: "pointer" }} onClick={ this.handleSkip }>
						Skip <ArrowForwardIosOutlined style={{ fontSize: "12px" }} />
					</strong>
				</div>
				<strong>Father's Details</strong>
				<br />
				<br />
				<React.Fragment>
					<form>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								1
							</div>
							<div className="container">
								<div>
									<strong>Father's Name</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"fatherName",
										"",
										"text",
										"Enter name"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								2
							</div>
							<div className="container">
								<div>
									<strong>Father's Mobile Number</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"mobile",
										"",
										"text",
										"Enter mobile number"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								3
							</div>
							<div className="container">
								<div>
									<strong>Father's Home Number (Optional)</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"fatherHomeNo",
										"",
										"text",
										"Enter home number"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								4
							</div>
							<div className="container">
								<div>
									<strong>Father's Email Address</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderInput(
										"email",
										"",
										"text",
										"Enter email address"
									)}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								5
							</div>
							<div className="container">
								<div>
									<strong>Father's Marital Status</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ maritalStatus.length ?
										this.renderSelect(
											"fatherMaritalStatus",
											"",
											maritalStatus,
										) : null
									}
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								6
							</div>
							<div className="ml-3">
								<div>
									<strong>Father married in the Catholic Church?</strong>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="isFatherMarriedInChurch"
										type="radio"
										id="isFatherMarriedInChurch1"
										value={ "Yes" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("isFatherMarriedInChurch") }
										checked={ data.isFatherMarriedInChurch === "Yes" }
									/>
									<label className="form-check-label ml-2" htmlFor="isFatherMarriedInChurch1">Yes</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="isFatherMarriedInChurch"
										type="radio"
										id="isFatherMarriedInChurch2"
										value={ "No" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("isFatherMarriedInChurch") }
										checked={ data.isFatherMarriedInChurch === "No" }
									/>
									<label className="form-check-label ml-2" htmlFor="isFatherMarriedInChurch2">No</label>
								</div>
								<div className="ml-4 mt-2 mb-3">
									<input
										className="form-check-input"
										name="isFatherMarriedInChurch"
										type="radio"
										id="isFatherMarriedInChurch3"
										value={ "Rather not say" }
										style={{ transform: "scale(1.5)" }}
										onChange={ this.handleRadioChange("isFatherMarriedInChurch") }
										checked={ data.isFatherMarriedInChurch === "Rather not say" }
									/>
									<label className="form-check-label ml-2" htmlFor="isFatherMarriedInChurch3">Rather not say</label>
								</div>
							</div>
						</div>
						<div className="d-flex mt-2">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								7
							</div>
							<div className="container">
								<div>
									<strong>Father's Religion</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ religions.length ?
										this.renderSelect(
											"fatherReligion",
											"",
											religions,
										) : null
									}
								</div>
							</div>
						</div>
						<div className="d-flex">
							<div className="rounded-circle bg-primary d-flex justify-content-center align-items-center text-white"
								style={{ height: "25px", minWidth: "25px" }}>
								8
							</div>
							<div className="container">
								<div>
									<strong>Father involved in any parish ministry or community? (Optional)</strong>
								</div>
								<div className="ml-2 mt-2 pl-0 container">
									{ this.renderTextarea(
										"fatherParishInvolvement",
										"",
										"text",
										"e.g. Roman Catholic Archdiocese of Singapore"
									)}
								</div>
							</div>
						</div>
					</form>
					<br />
					<br />
					{ this.props.coordinatorEmail ?
						<>
							If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
							<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
							<br />
							<br />
						</> : null
					}
					{ this.renderNextButton() }
					<Button className="btn-block mb-3" variant="outline-primary" size="lg"
						onClick={ this.handlePressBack }>
						Back
					</Button>
					<button
						className="btn-block mb-3 btn outline-primary btn-lg"
						style={{ border: "none", color: "#007bff" }}
						onClick={ this.handleOnClick }
					>
						Save as draft
					</button>
				</React.Fragment>
			</div>
		);
	}
}

export default RegistrationFormFather;