import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";

import { useLookupConfig } from "../hooks/hooks";

import { downloadCert } from "../services/downloadCert";

export default function TransferDetails({ user, selectedItem, external }) {
  const lookupConfig = useLookupConfig();

  // console.log(selectedItem);

  if (selectedItem == null) return null;

  const {
    parish: fromParish,
    programme: fromProgramme,
    timeslot: fromTimeslot,
  } = selectedItem.from.inSingapore
    ? lookupConfig(
        selectedItem.from.id,
        selectedItem.from.programmeId,
        selectedItem.from.timeslotId
      )
    : {
        parish:{ parish: selectedItem.from.id },
        programme: {
          name: (
            <span className="text-muted font-italic">(Overseas Parish)</span>
          ),
        },
        timeslot: null,
      };
  const {
    parish: toParish,
    programme: toProgramme,
    timeslot: toTimeslot,
  } = selectedItem.to.inSingapore
    ? lookupConfig(
        selectedItem.to.id,
        selectedItem.to.programmeId,
        selectedItem.to.timeslotId
      )
    : {
        parish: { parish: selectedItem.to.id },
        programme: {
          name: (
            <span className="text-muted font-italic">(Overseas Parish)</span>
          ),
        },
        timeslot: null,
      };

  return (
    <>
      <div className="mb-sm-3">
        <div className="mb-sm-3">
          <h5>Transfer Request</h5>
        </div>
        <span className="font-weight-bold">Name</span>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.registration.name : "-"}
        </div>
        <Row className="py-2">
          <Col xs={12} sm={6}>
            <span className="font-weight-bold">From</span>
            <div>{fromParish.parish}</div>
            {fromProgramme != null && <div>{fromProgramme.name}</div>}
            {fromTimeslot != null && <div>{fromTimeslot.name}</div>}
          </Col>
          <Col xs={12} sm={6} className="border-left">
            <span className="font-weight-bold">To</span>
            <div>{toParish.parish}</div>
            {toProgramme != null && <div>{toProgramme.name}</div>}
            {toTimeslot != null && <div>{toTimeslot.name}</div>}
          </Col>
        </Row>
      </div>
      <div className="mb-sm-3">
        <span className="font-weight-bold">Outside Boundary Reason</span>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.outsideBoundaryReason : "-"}
        </div>
        <span className="font-weight-bold">Reason for Transfer</span>
        <div className="mb-sm-3">
          {selectedItem ? selectedItem.transferReason : "-"}
        </div>
        {external && (
          <>
            <span className="font-weight-bold">Transfer Letter</span>
            <br />
            {selectedItem && (
              <Button
                variant="secondary"
                className="text-wrap text-dark"
                style={{
                  backgroundColor: "#f3f3f3",
                  borderColor: "#f3f3f3",
                }}
                onClick={() => {
                  downloadCert(
                    {
                      path: selectedItem.registration.transferLetterPath,
                      name: selectedItem.registration.transferLetterName,
                    },
                    user
                  );
                }}
              >
                <AttachFileOutlinedIcon
                  className="mr-1"
                  style={{ color: "#7c7c7c" }}
                />
                {selectedItem.registration.transferLetterPath
                  ? selectedItem.registration.transferLetterName
                  : "—"}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
}
