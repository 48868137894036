import React from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { useMediaQuery, useParishesConfig } from "../../hooks/hooks";

function formatDate(date, name) {
  if (!date) return date;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = date.getMonth();
  const monthStr = months[month];

  return {
    [`${name}Date`]: `${day} ${monthStr}`,
    [`${name}Idx`]: month * 100 + day,
  };
}

export default function PreRegistrationDetails({ setRegistrationStep }) {
  const parishesConfig = useParishesConfig();
  const isGteSmWidth = useMediaQuery("(min-width: 576px)");

  const registrationOpenList = parishesConfig
    .filter(({ isActive, isCatchParish }) => isActive && isCatchParish)
    .map(({ parish, programmes }) => ({
      parish,
      programmes: programmes
        .map(({ name, registrationOpen, registrationClose }) => ({
          name,
          ...formatDate(registrationOpen.toDate(), "open"),
          ...formatDate(registrationClose?.toDate(), "close"),
        }))
        .sort(({ openIdx: idx1 }, { openIdx: idx2 }) => idx1 - idx2),
    }));

  // console.log(registrationOpenList);

  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-5">
          <Container
            className={`d-flex flex-column align-items-start w-100 ${
              isGteSmWidth ? "px-5" : "px-2"
            }`}
          >
            <p>
              This is an online registration service for Catechesis for certain
              Parishes in Singapore. The list of supported Parishes you can
              register for is as shown below. If your Parish is not in this
              list, please contact your desired Parish directly to register,
              instead of doing so through this system.
            </p>
            <Table bordered responsive size="sm">
              <thead>
                <tr>
                  <th>Parish</th>
                  <th>Programme</th>
                  <th>Open</th>
                  <th>Close</th>
                </tr>
              </thead>
              <tbody>
                {registrationOpenList.flatMap(({ parish, programmes }) =>
                  programmes.map(({ name, openDate, closeDate }, idx) => (
                    <tr key={parish + " " + name}>
                      {idx === 0 ? (
                        <th scope="row" rowSpan={programmes.length}>
                          {parish}
                        </th>
                      ) : null}
                      <td>{name}</td>
                      <td style={{ minWidth: 60 }}>{openDate}</td>
                      <td style={{ minWidth: 60 }}>{closeDate ?? "-"}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <br />
            <Alert
              variant="warning"
              className="d-flex justify-content-start align-items-center px-2 w-100"
            >
              <InfoOutlinedIcon className="text-warning mr-1" />
              <span className="text-dark">
                Please have all Sacramental Certificates (e.g., Baptism Cert,
                Confirmation Cert) on hand before proceeding.
              </span>
            </Alert>
            <br />
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={() => setRegistrationStep(1)}
            >
              Begin Registration
            </Button>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
}
