import React, { useContext, useState } from "react";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Header from "../../../common/header";

import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";

import AppGlobalContext from "../../../../AppGlobalContext";

import { useActiveRegistration, useGuardian } from "../hooks/hooks";
import { isValidPage } from "../services/registrationStatus";

import ForbiddenRoute from "../components/ForbiddenRoute";

import GuardianParticulars from "../components/guardianSetup/GuardianParticulars";
import GuardianLink from "../components/guardianSetup/GuardianLink";
import QuickGuide from "../components/QuickGuide";

function GuardianSetup({
  guardianSetupPart,
  setGuardianSetupPart,
  guardianDetails,
  setGuardianDetails,
}) {
  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center p-0 m-0">
          <Container
            className="py-5 border-bottom"
            style={{ backgroundColor: "#e5f2ff" }}
          >
            <Row className="justify-content-center mb-3">
              <SupervisorAccountOutlinedIcon
                className="text-primary"
                fontSize="large"
              />
            </Row>
            <Row className="justify-content-center">
              <h4 className="my-0">You're on a Guardian Account</h4>
            </Row>
          </Container>
          <GuardianParticulars
            guardianSetupPart={guardianSetupPart}
            setGuardianSetupPart={setGuardianSetupPart}
            setGuardianDetails={setGuardianDetails}
          />
          <GuardianLink
            guardianSetupPart={guardianSetupPart}
            guardianDetails={guardianDetails}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default function GuardianControl() {
  const { user } = useContext(AppGlobalContext);
  const activeRegistrationState = useActiveRegistration();
  const guardianState = useGuardian();

  const statusName = activeRegistrationState.status;

  const isAccessingValidPage = isValidPage({
    isGuardian: guardianState.isGuardian,
    statusName,
    page: "guardianControl",
  });

  const [guardianSetupPart, setGuardianSetupPart] = useState(0);
  const [guardianDetails, setGuardianDetails] = useState({});

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ minHeight: "calc(100vh - 4.5rem)" }}
      >
        <Header type="backandlogo" smallpic={user.photoURL} />
        <Container className="my-4 flex-grow-1 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <h2 className="mb-2">Family Setup</h2>
            <QuickGuide />
          </div>
          {isAccessingValidPage ? (
            <GuardianSetup
              guardianSetupPart={guardianSetupPart}
              setGuardianSetupPart={setGuardianSetupPart}
              guardianDetails={guardianDetails}
              setGuardianDetails={setGuardianDetails}
            />
          ) : (
            <Card className="flex-grow-1 d-flex flex-column">
              <Card.Body
                className="d-flex flex-column justify-content-center align-items-center py-5"
                style={{ gap: "1.5rem" }}
              >
                <ForbiddenRoute />
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
}
