import React from "react";

const InputFocus = ({ name, label, error, showsuccess, ...rest }) => {
  let formClass = "form-control form-control-lg ";
  if (error) {
    formClass = `${formClass} is-invalid`;
  }
  if (showsuccess) {
    formClass = `${formClass} is-valid`;
  }
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <input
        {...rest}
        ref={(input) => input && input.focus()}
        name={name}
        id={name}
        className={formClass}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      />
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default InputFocus;
