import React from "react";

function ForgotErrorMessages({ error, email }) {
  const GetMessage = () => {
    switch (error.code) {
      case "auth/user-not-found":
        return (
          <>
            <h5 className="pt-2 pb-0">
              We have sent an email to{" "}
              <span className="text-danger">{email}</span>
            </h5>
            <h5 className="pt-3 pb-5">
              If this is a valid email, you should receive it. Otherwise, double
              check if you have used the correct email address.
            </h5>
          </>
        );
      //TODO: what else we need to add here?
      default:
        return (
          <>
            <p className="defaultfontsize pt-0 pb-2">
              We are currently experiencing a high volume of requests from users
              to reset their passwords.
            </p>
            <div className="alert alert-primary p-2">
              To protect your account, we only send a limited number of emails
              per day. Please try again later, or contact us if you need urgent
              help.
            </div>
            <p className="defaultfontsize pt-3 pb-5">
              Thank you for your patience. God bless!
            </p>
          </>
        );
    }
  };

  return <GetMessage />;
}

export default ForgotErrorMessages;
