import React from "react";
import { Link } from "react-router-dom";
import Header from "../../common/header";
import ReloadModal from "../../common/reloadModal";
import ArchdioceseLogo from "../../blocks/archdioceseLogoBlock";
import LinkButton from "../../blocks/linkButtonBlock";

function RegistrationDisabled() {
  return (
    <React.Fragment>
      <Header type="backandlogo" hide="profile" />
      <ReloadModal />

      <div className="row justify-content-center  mx-0">
        <div className="col-lg-6">
          <main className="container mx-auto px-0 pt-5">
            <ArchdioceseLogo />
            <div className="my-4 pt-4 pb-4 text-center greyframecolor roundcornernocolor">
              <h3>Registration Closed</h3>
              <div className="pt-3">
                <h5>We have temporarily closed the registration.</h5>
              </div>
              <div className="pt-3">
                <h5>Thank you very much for your patience.</h5>
              </div>
            </div>
            <div className="mt-5 text-center">
              <Link to="/home" className="biggerfontsize">
                Back to Home
              </Link>
            </div>
          </main>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegistrationDisabled;
