import React, { useContext } from "react";
import PayplusContext from "../payplusContext";
import PaymentNotice from "./PaymentNotice";

const PaymentSelector = ({
  isGeneral = false,
  genSelectedCampaign = {},
  genState = {
    hasConsent: false,
    displayPaymentSelectorReturnBtn: true,
  },
  genCallbacks = {
    onConsent: () => {},
    onNextStep: () => {},
    onReturnBtnCB: () => {},
  },
}) => {
  const { payplusState, payplusDispatch } = useContext(PayplusContext);
  let campaignName,
    orgName,
    payplusUEN,
    orgShortName,
    selectedCampaign,
    paymentConsent;

  if (isGeneral) {
    ({ campaignName, orgName, payplusUEN, orgShortName } = genSelectedCampaign);
  } else {
    ({ selectedCampaign, paymentConsent } = payplusState);
    ({ campaignName, orgName, payplusUEN, orgShortName } = selectedCampaign);
  }

  return (
    <div className="row justify-content-center mx-0">
      <div className="col px-1">
        <main className="container">
          <div className="pb-3 pt-3">
            <>
              <PaymentNotice
                campaignName={campaignName}
                orgShortName={orgShortName}
                orgName={orgName}
                payplusUEN={payplusUEN}
                paymentConsent={
                  isGeneral ? genState.hasConsent : paymentConsent
                }
                onConsent={() =>
                  isGeneral
                    ? genCallbacks.onConsent(!genState.hasConsent)
                    : payplusDispatch({
                        type: "SET_CONSENT",
                        payload: { paymentConsent: !paymentConsent },
                      })
                }
              />
              <button
                type="button"
                disabled={isGeneral ? !genState.hasConsent : !paymentConsent}
                onClick={() =>
                  isGeneral
                    ? genCallbacks.onNextStep()
                    : payplusDispatch({ type: "SET_FINAL_STEP" })
                }
                className="btn btn-danger mt-4 btn-block btn-lg"
              >
                Continue with Payment
              </button>
              {genState.displayPaymentSelectorReturnBtn && (
                <button
                  type="button"
                  onClick={() =>
                    isGeneral
                      ? genCallbacks.onReturnBtnCB()
                      : payplusDispatch({ type: "SET_STEP_ONE" })
                  }
                  className="btn bg-white text-primary mt-3 btn-block btn-lg roundcornernocolor"
                >
                  {isGeneral ? "Go Back" : "Select a different payment"}
                </button>
              )}
            </>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PaymentSelector;
