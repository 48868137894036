import React from "react";
import { useHistory } from "react-router-dom";
import { getGoogleMapSearchUrl } from "../../utils/utils";
import IconByField from "./iconByField";

function RoundParishIconShortcuts({ item }) {
  const history = useHistory();
  let {
    address,
    website,
    facebook,
    phone,
    bulletin,
    // schedule,
    more,
  } = item;

  let origwebsite = website;
  if (website) {
    website = website.split("/");
    website = website[2];
  }

  return (
    <div className=" text-center px-2">
      <div className="d-flex justify-content-between text-center">
        {bulletin && (
          <div className="text-truncate text-center  px-0">
            <div
              className="text-center text-primary mx-auto"
              onClick={() => history.push(bulletin)}
            >
              <div className="text-center roundborder">
                <IconByField field="bulletin" />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize font-weight-bold">
                BULLETIN
              </div>
            </div>
          </div>
        )}
        {address && (
          <div className="text-truncate text-center px-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-truncate  text-center"
              href={getGoogleMapSearchUrl(address)}
            >
              <div className="text-center roundborder">
                <IconByField field={"directions"} />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize font-weight-bold">
                DIRECTIONS
              </div>
            </a>
          </div>
        )}
        {more && (
          <div className="text-truncate text-center  px-0">
            <div className="text-center text-primary mx-auto" onClick={more}>
              <div className="text-center roundborder">
                <IconByField field="more" />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize font-weight-bold">
                MORE
              </div>
            </div>
          </div>
        )}
        {facebook && (
          <div className="text-truncate  text-center px-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-truncate text-center"
              href={facebook}
            >
              <div className="text-center roundborder">
                <IconByField field={"facebook"} />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize font-weight-bold">
                FB
              </div>
            </a>
          </div>
        )}

        {website && (
          <div className="text-truncate text-center  px-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-center mx-auto"
              href={origwebsite}
            >
              <div className="text-center roundborder">
                <IconByField field={"website"} />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize font-weight-bold">
                WEBSITE
              </div>
            </a>
          </div>
        )}

        {phone && (
          <div className="text-truncate text-center  px-0">
            <a
              target="_blank"
              className="text-center"
              rel="noopener noreferrer"
              href={`tel:${phone}`}
            >
              <div className="text-center roundborder">
                <IconByField field={"phone"} />
              </div>
              <div className="pt-1 text-truncate defaultstandardfontsize font-weight-bold">
                CALL
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default RoundParishIconShortcuts;
