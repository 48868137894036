import React from "react";
import { Button, Card } from "react-bootstrap";

const PreRegistration = ({ setFormStatus, isRetrievingData, draft = {} }) => {
	let setTo = "input";

	if (draft.selectedParish) {
		setTo = "review";
	}

	return (
		<>
			<div className="text-left my-4">
				<h1>Registration for Catechesis</h1>
			</div>
			<Card className="my-5">
				<Card.Body className="p-4">
					<div className="w-100 text-left">
						Hello and peace be with you!
					</div>
					<br />
					<div className="w-100 text-left">
					Welcome to the Archdiocesan Catechetical Registration system.
					</div>
					<br />
					This is an online registration service for Catechesis for certain Parishes in Singapore.{" "}
					The list of supported Parishes you can register for is as shown below.{" "}
					If your Parish is not in this list, please contact your desired Parish directly to register, instead of doing so through this system.
					<br />
					<br />
					<strong>Opens 1 Oct</strong>
					<br />
					&bull;{" "}Church of Christ the King
					<br />
					&bull;{" "}Church of Divine Mercy
					<br />
					&bull;{" "}Church of Our Lady Star of the Sea
					<br />
					&bull;{" "}Church of St Anthony
					<br />
					&bull;{" "}Church of St Bernadette
					<br />
					&bull;{" "}Church of St Francis Xavier
					<br />
					&bull;{" "}Church of St Mary of the Angels
					<br />
					&bull;{" "}Church of St Vincent de Paul
					<br />
					&bull;{" "}Church of the Holy Spirit
					<br />
					<strong>Opens 15 Oct</strong>
					<br />
					&bull;{" "}Church of St Teresa
					<br />
					&bull;{" "}Church of the Risen Christ
					<br />
					<strong>Opens 19 Oct</strong>
					<br />
					&bull;{" "}St Joseph's Church (Bukit Timah)
					<br />
					<br />
					If your desired Parish is in the list, you will be registering for Catechesis. This process will take approximately <strong>15 minutes</strong>.
					<br />
					<br />
					<strong style={{ color: "red" }}>
						Please do prepare a soft copy of your child's baptism certificate for upload upon submission of this form.
					</strong>
				</Card.Body>
			</Card>
			<Button
				className="btn-block mb-3"
				size="lg"
				onClick={() => setFormStatus(setTo)}
				disabled={ isRetrievingData }
			>
				Begin Registration
			</Button>
		</>
	);
};

export default PreRegistration;
