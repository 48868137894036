import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../../common/loadingSpinner";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { getCurrentUserData, userLogout } from "../../../services/user";
import ParishPriest from "../../blocks/parishPriestPhotoBlock";
import { isValidParish } from "../../../services/validation";
import { logEvent } from "../../../services/log";
import DemoBar from "../../common/demoBar";

class AccountSetupSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fullname: "",
        identification: "",
        email: "",
        dob: "",
        mobile: "+65",
        parish: 0,
      },
      user: this.props.user,
      errors: {},
      parish: [],
      loading: true,
    };
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        fullname,
        email,
        mobile,
        dob,
        identification,
        parish,
        unaffiliated,
      } = user;

      this.setState({
        data: {
          fullname,
          email,
          mobile,
          dob,
          identification,
          parish,
          unaffiliated: unaffiliated || false,
        },
        loading: false,
      });
      if (!parish) {
        this.props.history.push("/account/setup");
        return;
      }
      /*
          potentially redirect to home if filled in already
          */
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }
  componentDidMount() {
    this.getCurrentUserData();
    window.scrollTo(0, 0);
  }
  getThankYouMessage(data) {
    let message = "Thank you for registering.";
    // const parish = data.parish;
    // if (isValidParish(parish)) {
    //   message = `${message} at ${getParishCompleteNameById(parish)}`;
    // }
    return message;
  }

  getImageDisplay(data) {
    const parish = data.parish;
    let image;
    if (data?.unaffiliated) {
      image = (
        <div>
          <span role="img" style={{ fontSize: "100px" }} aria-label="thumbs-up">
            👍
          </span>
        </div>
      );
    } else if (isValidParish(parish)) {
      image = <ParishPriest parishId={parish} />;
    }
    return image;
  }

  doSubmit = async () => {
    try {
      await userLogout();
      // signed out
      window.location.reload();
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };
  render() {
    const { data, loading, user } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }
    const { emailVerified } = user;
    logEvent("account_setup_success", {
      email: data.email,
    });
    return (
      <React.Fragment>
        <DemoBar />
        <div className="row justify-content-center mt-5 mx-auto">
          <div className="col-lg-4">
            <main className="container">
              <div className="pb-3 mt-3 text-center">
                {this.getImageDisplay(data)}
                <h1 className="py-1">You're all set!</h1>
                <h3 className="py-4 ">{this.getThankYouMessage(data)}</h3>

                <Link
                  className="btn btn-lg btn-primary btn-block"
                  to="/home"
                  role="button"
                >
                  Visit Home Page
                </Link>
                <div className="text-center mt-5">
                  <span className="defaultfontsize">
                    All done?{" "}
                    <Button
                      variant="link"
                      className="mx-0 p-0 defaultfontsize"
                      style={{ marginTop: "-4px" }}
                      onClick={this.doSubmit}
                    >
                      Logout
                    </Button>
                  </span>
                </div>
                {emailVerified === false && (
                  <React.Fragment>
                    <p className=" defaultfontsize pt-2  my-0">
                      <MailOutlineIcon
                        style={{
                          fontSize: "24",
                          color: "red",
                          marginTop: "-4px",
                        }}
                      />{" "}
                      Please check your email
                    </p>
                    <p className=" defaultfontsize my-0">
                      to verify your account.
                    </p>
                  </React.Fragment>
                )}
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountSetupSuccess;
