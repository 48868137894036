import React, { useReducer, useEffect } from "react";
import fire from "../../../services/fire";
import { getCurrentUserData } from "../../../services/user";
import FinanceTopNav from "./FinanceTopNav";
import FinanceSideNav from "./FinanceSideNav";
import TransactionPage from "./TransactionPage";
import QRPage from "./QRPage";
import VendorAssignmentPage from "./VendorAssignmentPage";
import LoadingSpinner from "../../common/loadingSpinner";
import {
  isSuperAdminRole,
  isFinanceRole,
  isParishPriestRole,
  isGenericAdminRole,
} from "../../roles/service/roles";
import { hasCommuneOverallAdminMembership } from "../../roles/service/membership";
import FinanceContext from "./financeContext";
import {
  INIT_FINANCE_DASHBOARD_STATE,
  financeDashboardReducer,
} from "../reducers/financeDashboardReducer";
import {
  INIT_TRANSACTION_STATE,
  transactionDashboardReducer,
} from "../reducers/transactionReducer";
import {
  INIT_STATISTIC_STATE,
  statisticDashboardReducer,
} from "../reducers/statisticReducer";
import { INIT_QR_STATE, qrDashboardReducer } from "../reducers/qrReducer";
import {
  INIT_VENDOR_STATE,
  vendorDashboardReducer,
} from "../reducers/vendorReducer";
import "../stylesheets/finance-dashboard.css";

const FinanceDashboard = ({ user, history }) => {
  const [financeDashboardState, financeDashboardDispatch] = useReducer(
    financeDashboardReducer,
    INIT_FINANCE_DASHBOARD_STATE
  );
  const [transactionDashboardState, transactionDashboardDispatch] = useReducer(
    transactionDashboardReducer,
    INIT_TRANSACTION_STATE
  );
  const [statisticDashboardState, statisticDashboardDispatch] = useReducer(
    statisticDashboardReducer,
    INIT_STATISTIC_STATE
  );
  const [qrDashboardState, qrDashboardDispatch] = useReducer(
    qrDashboardReducer,
    INIT_QR_STATE
  );
  const [vendorDashboardState, vendorDashboardDispatch] = useReducer(
    vendorDashboardReducer,
    INIT_VENDOR_STATE
  );

  // Check appropriate roles and init user + org data
  useEffect(() => {
    const isGenericAdmin = isGenericAdminRole(user);
    if (
      isSuperAdminRole(user) ||
      isFinanceRole(user) ||
      isParishPriestRole(user) ||
      isGenericAdmin
    ) {
      const hasRequiredMembership = isGenericAdmin
        ? hasCommuneOverallAdminMembership(user.uid)
        : Promise.resolve(true);
      hasRequiredMembership.then((hasMembership) => {
        if (hasMembership)
          getCurrentUserData(user.uid).then((userData) => {
            const userFinanceOrg = userData.financeorg ?? -1;
            let payplusOrg = false;
            fire
              .firestore()
              .collection("payplusorgs")
              .where("orgId", "==", userFinanceOrg)
              .get()
              .then((resultSnapshot) => {
                resultSnapshot.docs.map((doc) => (payplusOrg = doc.data()));
                if (payplusOrg && userData) {
                  // Check if is Approver
                  let isApprover = false;
                  const approverList = payplusOrg.approvers ?? [];
                  approverList.forEach((approver) => {
                    if (approver === userData.userid) {
                      isApprover = true;
                    }
                  });
                  financeDashboardDispatch({
                    type: "SET_READY",
                    payload: {
                      user: {
                        ...userData,
                        isApprover,
                      },
                      org: payplusOrg,
                      isReady: true,
                    },
                  });
                }
              });
          });
      });
    } else {
      history.push("/home");
    }
  }, [user, history]);

  const displayDashboard = () => {
    const { selection } = financeDashboardState ?? 0;
    switch (selection) {
      case 0:
        return <TransactionPage />;
      case 1:
        return <div>Coming Soon</div>;
      case 2:
        return <QRPage />;
      case 3:
        return <VendorAssignmentPage />;
      default:
        return <TransactionPage />;
    }
  };

  return (
    <div className="finance-dashboard__container">
      {financeDashboardState.isReady ? (
        <FinanceContext.Provider
          value={{
            financeDashboardState,
            transactionDashboardState,
            statisticDashboardState,
            qrDashboardState,
            vendorDashboardState,
            financeDashboardDispatch,
            transactionDashboardDispatch,
            statisticDashboardDispatch,
            qrDashboardDispatch,
            vendorDashboardDispatch,
          }}
        >
          <FinanceTopNav />
          <FinanceSideNav />
          {displayDashboard()}
        </FinanceContext.Provider>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default FinanceDashboard;
