import React, { Component } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import fire from "../../../services/fire";

import LinkButton from "../../blocks/linkButtonBlock";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";

import { logEvent } from "../../../services/log";
import { userLogout } from "../../../services/user";

import { getNameById } from "../services/resources";
import { saveUserRegistry } from "../services/catechismRegistration";

import {
	getLongMonths,
} from "../../../utils/utils";

class RegistrationReview extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			current: "child",
			imageDataUrl: "",
			imageDataFile: "",
			imageDataType: "",
			isUploading: false,
			isResubmitting: false,
			isSubmitting: false,
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {
		const { draft } = this.props;
		const { imageDataUrl = "" } = this.state;

		if (imageDataUrl && imageDataUrl !== prevState.imageDataUrl) {
			this.saveBaptismCert();
		}

		if (draft.formStatus === 2 && draft.formStatus !== prevProps.draft.formStatus) {
			this.setIsUploading(false);
		}
	}

	componentWillUnmount() {
		this.setState({
			showModal: false,
			current: "child",
			imageDataUrl: "",
			imageDataFile: "",
			imageDataType: "",
			isUploading: false,
			isResubmitting: false,
			isSubmitting: false,
		});
	}

	setIsUploading = (isUploading) => this.setState({ isUploading });

	doSubmit = async () => {
		try {
			const data = this.props.userDetails;
			logEvent("logout_click", { email: data.email });
			await userLogout();
			window.location.reload();

			// signed out
		} catch (e) {
			// an error
			window.location.reload();
		}
		//potentially force redirect or refresh here
	}

	displayDateFormat = (date) => {
		if (date === "") {
			return "";
		}

		const longMonths = getLongMonths();
		const monthStr = longMonths[date.getMonth()];
		const day = date.getDate();
		const year = date.getFullYear();
		const dateStr = monthStr + " " + day + ", " + year;

		return dateStr;
	}

	renderCurrentContent = () => {
		const { draft = {} } = this.props;
		const { current } = this.state;

		let content;

		switch (current) {
		case "child":
			let dateOfBirth = "", dateOfBaptism = "", gender = "", citizenship = "", isBaptized = "", isBaptizedinSG = "",
				hasSiblings = "";

			if (draft.dob) {
				dateOfBirth = new Date(draft.dob.seconds * 1000 + draft.dob.nanoseconds/1000000);
			}

			if (draft.dateOfBaptism) {
				dateOfBaptism = new Date(draft.dateOfBaptism.seconds * 1000 + draft.dateOfBaptism.nanoseconds/1000000);
			}

			if (draft.hasOwnProperty("childGender")) {
				gender = getNameById("gender", draft.childGender + 1);
			}

			if (draft.hasOwnProperty("citizenship")) {
				citizenship = getNameById("citizenship", draft.citizenship + 1);
			}

			if (draft.hasOwnProperty("isBaptized")) {
				isBaptized = draft.isBaptized ? "Yes" : "No" 
			}

			if (draft.hasOwnProperty("isBaptizedinSG")) {
				isBaptizedinSG = draft.isBaptizedinSG ? "Yes" : "No"
			}

			if (draft.hasOwnProperty("hasSiblings")) {
				hasSiblings = draft.hasSiblings ? "Yes" : "No"
			}

			content = (
				<Card>
					<Card.Body>
						<div>
							<h5>Basic Details</h5>
						</div>
						<div>
							<strong className="pr-1">Name:</strong>{ draft.childName }
						</div>
						<div>
							<strong className="pr-1">Gender:</strong>{ gender }
						</div>
						<div>
							<strong className="pr-1">Date of Birth:</strong>{ this.displayDateFormat(dateOfBirth) }
						</div>
						{ draft.countryOfBirth ?
							<div>
								<strong className="pr-1">Country of Birth:</strong>{ draft.countryOfBirth }
							</div> : null
						}
						<div>
							<strong className="pr-1">Nationality:</strong>{ draft.nationality }
						</div>
						{ draft.citizenship ?
							<div>
								<strong className="pr-1">Residential Status:</strong>{ citizenship }
							</div> : null
						}
						<div>
							<strong className="pr-1">Home Address:</strong>{`${draft.homeAddress} S${draft.postalCode}`}
						</div>
						<br />
						<div>
							<h5>Baptism Details</h5>
						</div>
						<div>
							<strong className="pr-1">Has the child received the sacrament of baptism?</strong>{ isBaptized }
						</div>
						{ draft.isBaptized ?
							<>
								<div>
									<strong className="pr-1">Was the child baptised in Singapore?</strong>{ isBaptizedinSG }
								</div>
								<div>
									<strong className="pr-1">When was the child baptised?</strong>{ this.displayDateFormat(dateOfBaptism) }
								</div>
								<div>
									<strong className="pr-1">Where was the child baptised?</strong>{ draft.churchOfBaptism }
								</div>
							</> : null
						}
						<br />
						<div>
							<h5>Education Details</h5>
						</div>
						<div>
							<strong className="pr-1">Name of School:</strong>{ draft.nameOfSchool }
						</div>
						<div>
							<strong className="pr-1">Level at School:</strong>{ draft.levelInSchool }
						</div>
						<br />
						<div>
							<h5>Miscellaneous Details</h5>
						</div>
						<div>
							<strong className="pr-1">Does your child have siblings attending catechesis at the same parish?</strong>{ hasSiblings }
						</div>
						{ hasSiblings ?
							<div>
								<strong className="pr-1">Name and Class Level of Sibling:</strong>{ draft.siblingInformation }
							</div> : null
						}
						{ draft.additionalInfo ?
							<div>
								<strong className="pr-1">Do you have any additional information to share with us?</strong>{ draft.additionalInfo }
							</div> : null
						}
					</Card.Body>
				</Card>
			);
			break;
		case "parent":
			content = (
				<Card>
					<Card.Body>
						{ draft.motherName ?
							<>
								<div>
									<h5>Mother's Details</h5>
								</div>
								<div>
									<strong className="pr-1">Name:</strong>{ draft.motherName }
								</div>
								<div>
									<strong className="pr-1">Mobile Number:</strong>{ draft.motherMobileNo ? "+" + draft.motherMobileNo : "" }
								</div>
								<div>
									<strong className="pr-1">Home Number:</strong>{ draft.motherHomeNo }
								</div>
								<div>
									<strong className="pr-1">Email:</strong>{ draft.motherEmail }
								</div>
								<div>
									<strong className="pr-1">Marital Status:</strong>{ draft.motherMaritalStatus }
								</div>
								<div>
									<strong className="pr-1">Is Married in Church?</strong>{ draft.isMotherMarriedInChurch }
								</div>
								<div>
									<strong className="pr-1">Religion:</strong>{ draft.motherReligion }
								</div>
								<div>
									<strong className="pr-1">Is involved in any parish ministry or community?</strong>{ draft.motherParishInvolvement }
								</div>
								<br />
							</> : null
						}
						{ draft.fatherName ?
							<>
								<div>
									<h5>Father's Details</h5>
								</div>
								<div>
									<strong className="pr-1">Name:</strong>{ draft.fatherName }
								</div>
								<div>
									<strong className="pr-1">Mobile Number:</strong>{ draft.fatherMobileNo ? "+" + draft.fatherMobileNo : "" }
								</div>
								<div>
									<strong className="pr-1">Home Number:</strong>{ draft.fatherHomeNo }
								</div>
								<div>
									<strong className="pr-1">Email:</strong>{ draft.fatherEmail }
								</div>
								<div>
									<strong className="pr-1">Marital Status:</strong>{ draft.fatherMaritalStatus }
								</div>
								<div>
									<strong className="pr-1">Is Married in Church?</strong>{ draft.isFatherMarriedInChurch }
								</div>
								<div>
									<strong className="pr-1">Religion:</strong>{ draft.fatherReligion }
								</div>
								<div>
									<strong className="pr-1">Is involved in any parish ministry or community?</strong>{ draft.fatherParishInvolvement }
								</div>
								<br />
							</> : null
						}
						{ draft.guardianName && draft.mainContact === 3 ?
							<>
								<div>
									<h5>Guardian's Details</h5>
								</div>
								<div>
									<strong className="pr-1">Name:</strong>{ draft.guardianName }
								</div>
								<div>
									<strong className="pr-1">Mobile Number:</strong>{ draft.guardianMobileNo ? "+" + draft.guardianMobileNo : "" }
								</div>
								<div>
									<strong className="pr-1">Email:</strong>{ draft.guardianEmail }
								</div>
							</> : null
						}
					</Card.Body>
				</Card>
			);
			break;
		default:
		}

		return content;
	}

	handleOnClick = (current) => () => this.setState({ current });

	renderModalContent = () => {
		const { current } = this.state;

		return (
			<>
				<div className="d-flex flex-row">
					<div className="w-100 mr-1">
						<Button
							className="btn-block"
							variant="outline-primary"
							size="lg"
							onClick={ this.handleOnClick("child") }
							active={ current === "child" }
						>
							<div style={{ fontSize: "18px" }}>
								Child's
							</div>
							<div style={{ fontSize: "14px" }}>
								Details
							</div>
						</Button>
					</div>
					<div className="w-100 ml-1">
						<Button
							className="btn-block"
							variant="outline-primary"
							size="lg"
							onClick={ this.handleOnClick("parent") }
							active={ current === "parent" }
						>
							<div style={{ fontSize: "18px" }}>
								Parent's
							</div>
							<div style={{ fontSize: "14px" }}>
								Details
							</div>
						</Button>
					</div>
				</div>
				<br />
				{ this.renderCurrentContent() }
			</>
		);
	}

	handleModal = () => this.setState({ showModal: !this.state.showModal });

	handleEdit = () => {
		const { setFormStatus } = this.props;

		setFormStatus("input");

		this.setState({ showModal: false });
	}

	handleNewSubmission = () => {
		const { handleCreateNewSubmission } = this.props;

		handleCreateNewSubmission();

		this.setState({ isResubmitting: true });
	}

	handleConfirmSubmission = () => {
		const { handleEnterSubmission } = this.props;

		handleEnterSubmission();

		this.setState({ isSubmitting: true });

		setTimeout(() => {
			this.setState({ isSubmitting: false });
		}, 4500);
	}

	saveBaptismCert = () => {
		const { userDetails, draft } = this.props;
		const { imageDataFile, imageDataType } = this.state;
		const uploadpath = `user/${userDetails.userid}/private/baptism`;

		let fileName = "";

		switch (imageDataType) {
		case "application/pdf":
			fileName = `${userDetails.userid}${draft.id}.pdf`;
			break;
		case "image/jpeg":
			fileName = `${userDetails.userid}${draft.id}.jpeg`;
			break;
		default:
		}

		if (fileName) {
			fire
				.storage()
				.ref(uploadpath)
				.child(fileName)
				.put(imageDataFile, { contentType: imageDataType })
				.then((snapshot) => {
					// const birthCertUrl = getBirthCertUrl(userDetails.userid, fileName);
					const birthCertUrl = `${uploadpath}/${fileName}`;

					this.createUserRegistry(birthCertUrl);
				})
				.catch((e) => {
					console.log("saveBaptismCert::error:", e);
					alert("An error has occured");
				});
		}
	}

	createUserRegistry = async (baptismCertKey) => {
		const { userDetails, draft, getDraft } = this.props;
		const docData = {
			userId: userDetails.userid,
			dob: draft.dob,
			isBaptized: draft.isBaptized,
			isBaptizedInSG: draft.isBaptizedinSG,
			baptismCertKey: baptismCertKey,
			churchOfBaptism: draft.churchOfBaptism,
			dateOfBaptism: draft.dateOfBaptism,
			mainContact: draft.mainContact,
			userRegistrationId: draft.userRegistrationId || "",

			motherName: draft.motherName,
			motherEmail: draft.motherEmail,
			motherReligion: draft.motherReligion,
			motherMobileNo: draft.motherMobileNo,
			motherMaritalStatus: draft.motherMaritalStatus,

			fatherName: draft.fatherName,
			fatherEmail: draft.fatherEmail,
			fatherReligion: draft.fatherReligion,
			fatherMobileNo: draft.fatherMobileNo,
			fatherMaritalStatus: draft.fatherMaritalStatus,

			guardianName: draft.guardianName,
			guardianEmail: draft.guardianEmail,
			guardianMobileNo: draft.guardianMobileNo,

			selectedParishName: draft.selectedParishName,
			coordinatorEmail: draft.coordinatorEmail,
		};

		await saveUserRegistry(docData);

		setTimeout(() => {
			getDraft();
		}, 2000);
	}

	onFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const imageDataUrl = await this.readFile(file);

			this.setState({
				imageDataUrl,
				imageDataFile: file,
				imageDataType: file.type,
				isUploading: true,
			});
		}
	}

	readFile = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	}

	openImagePicker = () => {
		if (this.image) {
			this.image.click();
		}
	}

	imageRef = (ref) => (this.image = ref);

	render() {
		const { draft, isSaving, user } = this.props;
		const { showModal, isUploading, isResubmitting, isSubmitting } = this.state;

		if (isSaving === true) {
			return <LoadingSpinnerNav type="backandlogo" smallpic={user.photoURL} />;
		}

		let status = null, conversationalText = "";

		switch (draft.formStatus) {
		case 1:
			status = (<div className="ml-1" style={{ color: "#ffc107", fontWeight: "bold" }}>Pending Baptism Certificate Upload</div>);
			conversationalText = (<h5>You're almost there! Please upload your child's baptism certificate in order for your application to be reviewed.</h5>);
			break;
		case 2:
			status = (<div className="ml-1" style={{ color: "#ffc107", fontWeight: "bold" }}>Review in Progress</div>);
			conversationalText = (<h5>Everything is in place! The parish catechetical coordinator is in the process of reviewing your application.</h5>);
			break;
		case 3:
			status = (<div className="ml-1" style={{ color: "green", fontWeight: "bold" }}>Accepted</div>);
			conversationalText = (<h5>We are excited to see your child for Catechesis soon!</h5>);
			break;
		case 4:
			status = (<div className="ml-1" style={{ color: "#ffc107", fontWeight: "bold" }}>Pending confirmation</div>);
			conversationalText = (<h5>You're almost there! Please review your details before confirming the submission.</h5>);
			break;
		case -1:
			status = (<div className="ml-1" style={{ color: "red", fontWeight: "bold" }}>Rejected</div>);
			conversationalText = (
				<h5>
					Please contact the parish at{" "}
					<a href={ `mailto:${ this.props.coordinatorEmail }` }>{this.props.coordinatorEmail}</a>.
				</h5>
			);
			break;
		default:
			status = (<div className="ml-1" style={{ color: "red", fontWeight: "bold" }}>Draft</div>);
			conversationalText = (<h5>Your application has not yet been submitted</h5>);
		}

		return (
			<>
				<Modal size="lg" show={ showModal } onHide={ this.handleModal }>
					<Modal.Header closeButton>
						<Modal.Title>Review</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{ this.renderModalContent() }
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={ this.handleModal }>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
				<div className="text-left my-4">
					<h1>Registration for Parish Catechesis</h1>
					<h3 className="text-muted">Foundation Level 2023</h3>
				</div>
				<div>
					{ conversationalText }
				</div>
				<Card className="mt-4 mb-5">
					<Card.Body className="p-4">
						<br />
						<h5>{ draft.selectedParishName }</h5>
						<br />
						Timeslot: { draft.timeslot }
						<div className="d-flex flex-row">
							Status: { status }
						</div>
						<br />
						{ draft.formStatus !== 0 ?
							<Button
								className="btn-block mb-3"
								size="lg"
								onClick={ this.handleModal }
								disabled={ isUploading || isResubmitting || isSubmitting }
							>
								View
							</Button> : null
						}
						{ draft.formStatus === 0 || draft.formStatus === 1 || draft.formStatus === 4 ?
							<Button
								className="btn-block mb-3"
								size="lg"
								variant="outline-primary"
								onClick={ this.handleEdit }
								disabled={ isUploading || isSubmitting }
							>
								Edit
							</Button> : null
						}
						{ draft.formStatus === 1 ?
							<Button
								className="btn-block mb-3 btn-warning"
								size="lg"
								onClick={ this.openImagePicker }
								disabled={ isUploading }
							>
								{ isUploading ?
									<div className="spinner-border mr-1" role="status" style={{ width: "1.5rem", height: "1.5rem" }}>
										<span className="sr-only">Loading...</span>
									</div> : null
								}
								Upload Child Baptism Cert
							</Button> : null
						}
						{ draft.formStatus === -1 ?
							<Button
								className="btn-block mb-3 btn-warning"
								size="lg"
								onClick={ this.handleNewSubmission }
								disabled={ isResubmitting }
							>
								{ isResubmitting ?
									<div className="spinner-border mr-1" role="status" style={{ width: "1.5rem", height: "1.5rem" }}>
										<span className="sr-only">Loading...</span>
									</div> : null
								}
								Resubmit
							</Button> : null
						}
						{ draft.formStatus === 4 ?
							<Button
								className="btn-block mb-3 btn-warning"
								size="lg"
								onClick={ this.handleConfirmSubmission }
								disabled={ isSubmitting }
							>
								{ isSubmitting ?
									<div className="spinner-border mr-1" role="status" style={{ width: "1.5rem", height: "1.5rem" }}>
										<span className="sr-only">Loading...</span>
									</div> : null
								}
								Confirm Submission
							</Button> : null

						}
					</Card.Body>
				</Card>
				{ this.props.coordinatorEmail ?
					<>
						If you have enquiries, plase contact your Parish Catechist Coordinator at{" "}
						<a href={ `mailto:${ this.props.coordinatorEmail }` }>{ this.props.coordinatorEmail }</a>
						<br />
						<br />
					</> : null
				}
				<Button
					variant="outline-primary"
					className="btn-block mb-3"
					size="lg"
					style={{ border: "1px solid #dddddd" }}
					onClick={ this.doSubmit }
				>
					Logout
				</Button>
				<LinkButton type="link" link="/home" label="Back to Home" />
				<input
					style={{ height: "0px" }}
					id="file-input"
					type="file"
					onChange={this.onFileChange}
					accept="application/pdf,image/jpeg"
					ref={ this.imageRef }
				/>
			</>
		);
	}
}

export default RegistrationReview;