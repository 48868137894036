import React, { useContext } from "react";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Header from "../../../common/header";

// import NotRegistered from "./NotRegistered";
import ForbiddenRoute from "./ForbiddenRoute";
import QuickGuide from "./QuickGuide";

import AppGlobalContext from "../../../../AppGlobalContext";
import { useActiveRegistration, useGuardian } from "../hooks/hooks";

import { pageTitleDictionary } from "../services/routeInfo";
import { isValidPage } from "../services/registrationStatus";

/**
 * @param {{ id: import("../services/routeInfo").UserPageId, children: JSX.Element | JSX.Element[] }} props
 */
function Layout({ id, children }) {
  const { user } = useContext(AppGlobalContext);
  const activeRegistrationState = useActiveRegistration();
  const guardianState = useGuardian();

  // console.log("In layout:", { guardianState });

  const statusName = activeRegistrationState.status;

  const isAccessingValidPage = isValidPage({
    isGuardian: guardianState.isGuardian,
    statusName,
    page: id,
  });

  return (
    <div
      className="d-flex flex-column"
      style={{ minHeight: "calc(100vh - 4.5rem)", minWidth: 450 }}
    >
      <Header type="backandlogo" smallpic={user.photoURL} />
      <Container className="my-4 flex-grow-1 d-flex flex-column">
        <div className="d-flex mb-2 justify-content-between">
          <h2>{pageTitleDictionary[id]}</h2>
          <QuickGuide />
        </div>
        <Card className="flex-grow-1 d-flex flex-column">
          <Card.Body
            className="d-flex flex-column justify-content-center align-items-center py-5"
            style={{ gap: "1.5rem" }}
          >
            {isAccessingValidPage ? children : <ForbiddenRoute />}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

/**
 *
 * @template RawComponentProps
 * @param {(props: RawComponentProps) => JSX.Element} RawComponent
 * @param {UserPageId} id
 */
export default function withLayout(RawComponent, id) {
  return (props) => (
    <Layout id={id}>
      <RawComponent {...props} />
    </Layout>
  );
}
