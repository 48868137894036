import React, { useState, useMemo } from "react";

/**
 * A view object.
 * @typedef View
 * @prop {string} name Name of the view.
 * @prop {object} props Props specific to the view component.
 */

/** View props cached for `restoreView` procedure.
 * @type {{ [viewName: string]: { [propName: string]: any } }}
 */
const CACHED_VIEW_PROPS = {};

/**
 * [Multiplexer](https://en.wikipedia.org/wiki/Multiplexer) component to select between multiple view components. \
 * View components must optionally accept the following properties: `viewProps`, `setView`, `restoreView`, and all `propName`s in `commonProps`.
 * @param {object} config Configuration for the mux.
 * @param {View} config.initialView The first view to be rendered.
 * @param {{ [viewName: string]: JSX.Element }} config.viewComponentRef An object mapping each view name to its component.
 * @param {{ [propName: string]: any }} config.commonProps Props shared across all views. Mainly to access `user` and `history`.
 * @returns {JSX.Element} View component rendered by the mux. Memoised.
 */
export default function MultiViewMux({
  viewComponentRef,
  initialView,
  commonProps,
}) {
  CACHED_VIEW_PROPS[initialView.name] = initialView.props;

  const [view, rawSetView] = useState(initialView);

  /**
   * Procedure to render a new view. Requires new view props.
   * @param {View} newView The new view to be rendered.
   */
  function setView(newView) {
    CACHED_VIEW_PROPS[newView.name] = newView.props;
    // console.log({ CACHED_VIEW_PROPS });
    rawSetView(newView);
  }

  /**
   * Procedure to render an old view. Reuses old props. \
   * To render an old view with new props, use `setView` instead.
   * @param {string} oldViewName The name of the view to be restored.
   * @return {boolean} `true` if view was successfully restored, `false` if no cached view exists.
   */
  function restoreView(oldViewName) {
    if (oldViewName in CACHED_VIEW_PROPS)
      rawSetView({ name: oldViewName, props: CACHED_VIEW_PROPS[oldViewName] });
  }

  /**
   * Function to render a view.
   * @param {View} viewToRender The view to be rendered.
   * @returns {JSX.Element} The rendered view.
   */
  function renderView(viewToRender) {
    const ViewComponent = viewComponentRef[viewToRender.name];
    return (
      <ViewComponent
        {...commonProps}
        viewProps={viewToRender.props}
        setView={setView}
        restoreView={restoreView}
      />
    );
  }

  /** @type {JSX.Element} */
  const RenderedView = useMemo(() => renderView(view), [renderView, view]);
  return RenderedView;
}
