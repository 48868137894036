import countryList from "react-select-country-list";

export function getMappedCountries(exclude = []) {
  let filteredCountries = [];
  if (exclude.length > 0) {
    const omitCodes = exclude; // Example: Omit countries with these ISO codes
    filteredCountries = countryList()
      .getData()
      .filter((country) => !omitCodes.includes(country.value));
  } else {
    filteredCountries = countryList().getData();
  }
  //mapped countries
  const mappedCountries = filteredCountries.map((country) => {
    return { _id: country.value, name: country.label };
  });
  return mappedCountries;
}
