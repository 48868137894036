import fire from "./fire";

export async function getServerTimeTS() {
  //get server time
  const serverTime = fire
    .functions("asia-east2")
    .httpsCallable("getServerTime");

  const time = await serverTime();

  // console.log(time);
  const now = time?.data?.servertime?.servertime || new Date().getTime();
  return now;
}
