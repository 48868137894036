// let boundariesPromise = fetch(
//   'https://www.streetdirectory.com/ajax/jx_events/parish_map/'
// ).then(res => res.json());
let boundariesPromise = Promise.resolve(require("./parishBoundaries.json"));
let parishCache = {};

const idMap = {
  "Blessed Sacrament Church": 1,
  "Church of Christ the King": 3,
  "Church of Divine Mercy": 4,
  "Chuch of the Holy Cross": 5,
  "Holy Family Church": 6,
  "Church of the Holy Spirit": 7,
  "Church of the Holy Trinity": 8,
  "Church of the Immaculate Heart of Mary": 9,
  "Church of Our Lady of Lourdes": 10,
  "Church of the Nativity": 11,
  "Our Lady of Perpetual Succour Church": 12,
  "Church of Our Lady Queen of Peace": 13,
  "Church of Our Lady Star of the Sea": 14,
  "Church of the Risen Christ": 15,
  "Church of the Sacred Heart": 16,
  "St Anne's Church": 18,
  "Church of St Anthony": 19,
  "Church of St Bernadette": 20,
  "Church of St Francis of Assisi": 21,
  "St Francis Xavier Catholic Church": 22,
  "Catholic Church of St Ignatius": 23,
  "Church of St Joseph": 24,
  "Church of St Mary of the Angels": 26,
  "Saint Michael Church": 27,
  "Church of St Stephen": 28,
  "Church of St Teresa": 29,
  "Church of Saint Vincent De Paul": 30,
  "Church of Sts Peter & Paul": 31,
  "Church of the Transfiguration": 32,
};

function detectedParishNameToId(parishName) {
  if (parishName == null) return null;
  return idMap[parishName];
}

function checkEdge(p, a, b) {
  if (a.y > b.y) {
    let temp = a;
    a = b;
    b = temp;
  }
  let eps = 1e-8,
    inf = 1e8;
  let mRed, mBlue;
  if (p.y === a.y || p.y === b.y) p.y += eps;
  if (p.y < a.y || p.y > b.y) return false;
  if (p.x > Math.max(a.x, b.x)) return false;
  if (p.x < Math.min(a.x, b.x)) return true;
  if (a.x !== b.x) mRed = (b.y - a.y) / (b.x - a.x);
  else mRed = inf;
  if (a.x !== p.x) mBlue = (p.y - a.y) / (p.x - a.x);
  else mBlue = inf;
  return mBlue > mRed;
}

function polygonContainsPoint(p, abList) {
  let edgeCount = 0;
  for (let i = 0; i < abList.length - 1; i++) {
    if (checkEdge(p, abList[i], abList[i + 1])) edgeCount += 1;
  }
  return edgeCount % 2 === 1;
}

function getParishFromCoords(p, data) {
  for (let parish of data)
    if (polygonContainsPoint(p, parish.ll)) return parish;
}

//Function which takes in a postal code and returns the boundary parish ID
function detectParish(postalCode) {
  if (postalCode in parishCache) return parishCache[postalCode];
  return boundariesPromise
    .then((data) =>
      fetch(
        `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y`
      )
        .then((res) => res.json())
        .then((matches) => {
          matches = matches.results.filter((loc) => loc.POSTAL === postalCode);
          if (matches.length === 0) return null;
          let loc = matches[0];

          const details = {
            loc,
            parish: getParishFromCoords(
              {
                x: parseFloat(loc.LONGITUDE),
                y: parseFloat(loc.LATITUDE),
              },
              data
            ),
          };

          return details;
        })
    )
    .then((result) => {
      let parishId = detectedParishNameToId(result?.parish.nm);

      const details = {
        loc: result?.loc,
        parishId
      };

      parishCache[postalCode] = details;

      return details;
    })
    .catch((e) => {
      // console.log("detectParish::error:", e);

      return -1;
    });
}

export default detectParish;
