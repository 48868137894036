import React from "react";
import { Button } from "react-bootstrap";
import ShareIcon from "@material-ui/icons/Share";
import { logEvent } from "../../services/log";

function SharePage({ source, text, title, url }) {
  const shareDetails = { url, title, text };

  if (!navigator.share) {
    return null;
  }
  const handleSharing = async () => {
    logEvent(`share_${source}`, {
      type: source,
    });
    if (navigator.share) {
      try {
        await navigator.share(shareDetails).then(() => {
          console.log("Hooray! Your content was shared to tha world");
        });
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };
  return (
    <Button
      variant="link"
      className="float-right btn-md text-dark p-2"
      onClick={handleSharing}
    >
      <div className={`float-left `} style={{ width: "25px" }}>
        <ShareIcon
          className="text-danger"
          style={{
            fontSize: "18px",
            marginTop: "-2px",
          }}
        />
      </div>
      <div className={`float-left`}>Share</div>
    </Button>
  );
}

export default SharePage;
