import React, { Component } from "react";
import LinkButton from "../blocks/linkButtonBlock";
import "./assets/css/index.css";
import IconByField from "../common/iconByField";
import RoundParishIconShortcuts from "../common/roundParishIconShortcuts";
class ParishMainPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parish: props.parish,
    };
  }

  displayParishDetails(label, field, value, link = "") {
    let display = value;

    if (field === "facebook") {
      const fb = value.replace("https://www.facebook.com/", "");
      display = (
        <a target="_blank" rel="noopener noreferrer" href={value}>
          {fb?.indexOf("?id=") === -1 ? fb : "stignatiussg"}
        </a>
      );
    } else if (field === "youtube") {
      display = (
        <a target="_blank" rel="noopener noreferrer" href={value}>
          {value.replace("https://youtube.com/", "")}
        </a>
      );
    } else if (field === "telegram") {
      display = (
        <a target="_blank" rel="noopener noreferrer" href={value}>
          {value.replace("https://t.me/", "")}
        </a>
      );
    } else if (field === "website") {
      let origwebsite = value;
      value = value.split("/");
      value = value[2];
      display = (
        <a target="_blank" rel="noopener noreferrer" href={origwebsite}>
          {value}
        </a>
      );
    } else if (field === "instagram") {
      display = (
        <a target="_blank" rel="noopener noreferrer" href={value}>
          {value.replace("https://instagram.com/", "")}
        </a>
      );
    }

    return (
      <React.Fragment>
        <div
          className="pt-1 float-left"
          style={{ height: "30px", width: "30px" }}
        >
          <IconByField field={field} />
        </div>
        <div className="pt-1  text-left">
          {!link ? (
            display
          ) : (
            <span
              className="text-primary pointer"
              onClick={this.props.priestHandler}
            >
              {display}
            </span>
          )}
          <br />
          <span className="text-muted">{label}</span>
        </div>
      </React.Fragment>
    );
  }
  render() {
    const { parish, hidebutton } = this.props;
    const hide = hidebutton ? true : false;
    let {
      address,
      website,
      facebook,
      priest,
      phone,
      email,
      youtube,
      instagram,
      telegram,
      link,
    } = parish;
    let origwebsite = website;
    if (website) {
      website = website.split("/");
      website = website[2];
    }

    return (
      <React.Fragment>
        <RoundParishIconShortcuts item={parish} />
        <div className="clearfix"></div>
        <div className="card my-4">
          <ul className="list-group list-group-flush">
            {priest && (
              <li className="list-group-item">
                {this.displayParishDetails(
                  "Parish Priest",
                  "priest",
                  priest,
                  link
                )}
              </li>
            )}
            {phone && (
              <li className="list-group-item">
                {this.displayParishDetails("Phone", "phone", phone)}
              </li>
            )}
            {address && (
              <li className="list-group-item">
                {this.displayParishDetails("", "address", address)}
              </li>
            )}
            {email && (
              <li className="list-group-item">
                {this.displayParishDetails(
                  "Email",
                  "email",
                  email.toLowerCase()
                )}
              </li>
            )}
            {website && (
              <li className="list-group-item">
                {this.displayParishDetails("Website", "website", origwebsite)}
              </li>
            )}
            {facebook && (
              <li className="list-group-item">
                {this.displayParishDetails("", "facebook", facebook)}
              </li>
            )}
            {youtube && (
              <li className="list-group-item">
                {this.displayParishDetails("Youtube", "youtube", youtube)}
              </li>
            )}
            {telegram && (
              <li className="list-group-item">
                {this.displayParishDetails("Telegram", "telegram", telegram)}
              </li>
            )}
            {instagram && (
              <li className="list-group-item">
                {this.displayParishDetails("Instagram", "instagram", instagram)}
              </li>
            )}
          </ul>
        </div>

        {!hide && (
          <LinkButton
            type="frameprimary"
            link="/parish"
            label="See all Churches"
          />
        )}
      </React.Fragment>
    );
  }
}

export default ParishMainPanel;
