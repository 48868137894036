import React, { useEffect, useState } from "react";
import "../../assets/css/spin.css"; // Add your styles for the spinner in a separate CSS file
import { getRandomWaitMessage } from "../../utils/waitmessage";

const CenterSpinner = () => {
  const [message, setMessage] = useState(getRandomWaitMessage());

  useEffect(() => {
    const timer = setInterval(() => {
      setMessage(getRandomWaitMessage());
    }, 4000);

    // Cleanup function to clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, []);

  console.log("Loading CenterSpinner");
  return (
    <div className="spinner-container">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="spinner"></div>
        <div className="text-center">{message}</div>
      </div>
    </div>
  );
};

export default CenterSpinner;
