import React from "react";
import DisplayScheduleItem from "./displayScheduleItem";
import { getHumanDate } from "../../../utils/date";
import { mapToDay } from "../../../utils/utils";

function InterpretSchedule({ schedule, selection, clickHandler }) {
  //showall show from beginning of month, else, start from current date only
  if (!schedule || !selection) {
    return null;
  }
  let prevDate = "";
  let prevDay = "";
  const dateNow = new Date();
  return (
    <div className="col-sm-12 px-0 pt-3 pb-4">
      {schedule &&
        schedule.map((sched, i) => {
          const { day, start } = sched;
          const startDate = new Date(start);
          if (dateNow.getTime() > startDate.getTime()) {
            return null;
          }
          let separator = "";
          let labelday = mapToDay(day);
          let labeldate = getHumanDate(startDate);
          const completedate = `${labelday}${labeldate}`;
          const prevcompletedate = `${prevDay}${prevDate}`;

          /* Start: This logic determines the separator and the labels to indicate groupings */
          if (prevcompletedate !== completedate) {
            if (prevDay === "Sunday") {
              separator = (
                <div
                  className="py-3 mb-5"
                  style={{ borderBottom: "2px dashed #aaaaaa" }}
                ></div>
              );
            }
            prevDay = labelday;
            prevDate = labeldate;
          } else {
            //remove labels if the dates are the same
            separator = "";
            labelday = "";
            labeldate = "";
          }
          /* End */

          return (
            <DisplayScheduleItem
              key={i}
              details={sched}
              selected={selection}
              labeldate={labeldate}
              labelday={labelday}
              separator={separator}
              clickHandler={clickHandler}
            />
          );
        })}
    </div>
  );
}

export default InterpretSchedule;
