import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAccountManagerInvalidAccounts({ ...rest }) {
  const AsyncLoad = loadable(() =>
    import("../page/accountManagerInvalidAccounts")
  );
  console.log("Loading", AsyncAccountManagerInvalidAccounts.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAccountManagerInvalidAccounts;
