/**
 * Static file config
 */
export const organisations = [
  {
    _id: 1,
    active: true,
    name: "Blessed Sacrament",
    shortname: "BSC",
    completename: "Blessed Sacrament Church",
  },
  {
    _id: 2,
    active: true,
    name: "Good Shepherd Cathedral",
    shortname: "CGS",
    completename: "Cathedral of the Good Shepherd",
  },
  {
    _id: 3,
    active: true,
    link: "ctk",
    name: "Christ the King",
    shortname: "CTK",
    completename: "Church of Christ the King",
    address: "2221 Ang Mo Kio Avenue 8 Singapore 569809",
    priest: "Rev Fr John Sim",
    phone: "+65 6459 9958",
    email: "parishoffice@christtheking.com.sg",
    website: "http://www.christtheking.com.sg",
    facebook: "",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30, //DON'T CHANGE, NO WEEKDAY LIMIT
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: true,
    currentstationofcrossenabled: true,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 4,
    nexttotalstationofcrossbookingallowed: 3,
    enablepetkidcode: false,
    kidallocation: 20,
    adorationenabled: true,
    currentadorationenabled: false,
    nextadorationenabled: true,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    selfcheckinenable: true,
    uen: "T08CC4038L",
    bulletinenabled: false,
    postenabled: true,
  },
  {
    _id: 4,
    active: true,
    name: "Divine Mercy",
    shortname: "CDM",
    completename: "Church of Divine Mercy",
  },
  {
    _id: 5,
    active: true,
    link: "chc",
    name: "Holy Cross",
    completename: "Church of the Holy Cross",
    shortname: "CHC",
    address: "450 Clementi Avenue 1 Singapore 129955",
    priest: "Rev Fr Henry Siew",
    phone: "+65 6777 5858",
    email: "holycross@singnet.com.sg",
    website: "http://www.holycross.org.sg",
    facebook: "",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4054D",
    postenabled: true,
    genericevent: {
      type: ["penitential"],
      config: [
        {
          active: true,
          showhomemenu: true,
          notes: "Live on Mar 22",
          type: "penitential",
          name: "Penitential",
          date: ["apr2022", "mar2022"],
          props: [
            {
              active: true,
              notes: "Live on Mar 22",
              date: "apr2022",
              month: "apr",
              year: 2022,
              limit: 1,
            },
            {
              active: true,
              notes: "Live on Mar 22",
              date: "mar2022",
              month: "mar",
              year: 2022,
              limit: 1,
            },
          ],
        },
      ],
    },
  },
  {
    _id: 6,
    active: true,
    link: "chf",
    name: "Holy Family",
    completename: "Church of the Holy Family",
    shortname: "CHF",
    address: "6 Chapel Road Singapore 429509",
    priest: "Rev Fr Adrian Yeo",
    phone: "+65 6344 0046",
    email: "chf.secretariat@catholic.org.sg",
    website: "http://www.holyfamily.org.sg",
    facebook: "https://www.facebook.com/churchoftheholyfamily",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: false,
    currentconfessionenabled: false,
    nextconfessionenabled: false,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    adorationenabled: true,
    currentadorationenabled: false,
    nextadorationenabled: true,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4048E",
    postenabled: true,
    genericevent: {
      type: ["penitential"],
      config: [
        {
          active: true,
          showhomemenu: true,
          notes: "Live on Mar 22",
          type: "penitential",
          name: "Penitential",
          date: ["apr2022"],
          props: [
            {
              active: true,
              notes: "Live on Mar 22",
              date: "apr2022",
              month: "apr",
              year: 2022,
              limit: 1,
            },
          ],
        },
      ],
    },
  },
  {
    _id: 7,
    active: true,
    link: "chs",
    name: "Holy Spirit",
    completename: "Church of the Holy Spirit",
    shortname: "CHS",
    address: "248 Upper Thomson Road Singapore 574371",
    priest: "Rev Fr Kamelus Kamus, CICM",
    phone: "+65 6453 6340",
    email: "parish@holyspirit.sg",
    website: "https://www.holyspirit.sg",
    facebook: "https://www.facebook.com/ChurchHolySpiritSg",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 60,
    checkinclose: 60,
    uen: "T08CC4041L",
    bulletinenabled: true,
    postenabled: true,
  },
  {
    _id: 8,
    active: true,
    link: "cht",
    name: "Holy Trinity",
    shortname: "CHT",
    completename: "Church of the Holy Trinity",
    address: "20 Tampines Street 11 Singapore 529455",
    priest: "Rev Fr Frederick Quek",
    phone: "+65 6784 2332",
    email: "htadmin@holytrinity.org.sg",
    website: "http://www.holytrinity.org.sg",
    facebook: "https://www.facebook.com/catholicchurchoftheholytrinity.sg",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30, //DON'T CHANGE, NO WEEKDAY LIMIT
    confessionenabled: false,
    currentconfessionenabled: false,
    nextconfessionenabled: false,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    selfcheckinenable: true,
    uen: "T08CC4049A",
    bulletinenabled: false,
    postenabled: true,
  },
  {
    _id: 9,
    active: true,
    name: "Immaculate Heart",
    shortname: "IHM",
    completename: "Church of the Immaculate Heart of Mary",
  },
  {
    _id: 10,
    active: true,
    link: "oll",
    name: "Lady of Lourdes",
    shortname: "COLOL",
    completename: "Church of Our Lady of Lourdes",
    address: "50 Ophir Road Singapore 188690",
    priest: "Rev Fr Michael Sitaram",
    phone: "+65 6294 0624",
    email: "ololsec@gmail.com",
    website: "http://www.lourdes.sg",
    facebook: "https://www.facebook.com/ChurchOfOurLadyOfLourdes",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: false,
    currentconfessionenabled: false,
    nextconfessionenabled: false,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 0,
    adorationenabled: false,
    currentadorationenabled: false,
    nextadorationenabled: false,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4026C",
    bulletinenabled: false,
    postenabled: true,
  },
  {
    _id: 11,
    active: true,
    name: "Nativity",
    shortname: "CNBVM",
    completename: "Church of the Nativity of the Blessed Virgin Mary",
  },
  {
    _id: 12,
    active: true,
    link: "olp",
    name: "Our Lady of Perpetual Succour",
    shortname: "OLPS",
    completename: "Church of Our Lady of Perpetual Succour",
    address: "31 Siglap Hill Singapore 456085",
    priest: "Rev Fr Anthony Kenny Tan",
    phone: "+65 6241 9565",
    email: "secretariat@olps.sg",
    website: "http://www.olps.sg",
    facebook: "https://www.facebook.com/olps.sg",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: false,
    currentconfessionenabled: false,
    nextconfessionenabled: false,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: true,
    currentstationofcrossenabled: true,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 4,
    nexttotalstationofcrossbookingallowed: 3,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4045F",
    postenabled: true,
  },
  {
    _id: 13,
    active: true,
    link: "qop",
    name: "Our Lady Queen of Peace",
    shortname: "OLQP",
    completename: "Church of Our Lady Queen of Peace",
    address: "4 Sandy Lane Singapore 437321",
    priest: "Rev Fr Timothy Yeo",
    phone: "+65 6744 2879",
    email: "admin@queenofpeace.sg",
    website: "http://www.queenofpeace.sg",
    facebook: "",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    selfcheckinenable: true,
    uen: "T08CC4046BOPS",
    bulletinenabled: false,
    postenabled: true,
  },
  {
    _id: 14,
    active: true,
    name: "Our Lady Star of the Sea",
    shortname: "OLSS",
    completename: "Church of Our Lady Star of the Sea",
  },
  {
    _id: 15,
    active: true,
    name: "Risen Christ",
    shortname: "CRC",
    completename: "Church of the Risen Christ",
  },
  {
    _id: 16,
    active: true,
    link: "csh",
    name: "Sacred Heart",
    shortname: "CSH",
    completename: "Church of the Sacred Heart",
    address: "111 Tank Road Singapore 238069",
    priest: "Rev Msgr Stephen Yim",
    phone: "+65 6737 9285",
    email: "sacredheartchurch@catholic.org.sg",
    website: "http://www.churchofthesacredheart.sg",
    facebook: "https://www.facebook.com/churchofthesacredheartsg",
    instagram: "https://instagram.com/sacred.heart.church",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: false,
    currentconfessionenabled: false,
    nextconfessionenabled: false,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    selfcheckinenable: true,
    uen: "T08CC4031F",
    bulletinenabled: false,
    postenabled: true,
  },
  {
    _id: 17,
    active: true,
    name: "Novena",
    shortname: "NOV",
    completename: "Church of St Alphonsus (Novena Church)",
  },
  {
    _id: 18,
    active: true,
    link: "sac",
    name: "St Anne's Church",
    shortname: "SAC",
    completename: "St Anne's Church",
    address: "66 Sengkang East Way Singapore 548593",
    priest: "Rev Fr Jovita Ho",
    phone: "+65 6386 5072",
    email: "sac.secretariat@catholic.org.sg",
    website: "https://stanne.catholic.sg",
    facebook: "https://www.facebook.com/st.annes.catholic.church",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: true,
    currentstationofcrossenabled: true,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 5,
    nexttotalstationofcrossbookingallowed: 3,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4037D",
    bulletinenabled: false,
    postenabled: true,
  },
  {
    _id: 19,
    active: true,
    name: "St Anthony",
    shortname: "CSA",
    completename: "Church of St Anthony",
  },
  {
    _id: 20,
    active: true,
    name: "St Bernadette",
    shortname: "CSB",
    completename: "Church of St Bernadette",
  },
  {
    _id: 21,
    active: true,
    link: "sfa",
    name: "St Francis of Assisi",
    shortname: "SFA",
    completename: "Church of St Francis of Assisi",
    address: "200 Boon Lay Avenue Singapore 649964",
    priest: "Rev Fr Peter Koh",
    phone: "+65 6264 0078",
    email: "csfa.secretariat@catholic.org.sg",
    website: "http://www.csfa.sg",
    facebook: "https://www.facebook.com/AssisiSG",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30, //DON'T CHANGE, NO WEEKDAY LIMIT
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4051E",
    postenabled: true,
  },
  {
    _id: 22,
    active: true,
    link: "sfx",
    name: "St Francis Xavier",
    shortname: "SFX",
    completename: "Church of St Francis Xavier",
    address: "63A Chartwell Drive Singapore 558758",
    priest: "Rev Fr Benedict Chng",
    phone: "+65 6280 6076",
    email: "secretariat@sfxchurch.sg",
    website: "http://www.sfxchurch.sg",
    facebook: "https://www.facebook.com/sfxchurch.sg",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: false,
    currentconfessionenabled: false,
    nextconfessionenabled: false,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    adorationenabled: true,
    currentadorationenabled: false,
    nextadorationenabled: true,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75, //change to 1
    cancelbookingcutofftime: 1, //change to 1
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4033J",
    bulletinenabled: false,
    postenabled: true,
  },
  {
    _id: 23,
    active: true,
    link: "stignatius",
    name: "St Ignatius",
    shortname: "CSI",
    completename: "Church of St Ignatius",
    address: "120 King’s Road Singapore 268172",
    priest: "Rev Fr Colin Tan, SJ",
    phone: "+65 6466 0625",
    email: "church@stignatius.org.sg",
    website: "https://www.stignatius.org.sg",
    facebook: "https://www.facebook.com/profile.php?id=100083174236471",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    bannerenabled: false,
    confessionenabled: false,
    currentconfessionenabled: false,
    nextconfessionenabled: false,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 60,
    checkinclose: 60,
    selfcheckinenable: true,
    uen: "T08CC4052A",
    bulletinenabled: true,
    postenabled: false,
  },
  {
    _id: 24,
    active: true,
    link: "sjb",
    name: "St Joseph (Bukit Timah)",
    shortname: "SJCBT",
    completename: "St Joseph's Church (Bukit Timah)",
    address: "620 Upper Bukit Timah Road Singapore 678116",
    priest: "Rev Fr Christopher Lee",
    phone: "+65 6769 1666",
    email: "sjcbt.secretariat@catholic.org.sg",
    website: "http://www.stjoseph-bt.org.sg",
    facebook: "",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false,
    kidallocation: 20,
    adorationenabled: true,
    currentadorationenabled: false,
    nextadorationenabled: true,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75, //change to 1,
    cancelbookingcutofftime: 1, //change to 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4043CBT1",
    postenabled: true,
  },
  {
    _id: 25,
    active: true,
    name: "St Joseph (Victoria Street)",
    shortname: "SJCVS",
    completename: "St Joseph's Church (Victoria Street)",
  },
  {
    _id: 26,
    active: true,
    link: "sma",
    name: "St Mary of the Angels",
    shortname: "SMOTA",
    completename: "Church of St Mary of the Angels",
    address: "5 Bukit Batok East Avenue 2 Singapore 659918",
    priest: "Friar Michael D'Cruz , OFM",
    phone: "+65 6567 3866",
    email: "parish.stmary@catholic.org.sg",
    website: "http://www.stmary.sg",
    facebook: "https://www.facebook.com/stmary.sg",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 5,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: false,
    currentstationofcrossenabled: false,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 0,
    nexttotalstationofcrossbookingallowed: 0,
    enablepetkidcode: false, //PET test only
    kidallocation: 20, //PET test only
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4053H",
    postenabled: true,
  },
  {
    _id: 27,
    active: true,
    name: "St Michael",
    shortname: "CSM",
    completename: "Church of St Michael",
  },
  {
    _id: 28,
    active: true,
    name: "St Stephen",
    shortname: "CSS",
    completename: "Church of St Stephen",
  },
  {
    _id: 29,
    active: true,
    link: "cst",
    name: "St Teresa",
    shortname: "CST",
    completename: "Church of St Teresa",
    address: "510 Kampong Bahru Road Singapore 099446",
    priest: "Fr J J Fenelon",
    phone: "+65 6271 1184",
    email: "cst.secretariat@catholic.org.sg",
    website: "https://www.stteresa.org.sg",
    facebook: "https://www.facebook.com/stteresa.sg/",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    enablepetkidcode: false,
    kidallocation: 0,
    manualcheckenabled: true,
    festivalenabled: true,
    checkinopen: 60,
    checkinclose: 60,
    uen: "T08CC4029B",
    bulletinenabled: true,
    postenabled: true,
  },
  {
    _id: 30,
    active: true,
    link: "svp",
    name: "St Vincent de Paul",
    shortname: "SVDP",
    completename: "Church of St Vincent de Paul",
    address: "301 Yio Chu Kang Road Singapore 805910",
    priest: "Rev Fr Eugene Chong",
    phone: "+65 6482 0959",
    email: "secretariat@svdp.sg",
    website: "http://www.svdp.sg",
    facebook: "https://www.facebook.com/svdpsg",
    timezonewarning: true,
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 5,
    nexttotalweekendbookingallowed: 3,
    totalweekdaybookingallowed: 30,
    nexttotalweekdaybookingallowed: 30, //DON'T CHANGE, NO WEEKDAY LIMIT
    confessionenabled: true,
    currentconfessionenabled: true,
    nextconfessionenabled: true,
    devotionenabled: false,
    currentdevotionenabled: false,
    nextdevotionenabled: false,
    totaldevotionbookingallowed: 0,
    nexttotaldevotionbookingallowed: 0,
    stationofcrossenabled: true,
    currentstationofcrossenabled: true,
    nextstationofcrossenabled: false,
    totalstationofcrossbookingallowed: 4,
    nexttotalstationofcrossbookingallowed: 3,
    enablepetkidcode: false,
    kidallocation: 20,
    manualcheckenabled: true,
    festivalenabled: true,
    bookingcutofftime: 0.75,
    cancelbookingcutofftime: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4034E",
    bulletinenabled: false,
    postenabled: true,
  },
  {
    _id: 31,
    active: true,
    name: "Sts Peter & Paul",
    shortname: "SPP",
    completename: "Church of Sts Peter and Paul",
  },
  {
    _id: 32,
    active: true,
    name: "Transfiguration",
    shortname: "COTT",
    completename: "Church of the Transfiguration",
  },
  {
    _id: 34,
    active: true,
    link: "crs",
    name: "Caritas Humanitarian Aid & Relief Initiatives (Singapore)",
    shortname: "CHARIS",
    completename:
      "Caritas Humanitarian Aid & Relief Initiatives (Singapore) Ltd",
    address: "55 Waterloo Street, #07-02 Catholic Centre, Singapore 187954",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 35,
    active: true,
    link: "cfs",
    name: "Catholic Foundation",
    completename: "Catholic Foundation",
    shortname: "CF",
    address: "55 Waterloo St, #04-01, Singapore 187954",
    phone: "+65 6338 0348",
    email: "cf@catholic.org.sg",
    website: "https://www.catholicfoundation.sg/",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "201231391K",
  },
  {
    _id: 36,
    active: true,
    link: "csc",
    name: "Catholic Spirituality Centre",
    shortname: "CSC",
    completename: "Catholic Spirituality Centre",
    address: "1261 Upper Serangoon Road Singapore 534796",
    phone: "+65 6288 7901",
    email: "enquiries.csc@catholic.org.sg",
    website: "https://csctr.net/",
    facebook: "https://www.facebook.com/CatholicSpiritualityCentre/",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 37,
    active: true,
    link: "one",
    name: "Office for the New Evangelisation",
    shortname: "ONE",
    completename: "Office for the New Evangelisation",
    address:
      "2 Highland Road, #02-19 Catholic Archdiocesan Education Centre Singapore 549102",
    phone: "",
    email: "enquiries.one@catholic.org.sg",
    website: "https://one.org.sg/",
    facebook: "https://www.facebook.com/officeforthenewevangelisation",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 38,
    active: true,
    link: "alp",
    name: "Archdiocesan Land & Properties Singapore",
    shortname: "ALPS",
    completename: "Archdiocesan Land & Properties Singapore",
    address: "55 Waterloo Street, #05-01 Catholic Centre, Singapore 187954",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 39,
    active: true,
    link: "ccs",
    name: "Crossings Café",
    shortname: "CC",
    completename: "Crossings Café",
    address: "55 Waterloo Street, #01-01 Catholic Centre, Singapore 187954",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 40,
    active: true,
    link: "cag",
    name: "Catholic Architectural Guild",
    shortname: "CAG",
    completename: "Catholic Architectural Guild",
    address: "",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 41,
    active: true,
    link: "oyp",
    name: "Office for Young People",
    shortname: "OYP",
    completename: "Office for Young People",
    address: "2 Lor Low Koon, Singapore 536449",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 42,
    active: true,
    link: "ird",
    name: "Interreligious Dialogue",
    shortname: "ACCIRD",
    completename: "Archdiocesan Catholic Council for Interreligious Dialogue",
    address: "55 Waterloo Street, #07-01 Catholic Centre, Singapore 187954",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 43,
    active: true,
    link: "apc",
    name: "Archdiocesan Pastoral Council",
    shortname: "APC",
    completename: "Archdiocesan Pastoral Council",
    address: "",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 44,
    active: true,
    link: "crt",
    name: "Caritas Singapore",
    shortname: "CSCC",
    completename: "Caritas Singapore Community Council",
    address: "7A Lor 8 Toa Payoh, #04-01 Agape Village, Singapore 319264",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 45,
    active: true,
    link: "clg",
    name: "Catholic Lawyers Guild",
    shortname: "CLG",
    completename: "Catholic Lawyers Guild",
    address: "7A Lorong 8 Toa Payoh, Agape Village, Singapore 319264",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 46,
    active: true,
    link: "cls",
    name: "Clarity Singapore",
    shortname: "CLARITY",
    completename: "Clarity Singapore Ltd",
    address: "854 Yishun Ring Rd, #01-3511, Singapore 760854",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 47,
    active: true,
    link: "ccc",
    name: "CANA The Catholic Centre",
    shortname: "CANA",
    completename: "CANA The Catholic Centre",
    address: "55 Waterloo Street, #02-01 Catholic Centre, Singapore 187954",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 48,
    active: true,
    link: "ofc",
    name: "Office For Catechesis",
    shortname: "OFC",
    completename: "Office For Catechesis",
    address:
      "2 Highland Road, #02-02, Catholic Archdiocesan Education Centre, Singapore 549102",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 49,
    active: true,
    link: "ssv",
    name: "Society of St Vincent de Paul",
    shortname: "SSVP",
    completename: "Society of St Vincent de Paul Singapore",
    address: "7A Lor 8 Toa Payoh, #04-01 Agape Village, Singapore 319264",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 50,
    active: true,
    link: "acs",
    name: "Archdiocesan Commission for Catholic Schools",
    shortname: "ACCS",
    completename: "Archdiocesan Commission for Catholic Schools",
    address: "2 Highland Rd, Singapore 549102",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 51,
    active: true,
    link: "cws",
    name: "Catholic Welfare Services",
    shortname: "CWS",
    completename: "Catholic Welfare Services",
    address: "55 Waterloo Street, #06-01 Catholic Centre, Singapore 187954",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 55,
    active: true,
    link: "ams",
    name: "Archdiocesan Commission for Apostolate of Mandarin-Speaking",
    shortname: "AMS",
    completename: "Archdiocesan Commission for Apostolate of Mandarin-Speaking",
    address: "2 Highland Road, CAEC Building #02-12, Singapore 549102",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 60,
    active: true,
    link: "aco",
    name: "Archbishop's Communications Office",
    shortname: "ACO",
    completename: " Archbishop's Communications Office",
    address: "",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 71,
    active: true,
    link: "iao",
    name: "Internal Audit Office",
    shortname: "IAO",
    completename:
      "The Roman Catholic Archbishop of Singapore Internal Audit Office",
    address: "",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  {
    _id: 2020,
    active: true,
    link: "dco",
    name: "Digital Church Office",
    shortname: "DCO",
    completename: "Digital Church Office",
    address: "55 Waterloo Street, #05-04 Catholic Centre, Singapore 187954",
    phone: "",
    email: "mycatholicsg@catholic.org.sg",
    website: "https://mycatholic.sg",
    facebook: "",
    checkinopen: 45,
    checkinclose: 45,
    bookingcutofftime: 0.01,
    cancelbookingcutofftime: 1,
    manualcheckenabled: true,
    enablepetkidcode: false,
    kidallocation: 20,
    uen: "",
  },
  /** add more ORG configs */
];

export function getOrgs() {
  return organisations;
}

export function getActiveOrgs() {
  const active = organisations.filter((g) => g.active === true);
  return active;
}

export function getPayNowUENById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.uen;
}

export function getOrgCheckinOpenById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.checkinopen;
}

export function getOrgCheckinCloseById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.checkinclose;
}

export function getPETKidAllocationCodeEnableById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.enablepetkidcode;
}

export function getKidAllocationById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.kidallocation;
}

export function getOrgBookingCutoffTimeById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.bookingcutofftime;
}

export function getOrgCancelBookingCutoffTimeById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.cancelbookingcutofftime;
}

export function getOrgBookingAllowedById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.bookingenabled;
}

export function getOrgBookingAllowedByCode(code) {
  const [org] = organisations.filter((g) => g.link === code);

  return org.bookingenabled;
}

export function getOrgShortNameById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org?.shortname ?? org?.name;
}

export function getOrgCompleteNameById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org?.completename ?? org?.name;
}

export function isValidOrgCode(code) {
  if (!code || code.trim() === "") {
    return false;
  }
  const [org] = organisations.filter((g) => g.link === code);
  return org ? true : false;
}

export function getOrgNameById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));

  return org.name;
}
export function getOrgLinkById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));

  return org.link;
}

export function getManualCheckInEnableById(id) {
  const [org] = organisations.filter((g) => g._id === parseInt(id));
  return org.manualcheckenabled;
}

export function getOrgCompleteNameByCode(code) {
  const [org] = organisations.filter((g) => g.link === code);

  return org?.completename ?? org?.name;
}

export function getOrgIdByCode(code) {
  const [org] = organisations.filter((g) => g.link === code);

  return org._id;
}

export function getOrgByCode(code) {
  const [org] = organisations.filter((g) => g.link === code);

  return org;
}
