import { StatusDictionary, TransfersStatus } from "../hooks/hooks";

/**
 * @type {StatusDictionary<TransfersStatus>}
 */
const transfersStatusDictionary = {
  accepted: {
    message: "You're successfully transferred!",
    userLabel: "Accepted",
    adminLabel: "Accepted",
    pages: ["updateContact", "transferParish"],
    bg: "success",
    text: "light",
  },
  pendingOut: {
    message: "Your outbound application is being processed!",
    userLabel: "Pending (Outbound)",
    adminLabel: "Pending (Out)",
    pages: ["reviewRegistration"],
    bg: "secondary",
    text: "light",
  },
  pendingIn: {
    message: "Your inbound application is being processed!",
    userLabel: "Pending (Inbound)",
    adminLabel: "Pending (In)",
    pages: ["reviewRegistration"],
    bg: "secondary",
    text: "light",
  },
  rejectedOut: {
    message: "Your outbound application was not approved.",
    userLabel: "Unsuccessful (Inbound)",
    adminLabel: "Rejected (Out)",
    pages: ["register"],
    bg: "danger",
    text: "light",
  },
  rejectedIn: {
    message: "Your inbound application was not approved.",
    userLabel: "Unsuccessful (Inbound)",
    adminLabel: "Rejected (In)",
    pages: ["register"],
    bg: "danger",
    text: "light",
  },
};

export { transfersStatusDictionary };
