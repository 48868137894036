import fire from "../../../services/fire";

/** Reference names for membership types */
export const membershipTypes = {
  COMMUNE_OVERALL_ADMIN: "Commune Overall Admin",
  COMMUNE_ORG_BOOKER: "Commune Organisational Booker",
};

/** Check the `membership` collection if the user has the given `membershipType`.
 * @param {string} userId ID of the user to check.
 * @param {string} membershipType String representing the membership type.
 * @param {Object} filterProps Optional proprties if further filtering is required.
 * @param {string[]} [filterProps.orgIds] IDs of valid organisations to restrict the search space to.
 * @return Promise resolving to `true` if condition is met or `false` if not met.
 */
async function userHasMembership(userId, membershipType, { orgIds }) {
  const db = fire.firestore();

  let query = db
    .collection("membership")
    .where("userid", "==", userId)
    .where("membershipType", "==", membershipType)
    .where("isActive", "==", true);
  if (orgIds != null) {
    query = query.where("orgId", "in", orgIds);
  }
  const queryResult = await query.get();
  return !queryResult.empty;
}

/** Check the `membership` collection and return document for given user id and `membershipType`.
 * @param {string} userId ID of the user to check.
 * @param {string} membershipType String representing the membership type.
 * @param {Object} filterProps Optional proprties if further filtering is required.
 * @param {string[]} [filterProps.orgIds] IDs of valid organisations to restrict the search space to.
 * @return Promise resolving to a QuerySnapshot with matching `membership` documents.
 */
async function getUserMembershipDocs(userId, membershipType, { orgIds }) {
  const db = fire.firestore();

  let query = db
    .collection("membership")
    .where("userid", "==", userId)
    .where("membershipType", "==", membershipType)
    .where("isActive", "==", true);
  if (orgIds != null) {
    query = query.where("orgId", "in", orgIds);
  }
  const querySnapshot = await query.get();
  return querySnapshot.docs.map((doc) => doc.data());
}

/** Check if user with given id is a _Commune Overall Admin_. Async.
 * @param {string} userId ID of the user to check.
 * @return Promise resolving to `true` if condition is met or `false` if not met.
 */
export function hasCommuneOverallAdminMembership(userId) {
  return userHasMembership(userId, "COMMUNE_OVERALL_ADMIN", {});
}

/** Check if user with given id is a _Commune Organisational Booker_ within the given `orgIds`. Async.
 * @param {string} userId ID of the user to check.
 * @param {string[]} [orgIds] IDs of valid organisations wherein `orgId` should be restricted to. If omitted, does not check `orgId`.
 * @return Promise resolving to `true` if condition is met or `false` if not met.
 */
export function hasCommuneOrgBookerMembership(userId, orgIds) {
  return userHasMembership(userId, "COMMUNE_ORG_BOOKER", { orgIds });
}

/** Get all documents matching a user's _Commune Organisational Booker_ membership, within the given `orgIds`. Async.
 * @param {string} userId ID of the user to check.
 * @param {string[]} [orgIds] IDs of valid organisations wherein `orgId` should be restricted to. If omitted, does not check `orgId`.
 * @return Promise resolving to an array of DocumentData with matching `membership` documents.
 */
export function getCommuneOrgBookerMembershipDocs(userId, orgIds) {
  return getUserMembershipDocs(userId, "COMMUNE_ORG_BOOKER", { orgIds });
}

/** Get all documents matching a user's _Commune Organisational Booker_ membership, within the given `orgIds`. Async.
 * @param {string} userId ID of the user to check.
 * @param {string[]} [orgIds] IDs of valid organisations wherein `orgId` should be restricted to. If omitted, does not check `orgId`.
 * @return Promise resolving to an array of DocumentData with matching `membership` documents.
 */
export function getCATCHParishAdminMembershipDocs(userId, orgIds) {
  return getUserMembershipDocs(userId, "CATCH_PARISH_ADMIN", { orgIds });
}
