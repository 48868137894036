import React from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PublicIcon from "@material-ui/icons/Public";
import FacebookIcon from "@material-ui/icons/Facebook";
import DirectionsRoundedIcon from "@material-ui/icons/DirectionsRounded";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import TelegramIcon from "@material-ui/icons/Telegram";
import PersonIcon from "@material-ui/icons/Person";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ListAltIcon from "@material-ui/icons/ListAlt";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import DirectionsOutlinedIcon from "@material-ui/icons/DirectionsOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import CakeOutlinedIcon from "@material-ui/icons/CakeOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import PhonelinkRingOutlinedIcon from "@material-ui/icons/PhonelinkRingOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons//ConfirmationNumberOutlined";

function IconByField({ field, color, size }) {
  let iconColor = "#277af5";
  let iconSize = "20px";
  if (color) {
    iconColor = color;
    iconSize = size;
  }
  let icon;
  if (field === "email") {
    icon = <MailOutlineIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "phone") {
    icon = <LocalPhoneIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "parish") {
    icon = (
      <AccountBalanceOutlinedIcon
        style={{ color: iconColor, fontSize: iconSize }}
      />
    );
  } else if (field === "address") {
    icon = <LocationOnIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "website") {
    icon = <PublicIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "ticket") {
    icon = (
      <ConfirmationNumberOutlinedIcon
        style={{ color: iconColor, fontSize: iconSize }}
      />
    );
  } else if (field === "priest" || field === "name") {
    icon = <PersonIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "secure") {
    icon = (
      <HttpsOutlinedIcon style={{ color: iconColor, fontSize: iconSize }} />
    );
  } else if (field === "facebook") {
    icon = <FacebookIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "directions") {
    icon = (
      <DirectionsRoundedIcon style={{ color: iconColor, fontSize: iconSize }} />
    );
  } else if (field === "fax") {
    icon = (
      <PrintOutlinedIcon style={{ color: iconColor, fontSize: iconSize }} />
    );
  } else if (field === "ordination") {
    icon = (
      <EventAvailableIcon style={{ color: iconColor, fontSize: iconSize }} />
    );
  } else if (field === "group") {
    icon = <PublicIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "directionsoutline") {
    icon = (
      <DirectionsOutlinedIcon
        style={{ color: iconColor, fontSize: iconSize }}
      />
    );
  } else if (field === "youtube") {
    icon = <YouTubeIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "instagram") {
    icon = <InstagramIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "telegram") {
    icon = <TelegramIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "bulletin") {
    icon = <ListAltIcon style={{ color: iconColor, fontSize: iconSize }} />;
  } else if (field === "more") {
    icon = (
      <MoreHorizRoundedIcon style={{ color: iconColor, fontSize: iconSize }} />
    );
  } else if (field === "arrowright") {
    icon = (
      <ChevronRightOutlinedIcon
        style={{ color: iconColor, fontSize: iconSize }}
      />
    );
  } else if (field === "schedule") {
    icon = (
      <EventAvailableOutlinedIcon
        style={{ color: iconColor, fontSize: iconSize }}
      />
    );
  } else if (field === "type") {
    icon = (
      <LocalOfferOutlinedIcon
        style={{ color: iconColor, fontSize: iconSize }}
      />
    );
  } else if (field === "birthday") {
    icon = (
      <CakeOutlinedIcon style={{ color: iconColor, fontSize: iconSize }} />
    );
  } else if (field === "birthplace") {
    icon = (
      <HomeOutlinedIcon style={{ color: iconColor, fontSize: iconSize }} />
    );
  } else if (field === "uen") {
    icon = (
      <PhonelinkRingOutlinedIcon
        style={{ color: iconColor, fontSize: iconSize }}
      />
    );
  } else if (field === "eventoutline") {
    icon = (
      <EventOutlinedIcon style={{ color: iconColor, fontSize: iconSize }} />
    );
  }

  return icon;
}

export default IconByField;
