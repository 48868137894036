import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {
  getParishCompleteNameById,
  getParishIdByCode,
  getParishPriestById,
} from "../../services/parish";
import { isValidParish } from "../../services/validation";

class ParishPriest extends Component {
  images = [
    { id: 0, photo: require("../../img/logo/logo_archdiocese_white_150.webp") },
    { id: 1, photo: require("../../img/priest/blessedsacrament.webp") },
    { id: 2, photo: require("../../img/priest/cathedral.webp") },
    { id: 3, photo: require("../../img/priest/christtheking.webp") },
    { id: 4, photo: require("../../img/priest/divinemercy.webp") },
    { id: 5, photo: require("../../img/priest/holycross.webp") },
    { id: 6, photo: require("../../img/priest/holyfamily.webp") },
    { id: 7, photo: require("../../img/priest/holyspirit.jpg") },
    { id: 8, photo: require("../../img/priest/holytrinity.webp") },
    { id: 9, photo: require("../../img/priest/immaculateheartofmary.webp") },
    { id: 10, photo: require("../../img/priest/ladyoflourdes.webp") },
    {
      id: 11,
      photo: require("../../img/priest/nativityofblessedvirgin.webp"),
    },
    { id: 12, photo: require("../../img/parish/300/perpertualsuccour.webp") },
    { id: 13, photo: require("../../img/priest/queenofpeace.webp") },
    { id: 14, photo: require("../../img/priest/starofthesea.webp") },
    { id: 15, photo: require("../../img/priest/risenchrist.webp") },
    { id: 16, photo: require("../../img/priest/sacredheart.webp") },
    { id: 17, photo: require("../../img/priest/novena.webp") },
    { id: 18, photo: require("../../img/priest/stanne.webp") },
    { id: 19, photo: require("../../img/priest/stanthony.webp") },
    { id: 20, photo: require("../../img/priest/stbernadette.jpg") },
    { id: 21, photo: require("../../img/priest/stfrancisassisi.webp") },
    { id: 22, photo: require("../../img/priest/stfrancisxavier.webp") },
    { id: 23, photo: require("../../img/priest/stignatius.webp") },
    { id: 24, photo: require("../../img/priest/stjosephbukittimah.webp") },
    { id: 25, photo: require("../../img/priest/stjosephvictoria.webp") },
    { id: 26, photo: require("../../img/priest/stmaryofangels.webp") },
    { id: 27, photo: require("../../img/priest/stmichael.webp") },
    { id: 28, photo: require("../../img/priest/ststephen.webp") },
    { id: 29, photo: require("../../img/parish/300/stteresa.webp") },
    { id: 30, photo: require("../../img/priest/stvincentdepaul.jpg") },
    { id: 31, photo: require("../../img/priest/stspeterandpaul.jpg") },
    { id: 32, photo: require("../../img/priest/transfiguration.webp") },
    {
      id: 33,
      photo: require("../../img/logo/logo_archdiocese_white_150.webp"),
    },
  ];
  constructor(props) {
    super(props);
    const { parish, parishId, parishCode, size } = props;

    this.state = {
      size,
      parish,
      parishId,
      parishCode,
    };
  }
  getImageDisplay = (id) => {
    let imagedisplay;
    let parishId = id;
    if (!isValidParish(parishId)) {
      parishId = 33;
    }
    [imagedisplay] = this.images.filter((img) => img.id === parseInt(parishId));
    return imagedisplay.photo;
  };
  getUniqueParishId = () => {
    const { parish, parishId, parishCode } = this.state;
    let id;
    if (parishId) {
      id = parishId;
    } else if (parishCode) {
      id = getParishIdByCode(parishCode);
    } else if (parish) {
      id = parish._id;
    } else {
      id = 33;
    }
    return id;
  };

  getParishPriestNameOnly = (id) => {
    if (!isValidParish(id)) {
      return null;
    }
    return getParishPriestById(id);
  };

  getParishNameOnly = (id) => {
    let parish = getParishCompleteNameById(id);

    //check if need to split
    if (parish.indexOf("(") !== -1) {
      const first = parish.split("(");
      const second = first[1].split(")");
      parish = (
        <>
          <div className="pt-0 mb-0 text-center">{first[0]}</div>
          <div className="text-center">({second[0]})</div>
        </>
      );
    } else {
      parish = (
        <>
          <div className="my-0 pt-0 text-center">{parish}</div>
          <div className="py-1"></div>
        </>
      );
    }
    return parish;
  };

  getParishPriestName = (id) => {
    let display = (
      <h4 className="pt-1 text-center text-muted">
        <span className="font-italic">my</span>CatholicSG
      </h4>
    );
    if (!isValidParish(id)) {
      return display;
    }
    if (id) {
      display = getParishPriestById(id);
      let parish = getParishCompleteNameById(id);
      //check if need to split
      if (display.indexOf("(") !== -1) {
        let first = display.split("(");
        let second = first[1].split(")");
        display = (
          <React.Fragment>
            <h4 className="pt-1 mb-1 text-center text-muted">{first[0]}</h4>
            <h4 className="text-center text-muted">({second[0]})</h4>
          </React.Fragment>
        );
      } else {
        display = (
          <React.Fragment>
            <h5 className="pt-1 mb-0  pb-0 text-center">{display}</h5>
            <p className="my-0 pt-0 text-center text-muted defaultfontsize">
              {parish}
            </p>
            <div className="py-1"></div>
          </React.Fragment>
        );
      }
    }
    return display;
  };
  render() {
    const id = this.getUniqueParishId();
    if (this.props.size === "medium") {
      return (
        <>
          <Image
            src={this.getImageDisplay(id)}
            width="80px"
            height="80px"
            alt=""
            roundedCircle
          />
          <p className="mb-0 font-weight-bold">
            {this.getParishPriestNameOnly(id)}
          </p>
          <div className="">{this.getParishNameOnly(id)}</div>
        </>
      );
    } else if (this.props.size === "small") {
      return (
        <Image
          src={this.getImageDisplay(id)}
          width="60px"
          height="60px"
          alt=""
          roundedCircle
        />
      );
    }
    return (
      <nav className="navbar navbar-light bg-white flex-column">
        <a className="navbar-brand mx-auto" href="# ">
          <Image
            src={this.getImageDisplay(id)}
            width="150px"
            height="150px"
            alt=""
            roundedCircle
          />
        </a>
        {this.getParishPriestName(id)}
      </nav>
    );
  }
}

export default ParishPriest;
