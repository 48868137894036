import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { useMembership } from "../hooks/hooks";
// import ArchdioceseGraph from "../components/ArchdioceseGraph";
import OverviewStatisticGraph from "../components/OverviewStatisticGraph";

// function ArchdioceseGraphModule() {
//   return (
//     <Col lg={12}>
//       <Card className="my-2">
//         <Card.Body className="d-flex flex-column justify-content-center text-center">
//           <span className="bold mediumfontsize">Registrations & Transfers</span>
//           <ArchdioceseGraph />
//         </Card.Body>
//       </Card>
//     </Col>
//   );
// }

export default function AnalyticsDashboard(props) {

  return (
    <div className="text-center">
      <Row>{<OverviewStatisticGraph />}</Row>
    </div>
  );
}
