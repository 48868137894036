import React from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import ClearIcon from "@material-ui/icons/Clear";

import { Link } from "react-router-dom";

export default function SaveDraftConfirmationModal({
  showModal,
  setShowModal,
  onSaveDraft,
}) {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Save Draft?</Modal.Title>
        <ClearIcon
          onClick={handleClose}
          style={{ fontSize: "36px", cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body>
        <Container className="px-0">
          <Row className="mx-0">
            Please note that the registration will not be considered if you save
            as draft. Are you sure you want to save as draft and exit the
            current submission process?
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col className="d-none d-sm-flex" />
            <Col xs="auto">
              <Button
                variant="outline-primary"
                size="lg"
                className="w-100"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Col>
            <Col className="col" sm="auto">
              <Link to="/catch">
                <Button
                  variant="primary"
                  size="lg"
                  className="w-100"
                  onClick={onSaveDraft}
                >
                  Save & Exit
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
