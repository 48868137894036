import React from "react";

export default function EucharistIcon({ color, size }) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      id="svg19"
      fill={color}
      width={size}
      height={size}
    >
      <defs id="defs23" />
      <g id="g13" transform="translate(-0.15,0.15)">
        <path
          d="m 56.4,28.4 c 1.3,0 2.3,-1.1 2.3,-2.3 0,-1.3 -1.1,-2.3 -2.3,-2.3 h -4.1 v -2.9 c 0,-1.3 -1.1,-2.3 -2.3,-2.3 -1.2,0 -2.3,1.1 -2.3,2.3 v 2.9 h -4.1 c -1.3,0 -2.3,1.1 -2.3,2.3 0,1.3 1.1,2.3 2.3,2.3 h 4.1 V 37 c 0,1.3 1.1,2.3 2.3,2.3 1.2,0 2.3,-1.1 2.3,-2.3 v -8.6 z"
          id="path9"
        />
        <path
          d="m 80.5,49.6 h -1.6 c 0,-0.5 0,-1.1 0,-1.6 v -3.4 c 0,-1.3 -1.1,-2.3 -2.3,-2.3 h -12 C 67.9,38.7 69.8,34 69.8,28.9 69.8,18 60.9,9.1 50,9.1 39.1,9.1 30.2,18 30.2,28.9 c 0,5 1.9,9.8 5.2,13.4 h -12 c -1.3,0 -2.3,1.1 -2.3,2.3 V 48 c 0,0.5 0,1.1 0,1.6 h -1.6 c -1.3,0 -2.3,1.1 -2.3,2.3 0,1.3 1.1,2.3 2.3,2.3 h 2.3 C 24,64 31.3,72 40.8,75.1 h -0.1 c -1.3,0 -2.3,1.1 -2.3,2.3 0,1.2 1.1,2.3 2.3,2.3 H 44 V 86 H 37.3 C 36,86 35,87.1 35,88.3 c 0,1.3 1.1,2.3 2.3,2.3 H 63 c 1.3,0 2.3,-1.1 2.3,-2.3 C 65.3,87 64.2,86 63,86 h -6.7 v -6.3 h 3.3 c 1.3,0 2.3,-1.1 2.3,-2.3 0,-1.2 -1.1,-2.3 -2.3,-2.3 H 59.5 C 69,72 76.2,64 78.5,54.2 h 2.3 c 1.3,0 2.3,-1.1 2.3,-2.3 -0.3,-1.2 -1.3,-2.3 -2.6,-2.3 z M 42.8,42.2 c -4.9,-2.7 -7.9,-7.7 -7.9,-13.3 0,-8.3 6.8,-15.1 15.1,-15.1 8.3,0 15.1,6.8 15.1,15.1 0,5.5 -3,10.6 -7.9,13.3 0,0 -0.1,0 -0.1,0.1 H 42.9 c -0.1,0 -0.1,-0.1 -0.1,-0.1 z"
          id="path11"
        />
      </g>
    </svg>
  );
}
