import React, { useState, useContext, useEffect } from "react";
import fire from "../../../../services/fire";
import { Modal } from "react-bootstrap";
import QRSetupStatus from "./QRSetupStatus";
import QRDetailForm from "./QRDetailForm";
import QRAssignForm from "./QRAssignForm";
import QRFormConfirmation from "./QRFormConfirmation";
import { sendCampaignRequestEmail } from "../../../email/emailTemplateHelpers";

import "../../stylesheets/finance-qr-modal.css";
import FinanceContext from "../financeContext";

const INITIAL_STATE = {
  status: 0,
  campaignName: "",
  amount: 0,
  isEditable: false,
  isGlobal: false,
  isPayOnce: false,
  isPrivate: false,
  expiry: null,
  campaignStart: null,
  comments: "",
  qrType: "U",
  reference: {
    subOrg: "GP",
    productRef: "",
    subRef: "",
    runningNum: "",
  },
};

const CreateQRModal = () => {
  const { qrDashboardState, financeDashboardState, qrDashboardDispatch } =
    useContext(FinanceContext);
  const [state, setState] = useState({ ...INITIAL_STATE });
  const { isCreateModalOpen } = qrDashboardState;
  const { user, org } = financeDashboardState;

  const checkRunningNum = () => {
    const productRef = state?.reference?.productRef;
    const subRef = state?.reference?.subRef;
    fire
      .firestore()
      .collection("paypluscampaigns")
      .where("productRef", "==", productRef)
      .where("subRef", "==", subRef)
      .get()
      .then((resultSnapshot) => {
        const campaignList = [];
        resultSnapshot.docs.map((doc) => campaignList.push(doc.data()));
        const nextNum = campaignList.length;
        let runningNum = "";
        if (nextNum < 100) {
          if (nextNum < 10) {
            runningNum = "00" + nextNum;
          } else {
            runningNum = "0" + nextNum;
          }
        } else {
          runningNum = nextNum.toString();
        }
        setState({
          ...state,
          reference: {
            ...state.reference,
            runningNum,
          },
        });
      });
  };

  const createQRCampaign = () => {
    const {
      reference,
      campaignName,
      amount,
      isEditable,
      isGlobal,
      isPayOnce,
      isPrivate,
      campaignStart,
      expiry,
      comments,
      qrType,
    } = state;
    const { subOrg, productRef, subRef, runningNum } = reference;
    const orgRef = org?.orgRef;
    const orgApprovers = org?.approvers ?? [];
    const orgId = org?.orgId;
    const userId = user?.userid;
    const campaignRef = getCampaignRef();

    const qrCampaign = {
      campaignName,
      amount,
      isEditable,
      isGlobal,
      isPayOnce,
      isPrivate,
      campaignStart,
      expiry,
      comments,
      orgRef,
      subOrg,
      productRef,
      subRef,
      runningNum,
      qrType,
      campaignRef,
      orgId,
      approvalStatus: 0,
      createdBy: userId,
      createdAt: new Date(),
    };

    const newCampaignRef = fire
      .firestore()
      .collection("paypluscampaigns")
      .doc();

    newCampaignRef
      .set({
        ...qrCampaign,
        id: newCampaignRef.id,
      })
      .then(() => {
        qrDashboardDispatch({ type: "SET_CREATE_MODAL_CLOSE" });
        resetState();

        // Send Confirmation Email to all orgApprovers
        if (orgApprovers.length > 0) {
          orgApprovers.forEach((approverId) => {
            fire
              .firestore()
              .collection("users")
              .doc(approverId)
              .get()
              .then((approverRef) => {
                const approverObj = approverRef.data();
                const approverEmail = approverObj.email;
                const approverName = approverObj.fullname;
                const requesterName = user.fullname;

                sendCampaignRequestEmail({
                  email: approverEmail,
                  approverName,
                  requesterName,
                  campaignName,
                });
              });
          });
        }
      });
  };

  const resetState = () => {
    setState({
      ...INITIAL_STATE,
    });
    return;
  };

  const isRefComplete = () => {
    const { reference } = state;
    if (
      reference?.productRef?.length === 2 &&
      reference?.subRef?.length === 4
    ) {
      return true;
    }
    return false;
  };

  const isComplete = () => {
    if (isRefComplete()) {
      const amount = state.amount.toString();
      return amount !== "0" && state.campaignName.length > 0;
    }
    return false;
  };

  const getCampaignRef = () => {
    const orgRef = financeDashboardState?.org?.orgRef;
    const { subOrg, productRef, subRef, runningNum } = state.reference;
    const campaignRef = orgRef + subOrg + productRef + subRef + runningNum;

    return campaignRef;
  };

  const closeModal = () => {
    qrDashboardDispatch({ type: "SET_CREATE_MODAL_CLOSE" });
    resetState();
  };

  useEffect(() => {
    if (isRefComplete()) {
      checkRunningNum();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reference.productRef, state.reference.subRef]);

  // Default campaign start date to today
  useEffect(() => {
    setState({
      ...INITIAL_STATE,
      campaignStart: new Date(),
    });
  }, [isCreateModalOpen]);

  return (
    <Modal
      dialogClassName="create-qr-modal__dialog"
      keyboard={false}
      show={isCreateModalOpen}
      onHide={() => {}}
    >
      <Modal.Body className="create-qr-modal__container">
        <div className="create-qr-modal-header">
          Create New QR
          <QRSetupStatus status={state.status} />
        </div>
        {state.status === 0 && (
          <QRDetailForm
            modalProps={state}
            setModalState={setState}
            isRefComplete={isRefComplete}
            getCampaignRef={getCampaignRef}
          />
        )}{" "}
        {state.status === 1 && (
          <QRAssignForm
            modalProps={state}
            setModalState={setState}
            getCampaignRef={getCampaignRef}
          />
        )}{" "}
        {state.status === 2 && (
          <QRFormConfirmation
            modalProps={state}
            getCampaignRef={getCampaignRef}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="create-qr-modal__footer">
        <div
          className="create-qr-modal__footer-btn"
          onClick={() => closeModal()}
        >
          Cancel
        </div>
        <div className="create-qr-modal__footer-btn-grp">
          {state.status > 0 && (
            <div
              className="create-qr-modal__footer-btn--margin"
              onClick={() =>
                setState({
                  ...state,
                  status: state.status - 1,
                })
              }
            >
              Back
            </div>
          )}
          {state.status < 2 ? (
            <div
              className={
                isComplete()
                  ? "create-qr-modal__footer-btn--success"
                  : "create-qr-modal__footer-btn"
              }
              onClick={() =>
                isComplete() &&
                setState({
                  ...state,
                  status: state.status + 1,
                })
              }
            >
              Next
            </div>
          ) : (
            <div
              className="create-qr-modal__footer-btn--success"
              onClick={() => createQRCampaign()}
            >
              Confirm
            </div>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateQRModal;
