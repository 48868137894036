export const TRANSACTION_COLUMNS = [
  {
    Header: "Date",
    accessor: (row) => {
      return row.timeStamp ? row.timeStamp.toDate().toString() : "";
    },
    sortType: (a, b) => {
      const aTime = new Date(a.values.Date).getTime();
      const bTime = new Date(b.values.Date).getTime();
      if (aTime < bTime) {
        return 1;
      } else if (aTime > bTime) {
        return -1;
      } else return 0;
    },
  },
  { Header: "Reference", accessor: "customerReference" },
  { Header: "Campaign", accessor: "campaignName" },
  {
    Header: "User",
    accessor: (row) => {
      return row.senderParty?.name ? row.senderParty.name : "-";
    },
  },
  {
    Header: "Amount",
    accessor: (row) => {
      return row.amtDtls?.txnAmt ? row.amtDtls.txnAmt : "-";
    },
    width: 50,
  },
  {
    Header: "Email",
    accessor: (row) => {
      return row.myCatholicSGEmail ? row.myCatholicSGEmail : "-";
    },
  },
  {
    Header: "Mobile",
    accessor: (row) => {
      return row.myCatholicSGMobile ? row.myCatholicSGMobile : "-";
    },
  },
  { Header: "Action", accessor: "action", width: 50 },
];

export const INIT_TRANSACTION_STATE = {
  startDate: false,
  endDate: false,
  isLive: true,
  searchKey: "",
  transactionList: [],
  filteredData: [],
};

export const transactionDashboardReducer = (state, action) => {
  const { type = "", payload = {} } = action;
  switch (type) {
    case "SET_DATE_RANGE": {
      const { startDate, endDate } = payload;
      return { ...state, startDate, endDate };
    }
    case "SET_LIVE": {
      const { isLive } = payload;
      return { ...state, isLive };
    }
    case "SET_LIVE_NOW": {
      const { endDate, isLive } = payload;
      return { ...state, endDate, isLive };
    }
    case "SET_SEARCH_KEY": {
      const { searchKey } = payload;
      return { ...state, searchKey };
    }
    case "SET_TRANSACTION_LIST": {
      const { transactionList } = payload;
      return { ...state, transactionList };
    }
    case "SET_FILTERED_DATA": {
      const { filteredData } = payload;
      return { ...state, filteredData };
    }
    default:
      return state;
  }
};
