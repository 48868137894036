import React from "react";
import BaptismIcon from "../../../../../img/sacraments/BaptismIcon.jsx";
import ReconciliationIcon from "../../../../../img/sacraments/ReconciliationIcon.jsx";
import EucharistIcon from "../../../../../img/sacraments/EucharistIcon.jsx";
import ConfirmationIcon from "../../../../../img/sacraments/ConfirmationIcon.jsx";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function SacramentIcon({ name, received, certExists, Icon }) {
  const size = "1.75em";
  const bg = !received ? "secondary" : !certExists ? "warning" : "success";
  const remark = !received
    ? "Not Received"
    : !certExists
    ? "Received, No Cert"
    : "Received";
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ opacity: 1 }}>
          <span className="font-weight-bold">{name}</span>
          <br />({remark})
        </Tooltip>
      }
    >
      <div
        className={`rounded-circle bg-${bg} d-flex justify-content-center align-items-center`}
        style={{
          height: size,
          width: size,
          padding: "3px",
          opacity: received ? 1 : 0.5,
        }}
      >
        <Icon color="white" size="100%" />
      </div>
    </OverlayTrigger>
  );
}

/**
 * @typedef SacramentsPillProps
 * @prop {import("../../hooks/hooks").Sacraments} sacraments
 */

/**
 * @param {SacramentsPillProps}
 */
export default function SacramentsPill({ sacraments }) {
  const baptism = sacraments?.baptism ?? { received: false };
  const reconciliation = sacraments?.reconciliation ?? { received: false };
  const eucharist = sacraments?.eucharist ?? { received: false };
  const confirmation = sacraments?.confirmation ?? { received: false };

  return (
    <div className="d-flex" style={{ gap: "4px" }}>
      <SacramentIcon
        name="Baptism"
        received={baptism.received}
        certExists={baptism.cert?.path}
        Icon={BaptismIcon}
      />
      <SacramentIcon
        name="Reconciliation"
        received={reconciliation.received}
        certExists={true}
        Icon={ReconciliationIcon}
      />
      <SacramentIcon
        name="Eucharist"
        received={eucharist.received}
        certExists={true}
        Icon={EucharistIcon}
      />
      <SacramentIcon
        name="Confirmation"
        received={confirmation.received}
        certExists={confirmation.cert?.path}
        Icon={ConfirmationIcon}
      />
    </div>
  );
}
