import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { transfersStatusDictionary } from "../../services/transfersStatus";
import { getSacramentsSortKey } from "./tableUtils";

import { Column, UseSortByState } from "react-table";
import ParishPill from "../components/ParishPill";
import ProgrammePill from "../components/ProgrammePill";
import LevelPill from "../components/LevelPill";
import SacramentsPill from "../components/SacramentsPill";
import StatusPill from "../components/StatusPill";

import formatTimestamp from "./formatTimestamp";
import { TransfersStatus, TransfersDatum } from "../../hooks/hooks";
import { LookupConfig } from "../hooks/hooks";
// import { getLevelAtDate } from "../../services/levels";
import {
  StaticActionProps,
  StatusActionDictionary,
} from "../components/AbstractList";

import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import MailIcon from "@material-ui/icons/Mail";
import VisibilityIcon from "@material-ui/icons/Visibility";

/**
 * @typedef ViewButtonProps
 * @prop {RegistrationDatum} selectedItem
 * @prop {() => void} openModal
 */

/** @type {(lookupConfig: LookupConfig, viewButtonProps: ViewButtonProps) => Column<TransfersDatum>[]} */
const getColumnProps = (lookupConfig, { selectedItem, openModal }) => [
  {
    id: "submittedAt",
    Header: "Submitted at",
    accessor: ({ submittedAt }) => submittedAt,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">{formatTimestamp(value)}</div>
    ),
    width: 150,
    sortType: ({ original: rowA }, { original: rowB }) => {
      const timestampA = rowA.submittedAt;
      const timestampB = rowB.submittedAt;

      // Convert to JavaScript Date objects
      let dateA;

      if (Object.hasOwn(timestampA, "_seconds")) {
        dateA = new Date(
          timestampA._seconds * 1000 + timestampA._nanoseconds / 1000000
        );
      } else {
        dateA = new Date(
          timestampA.seconds * 1000 + timestampA.nanoseconds / 1000000
        );
      }

      let dateB;

      if (Object.hasOwn(timestampB, "_seconds")) {
        dateB = new Date(
          timestampB._seconds * 1000 + timestampB._nanoseconds / 1000000
        );
      } else {
        dateB = new Date(
          timestampB.seconds * 1000 + timestampB.nanoseconds / 1000000
        );
      }

      return Math.sign(dateA - dateB);
    },
  },
  {
    id: "status",
    Header: "Status",
    accessor: ({ status }) => status,
    Cell: ({ value }) =>
      value in transfersStatusDictionary ? (
        <StatusPill
          statusDictionary={transfersStatusDictionary}
          value={value}
        />
      ) : (
        value
      ),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "name",
    Header: "Name",
    accessor: ({ registration: { name } }) => name,
    Cell: ({ value }) => <div className="text-wrap pt-1 pb-1">{value}</div>,
    width: 150,
  },
  {
    id: "fromparish",
    Header: "From Parish",
    accessor: ({ from }) => from,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        {!value.id ? (
          <>&mdash;</>
        ) : !value.inSingapore ? (
          value.id
        ) : (
          <h6 className="m-0">
            <ParishPill parish={lookupConfig(value.id)?.parish} />
          </h6>
        )}
      </div>
    ),
    sortType: ({ original: rowA }, { original: rowB }) =>
      Math.sign(+rowA.from.id - +rowB.from.id),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "fromprogramme",
    Header: "From Programme",
    accessor: ({ from }) => from,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        {!value.programmeId ? (
          <>&mdash;</>
        ) : (
          <h6 className="m-0">
            <ProgrammePill
              name={
                value.programmeName ??
                lookupConfig(value.id, value.programmeId)?.programme?.name
              }
              type={value.programmeId}
            />
          </h6>
        )}
      </div>
    ),
    sortType: ({ original: rowA }, { original: rowB }) =>
      Math.sign(+rowA.from.id - +rowB.from.id) ||
      (rowA.from.programmeId?.localeCompare?.(rowB.from.programmeId) ?? 0),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "toparish",
    Header: "To Parish",
    accessor: ({ to }) => to,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        {!value.id ? (
          <>&mdash;</>
        ) : !value.inSingapore ? (
          value.id
        ) : (
          <h6 className="m-0">
            <ParishPill parish={lookupConfig(value.id)?.parish} />
          </h6>
        )}
      </div>
    ),
    sortType: ({ original: rowA }, { original: rowB }) =>
      Math.sign(+rowA.to.id - +rowB.to.id),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "toprogramme",
    Header: "To Programme",
    accessor: ({ to }) => to,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        <h6 className="m-0">
          <ProgrammePill
            name={
              value.programmeName ??
              lookupConfig(value.id, value.programmeId)?.programme?.name
            }
            type={value.programmeId}
          />
        </h6>
      </div>
    ),
    sortType: ({ original: rowA }, { original: rowB }) =>
      Math.sign(+rowA.to.id - +rowB.to.id) ||
      (rowA.to.programmeId?.localeCompare?.(rowB.to.programmeId) ?? 0),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "level",
    Header: "Level",
    accessor: ({ registration: { level } }) => +level,
    Cell: ({ value }) => (
      <h6 className="m-0">
        <LevelPill id={value} />
      </h6>
    ),
    width: 100,
  },
  {
    id: "timeslot",
    Header: "Timeslot",
    accessor: ({ status, to, from }) =>
      status.slice(-3) === "Out" ? from : to,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        {lookupConfig(value.id, value.programmeId, value.timeslotId)?.timeslot
          ?.name ?? value.timeslotId}
      </div>
    ),
    sortType: ({ original: rowA }, { original: rowB }) => {
      const a = rowA[rowA.status.slice(-3) === "Out" ? "from" : "to"];
      const b = rowB[rowB.status.slice(-3) === "Out" ? "from" : "to"];
      return (
        Math.sign(+a.id - +b.id) ||
        (a.programmeId?.localeCompare?.(b.programmeId) ?? 0) ||
        Math.sign(+a.timeslotId - +b.timeslotId)
      );
    },
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "class",
    Header: "Class",
    accessor: ({ registration: { assignedClass } }) => assignedClass,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">{value ?? <>&mdash;</>}</div>
    ),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "sacraments",
    Header: "Sacraments",
    accessor: ({ registration: { sacraments } }) => sacraments,
    Cell: ({ value }) => <SacramentsPill sacraments={value} />,
    sortType: (rowA, rowB, id) =>
      Math.sign(
        getSacramentsSortKey(rowA.values[id]) -
          getSacramentsSortKey(rowB.values[id])
      ),
    width: 600,
    disableGlobalFilter: true,
  },
  {
    id: "view",
    Header: "View",
    accessor: (registrationDatum) => registrationDatum,
    Cell: ({ value }) => (
      <Button
        id={`viewbutton-${value.id}`}
        variant="outline-secondary"
        className="w-100"
        onClick={() => openModal(value)}
        style={{ minWidth: 50 }}
        disabled={!!selectedItem?.id}
      >
        {selectedItem?.id === value.id ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <VisibilityIcon />
        )}
      </Button>
    ),
    width: 100,
    disableGlobalFilter: true,
    sticky: "right",
  },
];

/** @type {UseSortByState<TransfersDatum>} */
const sortByProps = [
  {
    id: "submittedAt",
    desc: true,
  },
];

/**
 * @typedef { "unassign" | "accept" | "reject" | "email" } TransfersAction
 */

/**
 * @type {Record<TransfersAction, StaticActionProps<TransfersAction>>}
 */
const actions = {
  unassign: {
    action: "unassign",
    Icon: RemoveCircleIcon,
    title: "Set as Pending",
    variant: "outline-secondary",
  },
  accept: {
    action: "accept",
    Icon: CheckCircleIcon,
    title: "Accept",
    variant: "outline-success",
  },
  reject: {
    action: "reject",
    Icon: CancelIcon,
    title: "Reject",
    variant: "outline-danger",
  },
  email: {
    action: "email",
    Icon: MailIcon,
    title: "Send Email",
    variant: "primary",
  },
};
/**
 * @type {Record<TransfersAction, StaticActionProps<TransfersAction>>}
 */
const disabledActions = Object.fromEntries(
  Object.keys(actions).map((key) => [key, { ...actions[key], disabled: true }])
);

/** @type {Partial<Record<TransfersAction, Partial<Record<TransfersStatus, TransfersStatus>>>>} */
const intFsmEdges = {
  unassign: {
    rejectedOut: "pendingOut",
    rejectedIn: "pendingIn",
  },
  accept: {
    pendingOut: "pendingIn",
    pendingIn: "accepted",
  },
  reject: {
    pendingOut: "rejectedOut",
    pendingIn: "rejectedIn",
  },
};

/** @type {Partial<Record<TransfersAction, Partial<Record<TransfersStatus, TransfersStatus>>>>} */
const extFsmEdges = {
  unassign: {
    rejectedOut: "pendingOut",
    rejectedIn: "pendingIn",
  },
  accept: {
    pendingOut: "accepted",
    pendingIn: "accepted",
  },
  reject: {
    pendingOut: "rejectedOut",
    pendingIn: "rejectedIn",
  },
};

/**
 * @type {StatusActionDictionary<TransfersStatus, TransfersAction>}
 */
const statusActionDictionary = {
  pendingOut: [
    [disabledActions.unassign, actions.accept, actions.reject],
    [actions.email],
  ],
  pendingIn: [
    [disabledActions.unassign, actions.accept, actions.reject],
    [actions.email],
  ],
  rejectedOut: [
    [actions.unassign, disabledActions.accept, disabledActions.reject],
    [actions.email],
  ],
  rejectedIn: [
    [actions.unassign, disabledActions.accept, disabledActions.reject],
    [actions.email],
  ],
  accepted: [
    [disabledActions.unassign, disabledActions.accept, disabledActions.reject],
    [actions.email],
  ],
};

const getListProps = (lookupConfig, viewButtonProps) => ({
  columnProps: getColumnProps(lookupConfig, viewButtonProps),
  sortByProps,
  statusActionDictionary,
});

export { getListProps, intFsmEdges, extFsmEdges };
