import React from "react";

export default function ReconciliationIcon({ color, size }) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      id="svg31"
      fill={color}
      width={size}
      height={size}
    >
      <defs id="defs35" />
      <g id="g25">
        <g id="g23">
          <path
            d="M 16,75 H 34 V 25 7.4 l -18,18 z m 2,-23 c 0,-0.6 0.4,-1 1,-1 h 3 v -3 c 0,-0.6 0.4,-1 1,-1 h 4 c 0.6,0 1,0.4 1,1 v 3 h 3 c 0.6,0 1,0.4 1,1 v 4 c 0,0.6 -0.4,1 -1,1 h -3 v 11 c 0,0.6 -0.4,1 -1,1 h -4 c -0.6,0 -1,-0.4 -1,-1 V 57 h -3 c -0.6,0 -1,-0.4 -1,-1 z"
            id="path9"
          />
          <rect x="16" y="77" width="18" height="8" id="rect11" />
          <path
            d="m 16,92 c 0,1.1 0.9,2 2,2 h 14 c 1.1,0 2,-0.9 2,-2 V 87 H 16 Z"
            id="path13"
          />
          <rect x="36" y="6" width="28" height="18" id="rect15" />
          <path
            d="m 66,92 c 0,1.1 0.9,2 2,2 h 14 c 1.1,0 2,-0.9 2,-2 V 87 H 66 Z"
            id="path17"
          />
          <path
            d="M 66,7.4 V 25 75 H 84 V 25.4 Z M 82,56 c 0,0.6 -0.4,1 -1,1 h -3 v 11 c 0,0.6 -0.4,1 -1,1 h -4 c -0.6,0 -1,-0.4 -1,-1 V 57 h -3 c -0.6,0 -1,-0.4 -1,-1 v -4 c 0,-0.6 0.4,-1 1,-1 h 3 v -3 c 0,-0.6 0.4,-1 1,-1 h 4 c 0.6,0 1,0.4 1,1 v 3 h 3 c 0.6,0 1,0.4 1,1 z"
            id="path19"
          />
          <rect x="66" y="77" width="18" height="8" id="rect21" />
        </g>
      </g>
    </svg>
  );
}
