import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import SharePage from "../../common/sharePage";
import { Modal } from "react-bootstrap";
import ParishMainPanel from "../../parish/parishMainPanel";
import PriestCard from "../../priest/components/priestCard";
// import { logEvent } from "../../../services/log";

function DisplaySchedules({
  data,
  options,
  selectHandler,
  selection,
  parishlist,
  changeHandler,
  mode,
}) {
  const [selected, setSelected] = useState(selection);
  const [showParishModal, setShowParishModal] = useState(false);
  const [showParishPriest, setShowParishPriest] = useState(true);
  const [parish, setParish] = useState("");

  useEffect(() => {
    setSelected(selection);
  }, [selection]);

  // if (data.length <= 0) {
  //   return null;
  // }

  //filter
  const handleChange = ({ currentTarget: input }) => {
    setSelected(input.value);
    selectHandler(input.value);
  };
  const shareText = `Schedule of Masses for Catholic Churches in Singapore

`;
  //add modals here
  const closeParishModal = () => {
    setShowParishModal(false);
    setParish("");
  };

  // const openParishModal = (parish) => {
  //   const [obj] = parishlist.filter((i) => i.name === parish);
  //   setParish(obj);
  //   setShowParishModal(true);
  //   logEvent(`view_2022_sched_open_${obj.link}`, {
  //     source: "webschedule",
  //   });
  // };

  const ParishDetails = () => {
    return (
      <Modal
        show={showParishModal}
        onHide={closeParishModal}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark font-weight-bold">
            {parish.completename}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          {showParishPriest && (
            <PriestCard
              border={false}
              key={parish._id}
              details={parish}
              link={parish.link}
              label="Select"
            />
          )}
          <ParishMainPanel
            parish={parish}
            hidebutton={true}
            priestHandler={() => setShowParishPriest(!showParishPriest)}
          />
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div>
      <div className="pb-0">
        <div className="form-group">
          <div className="float-left pt-1 pb-2">
            <label htmlFor="parish">
              <h4 className="text-danger">
                Select Church <AccountBalanceIcon className="ml-1 adjusticon" />
              </h4>
            </label>
          </div>
          <div className="float-right">
            <SharePage
              source="schedule"
              title={`Schedule of Masses`}
              text={shareText}
              url={`/schedules`}
            />
          </div>
          <div className="clearfix"></div>
          <select
            name="parish"
            id="parish"
            value={selected}
            onChange={handleChange}
            className="form-control form-control-lg custom-select custom-select-lg"
          >
            {options.map((option) => (
              <option key={option._id} value={option._id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <ParishDetails />
      {/* {data.length === 0 && (
        <div className="text-center py-4 mt-4">
          <h3>No Schedule Found</h3>
        </div>
      )} */}
    </div>
  );
}

export default DisplaySchedules;
