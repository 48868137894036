import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAdminEventHubDashboard({ ...rest }) {
  const AsyncLoad = loadable(() => import("../admin/AdminEventHubDashboard"));
  console.log("Loading", AsyncAdminEventHubDashboard.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAdminEventHubDashboard;
