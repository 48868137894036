import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useReducer,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";

import AnalyticsDashboard from "../tab/AnalyticsDashboard";
import RegistrationList from "../tab/RegistrationList";
import TransfersListInt from "../tab/TransfersListInt";
import TransfersListExt from "../tab/TransfersListExt";

import Header from "../../../../common/header";
import { getParishNameById } from "../../../../../services/parish";
import { levelRef } from "../../services/levels";
import {
  guardianRegistrationStatusDictionary,
  // registrationStatusNameToStatus,
} from "../../services/registrationStatus";
import { transfersStatusDictionary } from "../../services/transfersStatus";

import LoadingSpinner from "../../../../common/loadingSpinner";
import ParishPill from "../components/ParishPill";
import LevelPill from "../components/LevelPill";
import StatusPill from "../components/StatusPill";

import fire from "../../../../../services/fire";
import AppGlobalContext from "../../../../../AppGlobalContext";
import {
  RegistrationStatus,
  RegistrationDatum,
  TransfersStatus,
  TransfersDatum,
  ParishConfig,
  ProgrammeConfig,
  TimeslotConfig,
} from "../../hooks/hooks";
import {
  QueriedAdminState,
  QueriedAdminContext,
  RowFiltersContext,
  LookupConfig,
  LookupConfigResult,
  LookupConfigContext,
} from "../hooks/hooks";
import {
  registrationStatusFilterOptions,
  transfersStatusFilterOptions,
} from "../services/tableUtils";
import {
  isSuperAdminRole,
  isCATCHAdminRole,
  isCATCHParishAdminRole,
} from "../../../../roles/service/roles";
import { getCATCHParishAdminMembershipDocs } from "../../../../roles/service/membership";
import {
  registrationCollectionName,
  transfersCollectionName,
  parishesConfigCollectionName,
  catchContactUpdatesCollectionName,
} from "../../services/collections";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Col, Row } from "react-bootstrap";

const getCatchRegistrations = fire
  .functions("asia-east2")
  .httpsCallable("getCatchRegistrations");
const getCatchParishTransfers = fire
  .functions("asia-east2")
  .httpsCallable("getCatchParishTransfers");

function initAllProgrammes(parishesConfig, setQueriedAdminState) {
  const allProgrammes =
    parishesConfig?.flatMap(
      ({ district, id: parishId, parish: parishName, programmes }) =>
        programmes?.map?.((programme) => ({
          ...programme,
          district,
          parishId,
          parishName,
        }))
    ) ?? [];

  setQueriedAdminState((state) => ({ ...state, allProgrammes }));
}

/**
 * Wrapper function to cache the results of rawLookupConfig
 * @param {ParishConfig[]} parishesConfig
 */
function initLookupConfig(parishesConfig) {
  /** @type {Record<string, LookupConfig>}} */
  const configCache = {};

  /**
   * Looks up and returns a specific configuration object based on the provided IDs.
   *
   * @param {string} [parishId] - The ID of the parish to look up.
   * @param {string} [programmeId] - The ID of the programme to look up.
   * @param {string} [timeslotId] - The ID of the timeslot to look up.
   * @return {LookupConfigResult} The configuration object corresponding to the provided IDs, or undefined if not found.
   */
  function rawLookupConfig(parishId, programmeId, timeslotId) {
    const cacheSeparatorToken = ":#%&";
    /** @type {LookupConfigResult} */
    const result = {};

    if (parishId === undefined) return result;

    let cacheKey = parishId;
    if (!configCache[cacheKey])
      configCache[cacheKey] = parishesConfig.find(({ id }) => id === parishId);
    // else console.log(`Cache Hit: ${cacheKey}`);
    result.parish = configCache[cacheKey];
    if (programmeId === undefined) return result;

    cacheKey += `${cacheSeparatorToken}${programmeId}`;
    if (!configCache[cacheKey])
      configCache[cacheKey] = result?.parish?.programmes?.find(
        ({ id }) => id === programmeId
      );
    // else console.log(`Cache Hit: ${cacheKey}`);
    result.programme = configCache[cacheKey];
    if (timeslotId === undefined) return result;

    cacheKey += `${cacheSeparatorToken}${timeslotId}`;
    if (!configCache[cacheKey])
      configCache[cacheKey] = result?.programme?.timeslots?.find(
        ({ id }) => id === timeslotId
      );
    // else console.log(`Cache Hit: ${cacheKey}`);
    result.timeslot = configCache[cacheKey];
    return result;
  }

  return (parishId, programmeId, timeslotId) =>
    rawLookupConfig(parishId, programmeId, timeslotId);
}

/**
 * @template Datum
 * @callback AdminQueryHandler
 * @param {string?} orgId
 * @param {string?} level
 * @param {React.Dispatch<React.SetStateAction<QueriedAdminState>>} setQueriedAdminState
 * @param {firestore.CollectionReference<Datum>} collection
 * @return {() => void} Unsubscribe method
 */

/**
 * @type {AdminQueryHandler<RegistrationDatum>}
 */
async function handleRegistrationQuery(
  setQueriedAdminState,
  user,
  isParishAdmin
  // filterState
) {
  try {
    if (!isParishAdmin) {
      const registrationState = {
        exists: false,
        data: [],
      };

      setQueriedAdminState((state) => ({
        ...state,
        registrationState,
      }));

      return;
    }

    const { data: { ok, data: { registrationQuery = [] } = {} } = {} } =
      await getCatchRegistrations({
        uid: user.uid,
        programmeType: null,
        level: null,
        status: null,
      });

    if (ok) {
      const queriedData = await Promise.all(registrationQuery);

      const registrationState = {
        exists: queriedData.length > 0,
        data: queriedData,
      };

      setQueriedAdminState((state) => ({ ...state, registrationState }));

      return () => {};
    } else {
      const registrationState = {
        exists: false,
        data: [],
      };

      setQueriedAdminState((state) => ({ ...state, registrationState }));

      return () => {};
    }
  } catch (error) {
    // Handle any potential errors here
    console.error("Error fetching data:", error);
    const registrationState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({ ...state, registrationState }));

    return () => {};
  }
}

async function handleAdminRegistrationQuery(
  orgId,
  setQueriedAdminState,
  registrationCollection,
  isAdminCATCH,
  isSuperAdmin
  // filterState
) {
  try {
    if (!isAdminCATCH && !isSuperAdmin) {
      const registrationState = {
        exists: false,
        data: [],
      };

      setQueriedAdminState((state) => ({
        ...state,
        registrationState,
      }));

      return;
    }

    let registrationQuery = registrationCollection;

    // if (orgId !== null) {
    //   registrationQuery = registrationQuery.where(
    //     "selectedParishId",
    //     "==",
    //     orgId
    //   );
    // }

    // if (filterProgramme !== "All") {
    //   const programmeTypeValueArray = filterProgramme.split("-");
    //   registrationQuery = registrationQuery
    //     .where("programmeType", "==", programmeTypeValueArray[0])
    //     .where("selectedParishId", "==", programmeTypeValueArray[1]);
    // }

    // if (filterLevel !== "All") {
    //   registrationQuery = registrationQuery.where("level", "==", filterLevel);
    // }

    // if (filterState.status != null) {
    //   registrationQuery = registrationQuery.where(
    //     "status",
    //     "==",
    //     registrationStatusNameToStatus(filterState.status)
    //   );

    //   if (filterState.status !== "accepted") {
    //     registrationQuery = registrationQuery
    //       .where("registrationType", "!=", "transfer")
    //       .orderBy("registrationType");
    //   }
    // }

    registrationQuery = registrationQuery
      .where(
        "status",
        "in",
        registrationStatusFilterOptions.map(({ value }) => value)
      )
      .orderBy("submittedAt", "desc")
      .orderBy("status");

    const snapshot = await registrationQuery.get();
    const data = snapshot.docs.map((doc) => doc.data());

    const queriedData = await Promise.all(data);

    const registrationState = {
      exists: queriedData.length > 0,
      data: queriedData,
    };

    setQueriedAdminState((state) => ({ ...state, registrationState }));

    // console.log({ parishesConfig, registrationState });

    return () => {};
  } catch (error) {
    // Handle any potential errors here
    console.error("Error fetching data:", error);

    const registrationState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({ ...state, registrationState }));

    return () => {};
  }
}

/**
 * @type {AdminQueryHandler<TransfersDatum>}
 */
async function handleTransfersQuery({
  orgId,
  setQueriedAdminState,
  user,
  isCatchParishAdmin,
}) {
  if (!isCatchParishAdmin) {
    const transfersState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({
      ...state,
      outTransfersState: transfersState,
      inTransfersState: transfersState,
    }));

    return;
  }

  const getCatchParishTransfersData = async (type) => {
    const { data: { ok, data: { transfersQuery = [] } = {} } = {} } =
      await getCatchParishTransfers({
        uid: user.uid,
        isExternal: false,
        type,
        programmeType: null,
        level: null,
      });

    // console.log(type, transfersQuery);

    return ok ? transfersQuery : [];
  };

  const handleTransferData = async (type, stateKey) => {
    const transfersData = await getCatchParishTransfersData(type);

    const transfersState = {
      exists: transfersData.length > 0,
      data: transfersData,
    };

    setQueriedAdminState((state) => ({
      ...state,
      [stateKey]: transfersState,
    }));
  };

  if (orgId !== null) {
    // Is Parish admin
    handleTransferData("out", "outTransfersState");
    handleTransferData("in", "inTransfersState");

    return () => {};
  }
  // else {
  //   setQueriedAdminState((state) => ({
  //     ...state,
  //     inTransfersState: { exists: false, data: [] },
  //   }));
  //   // Is Overall admin
  //   const unsubOut = await handleTransferData("out", "outTransfersState");

  //   return () => {
  //     unsubOut();
  //   };
  // }
}

async function handleAdminTransfersQuery(
  orgId,
  setQueriedAdminState,
  transfersCollection,
  isAdminCATCH,
  isSuperAdmin
) {
  if (!isAdminCATCH && !isSuperAdmin) {
    const transfersState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({
      ...state,
      outTransfersState: transfersState,
      inTransfersState: transfersState,
    }));

    return;
  }

  const getCatchParishTransfersData = async (type) => {
    let transfersQuery;
    transfersQuery = transfersCollection
      .where(
        "status",
        "in",
        transfersStatusFilterOptions.map(({ value }) => value)
      )
      .where("external", "==", false);

    if (transfersQuery) {
      const querySnapshot = await transfersQuery.get();
      const transferData = querySnapshot.docs.map((doc) => {
        return doc.data();
      });

      const filteredTransfers = transferData.filter(
        ({ external = false }) => external !== true
      );

      transfersQuery = filteredTransfers;
    }

    return transfersQuery ?? [];
  };

  const handleTransferData = async (type, stateKey) => {
    const transfersData = await getCatchParishTransfersData(type);

    const transfersState = {
      exists: transfersData.length > 0,
      data: transfersData,
    };

    setQueriedAdminState((state) => ({
      ...state,
      [stateKey]: transfersState,
    }));
  };

  if (orgId !== null) {
    // Is Parish admin
    const unsubOut = await handleTransferData("out", "outTransfersState");
    const unsubIn = await handleTransferData("in", "inTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  } else {
    setQueriedAdminState((state) => ({
      ...state,
      inTransfersState: { exists: false, data: [] },
    }));
    // Is Overall admin
    const unsubOut = await handleTransferData("out", "outTransfersState");

    return () => {
      unsubOut();
    };
  }
}

/**
 * @type {AdminQueryHandler<TransfersDatum>}
 */
async function handleExternalTransfersQuery({
  orgId,
  setQueriedAdminState,
  user,
  isCatchParishAdmin,
}) {
  if (!isCatchParishAdmin) {
    const transfersState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({
      ...state,
      outExternalTransfersState: transfersState,
      inExternalTransfersState: transfersState,
    }));

    return;
  }

  const getCatchParishTransfersData = async (type) => {
    const { data: { ok, data: { transfersQuery = [] } = {} } = {} } =
      await getCatchParishTransfers({
        uid: user.uid,
        isExternal: true,
        type,
        programmeType: null,
        level: null,
      });

    return ok ? transfersQuery : [];
  };

  const handleTransferData = async (type, stateKey) => {
    const transfersData = await getCatchParishTransfersData(type);

    const transfersState = {
      exists: transfersData.length > 0,
      data: transfersData,
    };

    setQueriedAdminState((state) => ({
      ...state,
      [stateKey]: transfersState,
    }));
  };

  if (orgId !== null) {
    // Is Parish admin
    const unsubOut = await handleTransferData(
      "out",
      "outExternalTransfersState"
    );
    const unsubIn = await handleTransferData("in", "inExternalTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  } else {
    // Is Overall admin
    const unsubOut = await handleTransferData(
      "out",
      "outExternalTransfersState"
    );
    const unsubIn = await handleTransferData("in", "inExternalTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  }
}

async function handleAdminExternalTransfersQuery(
  orgId,
  setQueriedAdminState,
  transfersCollection,
  isAdminCATCH,
  isSuperAdmin
) {
  if (!isAdminCATCH && !isSuperAdmin) {
    const transfersState = {
      exists: false,
      data: [],
    };

    setQueriedAdminState((state) => ({
      ...state,
      outTransfersState: transfersState,
      inTransfersState: transfersState,
    }));

    return;
  }

  const getCatchParishTransfersData = async (type) => {
    let transfersQuery;

    transfersQuery = transfersCollection.where("external", "==", type);

    if (transfersQuery) {
      const querySnapshot = await transfersQuery.get();
      transfersQuery = querySnapshot.docs.map((doc) => {
        return doc.data();
      });
    }

    return transfersQuery ?? [];
  };

  const handleTransferData = async (type, stateKey) => {
    let relevantStatuses = [];
    switch (type) {
      case "out":
        relevantStatuses = ["pendingOut", "rejectedOut"];
        break;
      case "in":
        relevantStatuses = ["pendingIn", "rejectedIn", "accepted"];
        break;
    }

    const transfersData = (await getCatchParishTransfersData(type)).filter(
      ({ status }) => relevantStatuses.includes(status)
    );

    // console.log({ processedData });

    const transfersState = {
      exists: transfersData.length > 0,
      data: transfersData,
    };

    setQueriedAdminState((state) => ({
      ...state,
      [stateKey]: transfersState,
    }));
  };

  if (orgId !== null) {
    // Is Parish admin
    const unsubOut = await handleTransferData(
      "out",
      "outExternalTransfersState"
    );
    const unsubIn = await handleTransferData("in", "inExternalTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  } else {
    // Is Overall admin
    const unsubOut = await handleTransferData(
      "out",
      "outExternalTransfersState"
    );
    const unsubIn = await handleTransferData("in", "inExternalTransfersState");

    return () => {
      unsubOut();
      unsubIn();
    };
  }
}

/**
 * @param {string} parishCollection
 * @returns {Promise<ParishConfig[]>}
 */
async function handleParishesConfigQuery(parishCollection) {
  const docSnapshot = await parishCollection.get();
  const parishesConfig = docSnapshot.docs.map((doc) => doc.data());
  return parishesConfig;
}

async function handleContactUpdatesQuery(user, isParishAdmin) {
  if (!isParishAdmin) {
    return;
  }

  // const { data: { ok, data: { contactUpdatesQuery = [] } = {} } = {} } =
  //   await getCatchContactUpdates({
  //     uid: user.uid,
  //   });

  // if (ok) {
  //   // console.log(contactUpdatesQuery);
  // }

  return () => {};
}

async function handleAdminContactUpdatesQuery(
  orgId,
  catchContactUpdatesCollection,
  isAdminCATCH,
  isSuperAdmin
) {
  if (!isAdminCATCH && !isSuperAdmin) {
    return;
  }

  let contactUpdatesQuery = catchContactUpdatesCollection;

  if (orgId !== null) {
    contactUpdatesQuery = contactUpdatesQuery.where(
      "selectedParishId",
      "==",
      orgId
    );
  }

  // const snapshot = await contactUpdatesQuery.get();
  // const catchContactUpdatesData = snapshot.docs.map((doc) => doc.data());

  // console.log(catchContactUpdatesData);

  return () => {};
}

export default function AdminMenu() {
  // const tableFilterPredicate = ({ selectedParishId, programmeType, level }) => {
  //   const programme =
  //     typeof programmeType === "object" ? programmeType.id : programmeType;
  //   return (
  //     selectedParishId === tableFilters.parish &&
  //     programme === tableFilters.programme &&
  //     level === tableFilters.level
  //   );
  // };
  const [isLoadingAdminData, setIsLoadingAdminData] = useState(false);
  const { user } = useContext(AppGlobalContext);
  const history = useHistory();
  // console.log("Render layout");

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const transfersCollection = db.collection(transfersCollectionName);
  const parishCollection = db.collection(parishesConfigCollectionName);
  const catchContactUpdatesCollection = db.collection(
    catchContactUpdatesCollectionName
  );

  /** @type {[QueriedAdminState, React.Dispatch<React.SetStateAction<QueriedAdminState>>]} */
  const [queriedAdminState, setQueriedAdminState] = useState({
    registrationState: null,
    outTransfersState: null,
    inTransfersState: null,
    outExternalTransfersState: null,
    inExternalTransfersState: null,
    membershipState: null,
    rerender: () => setQueriedAdminState((state) => ({ ...state })),
  });
  // useEffect(() => {
  //   console.log(queriedAdminState);
  // }, [queriedAdminState]);

  const [rowFilters, setRowFilters] = useState({
    status: "",
    parish: "",
    programme: "",
    level: "",
  });
  const [filterProgrammeName, setFilterProgrammeName] = useState("");

  /** @type {[LookupConfig | undefined, React.Dispatch<React.SetStateAction<LookupConfig | undefined>>]} */
  const [lookupConfig, setLookupConfig] = useState();
  const [memberOrgs, setMemberOrgs] = useState([]);

  /**
   * @template {string} Status
   * @typedef FilterOptions
   * @prop {Status} name
   * @prop {number | Status} value
   */
  /**
   * @template {string} Status
   * @typedef TabState
   * @prop {FilterOptions<Status>} options
   * @prop {import("../../hooks/hooks").StatusDictionary<Status>} dictionary
   */
  /**
   * @template {string} Status
   * @type {TabState<Status>}
   */
  const initialTabState = {
    tab: "dashboard",
    options: registrationStatusFilterOptions,
    dictionary: guardianRegistrationStatusDictionary,
  };
  function activeTabStateReducer(state, action) {
    const initialGroup = ["dashboard", "registrations"].includes(state.tab);
    const finalGroup = ["dashboard", "registrations"].includes(action);
    if (initialGroup !== finalGroup)
      setRowFilters((old) => ({ ...old, status: "" }));

    switch (action) {
      case "dashboard":
        return {
          tab: "dashboard",
          options: registrationStatusFilterOptions,
          dictionary: guardianRegistrationStatusDictionary,
        };
      case "registrations":
        return {
          tab: "registrations",
          options: registrationStatusFilterOptions,
          dictionary: guardianRegistrationStatusDictionary,
        };
      case "inttransfers":
        return {
          tab: "inttransfers",
          options: transfersStatusFilterOptions,
          dictionary: transfersStatusDictionary,
        };
      case "exttransfers":
        return {
          tab: "exttransfers",
          options: transfersStatusFilterOptions,
          dictionary: transfersStatusDictionary,
        };
      default:
        return { ...state };
    }
  }
  const [activeTabState, activeTabStateDispatch] = useReducer(
    activeTabStateReducer,
    initialTabState
  );

  const unsubUser = useRef(() => {});

  useEffect(() => {
    unsubUser.current();

    getCATCHParishAdminMembershipDocs(user.uid).then(async (membershipDocs) => {
      const unsubMethods = [];
      // console.log({ user, docs: membershipDocs });

      const isSuperAdmin = isSuperAdminRole(user);
      const isCatchOverallAdmin = isCATCHAdminRole(user);
      const isCatchParishAdmin = isCATCHParishAdminRole(user);

      setMemberOrgs(membershipDocs.map(({ orgId }) => orgId));

      if (
        isSuperAdmin ||
        isCatchOverallAdmin ||
        (isCatchParishAdmin && membershipDocs.length > 0)
      ) {
        setIsLoadingAdminData(true);

        const orgId = isCatchParishAdmin ? membershipDocs[0].orgId : null;
        const membershipState = isCatchParishAdmin
          ? { exists: true, datum: membershipDocs[0] }
          : { exists: false, datum: null };

        setQueriedAdminState((state) => ({
          ...state,
          membershipState,
        }));

        const parishesConfig = await handleParishesConfigQuery(
          parishCollection
        );

        setLookupConfig(() => initLookupConfig(parishesConfig));

        initAllProgrammes(parishesConfig, setQueriedAdminState);

        if (isSuperAdmin || isCatchOverallAdmin) {
          unsubMethods.push(
            handleAdminRegistrationQuery(
              orgId,
              setQueriedAdminState,
              registrationCollection,
              isCatchOverallAdmin,
              isSuperAdmin
              // registrationFilters
            )
          );
          unsubMethods.push(
            handleAdminTransfersQuery(
              orgId,
              setQueriedAdminState,
              transfersCollection,
              isCatchOverallAdmin,
              isSuperAdmin
            )
          );
          unsubMethods.push(
            handleAdminExternalTransfersQuery(
              orgId,
              setQueriedAdminState,
              transfersCollection,
              isCatchOverallAdmin,
              isSuperAdmin
            )
          );
          unsubMethods.push(
            handleAdminContactUpdatesQuery(
              orgId,
              catchContactUpdatesCollection,
              isCatchOverallAdmin,
              isSuperAdmin
            )
          );
        } else {
          unsubMethods.push(
            handleRegistrationQuery(
              setQueriedAdminState,
              user,
              isCatchParishAdmin
              // registrationFilters
            )
          );
          unsubMethods.push(
            handleTransfersQuery({
              orgId,
              setQueriedAdminState,
              user,
              isCatchParishAdmin,
            })
          );
          unsubMethods.push(
            handleExternalTransfersQuery({
              orgId,
              setQueriedAdminState,
              user,
              isCatchParishAdmin,
            })
          );
          unsubMethods.push(
            handleContactUpdatesQuery(user, isCatchParishAdmin)
          );
        }

        setIsLoadingAdminData(false);
      } else {
        history.push("/");
      }

      unsubUser.current = unsubMethods
        .filter((method) => typeof method === "function")
        .forEach((method) => method());
    });

    return unsubUser.current;
  }, [user]);

  if (
    !lookupConfig ||
    Object.values(queriedAdminState).some((state) => state === null)
  )
    return <LoadingSpinner />;

  return (
    <>
      <Header type="backandlogo" smallpic={user.photoURL} />
      <div className="container mt-4">
        <div className="row">
          <Col md={6}>
            <h2 className="mb-2">CATCH Admin Menu</h2>
            <h5 className="mb-2 text-muted">
              {queriedAdminState.membershipState.exists
                ? getParishNameById(
                    queriedAdminState.membershipState.datum.orgId
                  )
                : "All Parishes"}
            </h5>
          </Col>
          <Col md={6} className="d-flex flex-column" style={{ gap: "0.5em" }}>
            <Row>
              <Col
                sm={4}
                className="text-left text-sm-right font-weight-bold m-auto"
              >
                Programme
              </Col>
              <Col sm={8}>
                <Dropdown
                  onSelect={(eventKey) => {
                    const { parish, programme, programmeName } =
                      JSON.parse(eventKey);
                    setRowFilters((old) => ({
                      ...old,
                      parish,
                      programme,
                    }));
                    setFilterProgrammeName(programmeName);
                  }}
                >
                  <Dropdown.Toggle
                    className="w-100 d-flex justify-content-between"
                    variant="outline-secondary"
                    id="programmefilter-dropdown-button"
                    disabled={isLoadingAdminData}
                  >
                    <span>
                      {rowFilters.parish === "" &&
                      rowFilters.programme === "" ? (
                        "All Programmes"
                      ) : (
                        <>
                          {filterProgrammeName}{" "}
                          <ParishPill
                            parish={lookupConfig(rowFilters.parish)?.parish}
                          />
                        </>
                      )}
                    </span>
                    <ArrowDropDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="w-100"
                    style={{ maxHeight: "75vh", overflowY: "scroll" }}
                  >
                    <Dropdown.Item
                      eventKey={JSON.stringify({
                        parish: "",
                        programme: "",
                      })}
                      active={
                        rowFilters.parish === "" && rowFilters.programme === ""
                      }
                    >
                      All Programmes
                    </Dropdown.Item>
                    {Object.entries(
                      Object.groupBy(
                        queriedAdminState.allProgrammes?.filter(
                          ({ parishId }) =>
                            !(
                              isCATCHParishAdminRole(user) &&
                              !memberOrgs.includes(parishId)
                            )
                        ) ?? [],
                        ({ parishId }) => parishId
                      )
                    ).map(([parishId, programmes]) => (
                      <React.Fragment
                        key={`programmefilter-subheader-${parishId}`}
                      >
                        <Dropdown.Divider />
                        <Dropdown.Header>
                          <h6 className="m-0">
                            <ParishPill
                              parish={lookupConfig(parishId)?.parish}
                            />
                          </h6>
                        </Dropdown.Header>
                        {programmes.map((programme) => (
                          <Dropdown.Item
                            eventKey={JSON.stringify({
                              parish: programme.parishId,
                              programme: programme.id,
                              programmeName: programme.name,
                            })}
                            active={
                              rowFilters.parish === programme.parishId &&
                              rowFilters.programme === programme.id
                            }
                            key={`programmefilter-select-${programme.parishId}-${programme.id}`}
                          >
                            {programme.name}
                          </Dropdown.Item>
                        ))}
                      </React.Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col
                sm={4}
                className="text-left text-sm-right font-weight-bold m-auto"
              >
                Level
              </Col>
              <Col sm={8}>
                <Dropdown
                  onSelect={(eventKey) => {
                    setRowFilters((old) => ({
                      ...old,
                      level: eventKey,
                    }));
                  }}
                >
                  <Dropdown.Toggle
                    className="w-100 d-flex justify-content-between"
                    variant="outline-secondary"
                    id="levelfilter-dropdown-button"
                    disabled={isLoadingAdminData}
                  >
                    <span>
                      {rowFilters.level === "" ? (
                        "All Levels"
                      ) : (
                        <LevelPill id={+rowFilters.level} />
                      )}
                    </span>
                    <ArrowDropDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="w-100"
                    style={{ maxHeight: "75vh", overflowY: "scroll" }}
                  >
                    <Dropdown.Item eventKey="" active={rowFilters.level === ""}>
                      All Levels
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {levelRef.map(({ id }) => (
                      <Dropdown.Item
                        key={`levelfilter-select-${id}`}
                        eventKey={id}
                        active={+rowFilters.level === id}
                      >
                        <LevelPill id={+id} />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col
                sm={4}
                className="text-left text-sm-right font-weight-bold m-auto"
              >
                Status
              </Col>
              <Col sm={8}>
                <Dropdown
                  onSelect={(eventKey) => {
                    setRowFilters((old) => ({
                      ...old,
                      status: eventKey,
                    }));
                  }}
                >
                  <Dropdown.Toggle
                    className="w-100 d-flex justify-content-between"
                    variant="outline-secondary"
                    id="statusfilter-dropdown-button"
                    disabled={isLoadingAdminData}
                  >
                    <span>
                      {rowFilters.status === "" ? (
                        "All Statuses"
                      ) : (
                        <StatusPill
                          statusDictionary={activeTabState.dictionary}
                          name={
                            activeTabState.options.find(
                              ({ value }) =>
                                rowFilters.status === value.toString()
                            )?.name
                          }
                          value={rowFilters.status}
                        />
                      )}
                    </span>
                    <ArrowDropDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="w-100"
                    style={{ maxHeight: "75vh", overflowY: "scroll" }}
                  >
                    <Dropdown.Item
                      eventKey=""
                      active={rowFilters.status === ""}
                    >
                      All Statuses
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {activeTabState.options.map(({ name, value }) => (
                      <Dropdown.Item
                        key={`statusfilter-select-${value}`}
                        eventKey={value}
                        active={rowFilters.status === value.toString()}
                      >
                        <StatusPill
                          statusDictionary={activeTabState.dictionary}
                          name={name}
                          value={value}
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </div>

        <LookupConfigContext.Provider value={lookupConfig}>
          <QueriedAdminContext.Provider value={queriedAdminState}>
            <RowFiltersContext.Provider value={rowFilters}>
              <Tabs
                defaultActiveKey="dashboard"
                id="admin-panel-tabs"
                justify
                className="my-4"
                onSelect={(key) => {
                  activeTabStateDispatch(key);
                }}
              >
                <Tab
                  eventKey="dashboard"
                  title={<h4 className="my-0">Overview</h4>}
                >
                  <AnalyticsDashboard />
                </Tab>
                <Tab
                  eventKey="registrations"
                  title={<h4 className="my-0">Registrations</h4>}
                >
                  <RegistrationList />
                </Tab>
                <Tab
                  eventKey="inttransfers"
                  title={
                    <h4
                      className="my-0 d-flex justify-content-center align-items-center"
                      style={{ gap: "0.25em" }}
                    >
                      Transfers{" "}
                      <Badge variant="primary" className="h-100">
                        CATCH
                      </Badge>
                    </h4>
                  }
                >
                  <TransfersListInt />
                </Tab>
                <Tab
                  eventKey="exttransfers"
                  title={
                    <h4
                      className="my-0 d-flex justify-content-center align-items-center"
                      style={{ gap: "0.25em" }}
                    >
                      Transfers{" "}
                      <Badge variant="secondary" className="h-100">
                        External
                      </Badge>
                    </h4>
                  }
                >
                  <TransfersListExt />
                </Tab>
              </Tabs>
            </RowFiltersContext.Provider>
          </QueriedAdminContext.Provider>
        </LookupConfigContext.Provider>
      </div>
    </>
  );
}
