import React from "react";
import Container from "react-bootstrap/Container";
import { useParishesConfig, useRegistrationDetails } from "../../hooks/hooks";

export default function EnquiryEmail({ enquiryEmail }) {
  const registrationDetails = useRegistrationDetails();
  const parishesConfig = useParishesConfig();

  if (!enquiryEmail) {
    const savedParish = parishesConfig?.find(
      ({ id }) => id == registrationDetails.selectedParishId
    );
    const savedProgramme = savedParish?.programmes?.find(
      ({ id }) => id == registrationDetails.programmeType
    );
    enquiryEmail = savedProgramme?.enquiryEmail;
  }

  if (!enquiryEmail) return <></>;

  return (
    <Container className="px-0 mt-4 text-wrap text-center">
      If you have enquiries, please contact your Programme Coordinator at:{" "}
      <a href={`mailto:${enquiryEmail}`}>{enquiryEmail}</a>.
    </Container>
  );
}
