import React from "react";

function AgreeTerms() {
  return (
    <span className="text-left pointer">
      I consent to the{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.catholic.sg/terms/"
      >
        Terms and Conditions
      </a>{" "}
      governing the use of myCatholicSG, a Digital Church initiative by the
      Roman Catholic Archdiocese of Singapore to connect, empower, and grow our
      Catholic community.
    </span>
  );
}

export default AgreeTerms;
