import React from "react";
import Badge from "react-bootstrap/Badge";

import { levelIdToName } from "../../services/levels";

/**
 * @typedef LevelPillProps
 * @prop {number} id
 */

/**
 * @param {LevelPillProps} props
 */
export default function LevelPill({ id }) {
  try {
    const name = levelIdToName(id);
    const variant =
      id < 5
        ? "info"
        : id < 7
        ? "success"
        : id < 10
        ? "primary"
        : id < 13
        ? "danger"
        : id < 16
        ? "secondary"
        : "dark";

    return <Badge variant={variant}>{name}</Badge>;
  } catch (e) {
    // console.log({ level: id });
    throw e;
  }
}
