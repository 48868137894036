import { getSchedule } from "../../../services/schedule";
import { createScheduleObj, prepareInput } from "../../../utils/schedule";

export async function getSchedulesFromDB(parishCode, sorted = true, days = 40, date = new Date()) {
  const schedule = await getSchedule(parishCode);
  let obj = null;
  let type = [];
  if (schedule?.schedule) {
    const input = prepareInput(schedule?.schedule);
    obj = createScheduleObj(input, false, date, date, days);

    const now = date.getTime();
    //disregard past events
    const future = obj.filter((i) => i.start > now);
    obj = future;
    if (sorted) {
      obj.sort((a, b) => a.start - b.start);
    }
    type = [...new Set(obj.map((i) => i.type))];
  }
  return [obj, type];
}
