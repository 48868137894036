import React, { useState } from "react";
import Select from "../../common/select";
import { Button } from "react-bootstrap";
import { updateCatholicStatus, userLogout } from "../../../services/user";
import SpinnerText from "../../common/spinnerwithText";

const INIT_OPTION = "Select an option";

function AskCatholic({ user }) {
  const [value, setValue] = useState(INIT_OPTION);
  const [error, setError] = useState("");
  const [updating, setUpdating] = useState(false);

  const handleChange = (e) => {
    if (e.target.value?.trim() === "") {
      setError("Please select an option");
    } else {
      setError("");
    }
    setValue(e.target.value);
  };

  const handleClick = async () => {
    //update
    setUpdating(true);
    await updateCatholicStatus(user?.uid, value === "Yes" ? true : false);
    window.location.reload();
  };

  return (
    <div className="pt-4 mt-4 row justify-content-center mx-auto">
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4">
            <h3 className="mb-4">Are you a Catholic?</h3>
            <Select
              init={INIT_OPTION}
              name="catholic"
              value={value}
              label=""
              options={[
                { _id: "Yes", name: "Yes" },
                { _id: "No", name: "No" },
              ]}
              onChange={handleChange}
              error={error}
            />
            {!updating ? (
              value?.trim() !== INIT_OPTION ? (
                <Button
                  variant="primary"
                  className="btn btn-lg my-4"
                  onClick={handleClick}
                >
                  Submit
                </Button>
              ) : (
                <Button disabled variant="primary" className="btn btn-lg my-4">
                  Submit
                </Button>
              )
            ) : (
              <Button disabled variant="primary" className="btn btn-lg my-4">
                <SpinnerText text="Please wait..." />
              </Button>
            )}
            <Button
              variant="link"
              className="btn-block btn-lg mt-5 mb-0"
              onClick={async () => {
                await userLogout();
                window.location.reload();
              }}
            >
              <span className="">Logout</span>
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default AskCatholic;
