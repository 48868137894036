import React, { useEffect, useState } from "react";
import Header from "./header";
import { getRandomWaitMessage } from "../../utils/waitmessage";

const LoadingSpinnerNav = (props) => {
  const { type, user, disablecontrol, ...rest } = props;
  const [message, setMessage] = useState(getRandomWaitMessage());

  useEffect(() => {
    const timer = setInterval(() => {
      setMessage(getRandomWaitMessage());
    }, 4000);

    // Cleanup function to clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, []);

  return (
    <React.Fragment>
      <Header
        type={type}
        user={user}
        disablecontrol={disablecontrol}
        {...rest}
      />

      <div
        style={{
          position: "absolute",
          width: "200px",
          height: "200px",
          left: "50%",
          top: "50%",
          marginLeft: "-100px",
          marginTop: "-100px",
          zIndex: "1000",
        }}
      >
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="">{message}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoadingSpinnerNav;
