import React from "react";

export default function BaptismIcon({ color, size }) {
  return (
    <svg
      viewBox="0 0 32 32"
      x="0px"
      y="0px"
      version="1.1"
      id="svg227"
      fill={color}
      width={size}
      height={size}
    >
      <defs id="defs231" />
      <g id="g221">
        <path
          d="M 26.92194,18.15509 C 26.43024,8.08057 16,2.15924 16,2.15924 16.03455,1.916 4.73206,9.10175 5.05713,18.89783 A 10.945,10.945 0 1 0 26.92194,18.15509 Z M 16,26.28027 a 7.39046,7.39046 0 0 1 -7.38184,-7.38281 1,1 0 0 1 2,0 5.38849,5.38849 0 0 0 5.38184,5.38281 1.00011,1.00011 0 0 1 0,2 z"
          id="path219"
        />
      </g>
    </svg>
  );
}
