import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import fire from "../../../../services/fire";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import Select from "react-select";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import ExpandMoreOutlined from "@material-ui/icons/ExpandMoreOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import "../css/index.css";

import { useForm, Controller } from "react-hook-form";

import Header from "../../../common/header";

import AppGlobalContext from "../../../../AppGlobalContext";
import {
  useActiveRegistration,
  useGuardian,
  useParishesConfig,
  usePendingTransfer,
  useWardSelection,
} from "../hooks/hooks";

import {
  registrationCollectionName,
  transfersCollectionName,
} from "../services/collections";
import { sortedDistricts } from "../services/parishes";
import { isValidPage } from "../services/registrationStatus";

import ForbiddenRoute from "../components/ForbiddenRoute";

/**
 * @param {firebase.firestore.Firestore} db
 * @param {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>} registrationCollection
 * @param {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>} transfersCollection
 */
async function handleSaveTransferDetails(
  db,
  registrationCollection,
  transfersCollection,
  transferData,
  registrationDetails,
  fromParishDetails,
  toParishDetails,
  ward,
  uid,
  callBack
) {
  try {
    if (!ward.uid || !uid) {
      return;
    }

    let programmeType = "",
      timeslot = "",
      savedProgramme;

    if (registrationDetails.programmeType) {
      if (typeof registrationDetails.programmeType === "string") {
        savedProgramme = fromParishDetails?.programmes?.find(
          ({ id }) => id == registrationDetails.programmeType
        );

        if (!!savedProgramme) {
          programmeType = JSON.parse(JSON.stringify(savedProgramme));
        }
      } else if (typeof registrationDetails.programmeType === "object") {
        programmeType = registrationDetails.programmeType;
      }
    }

    if (registrationDetails.timeslot) {
      let savedTimeslot;

      if (typeof registrationDetails.programmeType !== "string") {
        savedTimeslot = registrationDetails?.programmeType?.timeslots?.find(
          ({ id, name }) =>
            id == registrationDetails.timeslot ||
            name == registrationDetails.timeslot
        );
      } else if (!!savedProgramme) {
        savedTimeslot = savedProgramme?.timeslots?.find(
          ({ id, name }) =>
            id == registrationDetails.timeslot ||
            name == registrationDetails.timeslot
        );
      }

      if (!!savedTimeslot) {
        timeslot = savedTimeslot?.id;
      }
    }

    const transferDoc = transfersCollection.doc();

    const docData = {
      id: transferDoc.id,
      submittedAt: new Date(),
      submittedBy: uid,
      child: {
        name: ward.name,
        uid: ward.uid,
      },
      registration: registrationDetails,
      status: "pendingOut",
      from: {
        inSingapore: true,
        id: registrationDetails.selectedParishId || "",
        programmeId: programmeType?.id || "",
        timeslotId: timeslot || "",
        isActive:
          !!fromParishDetails && typeof fromParishDetails.isActive === "boolean"
            ? fromParishDetails.isActive
            : "",
        hasCatechesis:
          !!fromParishDetails &&
          typeof fromParishDetails.hasCatechesis === "boolean"
            ? fromParishDetails.hasCatechesis
            : "",
        isCatchParish:
          !!fromParishDetails &&
          typeof fromParishDetails.isCatchParish === "boolean"
            ? fromParishDetails.isCatchParish
            : "",
      },
      to: {
        inSingapore: true,
        id: transferData.selectedParishId || "",
        programmeId:
          !!toParishDetails && toParishDetails.programmes?.length
            ? transferData.programmeType?.id
            : "",
        timeslotId:
          !!toParishDetails && toParishDetails.programmes?.length
            ? transferData.timeslot
            : "",
        isActive:
          !!toParishDetails && typeof toParishDetails.isActive === "boolean"
            ? toParishDetails.isActive
            : "",
        hasCatechesis:
          !!toParishDetails &&
          typeof toParishDetails.hasCatechesis === "boolean"
            ? toParishDetails.hasCatechesis
            : "",
        isCatchParish:
          !!toParishDetails &&
          typeof toParishDetails.isCatchParish === "boolean"
            ? toParishDetails.isCatchParish
            : "",
      },
      outsideBoundaryReason: transferData.outsideBoundaryReason || "",
      transferReason: transferData.transferReason || "",
      external:
        (!!toParishDetails && typeof toParishDetails.isCatchParish === "boolean"
          ? !toParishDetails.isCatchParish
          : true) && "out",
    };

    // console.log({ docData });
    const registrationDoc = registrationCollection.doc(registrationDetails.id);

    const batch = db.batch();

    batch.set(transferDoc, docData);
    batch.update(registrationDoc, { transfer: { id: transferDoc.id } });

    await batch.commit();

    if (typeof callBack === "function") {
      await callBack();
    }

    window.scrollTo(0, 0);
  } catch (error) {
    console.error("handleSaveTransferDetails::error:", error.message);
  }
}

function ParishOfInterest({ user, ward, registrationDetails = {} }) {
  const history = useHistory();
  const parishesConfig = useParishesConfig();
  const pendingTransferState = usePendingTransfer();

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const transfersCollection = db.collection(transfersCollectionName);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    // reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedParishId: "",
      outsideBoundaryReason: "",
      programmeType: "",
      timeslot: "",
      transferReason: "",
    },
  });

  const selectedParishId = watch("selectedParishId"); // Hooked, will trigger rerender when changed
  const selectedParish = parishesConfig.find(
    ({ id }) => id == selectedParishId
  );

  const catchParishes = parishesConfig.filter(
    ({ isActive, hasCatechesis, id }) =>
      isActive && hasCatechesis && id != registrationDetails.selectedParishId
  );

  const boundary = registrationDetails.boundary;

  let homeParish = "";

  if (boundary) {
    const boundayParish = parishesConfig.find(({ id }) => id == boundary);

    if (boundayParish) {
      homeParish = boundayParish.parish;
    }
  }

  const [selectParishCount, setSelectParishCount] = useState(0);

  useEffect(() => {
    if (selectParishCount > 1) {
      setValue("outsideBoundaryReason", "");
    } else {
      setSelectParishCount(selectParishCount + 1);
    }

    return () => {};
  }, [selectedParishId]);

  const callBack = useCallback(
    () =>
      history.push({
        pathname: "/catch",
        transferSuccess: true,
      }),
    [history]
  );

  function onSubmit() {
    const transferData = getValues();

    const fromParishDetails = parishesConfig.find(
      ({ id }) => id == registrationDetails.selectedParishId
    );
    const toParishDetails = parishesConfig.find(
      ({ id }) => id == transferData.selectedParishId
    );

    handleSaveTransferDetails(
      db,
      registrationCollection,
      transfersCollection,
      transferData,
      registrationDetails,
      fromParishDetails,
      toParishDetails,
      ward,
      user.uid,
      callBack
    );
  }

  const outsideBoundaryReason = watch("outsideBoundaryReason");
  const programmeType = watch("programmeType");
  const transferReason = watch("transferReason");

  const customOptions = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <Container ref={innerRef} {...innerProps}>
        <Row className="px-3 pt-2">{props.data.label}</Row>
        <Row className="px-3 pb-2 text-muted" style={{ fontSize: "16px" }}>
          {props.data.caption}
        </Row>
      </Container>
    );
  };

  const typeOptions = selectedParish ? selectedParish.programmes : [];

  let isOutsideBoundary = !!selectedParishId && +selectedParishId !== boundary;

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  function onWithdraw() {
    transfersCollection
      .doc(pendingTransferState.data.id)
      .update({
        status: "withdrawn",
        withdrawnAt: new Date(),
        withdrawnBy: user.uid,
      })
      .then(() => {
        setShowWithdrawModal(false);
      })
      .catch((error) => {
        console.error("onWithdraw::error:", error);

        alert("An error has occured");

        setShowWithdrawModal(false);
      });
  }

  if (pendingTransferState.exists) {
    if (pendingTransferState.data.status.startsWith("pending")) {
      const {
        from = {},
        to = {},
        transferReason = "",
      } = pendingTransferState.data;

      const fromParish = parishesConfig.find(({ id }) => id == from.id);
      const toParish = parishesConfig.find(({ id }) => id == to.id);

      let fromTimeslot = from.timeslotId || "",
        toTimeslot = to.timeslotId || "",
        savedFromProgramme,
        savedToProgramme;

      if (from.programmeId) {
        if (typeof from.programmeId === "string") {
          savedFromProgramme = fromParish?.programmes?.find(
            ({ id }) => id == from.programmeId
          );
        } else {
          savedFromProgramme = from.programmeId;
        }
      }

      if (to.programmeId) {
        if (typeof to.programmeId === "string") {
          savedToProgramme = toParish?.programmes?.find(
            ({ id }) => id == to.programmeId
          );
        } else {
          savedToProgramme = to.programmeId;
        }
      }

      if (!!savedFromProgramme && from.timeslotId) {
        const fromTimeslotObj = savedFromProgramme?.timeslots?.find(
          ({ id, name }) => id == from.timeslotId || name == from.timeslotId
        );

        if (!!fromTimeslotObj) {
          fromTimeslot = fromTimeslotObj?.name;
        }
      }

      if (!!savedToProgramme && to.timeslotId) {
        const toTimeslotObj = savedToProgramme?.timeslots?.find(
          ({ id, name }) => id == to.timeslotId || name == to.timeslotId
        );

        if (!!toTimeslotObj) {
          toTimeslot = toTimeslotObj?.name;
        }
      }

      return (
        <>
          <br />
          <Row className="w-100 justify-content-center">
            <h5>You already have a pending transfer!</h5>
          </Row>
          <div>
            <Card className="text-center">
              <Card.Header className="text-secondary">
                <h5 className="my-0">{fromParish?.parish}</h5>
              </Card.Header>
              <Card.Body>
                <p className="mb-0 font-weight-bold">
                  {savedFromProgramme?.name ?? <i>Unknown programme</i>}
                </p>
                <p className="mb-0">{fromTimeslot}</p>
              </Card.Body>
            </Card>
          </div>
          <div className="d-flex justify-content-center align-items-center my-3">
            <ArrowDownwardIcon />
            <span className="font-weight-bold font-italic mediumfontsize">
              {transferReason}
            </span>
          </div>
          <div>
            <Card className="text-center">
              <Card.Header className="text-secondary">
                <h5 className="my-0">{toParish?.parish}</h5>
              </Card.Header>
              <Card.Body>
                <p className="mb-0 font-weight-bold">
                  {savedToProgramme?.name ?? <i>Unknown programme</i>}
                </p>
                <p className="mb-0">{toTimeslot}</p>
              </Card.Body>
            </Card>
          </div>
          <Container>
            <Row>
              <Col>
                <Link to="/catch">
                  <Button className="my-3 w-100" variant="secondary" size="lg">
                    Back to Menu
                  </Button>
                </Link>
              </Col>
              <Col>
                <Button
                  className="my-3 w-100"
                  variant="danger"
                  size="lg"
                  onClick={() => setShowWithdrawModal(true)}
                >
                  Withdraw Request
                </Button>
              </Col>
            </Row>
          </Container>
          <Modal show={showWithdrawModal} centered>
            <Modal.Header>
              <Modal.Title>Confirm Withdraw</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure? This action cannot be undone!</Modal.Body>
            <Modal.Footer>
              <Button
                size="lg"
                variant="secondary"
                onClick={() => setShowWithdrawModal(false)}
              >
                Cancel
              </Button>
              <Link to="/catch">
                <Button size="lg" variant="danger" onClick={onWithdraw}>
                  Confirm
                </Button>
              </Link>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }

  let showFields = true;

  if (!!selectedParish) {
    if (!selectedParish.isCatchParish) {
      showFields = false;
    }
  }

  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Parish of Interest</h3>
            Based on your address, your home parish is{" "}
            <span className="font-weight-bold">{homeParish}</span>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Which parish are you applying to?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    {...register("selectedParishId", { required: true })}
                    isInvalid={!!errors.selectedParishId}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {sortedDistricts.map(([districtId, districtName]) => (
                      <optgroup key={districtId} label={districtName}>
                        {catchParishes
                          .filter(
                            ({ district }) =>
                              district.charAt(0).toUpperCase() +
                                district.slice(1) ===
                              districtName
                          )
                          .map(({ id, parish }) => (
                            <option
                              key={parish}
                              value={id}
                              disabled={
                                id == registrationDetails.selectedParishId
                              }
                            >
                              {parish}
                            </option>
                          ))}
                      </optgroup>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                {isOutsideBoundary && (
                  <>
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        The parish you have selected is not your home parish,
                        please share why you are applying to another parish.
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        className="w-100"
                        as="textarea"
                        maxLength={500}
                        {...register("outsideBoundaryReason", {
                          required: true,
                        })}
                        isInvalid={!!errors.outsideBoundaryReason}
                      />
                      <Row className="w-100 mx-0">
                        <Col className="px-0 text-muted">e.g. Moved house</Col>
                        <Col className="px-0 text-muted" xs="auto">
                          {outsideBoundaryReason.length}/500
                        </Col>
                      </Row>
                    </Form.Row>
                    <br />
                  </>
                )}
                {showFields ? (
                  <>
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">Programme</Form.Label>
                      <Row className="w-100 mx-0">
                        <Controller
                          name={"programmeType"}
                          control={control}
                          rules={{
                            required: true,
                            validate: (val) => {
                              for (let x = 0; x < typeOptions.length; x++) {
                                if (
                                  typeOptions[x].id === val.id &&
                                  typeOptions[x].enquiryEmail ===
                                    val.enquiryEmail
                                ) {
                                  return true;
                                }
                              }

                              return false;
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              className={`w-100 programme-select ${
                                !!errors.programmeType
                                  ? "select-error"
                                  : "error"
                              }`}
                              value={value}
                              onChange={onChange}
                              options={typeOptions}
                              components={{
                                Option: customOptions,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              isSearchable={false}
                              placeholder="Please select"
                              isDisabled={!selectedParishId}
                              styles={{
                                control: (base, state) => {
                                  let borderColor = "#cccccc";

                                  const style = {
                                    ...base,
                                  };

                                  if (!!errors.programmeType) {
                                    borderColor = "#dc3545";
                                    if (state.isFocused) {
                                      style.boxShadow = "0 0 3px 1px #dc3545";
                                    }
                                  }

                                  style.borderColor = borderColor;

                                  return style;
                                },
                                menu: (base) => ({
                                  ...base,
                                  marginTop: "0px",
                                  borderRadius: "0px",
                                  boxShadow: "0 0 0 1px #555555",
                                }),
                              }}
                            />
                          )}
                        />
                        {!!errors.programmeType ? (
                          <ErrorOutline className="text-danger programme-select-error-icon" />
                        ) : null}
                        <ExpandMoreOutlined className="programme-select-expand-icon" />
                      </Row>
                      {parishesConfig.length &&
                      programmeType !== "" &&
                      programmeType.notice ? (
                        <Alert
                          variant="primary"
                          className="w-100 d-flex justify-content-start align-items-center px-2 mt-2 mb-0"
                        >
                          <InfoOutlinedIcon className="text-primary mr-1" />
                          <span className="text-dark">
                            {programmeType.notice}
                          </span>
                        </Alert>
                      ) : (
                        <></>
                      )}
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">Timeslot</Form.Label>
                      <Form.Control
                        as="select"
                        size="lg"
                        disabled={!programmeType}
                        {...register("timeslot", {
                          required: true,
                          validate: (val) => {
                            const timeslot = programmeType?.timeslots?.find(
                              ({ id }) => id == val
                            );

                            return !!programmeType && !!timeslot;
                          },
                        })}
                        isInvalid={!!errors.timeslot}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          className="text-muted font-italic"
                        >
                          Please select
                        </option>
                        {programmeType?.timeslots?.map(({ name, id }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Row>
                    <br />
                  </>
                ) : null}
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Reason for transfer</Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    as="textarea"
                    maxLength={100}
                    {...register("transferReason", {
                      required: true,
                    })}
                    isInvalid={!!errors.transferReason}
                  />
                  <Row className="w-100 mx-0">
                    <Col className="px-0 text-muted"></Col>
                    <Col className="px-0 text-muted" xs="auto">
                      {transferReason.length}/100
                    </Col>
                  </Row>
                </Form.Row>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col className="d-none d-sm-flex" />
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Link className="text-dark" to={"/catch"}>
              <Button variant="outline-primary" size="lg" className="w-100">
                Cancel
              </Button>
            </Link>
          </Col>
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default function ParishTransfer() {
  const { user } = useContext(AppGlobalContext);
  const activeRegistrationState = useActiveRegistration();
  const { isGuardian, wardsData } = useGuardian();
  const { selectedWardIndex } = useWardSelection();
  const history = useHistory();

  const ward = wardsData[selectedWardIndex];

  const statusName = activeRegistrationState.status;

  const isAccessingValidPage = isValidPage({
    isGuardian: isGuardian,
    statusName,
    page: "parishTransfer",
  });

  if (!activeRegistrationState.exists) {
    history.push("/catch");
    return null;
  }

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ minHeight: "calc(100vh - 4.5rem)" }}
      >
        <Header type="backandlogo" smallpic={user.photoURL} />
        <Container className="my-4 flex-grow-1 d-flex flex-column px-4">
          <h2 className="mb-2">Transfer Parish</h2>
          {isAccessingValidPage ? (
            <ParishOfInterest
              user={user}
              ward={ward}
              registrationDetails={activeRegistrationState.data}
            />
          ) : (
            <Card className="flex-grow-1 d-flex flex-column">
              <Card.Body
                className="d-flex flex-column justify-content-center align-items-center py-5"
                style={{ gap: "1.5rem" }}
              >
                <ForbiddenRoute />
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
}
