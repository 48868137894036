import React, { useCallback } from "react";
import { getMassTime as getRoomTime, getSGDateObj } from "../../../utils/utils";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import { isPastEvent } from "../../../utils/date";
import { timeslotRef } from "../service/references";
import { formatMultiRate } from "../service/currency";

function ScheduleEntry(props) {
  const getEndDate = (startDate, durationMins) => ({
    seconds: startDate.seconds + parseInt(durationMins) * 60,
    nanoseconds: startDate.nanoseconds,
  });

  const handleClick = () => {
    const { id } = props.details;
    props.onRoomSelection(id);
  };

  const disabledButton = (room, roomCapacity, closed, bookerRole) => {
    const { id } = room;
    const roomStartDate = getSGDateObj(room.date);
    const roomEndDate = getEndDate(roomStartDate, room.duration);
    let notes = room?.notes;
    const ispast = isPastEvent(room.date);
    const cap = parseInt(roomCapacity);

    return (
      <button
        id={`selectedTime_${id}`}
        disabled
        type="button"
        style={{ border: "1px solid #bbbbbb" }}
        className={`btn btn-block my-3 py-2`}
      >
        <h4 className="card-title">
          <span className="float-left text-secondary">
            {timeslotRef[room.slotType].name}
          </span>
          <span className="float-right text-secondary">{roomCapacity}</span>
        </h4>
        <div className="clearfix pb-1"></div>
        <div className="text-left float-left text-secondary defaultfontsize">
          {room.name} ({room.function})
          <br />
          <small>
            <BusinessOutlinedIcon fontSize="inherit" /> {room.venue}
          </small>
          <br />
          <small>
            <ScheduleOutlinedIcon fontSize="inherit" />{" "}
            {getRoomTime(roomStartDate)} &ndash; {getRoomTime(roomEndDate)}
          </small>
          <br />
          <small>{formatMultiRate(room.config[bookerRole].rate)}</small>
        </div>
        {notes && (
          <>
            <div className="clearfix"></div>
            <div className="float-left text-left text-secondary defaultfontsize">
              {notes}
            </div>
          </>
        )}
      </button>
    );
  };

  const enabledButton = (selected, room, roomCapacity, variant, bookerRole) => {
    const { id } = room;
    let notes = room?.notes;
    // if (disableWeekdayMassBooking(mass.date) && isWeekDayMass(mass.date)) {
    //   if (mass?.weekdaybookable !== "Y") {
    //     notes = getEventNoBookingNotes(mass.notes || "");
    //   }
    // }

    const roomStartDate = getSGDateObj(room.date);
    const roomEndDate = getEndDate(roomStartDate, room.duration);
    const ispast = isPastEvent(room.date);
    const buttonStyle =
      selected === id
        ? `btn btn-${variant} btn-block my-3 py-2`
        : `btn btn-outline-${variant} btn-block my-3 py-2`;
    const cap = parseInt(roomCapacity);

    return (
      <button
        id={`selectedTime_${id}`}
        type="button"
        onClick={handleClick}
        className={buttonStyle}
      >
        <h4 className="card-title">
          <span className="float-left">{timeslotRef[room.slotType].name}</span>
          <span className="float-right">{roomCapacity}</span>
        </h4>
        <div className="clearfix pb-1"></div>
        <div className="text-left float-left defaultfontsize">
          {room.name} ({room.function})
          <br />
          <small>
            <BusinessOutlinedIcon fontSize="inherit" /> {room.venue}
          </small>
          <br />
          <small>
            <ScheduleOutlinedIcon fontSize="inherit" />{" "}
            {getRoomTime(roomStartDate)} &ndash; {getRoomTime(roomEndDate)}
          </small>
          <br />
          <small>{formatMultiRate(room.config[bookerRole].rate)}</small>
        </div>
        {notes && (
          <>
            <div className="clearfix"></div>
            <div className="float-left text-left defaultfontsize">{notes}</div>
          </>
        )}
      </button>
    );
  };

  function render() {
    const { details, selectedTime, closed, overridelabel, bookerRole } = props;
    const available = details.available;
    let capacity = available;
    let disabled = false;
    let label = "";

    if (available === 0) {
      capacity = "FULL";
      disabled = true;
    } else {
      capacity += " Available";
    }
    label = capacity;
    //check if forced CLOSED
    if (closed) {
      // label = "CLOSED";
      disabled = true;
    }

    if (overridelabel !== "") {
      //check if disabled but using different labels
      // capacity = "CLOSED";
      label = overridelabel;
      disabled = true;
    }

    // let maxkidcount;
    let variant = "success";

    // if (
    //   enableWarningPETVaccinationButton() &&
    //   details.capacity > maxNoPETVaccinationCapacity()
    // ) {
    //   variant = "danger";
    //   if (kidpetenabled) {
    //     //compute capacity based on percent provided
    //     maxkidcount = parseInt(
    //       getMaxKidCountAllowed(maxkidcountpercent, details.capacity)
    //     );
    //     // console.log({ maxkidcount });
    //     // console.log(details);
    //     const currentcount = details?.kidcounter;
    //     //compute new maxkidcount limit based on available and counter
    //     if (currentcount >= 0) {
    //       maxkidcount = maxkidcount - currentcount;
    //       // console.log({ maxkidcount });
    //       if (maxkidcount > available) {
    //         //make sure our available kid counter does not exceed our available
    //         maxkidcount = available;
    //       }
    //       //if kid, force disabled
    //       if (iskid && maxkidcount === 0) {
    //         disabled = true;
    //       }
    //     } else {
    //       // console.log("no kidcounter");
    //     }
    //   }
    // }
    // if (
    //   disableWeekdayMassBooking(details.date) &&
    //   isWeekDayMass(details.date)
    // ) {
    //   if (details?.weekdaybookable !== "Y") {
    //     disabled = true;
    //   }
    // }
    if (!disabled) {
      // console.log({ closed });
      return enabledButton(selectedTime, details, label, variant, bookerRole);
    }
    // console.log({ closed });
    return disabledButton(details, label, closed, bookerRole);
  }

  return render();
}

export default ScheduleEntry;
