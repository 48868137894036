import { getParishes } from "../../../services/parish";

export const districts = [
	{
		_id: 0,
		name: "North",
		active: true,
		min: 1,
		max: 5,
	},
	{
		_id: 1,
		name: "East",
		active: true,
		min: 6,
		max: 10,
	},
	{
		_id: 2,
		name: "West",
		active: true,
		min: 11,
		max: 15,
	},
	{
		_id: 3,
		name: "Serangoon",
		active: true,
		min: 16,
		max: 20,
	},
	{
		_id: 4,
		name: "City",
		active: true,
		min: 21,
		max: 32,
	},
];

export function assignParishToDistrict() {
	const parishes = getParishes();
	const districts = getDistricts();
	const newDistricts = [];

	for (let x = 0; x <= 4; x++) {
		const newDistrict = { ...districts[x] };
		const assignedParishes = [];

		parishes.forEach((parish) => {
			if (parish._id >= districts[x].min && parish._id <= districts[x].max) {
				assignedParishes.push(parish);
			}
		});

		newDistrict.parishes = assignedParishes;

		newDistricts.push(newDistrict);
	}

	return newDistricts
};

export function getDistricts() {
  return districts;
};
