import fire from "../../../services/fire";
import { getOrgByCode } from "../../../services/organisation";
import {
  getDay,
  mapToDay,
  getMassTime as getRoomTime,
  getMassDate as getRoomDate,
} from "../../../utils/utils";

export async function isBookingEnabled(org, month) {
  let result = false;
  //do logic here to check if visible or not

  return result;
}

export async function getMonthlyRoomTimings(org, firstDay, lastDay) {
  let roomtimings = [];
  const db = fire.firestore();

  const snapshot = await db
    .collection(`rooms/${org}/orgrooms`)
    .where("isActive", "==", true)
    .where("date", ">=", firstDay)
    .where("date", "<=", lastDay)
    .orderBy("date", "asc")
    .get();

  snapshot.forEach(function (doc) {
    let data = doc.data();
    if (data.hasOwnProperty("type") === false) {
      roomtimings.push(doc.data());
    }
  });
  return roomtimings;
}

/**
 * Represents the configuration for rates.
 *
 * @typedef {Object} RateConfig
 * @property {number} currency - The currency code (0 for free, 1 for SGD, 2 for Credits).
 * @property {string} note - Additional note for the rate.
 * @property {number} value - The value of the rate.
 */

/**
 * Represents the configuration settings for different roles.
 *
 * @typedef {Object} ConfigSettings
 * @property {boolean} [epaymentEnabled] - If e-payments are enabled for this BookerRole.
 * @property {Array<RateConfig>} rate - Array of rate configurations.
 */

/**
 * Represents the booker roles.
 *
 * @typedef {"org" | "pub" | "su"} BookerRole
 */

/**
 * Represents the maximum number of people allowed per role.
 *
 * @typedef {Record<BookerRole, number>} PaxLimit
 */

/**
 * Represents the details of a room.
 *
 * @typedef {Object} RoomDetails
 * @property {string} id - The ID of the room.
 * @property {string} name - The name of the room.
 * @property {string} description - The description of the room.
 * @property {boolean} isActive - Indicates whether the room is active or not.
 * @property {number} org - The organization ID.
 * @property {string} orgcode - The organization code.
 * @property {string} schedule - The schedule ID.
 * @property {string} function - The function of the room.
 * @property {string} location - The location of the room.
 * @property {number} capacity - The maximum capacity of the room.
 * @property {number} available - The number of available spots in the room.
 * @property {number} day - The day of the week for the room booking.
 * @property {number} duration - The duration of the booking in minutes.
 * @property {string} date - The date and time of the booking.
 * @property {string} slotType - The type of time slot for the booking.
 * @property {string} venue - The venue address of the room.
 * @property {string} notes - Additional notes or comments about the room.
 * @property {PaxLimit} paxLimit - The maximum number of people allowed per role (org, pub, su).
 * @property {Record<BookerRole, ConfigSettings>} config - Configuration settings for rates based on roles.
 * @property {Array<string>} allowedTenantRoles - The roles allowed to book the room.
 */

/**
 * Retrieves the details of a specific room based on the provided organization, organization code, and preferred schedule.
 *
 * @param {string} org - The organization ID.
 * @param {string} orgcode - The organization code.
 * @param {string} preferredschedule - The preferred schedule ID.
 * @return {Promise<Array<RoomDetails>>} An array containing the details of the room, or null if the preferred schedule is not provided.
 */
export async function getRoomDetails(org, orgcode, preferredschedule) {
  if (!preferredschedule) {
    return null;
  }
  let chosenroom = [];
  const db = fire.firestore();

  const snapshot = await db
    .collection(`rooms/${orgcode}/orgrooms`)
    .where("isActive", "==", true)
    .where("id", "==", preferredschedule)
    .where("org", "==", parseInt(org))
    .get();

  snapshot.forEach(function (doc) {
    chosenroom.push(doc.data());
  });

  return chosenroom;
}

export async function getRoomBookingsByTenant(tenantId) {
  if (!tenantId) {
    return null;
  }
  const db = fire.firestore();

  const bookings = await db
    .collection("roombookings")
    .where("tenantId", "==", tenantId)
    .orderBy("created", "desc")
    .get();

  var list = [];
  bookings.forEach((doc) => {
    list.push(doc.data());
  });

  return list;
}

export function isAllowedToBook(org, preferredorg) {
  const orgobj = getOrgByCode(preferredorg);
  //ALLOW TO BOOK WHEN ORG MATCHES
  if (orgobj && isOrgMatch(org, orgobj._id)) {
    return true;
  }
  return false;
}

export function isOrgMatch(userorg, roomorg) {
  // console.log(`User Org: ${userorg} Chosen Org: ${roomorg}`);
  if (parseInt(userorg) === parseInt(roomorg)) {
    return true;
  }
  return false;
}

export async function hasBookWithinTimeframe(userdata) {
  var result = false;
  //TODO: do logic if user has booked in certain timeframe
  if (userdata) {
    let lastroombooking = await userdata.data();
    if (lastroombooking.hasOwnProperty("lastroombooking")) {
      // console.log("Last Room:", lastroombooking.lastroombooking);
      result = true;
    } else {
      // console.log("Have not booked");
      result = false;
    }
  }

  return result;
}

export function getMonthlyBookings(bookings, preferredYear, preferredMonth) {
  const actualdate = `${preferredMonth} 01 ${preferredYear}`;
  let date = new Date(actualdate);
  let y = date.getFullYear();
  let m = date.getMonth();
  let firstDay = new Date(y, m, 1).getTime() / 1000;
  let lastDay = new Date(y, m + 1, 0, 23, 59, 59).getTime() / 1000;

  let list = [];
  // console.log(`First Day: ${firstDay} Last Day: ${lastDay}`);

  for (let item = 0; item < bookings.length; item++) {
    let roomDate = bookings[item].roomDate.seconds;
    // console.log("Booking: ", bookings[item]);
    //skip if beyond the range
    if (roomDate >= firstDay && roomDate <= lastDay) {
      list.push(bookings[item]);
    }
  }
  return list;
}

export function isRoomFull(available) {
  if (available <= 0) {
    return true;
  }
  return false;
}

export function isRoomTimeExpired(date, localtime) {
  const roomtime = date.seconds * 1000;
  let roomclosed = false;

  if (localtime > roomtime) {
    roomclosed = true;
  }

  return roomclosed;
}

export function allowSelfCheckin(date, localtime, checkinclose = 45) {
  const time = localtime;
  const eventtime = date.seconds * 1000;
  const cutoff = checkinclose;
  const start = eventtime - cutoff * 60 * 1000;
  const end = eventtime + cutoff * 60 * 1000;

  if (start <= time && time <= end) {
    return true;
  }
  return false;
}

export function allowRoomCancel(date, localtime, cancelcutoff = 48) {
  const roomtime = date.seconds * 1000;
  let allowcancel = true;
  let hourstoclose = cancelcutoff;
  let hourstoclosesec = hourstoclose * 60 * 60 * 1000;

  //NOT EXPLICITLY CLOSED, just to estimate if closed or not
  if (localtime > roomtime - hourstoclosesec) {
    allowcancel = false;
  }
  return allowcancel;
}

export function getRoomObject(bookings, id) {
  return bookings.filter((booking) => booking.roomId === id);
}

export function removeRoomObject(bookings, id) {
  return bookings.filter((booking) => booking.roomId !== id);
}

export function getRoomLineDescription(booking) {
  let day = getDay(booking.roomDate);
  let labelday = mapToDay(day);
  let roomtime = getRoomTime(booking.roomDate);
  let labeldate = getRoomDate(booking.roomDate);
  return `${labelday}, ${labeldate} at ${roomtime}`;
}

export function isRoomClosed(room, localtime, cutoff = 48) {
  const { closed, date } = room;
  const roomtime = date.seconds * 1000;
  let roomclosed = false;
  let hourstoclose = cutoff;
  let hourstoclosesec = hourstoclose * 60 * 60 * 1000;
  if (closed) {
    //this room is marked as "closed" = true in the back end or code
    roomclosed = true;
  } else {
    //NOT EXPLICITLY CLOSED, just to estimate if closed or not
    if (localtime > roomtime - hourstoclosesec) {
      // console.log(`Date: ${date}`);
      // console.log(
      //   `Localtime: ${localtime} Roomtime: ${roomtime} Hours to Close: ${hourstoclosesec}`
      // );
      roomclosed = true;
    }
  }
  return roomclosed;
}
