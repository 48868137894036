import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncAccountManager({ ...rest }) {
  const AsyncLoad = loadable(() =>
    import("../page/accountManagerIntermediate")
  );
  console.log("Loading", AsyncAccountManager.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncAccountManager;
