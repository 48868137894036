import React from "react";
import { getGoogleMapSearchUrl } from "../../utils/utils";
import IconByField from "./iconByField";

function SmallDirections({ address }) {
  return (
    <div className="text-truncate text-center pointer px-0">
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="text-dark text-truncate  text-center d-flex justify-content-center"
        href={getGoogleMapSearchUrl(address)}
      >
        <div className="pt-1 text-truncate defaultstandardfontsize font-weight-bold text-primary">
          DIRECTIONS
        </div>
        <div className="text-center ml-1">
          <IconByField field="directionsoutline" size="16px" />
        </div>
      </a>
    </div>
  );
}

export default SmallDirections;
