export function getEventCancelButtonLabel(type) {
  let result = null;
  if (
    type === "penitential" ||
    type === "confession" ||
    type === "christmas" ||
    type === "reconciliation"
  ) {
    result = "Cancel Booking";
  } else if (type === "devotion") {
    result = "Cancel Devotion Booking";
  } else if (type === "stationofcross") {
    result = "Cancel Station Booking";
  } else if (type === "adoration") {
    result = "Cancel Adoration Booking";
  } else if (type === "holyhour") {
    result = "Cancel Holy Hour Booking";
  } else if (type === "rosary") {
    result = "Cancel Rosary Booking";
  } else if (type === "pentecostrally") {
    result = "Cancel Pentecost Rally Booking";
  } else if (type === "event") {
    result = "Cancel Event Booking";
  } else {
    result = "Cancel Mass Booking";
  }
  return result;
}

export function getEventDeleteButtonLabel(type) {
  let result = null;
  if (
    type === "penitential" ||
    type === "confession" ||
    type === "christmas" ||
    type === "reconciliation"
  ) {
    result = "Delete Booking";
  } else if (type === "devotion") {
    result = "Delete Devotion Booking";
  } else if (type === "stationofcross") {
    result = "Delete Station Booking";
  } else if (type === "holyhour") {
    result = "Delete Holy Hour Booking";
  } else if (type === "rosary") {
    result = "Delete Rosary Booking";
  } else if (type === "pentcostrally") {
    result = "Delete Pentecost Rally Booking";
  } else {
    result = "Delete Mass Booking";
  }
  return result;
}

export function getUserEventBookingObj(user, type) {
  let eventobj;
  //we can manually add new types here
  switch (type) {
    case "devotion":
      eventobj = user?.lastdevotionbooking;
      break;
    case "stationofcross":
    case "adoration":
    case "holyhour":
    case "confession":
    case "penitential":
    case "christmas":
    case "reconciliation":
      eventobj = user?.genericeventbooking;
      break;
    case "room":
      eventobj = user?.lastroombooking;
      break;
    default:
      eventobj = user?.lastmassbooking;
  }
  return eventobj;
}

export function generateUserEventBookingObj(type, obj) {
  let eventobj;
  //we can manually add new types here

  if (type === "devotion") {
    eventobj = {
      lastdevotionbooking: obj,
    };
  } else if (
    type === "stationofcross" ||
    type === "adoration" ||
    type === "holyhour" ||
    type === "penitential" ||
    type === "confession" ||
    type === "christmas" ||
    type === "reconciliation"
  ) {
    eventobj = {
      genericeventbooking: obj,
    };
  } else {
    eventobj = {
      lastmassbooking: obj,
    };
  }
  return eventobj;
}

export function getEventTypeLabel(type) {
  let typeText = "Mass";
  if (type === "penitential") {
    typeText = "Penitential Service";
  } else if (type === "confession") {
    typeText = "Confession";
    // typeText = "Penitential Service";
  } else if (type === "reconciliation") {
    typeText = "Reconciliation";
    // typeText = "Penitential Service";
  } else if (type === "christmas") {
    typeText = "Christmas";
  } else if (type === "devotion") {
    typeText = "Devotion";
  } else if (type === "stationofcross") {
    typeText = "Stations of the Cross";
  } else if (type === "adoration") {
    typeText = "Adoration";
  } else if (type === "holyhour") {
    typeText = "Holy Hour";
  } else if (type === "rosary") {
    typeText = "Rosary Devotion";
  } else if (type === "pentecostrally") {
    typeText = "Pentecost Rally";
  } else if (type === "event") {
    typeText = "Event";
  }
  return typeText;
}

export function generateLogEventCode(prefix, booking) {
  let logEventPrefix = "";
  if (
    booking.type === "penitential" ||
    booking.type === "confession" ||
    booking.type === "reconciliation"
  ) {
    logEventPrefix = `${prefix}_confession`;
  } else if (booking.type === "christmas") {
    logEventPrefix = `${prefix}_christmas`;
  } else if (booking.type === "devotion") {
    logEventPrefix = `${prefix}_devotion`;
  } else if (booking.type === "adoration") {
    logEventPrefix = `${prefix}_adoration`;
  } else if (booking.type === "stationofcross") {
    logEventPrefix = `${prefix}_stationofcross`;
  } else if (booking.type === "holyhour") {
    logEventPrefix = `${prefix}_holyhour`;
  } else if (booking.type === "rosary") {
    logEventPrefix = `${prefix}_rosary`;
  } else if (booking.type === "pentecostrally") {
    logEventPrefix = `${prefix}_pentecostrally`;
  } else if (booking.type === "event") {
    logEventPrefix = `${prefix}_event`;
  } else {
    logEventPrefix = `${prefix}_mass`;
  }
  return logEventPrefix;
}

export function getEventNoBookingNotes(notes) {
  const noBookingText = "No Booking Required";

  if (notes.trim() !== "") {
    //has notes
    notes = `${noBookingText}, ${notes}`;
  } else {
    //no notes
    notes = `${noBookingText}`;
  }
  return notes;
}

export function getEventTypes(obj) {
  let type = null;
  if (obj) {
    type = obj?.type;
  }
  return type;
}

export function getEventConfigByType(obj, type) {
  let config = null;
  if (obj && type !== "") {
    config = obj.config.filter((item) => item.type === type);
  }
  return config;
}

export function getEventAllowedDates(config, index = 0) {
  let dates = null;
  if (config) {
    dates = config[index].date;
  }
  return dates;
}

export function isEventActive(config) {
  let value = false;
  if (config.length) {
    value = config[0].active;
  }
  return value;
}

export function isShowEventHomeMenu(config) {
  let value = false;
  if (config.length) {
    value = config[0].showhomemenu || false;
  }
  return value;
}

export function hasEventMultipleDates(config) {
  let value = false;
  if (config.length) {
    //check if event has multiple dates
    if (config[0].date.length > 1) {
      value = true;
    }
  }
  return value;
}

export function getEventDateCount(config) {
  let value = 0;
  if (config.length) {
    //check if event has multiple dates
    value = config[0].date.length;
  }
  return value;
}

export function getEventActiveDateCount(config) {
  let value = 0;
  if (config.length) {
    //check if event has multiple dates that are active
    const len = config[0].date.length;
    for (let i = 0; i < len; i++) {
      const entries = config[0].props[i];
      if (entries.active === true) {
        value++;
      }
    }
  }
  return value;
}

export function getEventActiveDates(config) {
  const dates = [];
  if (config.length) {
    //check if event has multiple dates that are active
    const len = config[0].date.length;
    for (let i = 0; i < len; i++) {
      const entries = config[0].props[i];
      if (entries.active === true) {
        dates.push(entries);
      }
    }
  }
  return dates;
}

export function getEventMonth(config) {
  let value = "";
  if (config.length) {
    value = config[0].props[0].month;
  }
  return value;
}

export function getEventYear(config) {
  let value = "";
  if (config.length) {
    value = config[0].props[0].year;
  }
  return value;
}

export function getActiveEventMenuLinkList(obj, code) {
  // console.log({ genericeventobj });
  const menuList = [];
  if (obj) {
    //get active events; for now, we only have 1
    const events = getEventTypes(obj);
    // console.log({ events });
    for (let eventindex = 0; eventindex < events.length; eventindex++) {
      //get config
      const config = getEventConfigByType(obj, events[eventindex]);
      // console.log({ config });
      // console.log({ eventindex });
      // const eventactive = isEventActive(config);
      const showhomemenu = isShowEventHomeMenu(config);
      // console.log({ eventactive });
      if (showhomemenu) {
        // const datecount = getEventActiveDateCount(config);
        // console.log({ datecount });
        const eventname = getEventName(config);
        /** Force everyone to see the selector page for uniform behaviour */
        let genericlink;
        // if (datecount > 1) {
        //show selector if more than 1
        genericlink = `/event/${events[eventindex]}/selector/${code}`;
        // } else {
        //   // console.log(eventname, datecount);
        //   const eventmonth = getEventMonth(config);
        //   const eventyear = getEventYear(config);
        //   genericlink = `/event/${events[eventindex]}/${code}/${eventyear}/${eventmonth}`;
        // }
        // console.log(genericlink);
        menuList.push({ link: genericlink, name: eventname });
      }
    }
  }
  return menuList;
}

export function getActiveEventDateMenuLinkList(
  obj,
  eventType,
  code,
  access = "user"
) {
  // console.log({ genericeventobj });
  const menuList = [];
  if (obj) {
    //get active events; for now, we only have 1
    const events = getEventTypes(obj);
    // console.log({ events });
    for (let eventindex = 0; eventindex < events.length; eventindex++) {
      if (eventType === events[eventindex]) {
        //get config
        const config = getEventConfigByType(obj, events[eventindex]);
        // console.log({ config });
        // console.log({ eventindex });
        const eventactive = isEventActive(config);
        // console.log({ eventactive });
        if (eventactive) {
          const dates = getEventActiveDates(config);
          // console.log({ dates });
          const eventname = getEventName(config);
          /** Force everyone to see the selector page for uniform behaviour */
          for (let i = 0; i < dates.length; i++) {
            const entry = dates[i];
            const path = `${events[eventindex]}/${code}/${entry.year}/${entry.month}`;
            const genericlink =
              access === "user" ? `/event/${path}` : `/admin/${path}`;

            menuList.push({
              link: genericlink,
              name: eventname,
              month: entry.month,
            });
          }
        }
        break;
      }
    }
  }
  return menuList;
}

export function getEventName(config) {
  let name = null;
  if (config) {
    name = config[0].name;
  }
  return name;
}

export function getValidTimeRange(checkinopen, checkinclose, localtime) {
  const start = localtime - checkinopen * 60 * 1000;
  const end = localtime + checkinclose * 60 * 1000;
  return [start, end];
}

export function getEventBookingLimit(config, date, index = 0) {
  let limit = 0;
  if (config && date !== "") {
    limit = config[index].props.filter((item) => item.date === date);
    limit = limit[index].limit;
  }
  return limit;
}
