export function welcomeGreetings(accountcreated) {
  let greeting = "Welcome";

  const myDate = new Date();
  const hrs = myDate.getHours();
  const time = myDate.getTime();
  if (time - accountcreated > 10 * 60 * 1000) {
    //if more than 10 minutes, change greeting already
    if (hrs >= 4 && hrs <= 5) greeting = "Rise up";
    else if (hrs >= 6 && hrs < 12) greeting = "Good morning";
    else if (hrs >= 12 && hrs <= 17) greeting = "Good afternoon";
    else if (hrs >= 18 && hrs <= 22) greeting = "Good evening";
    else greeting = "Good night";
  }

  return `${greeting},`;
}
