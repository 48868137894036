import { SvgIcon } from "@material-ui/core";

import ListAltIcon from "@material-ui/icons/ListAlt";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

/** @typedef {"menu" | "guardianControl" | "wardControl" | "registrationReview" | "classFund" | "contactUpdate" | "parishTransfer" | "registrationForm" | "confirmation"} UserPageId */

/**
 * @typedef ButtonProps
 * @prop {string} key - Unique key to identify the button (for React change detection)
 * @prop {string} label - Label of the button
 * @prop {SvgIcon} [Icon] - Option icon of the button
 * @prop {string} link - Route to direct to upon button click
 */
/** @type {Record<Omit<UserPageId, "menu"> | "home", ButtonProps>} */
const buttonDictionary = {
  guardianControl: {
    key: "guardianControl",
    label: "Add Child",
    Icon: SupervisorAccountIcon,
    link: "/catch/guardian",
  },
  wardControl: {
    key: "wardControl",
    label: "Add Parent",
    Icon: SupervisorAccountIcon,
    link: "/catch/ward",
  },
  // registrationReview: {
  //   key: "registrationReview",
  //   label: "Review Registration",
  //   Icon: ListAltIcon,
  //   link: "/catch/registrationreview",
  // },
  classFund: {
    key: "classFund",
    label: "Class Fund",
    Icon: AccountBalanceWalletIcon,
    link: "/catch/classfund",
  },
  contactUpdate: {
    key: "contactUpdate",
    label: "Update Contact",
    Icon: ContactMailIcon,
    link: "/catch/contactupdate",
  },
  parishTransfer: {
    key: "parishTransfer",
    label: "Transfer Parish",
    Icon: TransferWithinAStationIcon,
    link: "/catch/parishtransfer",
  },
  registrationForm: {
    key: "registrationForm",
    label: "Register",
    Icon: HowToRegIcon,
    link: "/catch/register",
  },
  confirmation: {
    key: "confirmation",
    label: "Confirmation Form",
    Icon: WhatshotIcon,
    link: "/catch/confirmation",
  },
  home: {
    key: "home",
    label: "Back to Home",
    Icon: ExitToAppIcon,
    link: "/home",
  },
};

/** @type {Record<UserPageId, string>} */
const pageTitleDictionary = {
  menu: "Catechesis e-Service",
  guardianControl: "Guardian Control",
  registrationReview: "Registration Review",
  classFund: "Class Fund",
  contactUpdate: "Contact Update",
  parishTransfer: "Parish Transfer",
  registrationForm: "Registration Form",
  confirmation: "Sacrament of Confirmation",
};

export { buttonDictionary, pageTitleDictionary };
