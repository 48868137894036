/*
 * These config settings can be placed in separate config page
 */
export function getAllowedBookingCurrentDates() {
  /**
   * WARNING: Please make sure the dates are in proper order
   */
  return [
    "oct2022",
    "sep2022",
    "aug2022",
    "jul2022",
    "jun2022",
    "may2022",
    "apr2022",
    "mar2022",
    "feb2022",
    "jan2022",
    "dec2021",
    "nov2021",
    "oct2021",
    "sep2021",
    "aug2021",
    "jul2021",
    "jun2021",
    "may2021",
    "apr2021",
    "mar2021",
    "feb2021",
    "jan2021",
    "dec2020",
    "nov2020",
    "oct2020",
    "sep2020",
    "aug2020",
    "jul2020",
  ];
}

export function getAllowedDevotionBookingCurrentDates() {
  /**
   * WARNING: Please make sure the dates are in proper order
   */
  return [
    "oct2022",
    "sep2022",
    "aug2022",
    "jul2022",
    "jun2022",
    "may2022",
    "apr2022",
    "mar2022",
    "feb2022",
    "jan2022",
    "dec2021",
    "nov2021",
    "oct2021",
    "sep2021",
    "aug2021",
    "jul2021",
    "jun2021",
    "may2021",
    "apr2021",
    "mar2021",
    "feb2021",
    "jan2021",
  ];
}

export function getAllowedStationofCrossBookingCurrentDates() {
  /**
   * WARNING: Please make sure the dates are in proper order
   */
  return ["apr2021", "mar2021"];
}

export function getAllowedAdorationBookingCurrentDates() {
  return ["apr2021"];
}

export function getFullMonth(short) {
  const map = [
    { short: "jan", full: "January" },
    { short: "feb", full: "February" },
    { short: "mar", full: "March" },
    { short: "apr", full: "April" },
    { short: "may", full: "May" },
    { short: "jun", full: "June" },
    { short: "jul", full: "July" },
    { short: "aug", full: "August" },
    { short: "sep", full: "September" },
    { short: "oct", full: "October" },
    { short: "nov", full: "November" },
    { short: "dec", full: "December" },
  ];
  let match = map.filter((month) => month.short === short);
  // console.log(match[0]);
  return match[0].full;
}

export function getAllowedCurrentDates() {
  const datesallowed = getAllowedBookingCurrentDates();

  //return ["jan2021", "dec2020"];
  return [datesallowed[0], datesallowed[1]];
}

export function getAllowedConfessionCurrentDates() {
  // const datesallowed = getAllowedBookingCurrentDates();
  /**Currently disabled, maybe open during Holy Week */
  return ["mar2021"]; //fixed to March confession
  // return [datesallowed[0]];
}

export function getAllowedAdorationCurrentDates() {
  const datesallowed = getAllowedAdorationBookingCurrentDates();
  return [datesallowed[0]];
}

export function getAllowedDevotionCurrentDates() {
  const datesallowed = getAllowedDevotionBookingCurrentDates();

  // return ["jan2021","feb2021"];
  return [datesallowed[0], datesallowed[1]];
}

export function getAllowedStationofCrossCurrentDates() {
  const datesallowed = getAllowedStationofCrossBookingCurrentDates();
  /*
  This is the first stations of the cross event
  */
  // return ["feb2021"];
  return [datesallowed[0], datesallowed[1]];
}

export function getPreviousMonth() {
  const datesallowed = getAllowedBookingCurrentDates();
  let previous = datesallowed[1];
  previous = previous.substring(0, 3);
  // console.log(previous);
  // return "dec";
  return previous;
}

export function getNextMonth() {
  const datesallowed = getAllowedBookingCurrentDates();
  let next = datesallowed[0];
  next = next.substring(0, 3);
  // console.log(next);
  // return "jan";
  return next;
}

export function getYearPreviousMonth() {
  const datesallowed = getAllowedBookingCurrentDates();
  let previous = datesallowed[1];
  previous = previous.substring(3, 7);
  // console.log(previous);
  // return "2020";
  return previous;
}

export function getYearNextMonth() {
  const datesallowed = getAllowedBookingCurrentDates();
  let next = datesallowed[0];
  next = next.substring(3, 7);
  // console.log(next);
  // return "2020";
  return next;
}

export function getNextMonthMasstoUpload() {
  const datesallowed = getAllowedBookingCurrentDates();
  // return "dec2020";
  return datesallowed[0];
}

export function getNextMonthDate() {
  const nextmonth = getNextMonth();
  const yearnextmonth = getYearNextMonth();
  // return "December 1, 2020";
  return `${getFullMonth(nextmonth)} 1, ${yearnextmonth}`;
}

export function getNextFullMonth() {
  let nextmonth = getNextMonthDate().split(" ");
  return nextmonth[0];
}

export function enableCancelMassBooking() {
  return true;
}

export function enableCancelConfessionBooking() {
  return true;
}

export function enableEditBasicProfile() {
  return true;
}

export function enableFirstBookingReminderAdmin() {
  return false;
}

export function disableBooking() {
  return false;
}

export function enableSelfChangeParish() {
  return true;
}

export function getAllowChangeParishDate() {
  //7 days
  return 7;
}

export function getSubIDDigits() {
  //return 4; //4 digits
  return 2; //2 digits
}

export function getChangeParishDate() {
  //init
  // return "February 1, 2021 ";
  // return "January 29, 2020";
  // return "January 31, 2021 11:59 pm";
  // return "February 14, 2022 11:59 pm";
  // return "May 21, 2024 11:59 pm";
  // return "June 23, 2024 11:59 pm";
  return "September 24, 2024 11:59 pm";
}

export function getChangeParishStartDate() {
  //start date when we allow people to change their parish
  return "September 24, 2024 11:59 pm";
}

export function getChangeParishEndDate() {
  //start date when we allow people to change their parish
  // return "May 21, 2024 11:59 pm";
  return "December 31, 2024 11:59 pm";
}

export function getNextDate(dateString) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Add one day to the date
  date.setDate(date.getDate() + 1);

  // Format the date as "MMM dd"
  const options = { month: "short", day: "numeric" };
  const nextDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  return nextDate;
}

export function enableProfilePhotoUpload() {
  return true;
}

export function enableVerifyEmail() {
  return true;
}

export function enableBookAnyParish() {
  return true;
}

export function bookAnyParishStartDay() {
  //1 day after
  return 0;
}

export function bookAnyParishEndDay() {
  //2 days after
  return 2;
}

export function bookRoomStartDay() {
  return 0;
}

export function bookRoomEndDay() {
  return 180;
}

export function enableConfession() {
  return true;
}

export function allowDeleteBooking() {
  return false;
}

export function allowDeleteEventSchedule() {
  return true;
}

export function allowCloseEventSchedule() {
  return true;
}

export function forceDisableDangerButton() {
  return false;
}

export function enableWarningPETVaccinationButton() {
  return true;
}

export function enableDemoBar() {
  return false;
}

export function maxNoPETVaccinationCapacity() {
  return 2000; //GOV => 50, PET test only
}

export function maxKidAllocationPercent() {
  return 20; //GOV => 20, PET test only
}

export function getMaxScanTries() {
  return 1;
}

export function showBarcodeScanKidCounter() {
  return false; //ENABLE
}

export function getWalkinEnableDate() {
  let date;
  //apr 1, 2022 => 1648742400
  date = 1648742400;
  return date;
}

export function disableWeekdayMassBooking(date) {
  let disable = false;
  if (date.seconds > getWalkinEnableDate()) {
    disable = true;
  }
  return disable;
}

export function enableBooking(date) {
  let enable = false;
  if (date.seconds > getWalkinEnableDate()) {
    enable = true;
  }
  return enable;
}

export function allowWalkin() {
  return true;
}

export function enableFestival() {
  let enable = false;
  if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "mycatholicsg-prod01") {
    //production
    enable = false;
  } else if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "mycatholicsg-00") {
    //staging
    enable = false; //ENABLE
  }
  return enable;
}

export function getEventBookingEnableDate() {
  // return "November 3, 2021 11:26:59 am";
  return "November 8, 2021 11:59:59 am";
}

export function getBookingNumberMode() {
  //use serialcounter or use slotcounter
  const mode = 1;
  let modestring = "serialcounter";
  if (mode) {
    modestring = "slotcounter";
  }
  return modestring;
}

export function getDatetoClose() {
  return 1621094400; // Sunday, 16 May 2021 00:00:00 GMT+08:00
}

export function getDateBookingNumberEnabled() {
  return 1622217600; // timestamp when booking number enabled
}

export function getMaxCapacities() {
  return 250;
}

export function isEventDateAllowed(date) {
  // March (2), enable April (3) on March 22
  // activeDate.getFullYear() === 2022 &&
  // activeDate.getMonth() === 2; || activeDate.getMonth() === 3

  return (
    date.getFullYear() === 2022 &&
    (date.getMonth() === 2 || date.getMonth() === 3)
  );
}

export function getCurrentBookingDate() {
  return "Sep 28";
}

export function isEnableUnaffiliated() {
  return true;
}

export function isEnableStrongPassword() {
  return true;
}

export function isEnablePFSGroupBallot() {
  return true;
}

export function isEnablePFSDeclineInvitation() {
  return true;
}

export function isEnableBSCMassIntentions() {
  return false;
}

export function isEnableRegistration() {
  return true;
}

export function getWebVersion() {
  return "1.9.208"; //To force all users to reload
}
