import React, { useState, useContext } from "react";
import fire from "../../../../services/fire";
import { Modal } from "react-bootstrap";
import {
  sendReqCampaignApprovalEmail,
  sendAppCampaignApprovalEmail,
  sendAppCampaignRetireEmail,
} from "../../../email/emailTemplateHelpers";

import FinanceContext from "../financeContext";
import "../../stylesheets/finance-qr-modal.css";

const INITIAL_STATE = {
  approverComments: "",
  isConfirmationModalOpen: false,
  isApproved: false,
  isRetire: false,
};

const QRDetailModal = () => {
  const { qrDashboardState, qrDashboardDispatch, financeDashboardState } =
    useContext(FinanceContext);

  const [state, setState] = useState({ ...INITIAL_STATE });
  const { isDetailModalOpen, campaignModalDetails } = qrDashboardState;
  const userIsApprover = financeDashboardState?.user?.isApprover;
  const {
    campaignName,
    amount,
    isEditable,
    isGlobal,
    isPayOnce,
    isPrivate,
    campaignStart,
    expiry,
    campaignRef,
    qrType,
    comments,
    approvalComments,
    approvalStatus,
    createdBy,
  } = campaignModalDetails;

  const approveReject = (shouldApprove) => {
    setState({
      ...state,
      isConfirmationModalOpen: true,
      isApproved: shouldApprove,
    });
  };

  const handleRetire = () => {
    const newApprovalStatus = -2;
    const retiredAt = new Date();
    const retiredBy = financeDashboardState?.user?.userid;
    const approverEmail = financeDashboardState?.user?.email;
    const approverName = financeDashboardState?.user?.fullname;
    const campaignRef = fire
      .firestore()
      .collection("paypluscampaigns")
      .doc(campaignModalDetails.id);

    campaignRef
      .update({
        approvalStatus: newApprovalStatus,
        retiredAt,
        retiredBy,
      })
      .then(() => {
        setState({ ...INITIAL_STATE });
        qrDashboardDispatch({ type: "SET_DETAIL_MODAL_CLOSE" });
        sendAppCampaignRetireEmail({
          email: approverEmail,
          approverName,
          campaignName,
        });
      });
  };

  const handleConfirmation = () => {
    const newApprovalStatus = state.isApproved ? 1 : -1;
    const newApproverComments = state.approverComments;
    const newApproverId = financeDashboardState?.user?.userid;
    const approverEmail = financeDashboardState?.user?.email;
    const approverName = financeDashboardState?.user?.fullname;
    const campaignRef = fire
      .firestore()
      .collection("paypluscampaigns")
      .doc(campaignModalDetails.id);

    campaignRef
      .update({
        approvalStatus: newApprovalStatus,
        approvalBy: newApproverId,
        approvalComments: newApproverComments,
        approvalAt: new Date(),
      })
      .then(() => {
        const shouldApprove = state.isApproved;
        setState({ ...INITIAL_STATE });
        qrDashboardDispatch({ type: "SET_DETAIL_MODAL_CLOSE" });
        // Send Confirmation email to requester and approver
        sendAppCampaignApprovalEmail({
          email: approverEmail,
          approverName,
          campaignName,
          approvalResult: shouldApprove,
        });

        const requesterId = createdBy;
        fire
          .firestore()
          .collection("users")
          .doc(requesterId)
          .get()
          .then((requesterRef) => {
            const requesterObj = requesterRef.data();
            const requesterEmail = requesterObj.email;
            const requesterName = requesterObj.fullname;
            sendReqCampaignApprovalEmail({
              email: requesterEmail,
              approverName,
              requesterName,
              campaignName,
              approverComments: newApproverComments,
              approvalResult: shouldApprove,
            });
          });
      });
  };

  const closeModal = () => {
    setState({ ...INITIAL_STATE });
    qrDashboardDispatch({ type: "SET_DETAIL_MODAL_CLOSE" });
  };

  return (
    <Modal
      dialogClassName="create-qr-modal__dialog"
      keyboard={false}
      show={isDetailModalOpen}
      onHide={() => {}}
    >
      <Modal
        className="create-qr-modal__confirmation-dialog"
        show={state.isConfirmationModalOpen}
        onHide={() => {}}
      >
        <Modal.Body>
          <div className="create-qr-modal__confirmation-dialog-title">
            Confirmation
          </div>
          {state.isRetire ? (
            <div>Are you sure you would like to retire this campaign?</div>
          ) : (
            <div>
              Are you sure you would like to{" "}
              {state.isApproved ? <b>approve</b> : <b>reject</b>} this Campaign?
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="create-qr-modal__footer">
          <div
            className="create-qr-modal__footer-btn"
            onClick={() =>
              setState({ ...state, isConfirmationModalOpen: false })
            }
          >
            Cancel
          </div>
          <div
            className={
              state.isApproved
                ? "create-qr-modal__footer-btn--success"
                : "create-qr-modal__footer-btn--danger"
            }
            onClick={() =>
              state.isRetire ? handleRetire() : handleConfirmation()
            }
          >
            Confirm
          </div>
        </Modal.Footer>
      </Modal>
      <Modal.Body className="create-qr-modal__container">
        <div className="create-qr-modal-header">QR Campaign Details</div>
        <div className="create-qr-modal__confirmation">
          <table className="create-qr-modal__confirmation-table">
            <tbody>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Campaign Name:</td>
                <td>{campaignName}</td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Amount:</td>
                <td>S$ {amount}</td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Amount Editable:</td>
                <td>{isEditable ? "Yes" : "No"}</td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Start Date:</td>
                <td>
                  {campaignStart ? campaignStart.toDate().toString() : "-"}
                </td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Expiry:</td>
                <td>{expiry ? expiry.toDate().toString() : "-"}</td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Reference:</td>
                <td>{campaignRef}</td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Comments:</td>
                <td>{comments}</td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>QR Type:</td>
                <td>
                  {qrType === "U"
                    ? "User Specific QR"
                    : qrType === "P"
                    ? "Product Specific QR"
                    : "-"}
                </td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Is Private Campaign:</td>
                <td>{isPrivate ? "Yes" : "No"}</td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Allowed to Only Pay Once:</td>
                <td>{isPayOnce ? "Yes" : "No"}</td>
              </tr>
              <tr className="create-qr-modal__confirmation--row-space">
                <td>Publish Outside of Parish / Organization:</td>
                <td>{isGlobal ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="create-qr-modal__section">
          <div className="create-qr-modal__section-title">
            Approval / Rejection Comments
          </div>
          <textarea
            className="create-qr-modal__textarea"
            value={approvalComments ? approvalComments : state.approvalComments}
            onChange={(e) => setState({ approverComments: e.target.value })}
            disabled={approvalComments || !userIsApprover}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="create-qr-modal__footer">
        <div
          className="create-qr-modal__footer-btn"
          onClick={() => closeModal()}
        >
          Cancel
        </div>
        {approvalStatus === 0 && userIsApprover && (
          <div className="create-qr-modal__footer-btn-grp">
            <div
              className="create-qr-modal__footer-btn--danger"
              onClick={() => approveReject(false)}
            >
              Reject
            </div>
            <div
              className="create-qr-modal__footer-btn--success"
              onClick={() => approveReject(true)}
            >
              Approve
            </div>
          </div>
        )}{" "}
        {approvalStatus === 1 && userIsApprover && (
          <div
            className="create-qr-modal__footer-btn--danger"
            onClick={() =>
              setState({
                ...state,
                isConfirmationModalOpen: true,
                isRetire: true,
              })
            }
          >
            Retire
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default QRDetailModal;
