import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import ModalPageNavigator from "./ModalPageNavigator";

const ModalWindow = ({
  title = "",
  data = {},
  pageNumber = 1,
  showModal = false,
  onModalCloseCB = () => {},
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleCloseModal = async () => {
    setShow(!show);
    onModalCloseCB();
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => handleCloseModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="pl-1 largefontsize">{title}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalPageNavigator
            page={pageNumber}
            data={data}
            // setPageNumberCB={handleSetPageNumberCB}
            // ******** For future use? (These are the same props as in PageNavigator) ********
            // user={currentUserData}
            // setDataCB={handleSetDataCB}
            // resetDataCB={handleResetDataCD}
            // resetData={isResetData}
            // ********************************************************************************
          />
          <Button
            variant="link"
            className="mt-3 w-100"
            size="lg"
            onClick={handleCloseModal}
          >
            Close This Guide
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalWindow;
