import React, { Component } from "react";
import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
import LoadingSpinner from "../../common/loadingSpinner";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import {
  getCurrentUserData,
  getCurrentUser,
  userLogout,
} from "../../../services/user";
import { logEvent } from "../../../services/log";
import DemoBar from "../../common/demoBar";

class AccountVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      data: {
        fullname: "",
        identification: "",
        email: "",
        dob: "",
        mobile: "+65",
        parish: 0,
        preferredmasstiming: "",
      },
      errors: {},
      parish: [],
      loading: true,
      emailverificationsentfailed: false,
    };
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        fullname,
        email,
        mobile,
        dob,
        identification,
        parish,
        preferredmasstiming,
      } = user;

      this.setState({
        data: {
          fullname,
          email,
          mobile,
          dob,
          identification,
          parish,
          preferredmasstiming,
        },
        loading: false,
      });

      if (this.props.user.emailVerified) {
        if (parish) {
          this.props.history.push("/account/success");
          return;
        } else {
          this.props.history.push("/account/setup");
          return;
        }
      }
      /*
          potentially redirect to home if filled in already
          */
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  requestForgot = async () => {
    const { user } = this.state;
    await userLogout();
    logEvent("request_forgot_password", {
      email: user.email,
    });
    this.props.history.push("/forgot");
  };

  doSubmit = async () => {
    try {
      await userLogout();
      // signed out
      window.location.reload();
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  async doResend() {
    let currentUser = await getCurrentUser();
    try {
      await currentUser.sendEmailVerification();
      logEvent("send_email_verification_success", {
        email: currentUser.email,
      });
      // console.log("Done sending email verification");
      this.props.history.push("/home");
    } catch (error) {
      console.error("Error sending email: ", error);
      logEvent("send_email_verification_fail", {
        email: currentUser.email,
        message: error.message,
      });
      this.setState({
        emailverificationsentfailed: true,
        loading: false,
      });
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, data } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <DemoBar />
        <div className="row justify-content-center mt-5  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 text-center">
                <h1 className="pb-2 mt-2">
                  <EmailOutlinedIcon
                    style={{ fontSize: "150px" }}
                    className="mycatholicsgred"
                  />
                </h1>
                <h1 className="py-2">Verify your email</h1>
                <h5 className="pt-3 pb-4">
                  We have sent a verification email to {data.email}
                </h5>
                <p className="pb-2">
                  Please click on the link in the email to complete your signup.
                </p>
                <hr />
                <div className="py-2 text-left">
                  <p className="pb-0 mb-0">
                    <strong>Did not receive the verification email?</strong>
                  </p>
                  <ul className="pl-4">
                    <li> Check your spam folder after few minutes</li>
                    <li>
                      <span className="float-left">Try to </span>{" "}
                      <button
                        className="btn-link py-0 pl-1 m-0 float-left btn"
                        onClick={this.requestForgot}
                      >
                        reset your password
                      </button>
                    </li>
                  </ul>
                  {/* <Button
                      variant="link"
                      className="btn-lg mt-0"
                      onClick={this.requestForgot}
                    >
                      resetting your password here
                    </Button> */}
                </div>

                <Button
                  variant="link"
                  className="btn-lg mt-5 "
                  onClick={this.doSubmit}
                >
                  <span className="normalsmallfontsize">Logout</span>
                </Button>
                {/* <div className="py-2 text-center">
                  <p className="defaultfontsize">
                    Got the account? <Link to="/login">Log in</Link>
                  </p>
                </div> */}
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountVerify;
