import React from "react";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { ParishConfig } from "../../hooks/hooks";
import { getOrgShortNameById } from "../../../../../services/organisation";

/**
 * @typedef {Object} DistrictConfig
 * @property {string} variant - The variant color of the district.
 * @property {string} name - The name of the district.
 */

/** @type {{ [district: string]: DistrictConfig }} */
const districtsConfig = {
  city: { variant: "secondary", name: "City" },
  serangoon: { variant: "success", name: "Serangoon" },
  north: { variant: "danger", name: "North" },
  east: { variant: "primary", name: "East" },
  west: { variant: "info", name: "West" },
  other: { variant: "dark", name: "Other" },
};

/**
 * @typedef ParishPillProps
 * @prop {ParishConfig} parish
 */

/**
 * @param {ParishPillProps} props
 */
export default function ParishPill({
  parish: { id, parish: parishName, district },
}) {
  try {
    const parishShortName = getOrgShortNameById(id);
    const { variant, name: districtName } = districtsConfig[district];

    const renderTooltip = (props) => (
      <Tooltip id={`parish-${parishName}-tooltip`} {...props}>
        <span className="font-weight-bold">{parishName}</span>
        <br />({districtName})
      </Tooltip>
    );

    return (
      <OverlayTrigger
        placement="top"
        delay={{ hide: 400 }}
        overlay={renderTooltip}
      >
        <Badge variant={variant}>{parishShortName}</Badge>
      </OverlayTrigger>
    );
  } catch (e) {
    // console.log({ status: statusValue, dict: statusDictionary[statusValue] });
    return id;
  }
}
