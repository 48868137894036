import React, { Component } from "react";
import { enableDemoBar } from "../../services/settings";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

class DemoBar extends Component {
  render() {
    const enabledemobar = enableDemoBar();
    if (
      enabledemobar &&
      process.env.REACT_APP_FIREBASE_PROJECT_ID !== "mycatholicsg-prod01"
    ) {
      return (
        <div className="px-2 mb-2 py-2 text-center text-white clickablebar bg-danger">
          <h5>
            <WarningRoundedIcon className="my-2 xlargefontsize" />
            <div>
              DEMO SITE.
              <br /> Visit Official Website at{" "}
              <a
                target="_blank"
                className="text-white dangerunderline"
                rel="noopener noreferrer"
                href="https://mycatholic.sg"
              >
                https://mycatholic.sg
              </a>
            </div>
          </h5>
        </div>
      );
    }
    return null;
  }
}

export default DemoBar;
