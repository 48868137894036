import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";

const payPlusQRCodeImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fmassintentions%2FpayPlusQRCode.d4ee22fd.png?alt=media&token=939fcee4-8c96-4e44-a053-76aabfbb207d";
const scanAndPayButtonImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fmassintentions%2FscanAndPayButton.jpg?alt=media&token=9ee80c42-c7aa-4e95-909d-96733948ee83";

const PaymentGuide = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [viewPaymentGuideMode, setViewPaymentGuideMode] = useState("mobile"); // Sets whether to show the guide for "Mobile/Tablet" or "Laptop/Desktop".

  const handleViewMobilePaymentGuide = () => {
    setViewPaymentGuideMode("mobile");
  };

  const handleViewDesktopPaymentGuide = () => {
    setViewPaymentGuideMode("desktop");
  };

  // ************************ Payment guide sections ************************
  const paymentGuideTopSection = () => {
    return (
      <>
        {viewPaymentGuideMode !== null ? (
          <div>
            <p className="pt-2">
              Payment has to be made using the{" "}
              <span className="text-danger font-weight-bold">
                <em>Scan & Pay</em>
              </span>{" "}
              feature on your bank app.
            </p>
            <div className="row mb-4">
              <div className="col" style={{ textAlign: "left" }}>
                <Button
                  variant="link"
                  style={{ marginLeft: "-12px" }}
                  onClick={handleViewMobilePaymentGuide}
                >
                  Mobile/Tablet
                </Button>
              </div>
              <div className="col" style={{ textAlign: "left" }}>
                <Button
                  variant="link"
                  style={{ marginLeft: "-12px" }}
                  onClick={handleViewDesktopPaymentGuide}
                >
                  Laptop/Desktop
                </Button>
              </div>
              <div className="col">&nbsp;</div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const paymentGuideMainSection = () => {
    switch (viewPaymentGuideMode) {
      case "mobile":
        return (
          <>
            <h5 className="my-4">From your mobile/tablet device</h5>
            <div className="row">
              <div className="col-2" style={{ position: "relative" }}>
                <span className="mass-intention-help-circle-number"></span>
                <h5
                  className="mass-intention-help-circle-number-text"
                  style={{ left: "30px" }}
                >
                  1
                </h5>
              </div>
              <div className="col-10 pl-2">
                Tap and hold the QR code on your mobile phone, then select the
                option{" "}
                <span className="text-danger font-weight-bold">
                  <em>Add to Photos</em>
                </span>
                .
                <div className="mt-3 mb-5" style={{ textAlign: "center" }}>
                  <Image src={payPlusQRCodeImageUrl} width="320px" thumbnail />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <span className="mass-intention-help-circle-number"></span>
                <h5
                  className="mass-intention-help-circle-number-text"
                  style={{ left: "29px" }}
                >
                  2
                </h5>
              </div>
              <div className="col-10 pl-2 pb-3">
                Open your bank app and tap on the feature{" "}
                <span className="text-danger font-weight-bold">
                  <em>Scan & Pay</em>
                </span>
                .
                <div className="mt-3 mb-4" style={{ textAlign: "center" }}>
                  <Image
                    src={scanAndPayButtonImageUrl}
                    width="320px"
                    thumbnail
                  />
                </div>
                Upload the QR code you have just saved onto the bank app.
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2">
                <span className="mass-intention-help-circle-number"></span>
                <h5
                  className="mass-intention-help-circle-number-text"
                  style={{ left: "29px" }}
                >
                  3
                </h5>
              </div>
              <div className="col-10 pl-2">
                Payment amount and reference number will be reflected on your
                mobile phone.
                <br />
                <br />
                You can now make payment.
              </div>
            </div>
          </>
        );

      case "desktop":
        return (
          <>
            <h5 className="my-4">From your laptop/desktop computer</h5>
            <div className="row">
              <div className="col-2">
                <span className="mass-intention-help-circle-number"></span>
                <h5
                  className="mass-intention-help-circle-number-text"
                  style={{ left: "30px" }}
                >
                  1
                </h5>
              </div>
              <div className="col-10 pl-0 pl-2">
                Open your bank app and tap on the feature{" "}
                <span className="text-danger font-weight-bold">
                  <em>Scan & Pay</em>
                </span>
                .
                <div className="mt-3 mb-4" style={{ textAlign: "center" }}>
                  <Image
                    src={scanAndPayButtonImageUrl}
                    width="320px"
                    thumbnail
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2" style={{ position: "relative" }}>
                <span className="mass-intention-help-circle-number"></span>
                <h5
                  className="mass-intention-help-circle-number-text"
                  style={{ left: "29px" }}
                >
                  2
                </h5>
              </div>
              <div className="col-10 pl-0 pl-2">
                Scan the QR code with your bank app.
                <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
                  <Image src={payPlusQRCodeImageUrl} width="320px" thumbnail />
                </div>
                Payment amount and reference number will be reflected on your
                mobile phone.
                <br />
                <br />
                You can now make payment.
              </div>
            </div>
          </>
        );

      case null:
        return null;

      default:
        return null;
    }
  };
  // ************************************************************************

  return (
    <div className="px-1">
      {paymentGuideTopSection()}
      {paymentGuideMainSection()}
    </div>
  );
};

export default PaymentGuide;
