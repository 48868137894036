import React from "react";

const Input = ({
  name,
  label,
  error,
  showsuccess,
  disabled = false,
  css = "",
  bold = false,
  inputSize = "lg",
  labelextra = null,
  ...rest
}) => {
  let formClass = `form-control form-control-${inputSize} `;
  if (error) {
    formClass = `${formClass} is-invalid`;
  }
  if (showsuccess) {
    formClass = `${formClass} is-valid`;
  }
  return (
    <div className={`form-group ${css}`}>
      {bold === false
        ? label && (
            <label htmlFor={name}>
              {label} {labelextra}
            </label>
          )
        : label && (
            <label htmlFor={name}>
              <span className="font-weight-bold">
                {label}
                {labelextra}
              </span>
            </label>
          )}

      <input
        {...rest}
        name={name}
        id={name}
        className={formClass}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        disabled={disabled}
      />
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default Input;
