import React, { Component } from "react";
import Header from "./common/header";
import { Link } from "react-router-dom";
import ParishLogo from "./parish/parishLogoBlock";
import { Carousel, Button } from "react-bootstrap";
import {
  getCurrentUserData,
  userLogout,
  isUserLoggedIn,
  getCurrentUser,
} from "../services/user";
import {
  getSuperAdminCode,
  getArchdioceseCode,
  formatTime,
} from "../utils/utils";
import SystemBarError from "./common/systemBarError";
// import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import {
  getParishLinkById,
  getParishSafeEntryIdById,
  getParishPewFormIdById,
  getBannerEnabledByCode,
  getParishDevotionAllowedByCode,
  getGenericEventObjById,
  getPostEnabledById,
  getBulletinEnabledByCode,
  DEFAULT_PARISH_CODE,
} from "../services/parish";
import LoadingSpinnerNav from "./common/loadingSpinnerNavbar";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import MainMenuCardSimplePanel from "./blocks/mainMenuCardPanelSimpleBlock";
import { getAllAnnouncementsByParish } from "./announcement/service/announcement";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";
import EditIcon from "@material-ui/icons/Edit";
import AirlineSeatReclineNormalOutlinedIcon from "@material-ui/icons/AirlineSeatReclineNormalOutlined";
import SafeEntryLogo from "../img/safeentry/safeentry.png";
import Linkify from "react-linkify";
import { isValidFullname } from "../services/validation";
import { welcomeGreetings } from "../services/greetings";
import Footer from "./common/footer";
import YouTubeIcon from "@material-ui/icons/YouTube";
// import ClickBar from "./common/clickableBar";
import ReloadModal from "./common/reloadModal";
import BrowserTime from "./common/browserTime";
import DemoBar from "./common/demoBar";
import TimezoneWarning from "./common/timezoneWarning";
import Banner from "./common/banner";
import { getActiveEventMenuLinkList } from "../utils/event";
import { logEvent } from "../services/log";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import PostCard from "./instapost/component/postCard";
import { getPosts } from "./instapost/service/post";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SpinnerText from "./common/spinnerwithText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import YouTubeVideo from "./common/youTubeVideo";
// import SelfCheckinImage from "./common/selfCheckinImage";
import { isFestivalScannerRole } from "./roles/service/roles";
import OfferingGiving from "./booking/component/offeringGiving";
import InvisibleComponents from "./common/InvisibleComponents";
import { isEnableUnaffiliated } from "../services/settings";
import Catholic from "./catholic/page/catholic";
import Consent from "./consent/page/consent";
import ResetPassword from "./account/component/resetPassword";
import {
  isPFSTicketInChargeRole,
  isPFSTicketVolunteerRole,
} from "./roles/service/v2/roles";
import BannerCarousel from "./banner/component/bannerCarousel";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menutype: "light",
      superadmincode: getSuperAdminCode(),
      archdiocesecode: getArchdioceseCode(),
      data: {
        fullname: "",
        email: "",
        mobile: "",
        dob: "",
        identification: "",
        parish: 0,
        parishcode: "",
        askconsent: false,
      },
      announcementlist: [],
      systemannouncementlist: [],
      user: this.props.user,
      loading: true,
      viewmore: false,
      viewall: false,
      postloading: false,
      askcatholic: false,
      hasresetpass: false,
    };
  }

  async getAnnouncements(parish) {
    //retrieve data from firestore
    if (!parish) {
      return;
    }
    try {
      const announcement = await getAllAnnouncementsByParish(parish);
      //extract system
      const systemnotification = announcement.filter(
        (item) => item.parish === getSuperAdminCode() && !item.isArchived
      );
      const publicnotification = announcement.filter(
        (item) => item.parish !== getSuperAdminCode() && !item.isArchived
      );
      this.setState({
        loading: false,
        announcementlist: publicnotification,
        systemannouncementlist: systemnotification,
      });
    } catch (error) {
      console.error("Announcements not yet created");
      this.setState({ loading: false });
    }
  }

  getCurrentUserData = async () => {
    try {
      const uid = this.state.user.uid;
      const user = await getCurrentUserData(uid);

      if (user) {
        const {
          fullname,
          email,
          mobile,
          dob,
          identification,
          parish,
          created,
          disabled,
          smallpic,
          userid,
          payplusid,
          likes,
          catholic,
          agreeterms,
          agreemcsg,
          emailUnsub,
          hasresetpass,
          unaffiliated,
        } = user;

        if (!parish) {
          this.props.history.push("/account/setup");
          return;
        }
        if (disabled === true) {
          await userLogout();
          this.props.history.push("/login");
          return;
        }

        //check if user is catholic or non catholic, then redirect to appropriate page
        let askcatholic = true;
        if (catholic === false || catholic === true) {
          askcatholic = false;
        }
        let askconsent = true;
        if (agreeterms === true && agreemcsg === true) {
          //this user has already set consent, users will not be able to use this system if all are false or undefined
          askconsent = false;
        }
        let forceresetpass = true;
        if (hasresetpass === true) {
          forceresetpass = false;
        }

        const parishcode = getParishLinkById(parish);
        let userParish = parish;
        //check if parish is unaffiliated
        if (isEnableUnaffiliated() && unaffiliated === true) {
          userParish = DEFAULT_PARISH_CODE;
        }

        //check if valid fullname
        const isvalidfullname = isValidFullname(fullname);

        this.setState({
          isvalidfullname,
          askcatholic,
          askconsent,
          forceresetpass,
          data: {
            userid,
            payplusid,
            created,
            fullname,
            email,
            mobile,
            dob,
            identification,
            parish: userParish,
            parishcode,
            smallpic,
            likes,
            agreeterms,
            agreemcsg,
            emailUnsub,
          },
        });

        await this.getAnnouncements(userParish);
        await this.getPosts(userParish);
      } else {
        //USER NOT FOUND
        //LOGOUT!!!
        await userLogout();
        window.location.reload();
      }
    } catch (error) {
      console.log("Error:", error);
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  };

  async getPosts(parish) {
    const likes = this.state.data.likes;
    const postenabled = getPostEnabledById(parish);
    let post = postenabled ? await getPosts(parish, 4) : [];
    //if contents are more than 3, show view all, and redirect
    let viewall = post.length > 3 ? true : false;
    let viewmore = post.length > 1 ? true : false;
    let firstpost = null;
    if (post.length) {
      let like = [];
      if (likes && likes.length) {
        like = likes.filter((item) => item.objid === post[0].id);
      }
      //set value of liked
      post[0].liked = like.length ? true : false;
      //get the first post
      firstpost = [post[0]];
    }
    this.setState({
      maxposts: post,
      posts: firstpost,
      viewmore,
      viewall,
    });
  }

  async componentDidMount() {
    const loggedIn = await isUserLoggedIn();
    if (!loggedIn) {
      this.props.history.push("/login");
      return;
    }
    if (isFestivalScannerRole(this.state.user)) {
      this.props.history.push("/festival/attendance");
      return;
    }
    if (
      (await isPFSTicketVolunteerRole(await getCurrentUser())) ||
      (await isPFSTicketInChargeRole(await getCurrentUser()))
    ) {
      this.props.history.push("/commandcenter/pfsticket");
      return;
    }
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  displayBasicNotification(item) {
    return (
      <Carousel.Item key={item.id}>
        <div className="d-block w-100">
          <h3>{item.header}</h3>
          <p>{item.content}</p>
        </div>
      </Carousel.Item>
    );
  }

  getAnnouncementLink(item) {
    const label = item.header;
    const link = `/announcement/view/${item.id}`;
    return <Link to={link}>{label}</Link>;
  }

  displayExternalLinks(parish) {
    const safeentrylink = getParishSafeEntryIdById(parish);
    const pewform = getParishPewFormIdById(parish);
    return (
      <div className="row">
        {(safeentrylink || pewform) && (
          <div className="clearfix my-2 col-12">
            <h5 className="">Quick Links</h5>
          </div>
        )}
        {safeentrylink && (
          <div className="mt-0 mb-5 mr-0 px-0 col text-center">
            <a target="_blank" rel="noopener noreferrer" href={safeentrylink}>
              <img
                alt="SafeEntry"
                src={SafeEntryLogo}
                height="25px"
                className="d-inline-block align-top"
              />
            </a>
          </div>
        )}

        {pewform && (
          <div className="mt-0 mb-5 ml-0 pl-0 col text-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="defaultfontsize"
              href={pewform}
            >
              <AirlineSeatReclineNormalOutlinedIcon
                className="ml-0 text-primary"
                style={{
                  fontSize: "28px",
                  marginTop: "-6px",
                  marginRight: "0px",
                }}
              />{" "}
              Pew Number
            </a>
          </div>
        )}
      </div>
    );
  }

  componentDecorator = (href, text, key) => {
    let display = (
      <>
        Click here <LaunchOutlinedIcon className="mediumfontsize" />
      </>
    );
    if (href.startsWith("mailto")) {
      display = text;
    }
    return (
      <a href={href} key={key} rel="noopener noreferrer" target="_blank">
        {display}
      </a>
    );
  };

  displayNotification(item) {
    let substr = item.content;
    let timeago = Date.now() - item.created;
    if (timeago < 7 * 24 * 60 * 60 * 1000) {
      timeago = formatTime(item.created);
      timeago = (
        <span className={` badge badge-pill badge-danger`}>{timeago}</span>
      );
    } else {
      timeago = null;
    }
    return (
      <Carousel.Item key={item.id}>
        <div className="d-block w-100">
          <div
            className="alert darkgraypanel mb-0 py-3  carousel-padding"
            role="alert"
            key={item.id}
          >
            <p className="mb-0 text-truncate dangerunderline announcementpanelheaderblue defaultfontsize font-weight-bold">
              {this.getAnnouncementLink(item)}
            </p>
            <p className="mb-0 lineentry">
              <Linkify componentDecorator={this.componentDecorator}>
                {substr}
              </Linkify>
            </p>

            <div className="text-truncate float-left">
              {item.youtube && (
                <>
                  <YouTubeIcon className="normal-youtube-icon" />{" "}
                </>
              )}
              {!item.youtube && (
                <WatchLaterOutlinedIcon
                  className="text-muted"
                  style={{ fontSize: "14px" }}
                />
              )}{" "}
              <small className="text-muted">
                {new Date(item.created).toDateString()} {timeago}
              </small>{" "}
            </div>
            <div className="float-right dangerunderline">
              <Link
                style={{ textDecoration: "underline" }}
                to={`/announcement/view/${item.id}`}
              >
                Learn More
              </Link>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </Carousel.Item>
    );
  }

  getSystemAnnouncements() {
    const { announcementlist } = this.state;

    const content = (
      <div className="mt-4 mb-3">
        {announcementlist.length > 0 && (
          <Carousel indicators={false} controls={true}>
            {announcementlist.map((item) => this.displayNotification(item))}
          </Carousel>
        )}
      </div>
    );

    return content;
  }

  getMainMenuPanels = (parish) => {
    const parishcode = this.state.data.parishcode;
    const menutype = this.state.menutype;
    const bannerenabled = getBannerEnabledByCode(parishcode);
    const bulletinenabled = getBulletinEnabledByCode(parishcode);
    const devotionallowed = getParishDevotionAllowedByCode(parishcode);
    const supportparishlink = `support/${parishcode}`;
    const parishlink = `parish/${parishcode}`;
    const massbookinglink = `/schedules`;
    const massintentionlink = "/massintentions";
    const devotionlink = `devotion/selector/${parishcode}`;
    const additionalElements = [];
    const finalAdditionalElements = [];

    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink={massbookinglink}
        menulinklabel="Mass Schedules"
        menuicon="currentcalendar"
        menutype={menutype}
        isSmall
      />
    );

    // **** To uncomment this when Mass Intentions is ready to go live. ****
    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink={massintentionlink}
        menulinklabel="Mass Intentions"
        menuicon="celebration"
        menutype={menutype}
        isSmall
      />
    );
    // *********************************************************************

    if (bulletinenabled) {
      additionalElements.push(
        <MainMenuCardSimplePanel
          menulink={`/bulletin/${parishcode}`}
          menulinklabel="Bulletin"
          menuicon="bulletin"
          menutype="light"
          isSmall
        />
      );
    }

    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink="/catch"
        menulinklabel="Catechesis"
        menuicon="catechesis"
        menutype={menutype}
        isSmall
      />
    );

    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink="/events"
        menulinklabel="Events Hub"
        menuicon="celebration"
        menutype={menutype}
        isSmall
      />
    );

    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink="/apc"
        menulinklabel="Pray for APC"
        menuicon="support"
        menutype={menutype}
        isSmall
      />
    );

    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink="/forms"
        menulinklabel="Forms"
        menuicon="addnote"
        menutype={menutype}
        isSmall
      />
    );

    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink="/commune"
        menulinklabel="Commune Coworking"
        menuicon="work"
        menutype={menutype}
        isSmall
      />
    );

    if (devotionallowed) {
      additionalElements.push(
        <MainMenuCardSimplePanel
          menulink={devotionlink}
          menulinklabel="Devotion"
          menuicon="devotion"
          menutype={menutype}
          isSmall
        />
      );
    }
    //get generic event obj of the parish
    const genericeventobj = getGenericEventObjById(parish);
    const menulist = getActiveEventMenuLinkList(genericeventobj, parishcode);
    // console.log({ menulist });

    for (let i = 0; i < menulist.length; i++) {
      additionalElements.push(
        <MainMenuCardSimplePanel
          menulink={menulist[i].link}
          menulinklabel={menulist[i].name}
          menuicon="currentcalendar"
          menutype={menutype}
          isSmall
        />
      );
    }

    // if (this.state.user?.archAssembly?.isDelegate) {
    //   additionalElements.push(
    //     <MainMenuCardSimplePanel
    //       menulink="/assembly"
    //       menulinklabel="Arch Assembly"
    //       menuicon="organisation"
    //       menutype="light"
    //       isSmall
    //     />
    //   );
    // }

    // additionalElements.push(
    //   <MainMenuCardSimplePanel
    //     menulink="/synod"
    //     menulinklabel="Synod"
    //     menuicon="synod"
    //     menutype="light"
    //     isSmall
    //   />
    // );
    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink="/payplus"
        menulinklabel="Pay✝ Payments"
        menuicon="payment"
        menutype="light"
        isSmall
      />
    );
    // additionalElements.push(
    //   <MainMenuCardSimplePanel
    //     menulink="/catechesis"
    //     menulinklabel="Register for Catechism"
    //     menuicon="catechesis"
    //     menutype={menutype}
    //     isSmall
    //   />
    // );
    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink={supportparishlink}
        menulinklabel="Offertory & Giving"
        menuicon="support"
        menutype="light"
        isSmall
      />
    );
    additionalElements.push(
      <MainMenuCardSimplePanel
        menulink={parishlink}
        menulinklabel="Parish Information"
        menuicon="parishinfo"
        menutype={menutype}
        isSmall
      />
    );

    // This is a recursive fn to display additional elements in a two column format
    const displayAdditionalElements = (
      currentElements,
      newAdditionalElements
    ) => {
      if (newAdditionalElements.length === 0) {
        return currentElements;
      }

      if (newAdditionalElements.length > 1) {
        currentElements.push(
          <div className="row" key={newAdditionalElements.length}>
            <div className="col-6" style={{ padding: "0 10px" }}>
              {newAdditionalElements[0]}
            </div>
            <div className="col-6" style={{ padding: "0 10px" }}>
              {newAdditionalElements[1]}
            </div>
          </div>
        );
        newAdditionalElements.splice(0, 2);
        return displayAdditionalElements(
          currentElements,
          newAdditionalElements
        );
      }

      if (newAdditionalElements.length === 1) {
        currentElements.push(
          <div className="row" key={newAdditionalElements.length}>
            <div className="col-6" style={{ padding: "0 10px" }}>
              {newAdditionalElements[0]}
            </div>
          </div>
        );
        return currentElements;
      }
    };

    const banner = bannerenabled ? <Banner parish={parseInt(parish)} /> : null;
    const post = this.getPostPanel();
    return (
      <div className="row justify-content-between px-1">
        <div className="container">
          {banner}
          {displayAdditionalElements(
            finalAdditionalElements,
            additionalElements
          )}
          <div className="clearfix"></div>
          <div className="card pt-3 pb-4 text-center my-4">
            <OfferingGiving actor={this.state.data} />
          </div>
          {/* <SelfCheckinImage /> */}
          {/* <div className="my-4">
            <YouTubeVideo
              title="Book Mass in Another Parish"
              youtubeid="WrwAgCAOC7o"
              frameid="bookmassanotherparish"
              newvideo={true}
              description="Beginning 1 March 2022, parishioners will be able to book for Masses at another church - other than their registered church."
              buttonlabel="Read More"
              buttonlink="https://www.catholic.sg/resumption-of-mass47/"
            />
          </div> */}

          <div className="clearfix"></div>
          {/* <div className="mt-4">
            <hr />
            <h5 className="mt-4 text-left">Catholic200SG Wall of Hope</h5>
            <div className="normalfontsize pt-0 mt-0 mb-3 text-left">
              Share your hopes for the future of the Catholic Church by posting
              a message! You can also make a small love offering if you feel
              called to do so.
            </div>
            <WallOfHopeBlock
              userData={this.state.data}
              publicWallLink="/hope"
            />
          </div> */}
          {post}
        </div>
      </div>
    );
  };

  likeCallback = (obj) => {
    this.setState({ data: { ...this.state.data, likes: obj } });
  };

  viewMoreHandler = async () => {
    const viewmore = this.state.viewmore;
    const maxposts = this.state.maxposts;
    const user = this.state.user;

    if (viewmore) {
      this.setState({ postloading: true });
    }
    logEvent("post_viewmore_clicked", {
      email: user.email,
      parish: user.parish,
    });

    let post;
    //update posts here
    if (viewmore) {
      let limit = maxposts.length;
      if (maxposts.length > 3) {
        //limit only to 3 that's why we use -1
        limit = limit--;
      }
      const likes = this.state.data.likes;
      for (let i = 0; i < limit; i++) {
        const entry = maxposts[i];
        let like = [];
        if (likes && likes.length) {
          like = likes.filter((item) => item.objid === entry.id);
        }
        maxposts[i].liked = like.length ? true : false;
      }
      //get 3 posts for display
      post = maxposts;
    } else {
      //get only 1 post for display
      post = [maxposts[0]];
    }
    this.setState({
      viewmore: !viewmore,
      posts: post,
      postloading: false,
      maxposts,
    });
  };

  getPostPanel = () => {
    const { posts, maxposts, viewmore, viewall, data, postloading } =
      this.state;
    const { userid, fullname, email, mobile, parish, parishcode, smallpic } =
      data;
    const owner = {
      userid,
      fullname,
      email,
      mobile,
      parish,
      parishcode,
      smallpic,
    };

    let postcard = null;
    let showmore = null;
    let showall = null;
    if (posts && posts.length) {
      //add padding dynamically
      let padding = "mb-1 pb-1";
      let counter = 0;

      postcard = posts.map((post) => {
        if (counter < 3) {
          counter++;

          return (
            <PostCard
              variant="bg-white"
              showstats={true}
              source="homepage"
              item={post}
              user={owner}
              key={post.id}
              likecallback={this.likeCallback}
            />
          );
        }
        //ignore entries more than 4
        return null;
      });

      if (maxposts.length > 1) {
        //if posts are more than 1, add showmore
        padding = "mb-4 pb-4";

        showmore = (
          <div className="float-right pointer" style={{ marginTop: "-10px" }}>
            <div
              className="text-dark font-weight-bold pointer mr-2"
              onClick={async () => await this.viewMoreHandler()}
            >
              {viewmore ? (
                <>
                  <ExpandMoreIcon className="adjusticon defaultfontsize" /> Show
                  more{" "}
                  {postloading && <SpinnerText text="" color="lightspinner" />}
                </>
              ) : (
                <>
                  <ExpandLessIcon className="adjusticon defaultfontsize" /> Show
                  less
                </>
              )}
            </div>
          </div>
        );
      }

      showall = (
        <>
          <div className="clearfix"></div>
          <div className="pt-0 text-center" style={{ marginTop: "0px" }}>
            <Button
              variant="light"
              className="btn-sm bg-white text-primary"
              onClick={this.handleViewAll}
            >
              View All
            </Button>
          </div>
        </>
      );

      postcard = (
        <div className={`mt-3 ${padding} px-1 roundcorner darkgraypanel`}>
          <div
            className="font-weight-bold pl-2 pt-2 mb-0 pb-0 darkgraypanel"
            style={{ marginTop: "5px" }}
          >
            Recent Posts
          </div>
          {postcard}
          {/* show "Show more" if more than 1*/}
          {showmore}
          {/* optionally show link to View All Button */}
          {!viewmore && viewall && showall}
        </div>
      );
    }

    return postcard;
  };

  doLogout = async () => {
    try {
      await userLogout();
      window.location.reload();

      // signed out
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  handleClick = () => {
    logEvent("thankyou_charis", {
      type: "CHARIS",
    });
    let win = window.open(
      "https://makehopehappen.charis-singapore.org/index.php/2021/india-covid-19-response#pb-content-638",
      "_blank"
    );
    win.focus();
  };

  handleViewAll = () => {
    const user = this.state.user;

    logEvent("post_view_all", {
      email: user.email,
      parish: user.parish,
    });
    this.props.history.push("/posts");
  };

  displayAdditionalBtns = () => {};

  render() {
    const {
      data,
      loading,
      user,
      isvalidfullname,
      askcatholic,
      askconsent,
      forceresetpass,
    } = this.state;
    let parish = null;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          user={user}
          smallpic={user.photoURL}
          hide="profile"
        />
      );
    }

    if (data.parish) {
      parish = data.parish;
    }
    //add consent here before asking for catholic
    if (askconsent) {
      return <Consent user={user} data={data} />;
    }

    //add askcatholic here
    if (askcatholic) {
      return <Catholic user={user} />;
    }

    //add for password reset flow here?
    if (forceresetpass) {
      return <ResetPassword user={user} />;
    }

    return (
      <React.Fragment>
        <Header user={user} smallpic={user.photoURL} />
        <DemoBar />
        <ReloadModal />
        {/* {!user.photoURL && (
          <ClickBar text="Upload your own profile photo?" link="/profile" />
        )} */}
        {!isvalidfullname && (
          <SystemBarError text="Update your Full Name" link="/account/edit" />
        )}
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6 px-0">
            <main className="container text-left">
              <div className="d-flex mt-3">
                <ParishLogo
                  parishId={parish}
                  parishCode={data.parishcode}
                  isSmall
                />
                <div className=" d-flex flex-column justify-content-center px-3 pt-0">
                  <h5 className="pt-2 pb-1 mb-0">
                    {welcomeGreetings(data.created)}
                  </h5>
                  <h5 className="py-0 mb-1">
                    {isvalidfullname ? (
                      data.fullname
                    ) : (
                      <Link to="/account/edit">
                        <strong className="text-danger">
                          {data.fullname} <EditIcon />
                        </strong>
                      </Link>
                    )}
                  </h5>
                  <BrowserTime />
                </div>
              </div>
              <div className="row pt-0 px-2 defaultfontsize">
                <AccountCircleOutlinedIcon className="ml-2 mr-0 text-primary accountcircle" />{" "}
                <Link to="/profile">
                  <span className="font-italic ml-1"> my</span>Profile
                </Link>
                <EventNoteIcon className="ml-3 text-primary eventnote" />{" "}
                <Link to="/mybookings">
                  {" "}
                  <span className="font-italic">my</span>Bookings
                </Link>
              </div>
              {this.getSystemAnnouncements()}
              <h5 className="text-muted mt-0 mb-3">
                What would you like to do today?
              </h5>
              <BannerCarousel source="home" />
              {/* <div className="pb-3">
                <NotificationsActiveIcon className="adjusticon biggerfontsize text-danger mr-2" />
                <Link
                  to={`/schedules/${data.parishcode}`}
                  className="text-danger"
                >
                  See <u>Mass Schedules</u>
                </Link>{" "}
                <span
                  style={{ marginLeft: "5px", fontSize: "20px" }}
                  role="img"
                  aria-label="party"
                >
                  🎉
                </span>
              </div> */}
              <TimezoneWarning user={user} data={data} />

              {this.getMainMenuPanels(parish)}

              <div className="clearfix"></div>
              {/* <YoutubeWrapper
                className="text-left mb-0"
                title="🙏 Thank You from CHARIS"
                youtubeid="T5tg90Zipzk"
                frameid="charis_thank_you"
                onPlay={this.onPlay}
              />

              <div className="normalfontsize pt-0 mt-0 mb-0 text-left">
                Find out more on how contributions from Catholics in Singapore
                during the recent appeal for CHARIS' India Covid-19 Response
                have impacted the community in India.
                <div className="float-right">
                  <Button
                    variant="outline-danger"
                    className="btn-md mx-auto mt-2 mb-4"
                    onClick={this.handleClick}
                  >
                    <span className="">
                      Read Updates{" "}
                      <OpenInNewOutlinedIcon style={{ fontSize: "14px" }} />
                    </span>
                  </Button>
                </div>
              </div> */}

              <div className="my-3 py-0"></div>
              {/* {this.displayExternalLinks(parish)} */}
              <Button
                variant="primary"
                className="btn-block mt-0 mb-4"
                onClick={this.doLogout}
              >
                Logout
              </Button>
              <hr />
              <div className="pb-0 mb-0 pt-0 px-0 mx-0 text-center">
                <div className="float-left  pt-0 defaultfontsize pr-1">
                  Helping someone sign up?
                </div>
                <div className="float-left ">
                  <Button
                    variant="link"
                    className="btn-block btn-lg py-0 px-0 mx-0"
                    onClick={this.doLogout}
                  >
                    <span className="">Click here</span>
                  </Button>
                </div>
              </div>
              <Footer />
              <InvisibleComponents user={user} />
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HomePage;
