import React, { useContext } from "react";
import { Row, Col, Container, Card, CardDeck } from "react-bootstrap";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Moment from "moment-timezone";
import moment from "moment";

import { parishes, Parish } from "../../services/parishes";
import AppGlobalContext from "../../../../../AppGlobalContext";
import {
  isSuperAdminRole,
  isCATCHAdminRole,
} from "../../../../roles/service/roles";
import { useRegistrations, useTransfers } from "../hooks/hooks";
import { levelIdToName, levelRef } from "../../services/levels";
import {
  registrationStatusNameToStatus,
  registrationStatusToStatusName,
} from "../../services/registrationStatus";

import ArchdioceseGraph from "./ArchdioceseGraph";
import "../styles.css";

Chart.register(ChartDataLabels);

/**
 * @type {{[name: string]: string[]}}
 */
const CHART_PALETTES = {
  retroMetro: [
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#ede15b",
    "#bdcf32",
    "#87bc45",
    "#27aeef",
    "#b33dc6",
  ],
  dutchField: [
    "#e60049",
    "#0bb4ff",
    "#50e991",
    "#e6d800",
    "#9b19f5",
    "#ffa300",
    "#dc0ab4",
    "#b3d4ff",
    "#00bfa0",
  ],
  riverNights: [
    "#b30000",
    "#7c1158",
    "#4421af",
    "#1a53ff",
    "#0d88e6",
    "#00b7c7",
    "#5ad45a",
    "#8be04e",
    "#ebdc78",
  ],
  springPastels: [
    "#fd7f6f",
    "#7eb0d5",
    "#b2e061",
    "#bd7ebe",
    "#ffb55a",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#00b7c7",
  ],
  ibm: ["#0fb5ae", "#4046ca", "#f68511", "#de3d82", "#7e84fa", "#72e06a"],
};

const ACCEPTED_STATUS = registrationStatusNameToStatus("accepted");

export default function OverviewStatisticGraph() {
  const { user } = useContext(AppGlobalContext);
  const registrations = useRegistrations().data;
  /**
   * Response Time(average)
   *
   */
  const predicate = (reg) => !!reg.processedAt;
  const regCount = registrations.length;
  const averageResponseTime = registrations
    .filter(predicate)
    .map((reg) => {
      let processedAtDate, submittedAtDate;

      if (Object.hasOwn(reg.processedAt, "_seconds")) {
        processedAtDate = new Date(
          reg.processedAt._seconds * 1000 +
            reg.processedAt._nanoseconds / 1000000
        );
      } else {
        processedAtDate = new Date(
          reg.processedAt.seconds * 1000 + reg.processedAt.nanoseconds / 1000000
        );
      }

      if (Object.hasOwn(reg.submittedAt, "_seconds")) {
        submittedAtDate = new Date(
          reg.submittedAt._seconds * 1000 +
            reg.submittedAt._nanoseconds / 1000000
        );
      } else {
        submittedAtDate = new Date(
          reg.submittedAt.seconds * 1000 + reg.submittedAt.nanoseconds / 1000000
        );
      }

      const timeDifference = Math.abs(
        processedAtDate.getTime() - submittedAtDate.getTime()
      );
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      return daysDifference;
    })
    .reduce((acc, val) => acc + val / regCount, 0);

  /**
   * New, by Status
   *
   */
  const dataByStatusArr = [];

  for (const { status } of registrations) {
    const doesExist = dataByStatusArr.some(
      (obj) => obj.name === registrationStatusToStatusName(status)
    );

    if (doesExist) {
      for (const obj of dataByStatusArr) {
        if (obj.name === registrationStatusToStatusName(status)) {
          obj.value += 1;
        }
      }
    } else {
      dataByStatusArr.push({
        name: registrationStatusToStatusName(status),
        value: 1,
      });
    }
  }

  const byStatusGraphData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  };

  dataByStatusArr.forEach((d) => {
    const dataColor =
      CHART_PALETTES.ibm[byStatusGraphData.datasets[0].data.length];
    byStatusGraphData.labels.push(d.name.toUpperCase());
    byStatusGraphData.datasets[0].data.push(d.value);
    byStatusGraphData.datasets[0].backgroundColor.push(dataColor);
    byStatusGraphData.datasets[0].hoverBackgroundColor.push(dataColor);
  });

  /**
   * Inside/Outside Boundary
   *
   */
  const dataByBoundaryArr = [
    { name: "Inside", value: 0 },
    { name: "Outside", value: 0 },
  ];

  registrations.forEach(({ boundary = "", selectedParishId = "" }) => {
    if (
      boundary.toString() === selectedParishId.toString() &&
      boundary !== "" &&
      selectedParishId !== ""
    ) {
      dataByBoundaryArr.find((item) => item.name === "Inside").value += 1;
    } else {
      dataByBoundaryArr.find((item) => item.name === "Outside").value += 1;
    }
  });

  const byBoundaryGraphData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  };

  dataByBoundaryArr.forEach((d) => {
    const dataColor =
      CHART_PALETTES.ibm[byBoundaryGraphData.datasets[0].data.length];
    byBoundaryGraphData.labels.push(d.name.toUpperCase());
    byBoundaryGraphData.datasets[0].data.push(d.value);
    byBoundaryGraphData.datasets[0].backgroundColor.push(dataColor);
    byBoundaryGraphData.datasets[0].hoverBackgroundColor.push(dataColor);
  });

  /**
   * New, by Timeslot
   *
   */
  const dataByTimeslotArr = [];

  registrations
    .filter((reg) => !!reg.timeslotName)
    .forEach(({ timeslot, timeslotName }) => {
      const objName = timeslot;
      var doesExist = dataByTimeslotArr.some(function (obj) {
        return obj.name === objName;
      });

      if (doesExist) {
        dataByTimeslotArr.forEach(function (obj) {
          if (obj.name === objName) {
            obj.value += 1;
          }
        });
      } else {
        dataByTimeslotArr.push({ name: objName, value: 1 });
      }
    });

  const byTimeslotGraphData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  };

  dataByTimeslotArr.forEach((d) => {
    const dataColor =
      byTimeslotGraphData.datasets[0].data.length + 1 >
      CHART_PALETTES.springPastels.length
        ? CHART_PALETTES.dutchField[
            byTimeslotGraphData.datasets[0].data.length -
              CHART_PALETTES.springPastels.length
          ]
        : CHART_PALETTES.springPastels[
            byTimeslotGraphData.datasets[0].data.length
          ];
    byTimeslotGraphData.labels.push(d.name);
    byTimeslotGraphData.datasets[0].data.push(d.value);
    byTimeslotGraphData.datasets[0].backgroundColor.push(dataColor);
    byTimeslotGraphData.datasets[0].hoverBackgroundColor.push(dataColor);
  });

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          // eslint-disable-next-line array-callback-return
          dataArr.map((data) => {
            sum += data;
          });

          let percentage = ((value * 100) / sum).toFixed(2);
          return isNaN(percentage) ? "" : `${percentage}%`;
        },
        color: "black",
        font: {
          weight: "bold",
        },
      },
    },
  };

  /**
   * New, by Time (weekly)
   *
   */
  const startOfWeek = moment().startOf("week");
  const endOfWeek = moment().endOf("week");

  const registrationsForWeek = registrations.filter((reg) => {
    let submittedAt;

    if (Object.hasOwn(reg.submittedAt, "_seconds")) {
      submittedAt = moment(
        new Date(
          reg.submittedAt._seconds * 1000 +
            reg.submittedAt._nanoseconds / 1000000
        )
      );
    } else {
      submittedAt = moment(
        new Date(
          reg.submittedAt.seconds * 1000 + reg.submittedAt.nanoseconds / 1000000
        )
      );
    }

    return submittedAt.isBetween(startOfWeek, endOfWeek, undefined, "[]");
  });

  const getWeekDates = () => {
    const startOfWeek = moment().startOf("week");
    return Array.from({ length: 7 }, (_, i) =>
      startOfWeek.clone().add(i, "days")
    );
  };

  const countsByDate = registrationsForWeek.reduce((acc, reg) => {
    let date;

    if (Object.hasOwn(reg.submittedAt, "_seconds")) {
      date = moment(
        new Date(
          reg.submittedAt._seconds * 1000 +
            reg.submittedAt._nanoseconds / 1000000
        )
      ).format("YYYY-MM-DD");
    } else {
      date = moment(
        new Date(
          reg.submittedAt.seconds * 1000 + reg.submittedAt.nanoseconds / 1000000
        )
      ).format("YYYY-MM-DD");
    }

    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const datesOfLastWeek = Array.from({ length: 7 }, (_, i) =>
    startOfWeek.clone().add(i, "days")
  );
  const countsArray = datesOfLastWeek.map(
    (date) => countsByDate[date.format("YYYY-MM-DD")] || 0
  );

  const chartData = {
    labels: getWeekDates().map((date) => date.format("MMM D, YYYY")),
    datasets: [
      {
        label: "No. of Booking",
        data: countsArray,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
      },
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: "YYYY-MM-DD",
          },
        },
      },
    },
  };

  /**
   * New, by Level
   *
   */
  const dataByLevelArr = [];

  levelRef.forEach((level) => {
    dataByLevelArr.push({
      ...level,
      value: 0,
    });
  });

  registrations.forEach(({ level }) => {
    var doesExist = dataByLevelArr.some(function (obj) {
      return obj.id.toString() === level;
    });

    if (doesExist) {
      dataByLevelArr.forEach(function (obj) {
        if (obj.id.toString() === level) {
          obj.value += 1;
        }
      });
    }
  });

  const byLevelGraphData = {
    labels: [],
    datasets: [
      {
        label: "No. of Booking",
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  };

  dataByLevelArr.forEach((d) => {
    const dataColor =
      CHART_PALETTES.springPastels[byLevelGraphData.datasets[0].data.length];
    byLevelGraphData.labels.push(d.name.toUpperCase());
    byLevelGraphData.datasets[0].data.push(d.value);
    byLevelGraphData.datasets[0].backgroundColor.push(dataColor);
    byLevelGraphData.datasets[0].hoverBackgroundColor.push(dataColor);
  });

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      datalabels: {
        display: false,
        percentage: false,
      },
      labels: {
        render: () => {},
      },
    },
  };

  return (
    <Container>
      <Row className="mb-3 overview-statistic-row-contaier">
        <Col className="mb-3">
          <Card className="justify-content-center">
            <Card.Body>
              Total Enrolled
              <br />
              {
                registrations.filter(({ status }) => status === ACCEPTED_STATUS)
                  .length
              }
            </Card.Body>
          </Card>
        </Col>
        <Col className="mb-3">
          <Card className="justify-content-center">
            <Card.Body>
              New Registration(for{" "}
              {Moment().tz("Asia/Singapore").format("YYYY")})
              <br />
              {
                registrations.filter(({ submittedAt }) => {
                  if (
                    submittedAt == null ||
                    submittedAt === "" ||
                    (typeof submittedAt === "object" &&
                      Object.keys(submittedAt).length === 0)
                  ) {
                    return null;
                  }

                  let dateMoment;

                  if (Object.hasOwn(submittedAt, "_seconds")) {
                    const time = new Date(1970, 0, 1);

                    time.setSeconds(submittedAt._seconds);

                    dateMoment = Moment(time);
                  } else {
                    dateMoment = Moment(submittedAt.toDate());
                  }

                  return (
                    dateMoment.tz("Asia/Singapore").format("YYYY") ===
                    Moment().tz("Asia/Singapore").format("YYYY")
                  );
                }).length
              }
            </Card.Body>
          </Card>
        </Col>
        <Col className="mb-3">
          <Card className="justify-content-center">
            <Card.Body>
              Response Time(average)
              <br />
              {averageResponseTime.toFixed(2)} days
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={4} className="mb-3">
          <div style={{ height: "600px" }}>
            <h5>New, by Status</h5>
            <Doughnut data={byStatusGraphData} options={options} />
          </div>
        </Col>

        <Col md={4} className="mb-3">
          <div style={{ height: "600px" }}>
            <h5>Inside/Outside Boundary</h5>
            <Doughnut data={byBoundaryGraphData} options={options} />
          </div>
        </Col>

        <Col md={4} className="mb-3">
          <div style={{ height: "600px" }}>
            <h5>New, by Timeslot</h5>
            <Doughnut data={byTimeslotGraphData} options={options} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div style={{ height: "400px" }}>
            <h5>New, by Time (weekly)</h5>
            <Line data={chartData} options={chartOptions} />
          </div>
        </Col>
        <Col md={6}>
          <div style={{ height: "400px" }}>
            <h5>All, by Level</h5>
            <Bar data={byLevelGraphData} options={barOptions} />
          </div>
        </Col>
      </Row>
      {(isSuperAdminRole(user) || isCATCHAdminRole(user)) && (
        <Col>
          <Card className="my-2">
            <Card.Body className="d-flex flex-column justify-content-center text-center">
              <h5>Registrations & Transfers</h5>
              <ArchdioceseGraph />
            </Card.Body>
          </Card>
        </Col>
      )}
    </Container>
  );
}
