import React, { Component } from "react";
import GamepadTwoToneIcon from "@material-ui/icons/GamepadTwoTone";
import { Button, Modal } from "react-bootstrap";
import { logEvent } from "../../services/log";
import { CopyToClipboard } from "react-copy-to-clipboard";

class WeeklyMassOfferingBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
      showmodal: false,
      email: this.props.email,
      parish: this.props.parish,
      label: this.props.label,
      uen: this.props.uen,
      style: this.props.css,
    };
  }

  handleClose = () => {
    this.setState({
      showmodal: false,
      copied: false,
    });
  };

  handleCopy = () => {
    const email = this.state.email;
    const parish = this.state.parish;
    const label = this.state.label;
    const logname = `uen_copied_${label}`;
    console.log(logname);
    logEvent(logname, {
      email,
      parish,
    });
    this.setState({ copied: true });
  };

  showModal = () => {
    const email = this.state.email;
    const parish = this.state.parish;
    const label = this.state.label;
    logEvent(label, {
      email,
      parish,
    });
    this.setState({ showmodal: true });
  };

  displayUENModal = () => {
    const showmodal = this.state.showmodal;
    const uen = this.props.uen;
    const copied = this.state.copied;
    return (
      <Modal
        show={showmodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="text-danger">PayNow UEN</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>{uen}</h3>
          {copied && <p className="text-muted">UEN Copied!</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={this.handleClose}>
            Close
          </Button>
          <CopyToClipboard text={uen} onCopy={this.handleCopy}>
            <Button variant="danger">Copy UEN</Button>
          </CopyToClipboard>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    let style = "py-3";
    if (this.state.style) {
      style = this.state.style;
    }

    return (
      <div className={style}>
        <GamepadTwoToneIcon
          style={{
            fontSize: "25px",
            marginTop: "-5px",
            marginRight: "5px",
          }}
        />
        For Weekly Mass Offering:{" "}
        <span className="text-primary pointer uen" onClick={this.showModal}>
          PayNow UEN
        </span>
        {this.displayUENModal()}
      </div>
    );
  }
}

export default WeeklyMassOfferingBar;
