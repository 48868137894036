import fire from "./fire";
import { isValidEmail, isValidParish } from "./validation";
import { getLastDigitId, getSGTime } from "../utils/utils";
import { enableBookAnyParish, getSubIDDigits } from "./settings";
import { getParishIdByCode } from "./parish";
// import { constructSettings } from "google-gax";

export async function isUserLoggedIn() {
  const currentUser = await fire.auth().currentUser;
  if (currentUser) {
    return true;
  }
  return false;
}

export async function userLogout() {
  return await fire.auth().signOut();
}

export async function getCurrentUser() {
  return await fire.auth().currentUser;
}

export async function getCurrentUserData(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    return user.data();
  } else {
    return null;
  }
}

export function attachUserDataListener(uid, setUserState) {
  if (!uid) {
    return;
  }

  const db = fire.firestore();
  db.doc(`users/${uid}`).onSnapshot(
    user => user.exists && setUserState(user.data())
  );
}

export function overrideParish(user, preferredParish) {
  if (enableBookAnyParish()) {
    const parish = getParishIdByCode(preferredParish);
    user.parish = parish.toString();
  }
  return user;
}

export async function updateCurrentUserPhotos(uid, smallPic, bigPic) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({ smallpic: smallPic, bigpic: bigPic });
    return getCurrentUser();
  } else {
    return null;
  }
}

export async function getParishioners(parish) {
  if (!isValidParish(parish)) {
    return null;
  }

  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("parish", "==", parish)
    //.orderBy("fullname", "asc")
    .get();
  var list = [];
  parishioners.forEach(doc => {
    let data = doc.data();
    if (!data.hasOwnProperty("disabled")) {
      list.push(data);
    }
  });
  return list;
}

export async function createWithEmailAndPassword(email, password) {
  let errors;
  let result;
  let simulateError = false;
  try {
    result = await fire.auth().createUserWithEmailAndPassword(email, password);
    if (simulateError) {
      const customError = new Error(
        `Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.`
      );
      customError.code = 11;
      throw customError;
    } else {
      errors = { code: "success", user: result.user };
    }
  } catch (ex) {
    if (ex.code === "auth/email-already-in-use") {
      errors = {
        email: `${ex.message} If you own this account, please try to login instead.`,
        code: ex.code,
        message: ex.message,
      };
    } else if (ex.code === "auth/invalid-email") {
      errors = { email: ex.message, code: ex.code, message: ex.message };
    } else if (ex.code === "auth/operation-not-allowed") {
      errors = { email: ex.message, code: ex.code, message: ex.message };
    } else if (ex.code === "auth/weak-password") {
      errors = {
        password: "Password is too weak",
        code: ex.code,
        message: ex.message,
      };
    } else {
      let tempUser = result.user ? result.user : null;

      errors = {
        email:
          "Unknown error. Please clear your browser cache, reload this page and try again later.",
        code: ex.code,
        message: ex.message,
        user: tempUser,
      };
    }
  }
  return errors;
}

export function isChild(dob) {
  if (!dob) {
    return false;
  }
  const date = getSGTime();
  const year = date.getFullYear();
  // console.log(`year: ${year} - dob: ${dob} = ${year - dob}`);
  //we consider child/kid as 12 years old and below
  if (year - parseInt(dob) <= 12) {
    return true;
  }
  return false;
}

export async function getUserByEmailInAuth(email) {
  const emailtrim = email.toLowerCase().trim();
  if (!isValidEmail(emailtrim)) {
    return null;
  }

  const db = fire.firestore();
  const list = [];
  try {
    const parishioners = await db
      .collection("users")
      .where("email", "==", emailtrim)
      .get();
    parishioners.forEach(doc => {
      list.push(doc.data());
    });
  } catch (e) {
    console.log(e);
  }
  return list;
}

export async function getUserByUid(uid) {
  var list = [];
  if (uid === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("userid", "==", uid)
    .get();

  parishioners.forEach(doc => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserById(id) {
  try {
    const secureId = fire.functions("asia-east2").httpsCallable("secureId");
    const result = await secureId({ input: id });
    const secureid = result.data.message.trim();

    if (!secureid) return [];

    const db = fire.firestore();
    const parishioners = await db
      .collection("users")
      .where("identification", "==", secureid)
      .get();

    return parishioners.empty ? [] : parishioners.docs[0].data();
  } catch (err) {
    console.error("Error: ", err);
    return [];
  }
}

export async function getUsersBySubid(subid) {
  if (getSubIDDigits() === 2) {
    //find 2
    console.log("Searching for 2 digits only");
    return getUsersBy2Subid(subid.slice(-2));
  } else {
    //find 4
    return getUsersBy4Subid(subid);
  }
}

export async function getUsersBy4Subid(subid) {
  const list = [];
  if (subid === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("subid", "==", subid.toUpperCase().trim())
    .orderBy("fullname", "asc")
    .get();

  parishioners.forEach(doc => {
    list.push(doc.data());
  });
  return list;
}

export async function getUsersBy2Subid(subid) {
  const list = [];
  if (subid === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("subid", "==", subid.toUpperCase().trim())
    .orderBy("fullname", "asc")
    .get();
  console.log(parishioners.size);
  parishioners.forEach(doc => {
    list.push(doc.data());
  });
  console.log(list);
  return list;
}

export async function getUsersByMobile(mobile) {
  var list = [];
  if (mobile === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("mobile", "==", mobile.trim())
    .orderBy("fullname", "asc")
    .get();

  parishioners.forEach(doc => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserByEmail(email) {
  let emailtrim = email.toLowerCase().trim();
  if (!isValidEmail(emailtrim)) {
    return null;
  }
  //call admin cloud function here
  let foundemail = emailtrim;
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("email", "==", foundemail)
    .get();
  var list = [];
  parishioners.forEach(doc => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserMassBookings(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();

  const bookings = await db
    .collection("parishionerbookings")
    .where("parishionerid", "==", uid)
    .orderBy("massdate", "desc")
    .get();

  var list = [];
  bookings.forEach(doc => {
    let data = doc.data();
    if (data.hasOwnProperty("type") === false) {
      list.push(doc.data());
    }
  });
  return list;
}

export async function getUserBookingsByTimestamp(timestamp, uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();

  const bookings = await db
    .collection("parishionerbookings")
    .where("parishionerid", "==", uid)
    .where("massdate.seconds", "<=", timestamp)
    .orderBy("massdate.seconds", "desc")
    .get();

  const list = [];
  bookings.forEach(doc => {
    const data = doc.data();
    list.push(data);
  });
  return list;
}

export async function getUserBookings(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();

  const bookings = await db
    .collection("parishionerbookings")
    .where("parishionerid", "==", uid)
    .limit(60)
    .orderBy("massdate", "desc")
    .get();

  const list = [];
  bookings.forEach(doc => {
    const data = doc.data();
    list.push({ ...data, uniqKey: doc.id });
  });
  return list;
}

export async function getUserEventBookings(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();

  const bookings = await db
    .collection("eventhubbookings")
    .where("userId", "==", uid)
    .limit(60)
    .get();

  const list = [];
  bookings.forEach(doc => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserRoomBookings(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();

  const bookings = await db
    .collection("roombookings")
    .where("tenantId", "==", uid)
    .where("isCompleted", "==", true) // does not show draft bookings
    .limit(60)
    .get();

  // return bookings.docs;

  const list = [];
  bookings.forEach(doc => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserConfessionBookings(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();

  const bookings = await db
    .collection("parishionerbookings")
    .where("parishionerid", "==", uid)
    .where("type", "==", "penitential")
    .orderBy("massdate", "desc")
    .get();

  var list = [];
  bookings.forEach(doc => {
    list.push(doc.data());
  });
  return list;
}

export async function getUsersByDateRange(startDate, endDate) {
  const list = [];
  if (startDate === null || endDate === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();
  const parishioners = await db
    .collection("users")
    .where("created", ">=", startDate)
    .where("created", "<=", endDate)
    .get();

  parishioners.forEach(doc => {
    list.push(doc.data());
  });
  return list;
}

export async function getUsersBySubIDDateRange(startDate, endDate) {
  const list = [];
  if (startDate === null || endDate === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();
  const parishioners = await db
    .collection("users")
    .where("subid", "!=", null)
    .get();

  parishioners.forEach(doc => {
    list.push(doc.data());
  });

  return list;
}

export async function updateUserEmail(userId, email) {
  const db = fire.firestore();

  //1. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async transaction => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();

      transaction.update(userRef, {
        lastupdate: created,
        email,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateAccountIdentification(userId, id, secureid) {
  let subId = getLastDigitId(id);

  const db = fire.firestore();
  //1. update parishionerbookings
  const parishionerBookingRef = await db.collection("parishionerbookings");
  let result = await parishionerBookingRef
    .where("parishionerid", "==", userId)
    .get();
  //update identification and subid
  result.forEach(async doc => {
    await parishionerBookingRef
      .doc(doc.id)
      .update({ identification: secureid, subid: subId });
  });
  //3. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async transaction => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();

      transaction.update(userRef, {
        lastupdate: created,
        identification: secureid,
        subid: subId,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateParishDetail(userId, data) {
  const db = fire.firestore();
  console.log("Data: ", data);

  //1. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async transaction => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();
      const { parish } = data;

      transaction.update(userRef, {
        lastupdate: created,
        parish,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateBasicAccountDetails(userId, data) {
  const db = fire.firestore();
  console.log("Data: ", data);
  //1. update feedback
  const feedbackRef = await db.collection("feedback");
  let result = await feedbackRef.where("authorid", "==", userId).get();
  result.forEach(async doc => {
    console.log("Doc: ", doc);
    await feedbackRef.doc(doc.id).update({ author: data.fullname });
  });
  //2. update parishionerbookings
  const parishionerBookingRef = await db.collection("parishionerbookings");
  result = await parishionerBookingRef
    .where("parishionerid", "==", userId)
    .get();
  result.forEach(async doc => {
    console.log("Doc: ", doc);
    await parishionerBookingRef.doc(doc.id).update({ fullname: data.fullname });
  });
  //3. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async transaction => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();
      const { fullname, dob, mobile } = data;

      transaction.update(userRef, {
        lastupdate: created,
        fullname: fullname.trim(),
        dob: dob.trim(),
        mobile: mobile.trim(),
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateBasicUserDetailsByTransaction(userId, data) {
  const db = fire.firestore();
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async transaction => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }
      // const fbdoc = await transaction
      //   .where("authorid", "==", userId)
      //   .get(feedbackRef);
      // if (!fbdoc.exists) {
      //   console.log("authorid not found.");
      //   // throw "Document does not exist!";
      //   // return { status: 1, message: "User not found." };
      // }

      const created = new Date().getTime();
      const { fullname, dob, mobile } = data;
      //TODO: 1. update parishioner collection
      /*
      parishioner.fullname = fullname
      parishioner.parish = parish
      parishioner.parishcode
      */
      //TODO: 2. update feedback collection
      /*
     feedback.author = fullname
     */
      transaction.update(userRef, {
        lastupdate: created,
        fullname,
        dob,
        mobile,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateSubID(uid, subId) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({ subid: subId });
  }
}

export async function updateUserBallotStatus(uid, status = 0) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({ ballotstatus: status });
  }
}

export async function updateCatholicStatus(uid, isCatholic = false) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({ catholic: isCatholic });
  }
}

export async function updatePasswordResetStatus(uid, value = false) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({ hasresetpass: value });
  }
}
