/**
 * @type {[number, string][]}
 */
const sortedDistricts = [
  [4, "City"],
  [3, "Serangoon"],
  [0, "North"],
  [1, "East"],
  [2, "West"],
];

// /** @typedef {[number, number]} LatLng */

// /**
//  * @typedef Parish
//  * @prop {string} id
//  * @prop {number} parishId
//  * @prop {string} currentYear
//  * @prop {string} registrationYear
//  * @prop {string[]} timeslot
//  * @prop {string} notice
//  * @prop {string} parishFull
//  * @prop {string} parish
//  * @prop {string} parishShort
//  * @prop {boolean} isActive
//  * @prop {number} district
//  * @prop {string} districtName
//  * @prop {string} coordinatorEmail
//  * @prop {LatLng} location
//  */

// HARDCODED DUMMY DATA, DO NOT USE THIS ARRAY, QUERY FROM "settings/catchsettings/config" COLLECTION INSTEAD
// /**
//  * @type {Parish[]}
//  */
// const parishes = [
//   {
//     notice: "",
//     parishFull: "Church of St Ignatius",
//     id: "5Y3try3maMBOeGCMYaVo",
//     parish: "St Ignatius",
//     coordinatorEmail: "",
//     parishId: 23,
//     currentYear: "2023",
//     registrationYear: "2024",
//     level: 1,
//     district: 2,
//     timeslot: [],
//     isActive: false,
//     districtName: "West",
//     location: [1.32409, 103.8],
//     parishShort: "CSI",
//   },
//   {
//     currentYear: "2023",
//     registrationYear: "2024",
//     id: "6DSGRn9rLYzI7NkW4wHA",
//     notice: "",
//     timeslot: [],
//     district: 3,
//     districtName: "Serangoon",
//     isActive: false,
//     parish: "Immaculate Heart of Mary",
//     level: 1,
//     parishFull: "Church of the Immaculate Heart of Mary",
//     parishId: 9,
//     coordinatorEmail: "",
//     location: [1.35111, 103.905],
//     parishShort: "IHM",
//   },
//   {
//     parish: "St Mary of the Angels",
//     coordinatorEmail: "acheong.stmary@catholic.org.sg",
//     level: 1,
//     timeslot: ["Sat 4:00 pm", "Sun 9:00 am"],
//     parishId: 26,
//     id: "8rPN82vkFjtYvNGJV4WK",
//     currentYear: "2023",
//     registrationYear: "2024",
//     notice:
//       '<span class=\'text-danger\'><div class="alert alert-danger bold" role="alert">The registration window for St Mary of the Angels is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>St Mary of the Angels is receiving registrations for both <span className="font-weight-bold">P1 and P2</span>.<br>Please proceed to the next step if your child is in P1 or P2.</span>',
//     districtName: "West",
//     parishFull: "Church of St Mary of the Angels",
//     district: 2,
//     isActive: true,
//     location: [1.34506, 103.756],
//     parishShort: "SMOTA",
//   },
//   {
//     coordinatorEmail: "sppccdministry@gmail.com",
//     district: 4,
//     districtName: "City",
//     id: "A45iqZEgcOlqHFTrKhDI",
//     isActive: true,
//     level: 1,
//     notice: "",
//     parish: "Sts Peter and Paul",
//     parishFull: "Church of Sts Peter and Paul",
//     parishId: 31,
//     timeslot: ["Dummy Timeslot"],
//     currentYear: "2023",
//     registrationYear: "2024",
//     location: [1.26879, 103.859],
//     parishShort: "SPP",
//   },
//   {
//     level: 1,
//     parish: "Holy Family",
//     district: 1,
//     districtName: "East",
//     coordinatorEmail: "",
//     notice: "",
//     parishFull: "Church of the Holy Family",
//     timeslot: ["Sat 3:30 pm - 4:15 pm"],
//     parishId: 6,
//     isActive: false,
//     currentYear: "2023",
//     registrationYear: "2024",
//     id: "B8OaKXEVnvJd96EctvZR",
//     location: [1.30614, 103.931],
//     parishShort: "CHF",
//   },
//   {
//     parishId: 1,
//     parishFull: "Blessed Sacrament Church",
//     timeslot: ["Sat 4:00 pm - 5:30 pm"],
//     district: 2,
//     isActive: true,
//     districtName: "West",
//     level: 1,
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for Blessed Sacrament is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>',
//     coordinatorEmail: "bsccatechism@gmail.com",
//     parish: "Blessed Sacrament",
//     currentYear: "2023",
//     registrationYear: "2024",
//     id: "B8QwLHoE0qAbkZgrENB2",
//     location: [1.29988, 103.801],
//     parishShort: "BSC",
//   },
//   {
//     parishId: 22,
//     level: 1,
//     id: "CBOopeZfZro3Xm1sVtZW",
//     parish: "St Francis Xavier",
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for St Francis Xavier is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>',
//     isActive: true,
//     currentYear: "2023",
//     registrationYear: "2024",
//     parishFull: "Church of St Francis Xavier",
//     districtName: "Serangoon",
//     timeslot: ["Sun 3:00 pm - 4:30 pm"],
//     coordinatorEmail: "catechism@sfxchurch.sg",
//     district: 3,
//     location: [1.35763, 103.866],
//     parishShort: "SFX",
//   },
//   {
//     parishId: 18,
//     parishFull: "St Anne's Church",
//     timeslot: [],
//     level: 1,
//     coordinatorEmail: "",
//     id: "FFhJsRioEXsSWxTnXiZ7",
//     parish: "St Anne",
//     district: 3,
//     currentYear: "2023",
//     registrationYear: "2024",
//     isActive: false,
//     notice: "",
//     districtName: "Serangoon",
//     location: [1.38864, 103.899],
//     parishShort: "SAC",
//   },
//   {
//     parishId: 15,
//     district: 0,
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for Risen Christ is closed and at maximum capacity.<br />Registrations will be placed on a wait list and only accepted at the parish\'s discretion.</div>',
//     currentYear: "2023",
//     registrationYear: "2024",
//     parishFull: "Church of the Risen Christ",
//     parish: "Risen Christ",
//     level: 1,
//     id: "Fh7EnXpqHT92dUUdBqwy",
//     timeslot: ["Sat 2:00 pm - 3:30 pm", "Sun 2:00 pm - 3:30 pm"],
//     isActive: true,
//     districtName: "North",
//     coordinatorEmail: "catechists@risenchrist.org.sg",
//     location: [1.3406, 103.851],
//     parishShort: "CRC",
//   },
//   {
//     district: 3,
//     parish: "St Vincent de Paul",
//     notice:
//       "<span class='text-danger'>1. Make payment of $20 (admin and operational fee) via Paynow to :<ul><li>Payee Name : Church of St Vincent de Paul</li><li>UEN : T08CC4034E</li><li>Comments / Notes / Remarks / Reference for Recipient : state child's name and Level; eg Paul Tan L1</li></ul>2. Email PayNow screenshot to catechism@svdp.sg :<ul><li>Screenshot of Paynow transaction details (block out your personal bank details)</li></ul>Registration for St Vincent de Paul closes on 31 January 2023.</span>",
//     level: 1,
//     currentYear: "2023",
//     registrationYear: "2024",
//     parishId: 30,
//     id: "H4Nk9Lsdk2NrkrIgPJ7y",
//     isActive: true,
//     timeslot: ["Sat 11:15 am - 12:30 pm", "Sat 1:30 pm - 2:45 pm"],
//     districtName: "Serangoon",
//     parishFull: "Church of St Vincent de Paul",
//     coordinatorEmail: "catechism@svdp.sg",
//     location: [1.40572, 103.869],
//     parishShort: "SVDP",
//   },
//   {
//     level: 1,
//     parish: "Transfiguration",
//     timeslot: ["No fixed timeslots"],
//     parishFull: "Church of the Transfiguration",
//     notice: "",
//     coordinatorEmail: "",
//     parishId: 32,
//     district: 3,
//     districtName: "Serangoon",
//     isActive: false,
//     currentYear: "2023",
//     registrationYear: "2024",
//     id: "IwC2frLxKGcitN92cNlt",
//     location: [1.40819, 103.912],
//     parishShort: "COTT",
//   },
//   {
//     currentYear: "2023",
//     registrationYear: "2024",
//     parishId: 5,
//     notice: "",
//     coordinatorEmail: "",
//     level: 1,
//     id: "J7mrBCVZq9q13HnSJQ7T",
//     parish: "Holy Cross",
//     isActive: false,
//     districtName: "West",
//     district: 2,
//     parishFull: "Church of the Holy Cross",
//     timeslot: ["Sun 8:00 am - 9:15 am", "Sun 10:00 am - 11:15 am"],
//     location: [1.29094, 103.766],
//     parishShort: "CHC",
//   },
//   {
//     timeslot: ["Sun 9:00 am - 10:30 am"],
//     parish: "St Stephen",
//     level: 1,
//     districtName: "East",
//     coordinatorEmail: "",
//     parishId: 28,
//     id: "L6f0dZD1Ab53JmvepBFH",
//     isActive: false,
//     parishFull: "Church of St Stephen",
//     currentYear: "2023",
//     registrationYear: "2024",
//     district: 1,
//     notice: "",
//     location: [1.33108, 103.885],
//     parishShort: "CSS",
//   },
//   {
//     isActive: true,
//     timeslot: ["Sat 10:45 am - 12:15 pm", "Sat 1:45 pm - 3:15 pm"],
//     currentYear: "2023",
//     registrationYear: "2024",
//     id: "R5X5nq89rgzXN4IYbkMx",
//     parishId: 19,
//     coordinatorEmail: "catechistcoordinator@saint-anthony.org",
//     parish: "St Anthony",
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for St Anthony is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>',
//     parishFull: "Church of St Anthony",
//     districtName: "North",
//     level: 1,
//     district: 0,
//     location: [1.43682, 103.779],
//     parishShort: "CSA",
//   },
//   {
//     coordinatorEmail: "chs.catechetical@catholic.org.sg",
//     district: 0,
//     currentYear: "2023",
//     registrationYear: "2024",
//     id: "TH1kwQHNg5T6SbdNRrjQ",
//     level: 1,
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for Holy Spirit is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>',
//     districtName: "North",
//     timeslot: ["Sun 8:00 am - 9:15 am", "Sun 10:00 am - 11:15 am"],
//     parishId: 7,
//     parish: "Holy Spirit",
//     parishFull: "Church of the Holy Spirit",
//     isActive: true,
//     location: [1.37185, 103.808],
//     parishShort: "CHS",
//   },
//   {
//     parishId: 24,
//     parishFull: "St Joseph's Church (Bukit Timah)",
//     parish: "St Joseph (Bukit Timah)",
//     level: 1,
//     timeslot: ["Sat 3:15 pm - 4:45 pm"],
//     id: "UwRvdurQ4d01zsvF1JiM",
//     coordinatorEmail: "primarycatechesis@stjoseph-bt.org.sg",
//     currentYear: "2023",
//     registrationYear: "2024",
//     districtName: "North",
//     isActive: true,
//     district: 0,
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for St Joseph (Bukit Timah) is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>',
//     location: [1.38478, 103.759],
//     parishShort: "SJC BT",
//   },
//   {
//     district: 4,
//     notice:
//       '<span class=\'text-danger\'><div class="alert alert-danger bold" role="alert">The registration window for St Bernadette is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>Our Foundation Year class for children born in 2017 is held on Sundays, from 8:15am to 9:30am.</span>',
//     coordinatorEmail: "catherinegasper@yahoo.com.sg",
//     isActive: true,
//     currentYear: "2023",
//     registrationYear: "2024",
//     parishFull: "Church of St Bernadette",
//     timeslot: ["Sun 8:15 am - 9:30 am"],
//     level: 1,
//     parish: "St Bernadette",
//     parishId: 20,
//     id: "VTNcKcD8DeA9ve91GhIp",
//     districtName: "City",
//     location: [1.29103, 103.827],
//     parishShort: "CSB",
//   },
//   {
//     coordinatorEmail: "",
//     parishFull: "Church of St Francis of Assisi",
//     level: 1,
//     districtName: "West",
//     parishId: 21,
//     parish: "St Francis of Assisi",
//     id: "Y1o8kCY4elmvy9A50XNz",
//     currentYear: "2023",
//     registrationYear: "2024",
//     isActive: false,
//     timeslot: [],
//     notice: "",
//     district: 2,
//     location: [1.32403, 103.676],
//     parishShort: "CSFA",
//   },
//   {
//     timeslot: [],
//     parish: "St Michael",
//     notice: "",
//     parishFull: "Church of St Michael",
//     coordinatorEmail: "",
//     districtName: "City",
//     district: 4,
//     level: 1,
//     currentYear: "2023",
//     registrationYear: "2024",
//     id: "Z1qcvgIQA2b3FscJz1Ws",
//     parishId: 27,
//     isActive: false,
//     location: [1.32529, 103.864],
//     parishShort: "CSM",
//   },
//   {
//     district: 1,
//     parish: "Our Lady Queen of Peace",
//     id: "d5Gv8FsDVSUcqzi4kGb0",
//     isActive: false,
//     level: 1,
//     coordinatorEmail: "",
//     parishId: 13,
//     notice: "",
//     currentYear: "2023",
//     registrationYear: "2024",
//     timeslot: [],
//     parishFull: "Church of Our Lady Queen of Peace",
//     districtName: "East",
//     location: [1.30037, 103.886],
//     parishShort: "QOP",
//   },
//   {
//     isActive: false,
//     districtName: "City",
//     parish: "Sacred Heart",
//     notice: "",
//     level: 1,
//     id: "fCi4RKFUBjk8rAgTjkXc",
//     district: 4,
//     parishFull: "Church of the Sacred Heart",
//     coordinatorEmail: "",
//     timeslot: [],
//     currentYear: "2023",
//     registrationYear: "2024",
//     parishId: 16,
//     location: [1.31059, 103.832],
//     parishShort: "CSH",
//   },
//   {
//     id: "lDGaJ6LtUl8UKySmUqT2",
//     notice: "",
//     district: 1,
//     parishFull: "Church of Our Lady of Perpetual Succour",
//     parish: "Our Lady of Perpetual Succour",
//     currentYear: "2023",
//     registrationYear: "2024",
//     districtName: "East",
//     isActive: false,
//     level: 1,
//     coordinatorEmail: "",
//     timeslot: [],
//     parishId: 12,
//     location: [1.33001, 103.925],
//     parishShort: "OLPS",
//   },
//   {
//     parish: "Divine Mercy",
//     parishId: 4,
//     id: "lEiK8W1mbp2tO1okQ3xL",
//     level: 1,
//     currentYear: "2023",
//     registrationYear: "2024",
//     timeslot: ["Sat 4:15 pm - 5:15 pm", "Sun 10:15 am - 11:15 am"],
//     parishFull: "Church of the Divine Mercy",
//     isActive: true,
//     coordinatorEmail: "catechism@divinemercy.sg",
//     districtName: "East",
//     district: 1,
//     notice:
//       "<span class='text-danger'>For those aged 9 and above transferring in from another Parish, please register separately by printing and submitting the registration form located at <a href='https://drive.google.com/file/d/19UuM8npAUgeT5EtT6YdqHzSy7-PBtvma/'>this link</a>.</span>",
//     location: [1.37959, 104.013],
//     parishShort: "CDM",
//   },
//   {
//     level: 1,
//     parishFull: "Church of the Nativity of the Blessed Virgin Mary",
//     parish: "Nativity of the BVM",
//     notice: "",
//     id: "q32gevCIhJ5759Z59zdX",
//     district: 3,
//     coordinatorEmail: "",
//     timeslot: [],
//     districtName: "Serangoon",
//     isActive: false,
//     parishId: 11,
//     currentYear: "2023",
//     registrationYear: "2024",
//     location: [1.37221, 103.895],
//     parishShort: "BVM",
//   },
//   {
//     timeslot: [],
//     id: "sfUJSuLHPcIxGHakvGrd",
//     currentYear: "2023",
//     registrationYear: "2024",
//     level: 1,
//     notice: "",
//     isActive: false,
//     parish: "Holy Trinity",
//     district: 1,
//     districtName: "East",
//     parishId: 8,
//     coordinatorEmail: "",
//     parishFull: "Church of the Holy Trinity",
//     location: [1.34552, 103.955],
//     parishShort: "CHT",
//   },
//   {
//     parishFull: "Church of Our Lady of Lourdes",
//     parishId: 10,
//     currentYear: "2023",
//     registrationYear: "2024",
//     districtName: "City",
//     coordinatorEmail: "ololsec@gmail.com",
//     level: 1,
//     notice:
//       '<span class="text-danger">The decision for online or face-to-face sessions is currently pending confirmation by the Catechetical ministry. If you submit an application for Our Lady of Lourdes, you will be contacted once this decision is confirmed.<br/><br/>Registration for Our Lady of Lourdes closes on 31 January 2023.</span>',
//     timeslot: ["Sun 4:30 pm - 5:45 pm"],
//     parish: "Our Lady of Lourdes",
//     id: "y72DzAemp55Okr4hO3Rh",
//     district: 4,
//     isActive: true,
//     location: [1.30341, 103.856],
//     parishShort: "COLOL",
//   },
//   {
//     parishFull: "Church of Christ the King",
//     level: 1,
//     isActive: true,
//     parishId: 3,
//     coordinatorEmail: "ctk.catechists@gmail.com",
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for Christ the King is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>',
//     district: 0,
//     currentYear: "2023",
//     registrationYear: "2024",
//     id: "yFGtlDuRQdZXdJasLjcg",
//     timeslot: ["Sun 2:00 pm - 3:30 pm"],
//     districtName: "North",
//     parish: "Christ the King",
//     location: [1.37165, 103.847],
//     parishShort: "CTK",
//   },
//   {
//     coordinatorEmail: "catechetical.olss@gmail.com",
//     parish: "Our Lady Star of the Sea",
//     parishId: 14,
//     currentYear: "2023",
//     registrationYear: "2024",
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for Our Lady Star of the Sea is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div>',
//     parishFull: "Church of Our Lady Star of the Sea",
//     timeslot: ["Sat 10:00 am - 11:30 am"],
//     district: 0,
//     level: 1,
//     id: "yoaGTHWRxxNe5ZwBpKVS",
//     districtName: "North",
//     isActive: true,
//     location: [1.43939, 103.83],
//     parishShort: "OLSS",
//   },
//   {
//     isActive: true,
//     district: 4,
//     parishId: 29,
//     coordinatorEmail: "stc.catechist@gmail.com",
//     currentYear: "2023",
//     registrationYear: "2024",
//     districtName: "City",
//     notice:
//       '<div class="alert alert-danger bold" role="alert">The registration window for St Teresa is closed.<br />Registrations are now only accepted at the parish\'s discretion.</div><span class=\'text-danger\'>Foundation 3 to Level 2: Sessions are currently still on Zoom on Sunday from 9.30 am to 11 am. The Catechist of the level will provide the Zoom link.<br/><br/>Level 4 to Level 6: Sessions are currently still on Zoom on Sunday from 11 am to 12.30 pm. The Catechist of the level will provide the Zoom link.<br/><br/>Level 7 to Level 10: Face-to-face sessions have resumed and will be on Saturdays from 3.30 pm to 5 pm at the retreat center.<br/><br/></span>',
//     level: 1,
//     parish: "St Teresa",
//     id: "zHuwxKr0ctyPqJM4NKDm",
//     timeslot: ["Sun 9:30 am - 11:00 am"],
//     parishFull: "Church of St Teresa",
//     location: [1.21852, 103.782],
//     parishShort: "STC",
//   },
// ];

/**
 * Computes the distances between 2 Parishes. Commutative (order does not matter).
 * @param {import("../hooks/hooks").ParishConfig} parish1 Config props of first Parish.
 * @param {import("../hooks/hooks").ParishConfig} parish2 Config props of second Parish.
 * @return `-1` if either Parish is not valid, the distance between the 2 Parishes otherwise.
 */
function computeParishDistance(parish1, parish2, scale = 1) {
  if (!parish1 || !parish2) return -1;

  const { latitude: lat1, longitude: lon1 } = parish1.location;
  const { latitude: lat2, longitude: lon2 } = parish2.location;

  const φ1 = (lat1 * Math.PI) / 180,
    φ2 = (lat2 * Math.PI) / 180,
    Δλ = ((lon2 - lon1) * Math.PI) / 180,
    R = 6371e3;
  return (
    Math.acos(
      Math.sin(φ1) * Math.sin(φ2) + Math.cos(φ1) * Math.cos(φ2) * Math.cos(Δλ)
    ) *
    R *
    scale
  );
}

export { sortedDistricts, computeParishDistance };
