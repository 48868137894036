import React, { useRef, useState } from "react";
import "./stylesheets/scroll.css";

function SwipableButtonFilter({ entries, selected, selectHandler }) {
  const css = `ml-1 my-1 px-3 text-nowrap ovalcornernocolor btn btn-sm mr-2 `;
  const scrollContainer = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    scrollContainer.current.scrollLeft += startX - e.clientX;
    setStartX(e.clientX);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      ref={scrollContainer}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      className="hidescroll d-flex justify-content-start"
      style={{ overflowX: "auto" }}
    >
      {entries.map((i, index) => (
        <div key={index}>
          <button
            className={
              selected === i
                ? `${css} btn-primary `
                : `${css} alert alert-secondary py-1 `
            }
            onClick={() => selectHandler(i)}
          >
            {i}
          </button>
        </div>
      ))}
    </div>
  );
}

export default SwipableButtonFilter;
