import React, { Component } from "react";
import LoadingSpinner from "../../common/loadingSpinner";
import { getCurrentUserData, userLogout } from "../../../services/user";
import ParishionerDetails from "../component/parishionerDetails";
import ArchdioceseLogo from "../../blocks/archdioceseLogoBlock";
import DemoBar from "../../common/demoBar";
import ReloadModal from "../../common/reloadModal";

class AccountSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      data: {
        fullname: "",
        identification: "",
        email: "",
        dob: "",
        mobile: "+65",
        catholic: "",
      },
      errors: {},
      parish: [],
      loading: true,
    };
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        userid,
        created,
        fullname,
        email,
        mobile,
        dob,
        parish,
        catholic,
      } = user;
      //Comment this if you want to allow resetup of parish
      if (parish) {
        this.props.history.push("/home");
        return;
      }

      this.setState({
        data: {
          userid,
          created,
          fullname,
          email,
          mobile,
          dob,
          identification: "",
          parish,
          catholic: catholic,
        },
        loading: false,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  render() {
    const { data, loading } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }
    return (
      <React.Fragment>
        <DemoBar />
        <ReloadModal />
        <ArchdioceseLogo />

        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-3">
                <h1 className="pb-2">Setup your profile</h1>
                <ParishionerDetails userdetails={data} />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountSetup;
