import React from "react";

const Select = ({ name, label, options, error, init = "", ...rest }) => {
  const initValue = init?.trim();
  let formClass =
    "form-control form-control-lg custom-select custom-select-lg ";
  if (error) {
    formClass = `${formClass} is-invalid`;
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name} {...rest} className={formClass}>
        {initValue?.length === 0 ? (
          <option value="" />
        ) : (
          <option value={initValue}>{initValue}</option>
        )}
        {options.map((option) => (
          <option
            key={option._id}
            value={option._id}
            disabled={option.disabled}
          >
            {option.name}
          </option>
        ))}
      </select>
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default Select;
